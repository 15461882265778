export enum ModalType {
  connectWallet = 'CONNECT_WALLET',
  claimRewards = 'CLAIM_REWARDS',
  addSubscription = 'ADD_SUBSCRIPTION',
  withdraw = 'WITHDRAW',
  transfer = 'TRANSFER',
  flashbotsPending = 'FLASHBOTS_PENDING',
  flashbotsConfirmed = 'FLASHBOTS_CONFIRMED',
  txnError = 'TRANSACTION_ERROR',
  rewardProgramInfo = 'REWARD_PROGRAM_INFO',
  mint = 'MINT',
  mintSuccess = 'MINT_SUCCESS',
  feedback = 'FEEDBACK',
  strategies = 'STRATEGIES',
  vote = 'VOTE',
  selectProgram = 'SELECT_PROGRAM',
  rageQuit = 'RAGE_QUIT',
  selectToken = 'SELECT_TOKEN',
  leaderboardRewards = 'LEADERBOARD_REWARDS',
  removeVaultFactory = 'REMOVE_VAULT_FACTORY',
}
