import { Provider } from '@ethersproject/providers';
import { BigNumber, ethers } from 'ethers';
import { crucibleFactoryAbi } from 'abi/crucibleFactoryAbi';
import {
  Crucible,
  CrucibleLock,
  CrucibleLockObject,
  LockData,
  RewardProgram
} from 'types';
import { crucibleAbi } from 'abi/crucibleAbi';
import { formatTokenSymbol } from 'helpers/formatTokenSymbol';
import { range } from 'lodash';
import {
  getCrucibleIdsFromEvents,
  getCrucibleMembership
} from 'utils/crucible';
import { OWNED_CRUCIBLES } from 'helpers/queries';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import { SortBy } from 'store/zustand/useExploreFilter';
import { getTokenIcon } from 'api/icons';

export async function getOwnedCrucibles(
  crucibleFactoryAddress: string,
  crucibleFactoryCreationBlock: number,
  crucibleSubgraphUrl: string,
  customMinterAddress: string,
  transmuterAddress: string,
  mintingTokenAddress: string,
  provider: Provider,
  account: string
): Promise<Crucible[]> {
  const { chainId } = await provider.getNetwork();

  // TODO: Rework with pagination for RPC providers that limit query to 1000 blocks, or find alternative method.
  // if (chainId === 1) {
  //   const address = account;
  //   const crucibleFactory = new ethers.Contract(
  //     crucibleFactoryAddress,
  //     crucibleFactoryAbi,
  //     provider
  //   );

  //   const filter = crucibleFactory.filters.Transfer(null, address);
  //   const crucibleEvents = await crucibleFactory.queryFilter(
  //     filter,
  //     crucibleFactoryCreationBlock,
  //     'latest'
  //   );

  //   const ids = getCrucibleIdsFromEvents(crucibleEvents);

  //   const crucibles = await Promise.all(
  //     ids.map(async ({ id, event }) => {
  //       const owner = await crucibleFactory.ownerOf(id);
  //       let timestamp = 0;
  //       let blockNumber = 0;
  //       let membership;

  //       if (owner === address) {
  //         const filter = crucibleFactory.filters.Transfer(
  //           '0x0000000000000000000000000000000000000000',
  //           null,
  //           id
  //         );
  //         const crucibleEvents = await crucibleFactory.queryFilter(
  //           filter,
  //           crucibleFactoryCreationBlock,
  //           'latest'
  //         );

  //         const block = await provider.getBlock(crucibleEvents[0].blockNumber);
  //         blockNumber = block.number;
  //         timestamp = block.timestamp;

  //         membership = await getCrucibleMembership(
  //           id,
  //           customMinterAddress,
  //           transmuterAddress,
  //           mintingTokenAddress,
  //           crucibleFactory,
  //           crucibleFactoryCreationBlock,
  //           provider
  //         );
  //       }

  //       return {
  //         id,
  //         owner,
  //         mintTimestamp: timestamp,
  //         mintBlockNumber: blockNumber,
  //         membership
  //       } as Crucible;
  //     })
  //   );
  //   return crucibles
  //     .filter((crucible) => {
  //       return crucible.owner === address;
  //     })
  //     .sort((a, b) => (b.mintBlockNumber < a.mintBlockNumber ? -1 : 1));
  // } else {
  const crucibleFactory = new ethers.Contract(
    crucibleFactoryAddress,
    crucibleFactoryAbi,
    provider
  );

  const response = await fetch(crucibleSubgraphUrl, {
    method: 'POST',
    body: JSON.stringify({
      query: OWNED_CRUCIBLES,
      variables: {
        account
      }
    }),
    headers: {
      'content-type': 'application/json'
    }
  });

  const { data } = (await response.json()) as {
    data: {
      crucibleEntities: {
        id: string;
        timestamp: string;
        blockNumber: number;
      }[];
    };
  };

  const crucibles = await Promise.all(
    data.crucibleEntities.map(async ({ id, timestamp, blockNumber }) => {
      const membership = await getCrucibleMembership(
        id,
        customMinterAddress,
        transmuterAddress,
        mintingTokenAddress,
        crucibleFactory,
        crucibleFactoryCreationBlock,
        provider,
        blockNumber
      );

      return {
        id,
        owner: account,
        mintTimestamp: Number(timestamp),
        mintBlockNumber: blockNumber,
        membership
      } as Crucible;
    })
  );

  return crucibles.sort((a, b) =>
    b.mintBlockNumber < a.mintBlockNumber ? -1 : 1
  );
  // }
}

export async function getAllCrucibles(
  crucibleFactoryAddress: string,
  provider: Provider,
  pageParams: number,
  sortBy: SortBy,
  shiftBy: number
) {
  const crucibleFactory = new ethers.Contract(
    crucibleFactoryAddress,
    crucibleFactoryAbi,
    provider
  );

  const reverse = sortBy === SortBy.DESC;

  const totalCountBN = await crucibleFactory.instanceCount();
  const totalCount = totalCountBN.toNumber() - shiftBy;
  const maxIndex = totalCount - 1;

  const quantity = 12;
  const startIndex = Math.min((pageParams - 1) * quantity, maxIndex + 1);
  const nextIndex = startIndex + quantity;
  const endIndex = Math.min(nextIndex, maxIndex + 1);

  const crucibles = await Promise.all(
    range(startIndex, endIndex).map(async (idx) => {
      const position = reverse ? maxIndex - idx + shiftBy : idx + shiftBy;
      const crucibleAddress = await crucibleFactory.instanceAt(position);

      return { crucibleAddress, idx: position };
    })
  );

  return {
    crucibles,
    totalCount
  };
}

export async function getLocksForAllCrucibles(
  chainId: number,
  provider: Provider,
  crucibles: Crucible[],
  rewardPrograms: RewardProgram[],
  currentRewardProgram: RewardProgram
) {
  const locksForAllCrucibles: CrucibleLockObject = {};
  const rewardProgramsOnNetwork = rewardPrograms;

  await Promise.all(
    crucibles.map(async (crucible) => {
      const locksForCrucible = await getCrucibleLocks(
        crucible.id,
        provider,
        rewardProgramsOnNetwork,
        currentRewardProgram
      );

      locksForAllCrucibles[crucible.id] = locksForCrucible;
    })
  );

  return locksForAllCrucibles;
}

export async function getLocksForSingleCrucible(
  chainId: number,
  provider: Provider,
  rewardPrograms: RewardProgram[],
  currentRewardProgram: RewardProgram,
  crucibleAddress: string
) {
  const rewardProgramsOnNetwork = rewardPrograms;

  const locksForCrucible = await getCrucibleLocks(
    crucibleAddress,
    provider,
    rewardProgramsOnNetwork,
    currentRewardProgram
  );

  return locksForCrucible;
}

export const getCrucibleLocks = async (
  crucibleAddress: string,
  provider: Provider,
  rewardPrograms: RewardProgram[],
  currentRewardProgram: RewardProgram
) => {
  const { chainId } = await provider.getNetwork();
  try {
    const crucibleContract = new ethers.Contract(
      crucibleAddress,
      crucibleAbi,
      provider
    );

    const lockSetCount: BigNumber = await crucibleContract.getLockSetCount();

    const locks: CrucibleLock[] | undefined = (await Promise.all(
      Array.from(Array(lockSetCount.toNumber()).keys()).map(async (_, idx) => {
        const lockData: LockData = await crucibleContract.getLockAt(idx);
        if (
          rewardPrograms.find(
            (p) => p.address.toLowerCase() === lockData.delegate.toLowerCase()
          )
        ) {
          const tokenContract = new ethers.Contract(
            lockData.token,
            IUniswapV2ERC20.abi,
            provider
          );

          const currTokenSymbol = await tokenContract.symbol();

          const tokenSymbol = await formatTokenSymbol(
            lockData.token,
            currTokenSymbol,
            provider
          );

          const decimals = await tokenContract.decimals();

          const rewardContract = new ethers.Contract(
            lockData.delegate,
            currentRewardProgram.abi,
            provider
          );

          const vaultData = await rewardContract.getVaultData(crucibleAddress);

          const imageUrl = await getTokenIcon(lockData.token, chainId);

          return {
            crucibleAddress,
            rewardProgramAddress: lockData.delegate,
            subscribedToken: {
              value: { amount: lockData.balance },
              address: lockData.token,
              symbol: tokenSymbol,
              decimals,
              imageUrl
            },
            subscriptionCount: vaultData.stakes.length
          };
        }
      })
    )) as CrucibleLock[];

    const filteredLocks = locks.filter((lock) => !!lock);

    return filteredLocks;
  } catch (err) {
    throw err;
  }
};
