import { FC, useState } from 'react';
import { HappyIcon, NeutralIcon, SadIcon } from 'components/icons';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import { useModal } from 'store/modals';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  ModalFooter,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';
import { useWeb3React } from 'hooks/web3';
import { convertConnectorTypeToWalletName } from 'utils/convertConnectorTypeToWalletName';
import { getBrowser } from 'helpers/browser';
import { getDevice } from 'helpers/device';
import { ModalType } from './types';

const iconContainerProps = {
  color: 'gray.500',
  _hover: { cursor: 'pointer' }
};

type Reaction = 'happy' | 'neutral' | 'sad';

const reactionToDiscord: { [key in Reaction]: string } = {
  happy: 'blush',
  neutral: 'neutral_face',
  sad: 'frowning2'
};

const FeedbackModal: FC = () => {
  const { closeModal, isOpen } = useModal();
  const [reaction, setReaction] = useState<Reaction>();
  const [username, setUsername] = useState('');
  const [comments, setComments] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { account, connector } = useWeb3React();
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      if (!reaction) {
        throw new Error('Please select a reaction');
      }

      const discordReaction = reactionToDiscord[reaction];
      const connectorName = convertConnectorTypeToWalletName(connector);
      const browser = getBrowser();
      const device = getDevice();

      const response = await fetch(
        '/api/post-feedback?' +
          new URLSearchParams({
            reaction: discordReaction,
            username,
            comments,
            address: account || '',
            wallet: connectorName || '',
            device,
            browser
          })
      );

      if (!response.ok) {
        throw new Error('Something went wrong');
      }

      setSubmitted(true);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen(ModalType.feedback)}
      onClose={() => closeModal(ModalType.feedback)}
      size='sm'
    >
      <ModalOverlay />
      <ModalContent borderRadius='xl' bg='gray.800'>
        <ModalHeader>
          <Text>Feedback</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {submitted ? (
            <Text color='gray.300'>
              Your feedback has been submitted successfully. An Alchemist will
              reach out to you if necessary.
            </Text>
          ) : (
            <>
              <Stack direction='row' justifyContent='center' spacing={8} mb={6}>
                <Box
                  {...iconContainerProps}
                  onClick={() => setReaction('happy')}
                  color={reaction === 'happy' ? 'yellow.400' : 'gray.400'}
                  _hover={{
                    color: 'yellow.400',
                    cusor: 'pointer'
                  }}
                >
                  <HappyIcon />
                </Box>
                <Box
                  {...iconContainerProps}
                  onClick={() => setReaction('neutral')}
                  _hover={{
                    color: 'yellow.400',
                    cusor: 'pointer'
                  }}
                  color={reaction === 'neutral' ? 'yellow.400' : 'gray.400'}
                >
                  <NeutralIcon />
                </Box>
                <Box
                  {...iconContainerProps}
                  onClick={() => setReaction('sad')}
                  color={reaction === 'sad' ? 'yellow.400' : 'gray.400'}
                  _hover={{
                    color: 'yellow.400',
                    cusor: 'pointer'
                  }}
                >
                  <SadIcon />
                </Box>
              </Stack>
              <Input
                placeholder='Discord username/email'
                mb={4}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Textarea
                placeholder='Comments'
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
              {error && (
                <Alert status='error' mt={4} borderRadius='xl'>
                  <AlertIcon />
                  {error}
                </Alert>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter mb={2}>
          {!submitted ? (
            <Button isFullWidth onClick={handleSubmit} isLoading={submitting}>
              Submit
            </Button>
          ) : (
            <Button isFullWidth onClick={() => closeModal(ModalType.feedback)}>
              Close
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
