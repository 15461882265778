import { useWeb3React } from 'hooks/web3';
import { queryClient } from 'index';
import { useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Queries } from 'types/enum';

export function useBlockNumber() {
  const { chainId = 1, provider, account } = useWeb3React();

  const onNewBlock = useCallback(
    (blockNumber: number) => {
      queryClient.setQueryData(
        [Queries.BLOCK_NUMBER, chainId],
        () => blockNumber
      );
    },
    [chainId]
  );

  useEffect(() => {
    provider.on('block', onNewBlock);
    return () => {
      provider.off('block', onNewBlock);
    };
  }, [provider, onNewBlock]);

  return useQuery<number, Error>(
    [Queries.BLOCK_NUMBER, chainId],
    () => provider.getBlockNumber(),
    {
      enabled: !!account
    }
  );
}
