import { useWeb3React } from 'hooks/web3';
import { fetchSubscriptions } from 'api/blockchain/subscriptions';
import { queryClient } from 'index';
import { useQuery } from 'react-query';
import { useConnection } from 'store/connection';
import { useRewardPrograms } from 'store/zustand/useRewardPrograms';
import { useSelectedCrucible } from 'store/selectedCrucible';
import { Subscription, SubscriptionsMeta, SubscriptionsSummary } from 'types';
import { Queries } from 'types/enum';

export function useSubscriptions() {
  const { tried } = useConnection();
  const { chainId = 1, provider } = useWeb3React();
  const { currentRewardProgram } = useRewardPrograms();
  const { selectedCrucible } = useSelectedCrucible();

  const crucibleId = selectedCrucible?.id;
  const currentRewardProgramAddress = currentRewardProgram.address;

  const lpData = queryClient.getQueryData([Queries.LP_DATA, chainId]);
  const usdValues = queryClient.getQueryData([Queries.USD_VALUES, chainId]);

  return useQuery<
    {
      subscriptions: Subscription[];
      subscriptionsSummary: SubscriptionsSummary;
      subscriptionsMeta: SubscriptionsMeta;
    },
    Error
  >(
    /* 
      chainId excluded from query as network changes cause the query 
      to be refetched before current reward program is updated
    */
    [Queries.SUBSCRIPTIONS, crucibleId, currentRewardProgramAddress],
    () =>
      fetchSubscriptions(chainId, crucibleId!, provider, currentRewardProgram),
    {
      enabled:
        tried &&
        !!currentRewardProgramAddress &&
        !!selectedCrucible &&
        !!lpData &&
        !!usdValues
    }
  );
}
