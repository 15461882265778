import { useWeb3React } from 'hooks/web3';
import { fetchBlockTimestamp } from 'api/blockchain/blockTimestamp';
import { useQuery } from 'react-query';
import { Queries } from 'types/enum';

export function useBlockTimestamp() {
  const { chainId = 1 } = useWeb3React();
  return useQuery<number, Error>(
    [Queries.BLOCK_TIMESTAMP, chainId],
    () => fetchBlockTimestamp(chainId),
    {
      refetchInterval: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10
    }
  );
}
