import { FC, useEffect, useMemo } from 'react';
import { shuffleAddress, truncate } from 'utils/address';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { getAddress } from 'ethers/lib/utils';

import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useToast
} from '@chakra-ui/react';
import { Crucible, CrucibleLock } from 'types';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiCopy, FiSend } from 'react-icons/fi';
import { useClipboard } from '@chakra-ui/react';
import { useModal } from 'store/modals';
import { ModalType } from 'components/modals/types';
import { useCruciblesLocks } from 'hooks/query/useCrucibles';
import { FaTwitter } from 'react-icons/fa';
import formatNumber from 'utils/formatNumber';
import MintBall from 'components/minting/MintBall';
import { GiUpgrade } from 'react-icons/gi';
import { MintModalProps } from 'components/modals/MintModal';
import { useWeb3React } from 'hooks/web3';
import { EtherscanLinkType, getEtherscanLink } from 'utils/getEtherscanLink';
import { RiExternalLinkLine } from 'react-icons/ri';

type Props = {
  crucible: Crucible;
  isSelected: boolean;
};

const CrucibleCard: FC<Props> = ({ crucible, isSelected }) => {
  const { chainId = 1 } = useWeb3React();
  const crucibleId = getAddress(crucible.id);
  const history = useHistory();

  let { hasCopied, onCopy } = useClipboard(crucibleId);

  const { data: crucibleLocks, status } = useCruciblesLocks();
  const { openModal } = useModal();
  const toast = useToast();

  const crucibleSubscriptionLength = useMemo(() => {
    if (
      crucible &&
      crucible.id &&
      crucibleLocks &&
      crucibleLocks[crucible.id]
    ) {
      let sum = 0;
      crucibleLocks[crucible.id].forEach(
        (lock: CrucibleLock) => (sum += lock.subscriptionCount)
      );
      return sum;
    }
  }, [crucibleLocks, crucible]);

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: 'Copied crucible address',
        status: 'info',
        duration: 4000,
        isClosable: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied]);

  return (
    <Box
      pl={5}
      width='100%'
      borderRightColor={isSelected ? 'cyan.400' : 'gray.800'}
      borderRightWidth='2px'
      onClick={() => {
        history.push(`/crucible/${crucible.id}`);
      }}
      _hover={{
        cursor: 'pointer',
        borderRightWidth: '2px',
        borderRightColor: isSelected ? 'cyan.500' : 'gray.600'
      }}
    >
      <Flex alignItems='center'>
        <MintBall
          type={crucible.membership}
          mr={4}
          height='36px'
          width='36px'
          borderRadius='md'
        >
          <Flex alignItems='center' justifyContent='center' height='36px'>
            {status === 'idle' || status === 'loading' ? (
              <Spinner size='sm' />
            ) : (
              <Text fontWeight='500' fontSize='sm'>
                {crucibleSubscriptionLength}
              </Text>
            )}
          </Flex>
        </MintBall>
        <Box textAlign='left' flexGrow={1}>
          <Text fontWeight='500'>{truncate(crucibleId)}</Text>
          <Text fontSize='sm' color='gray.400'>
            {`Minted ${formatNumber.date(crucible.mintTimestamp * 1000)}`}
          </Text>
        </Box>
        <Menu placement='bottom-start'>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<BsThreeDotsVertical fontSize='18px' />}
            color='gray.400'
            variant='ghost'
            onClick={(e) => e.stopPropagation()}
            _hover={{
              bg: 'none',
              color: 'white'
            }}
            _active={{
              bg: 'none',
              color: 'white'
            }}
          />
          <MenuList zIndex={4}>
            {crucible.membership === 'basic' && (
              <MenuItem
                icon={<GiUpgrade />}
                onClick={(e) => {
                  e.stopPropagation();
                  openModal(ModalType.mint, {
                    type: 'pro',
                    upgrade: {
                      crucibleAddress: crucible.id
                    }
                  } as MintModalProps);
                }}
              >
                Upgrade Crucible
              </MenuItem>
            )}
            <MenuItem
              icon={<FiCopy />}
              onClick={(e) => {
                e.stopPropagation();
                onCopy();
              }}
            >
              Copy Address
            </MenuItem>
            <MenuItem
              icon={<FiSend />}
              onClick={(e) => {
                e.stopPropagation();
                openModal(ModalType.transfer, { crucibleId: crucible.id });
              }}
            >
              Transfer
            </MenuItem>
            <MenuItem
              icon={<RiExternalLinkLine />}
              onClick={() =>
                window.open(
                  getEtherscanLink(
                    chainId,
                    crucibleId,
                    EtherscanLinkType.ADDRESS
                  ),
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              View on Blockchain
            </MenuItem>
            <MenuItem
              icon={<FaTwitter />}
              onClick={() =>
                window.open(
                  chainId === 1
                    ? `https://twitter.com/intent/tweet?text=${window.location.origin}/explore/${crucibleId}`
                    : `https://twitter.com/intent/tweet?text=${window.location.origin}/explore/${crucibleId}?network=${chainId}`,
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              Share (Full Detail)
            </MenuItem>
            {crucible.membership !== 'basic' && (
              <MenuItem
                icon={<FaTwitter />}
                onClick={() =>
                  chainId === 1
                    ? window.open(
                        `https://twitter.com/intent/tweet?text=${encodeURI(
                          `${window.location.origin}/nft-scene/${shuffleAddress(
                            crucibleId,
                            'crucible'
                          )}`
                        )}`,
                        '_blank',
                        'noopener,noreferrer'
                      )
                    : window.open(
                        `https://twitter.com/intent/tweet?text=${encodeURI(
                          `${window.location.origin}/nft-scene/${shuffleAddress(
                            crucibleId,
                            'crucible'
                          )}&network=${chainId}`
                        )}`,
                        '_blank',
                        'noopener,noreferrer'
                      )
                }
              >
                Share (Artwork Only)
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

export default CrucibleCard;
