import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import { BigNumber } from '@ethersproject/bignumber';
import { formatTokenSymbol } from 'helpers/formatTokenSymbol';
import { ethers } from 'ethers';
import { Provider } from '@ethersproject/providers';
import { ERC20Token } from 'types';
import { crucibleAbi } from 'abi/crucibleAbi';
import {
  convertChainIdToIconPath,
  convertChainIdToNativeTokenSymbol
} from 'utils/convertChainIdToNetworkName';
import { getTokenIcon } from 'api/icons';

interface TransactionData {
  status: string;
  message: string;
  result: Transaction[];
}

interface Transaction {
  blockHash: string;
  blockNumber: string;
  confirmations: string;
  contractAddress: string;
  cumulativeGasUsed: string;
  from: string;
  gas: BigNumber;
  gasPrice: BigNumber;
  gasUsed: BigNumber;
  hash: string;
  input: string;
  nonce: string;
  timeStamp: string;
  to: string;
  tokenDecimal: string;
  tokenName: string;
  tokenSymbol: string;
  transactionIndex: string;
  value: BigNumber;
}

interface AssetDetail {
  contractAddress: string;
  tokenName: string;
  tokenSymbol: string;
  value: BigNumber;
  tokenDecimal: string;
}

interface Assets {
  [key: string]: AssetDetail;
}

async function getTokenAmount(
  tokenAddress: string,
  isCrucible: boolean | undefined,
  provider: Provider,
  address: string
): Promise<BigNumber> {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    IUniswapV2ERC20.abi,
    provider
  );

  const tokenBalance = await tokenContract
    .balanceOf(address)
    .catch((e: any) => {
      console.log(e);
      return BigNumber.from(0);
    });

  if (isCrucible) {
    const crucibleContract = new ethers.Contract(
      address,
      crucibleAbi,
      provider
    );
    const lockedBalance = await crucibleContract.getBalanceLocked(tokenAddress);
    const unlockedBalance = tokenBalance.sub(lockedBalance);
    return unlockedBalance;
  } else {
    return tokenBalance;
  }
}

export const getContainedAssets = async (
  address: string,
  chainId: number,
  etherscanApiKey: string,
  polygonscanApiKey: string,
  snowtraceApiKey: string,
  provider: Provider,
  isCrucible: boolean
) => {
  // default api endpoint: mainnet
  let endpoint = `https://api.etherscan.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=${etherscanApiKey}`;

  if (chainId === 5) {
    endpoint = `https://api-goerli.etherscan.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=${etherscanApiKey}`;
  }

  if (chainId === 137) {
    endpoint = `https://api.polygonscan.com/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=${polygonscanApiKey}`;
  }

  if (chainId === 80001) {
    endpoint = `https://api-testnet.polygonscan.com/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=${polygonscanApiKey}`;
  }

  if (chainId === 43114) {
    endpoint = `https://api.snowtrace.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=${snowtraceApiKey}`;
  }

  if (chainId === 43113) {
    endpoint = `https://api-testnet.snowtrace.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=${snowtraceApiKey}`;
  }

  try {
    const response = await fetch(endpoint);
    const data: TransactionData = await response.json();
    const ethBalance = await provider.getBalance(address);
    const assetsDetail: AssetDetail[] = [];

    if (data.message !== 'OK' && data.message !== 'No transactions found') {
      throw new Error(data.result as unknown as string);
    }

    const assets: Assets = data.result.reduce((acc, tx) => {
      return { ...acc, [tx.contractAddress]: { ...tx } };
    }, {});

    for (const o in assets) {
      assetsDetail.push(assets[o]);
    }

    const containedAssets: ERC20Token[] = await Promise.all(
      assetsDetail.map(async (result) => {
        let tokenSymbol;
        try {
          tokenSymbol = await formatTokenSymbol(
            result.contractAddress,
            result.tokenSymbol,
            provider
          );
        } catch (e) {
          console.log(
            '[Error] Failed to get token symbol:',
            result.contractAddress,
            result.tokenSymbol
          );
          tokenSymbol = result.tokenSymbol;
        }

        const imageUrl = await getTokenIcon(result.contractAddress, chainId);

        const amount = await getTokenAmount(
          result.contractAddress,
          isCrucible,
          provider,
          address
        );

        return {
          value: { amount },
          address: result.contractAddress,
          symbol: tokenSymbol,
          decimals: Number(result.tokenDecimal),
          imageUrl
        };
      })
    );

    containedAssets.push({
      value: { amount: ethBalance },
      address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      decimals: 18,
      imageUrl: convertChainIdToIconPath(chainId),
      symbol: convertChainIdToNativeTokenSymbol(chainId)
    });

    return containedAssets
      .sort((a, b) => a.symbol.localeCompare(b.symbol))
      .filter((asset) => asset.value.amount.gt(0));
  } catch (err) {
    throw err;
  }
};
