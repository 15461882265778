import { BigNumber, BigNumberish, ethers } from 'ethers';
import { shuffle, unshuffle } from 'shuffle-seed';

export function truncate(
  string: string = '',
  start: number = 5,
  end: number = 3
) {
  if (start < 1) {
    return string;
  }

  if (string.length <= start + end) {
    return string;
  }

  return string.slice(0, start) + '...' + (end > 0 ? string.slice(-end) : '');
}

export function convertTokenIdToAddress(tokenId: BigNumberish) {
  return ethers.utils.hexZeroPad(BigNumber.from(tokenId).toHexString(), 20);
}

export function shuffleAddress(crucibleAddress: string, seed: string) {
  const prefix = '8x';
  const rest = crucibleAddress.toLowerCase().substring(2);
  const restArray = rest.split('');
  const shuffled = shuffle(restArray, seed);
  return prefix + shuffled.join('');
}

export function unshuffleAddress(shuffledAddress: string, seed: string) {
  const prefix = '0x';
  const rest = shuffledAddress.substring(2);
  const restArray = rest.split('');
  const unshuffled = unshuffle(restArray, seed);
  return prefix + unshuffled.join('');
}
