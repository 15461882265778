import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TxnStatus, TxnType } from 'store/transactions/types';
import TxnError from 'components/toasts/TxnError';
import { truncate } from 'utils/address';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';

export const rescueERC20 = createAsyncThunk(
  THUNK_PREFIX.RESCUE_ERC20,
  async ({
    web3React,
    updateTx,
    toast,
    modal,
    logger,
    aludelAddress,
    rewardPoolAddress,
    tokens,
    recipient,
    rewardPoolAbi
  }: any) => {
    // create UUID for tracking these tx
    const txnId = uuid();

    let description = `Rescue ${tokens.length} token${
      tokens.length > 1 ? 's' : ''
    } from aludel ${truncate(aludelAddress)}`;

    updateTx({
      id: txnId,
      type: TxnType.rescueFromRewardPool,
      status: TxnStatus.Initiated,
      description
    });

    try {
      const { account, chainId, library } = web3React;

      const rewardPoolContract = new ethers.Contract(
        rewardPoolAddress,
        rewardPoolAbi,
        library.getSigner()
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm token rescue transaction' />
        )
      });

      const tx = await rewardPoolContract.rescueERC20(tokens, recipient);

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: tx.hash
      });

      await tx.wait(1);

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: tx.hash
      });
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });
      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error: errorMessage
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
