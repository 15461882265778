import { Alert, Link, Text } from '@chakra-ui/react';
import { ModalType } from 'components/modals/types';
import { useWalletAssets } from 'hooks/query/useWalletAssets';
import { useWeb3React } from 'hooks/web3';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useConfig } from 'store/config';
import { useModal } from 'store/modals';
import { useSelectedAssets } from 'store/selectedAssets';
import { useSelectedCrucible } from 'store/selectedCrucible';
import { RewardProgram, RewardProgramTokens } from 'types';

type Props = {
  rewardProgram: RewardProgram;
  rewardProgramTokens: RewardProgramTokens;
};

export const ZeroBalance: FC<Props> = ({
  rewardProgramTokens,
  rewardProgram
}) => {
  const history = useHistory();
  const { chainId = 1 } = useWeb3React();
  const { setSelectedWalletAsset } = useSelectedAssets();
  const { wrappableTokens } = useConfig(chainId).config;
  const { data: walletAssets } = useWalletAssets();
  const { selectedCrucible } = useSelectedCrucible();
  const { closeModal } = useModal();

  const [
    matchingUnderlyingTokenAddress,
    matchingUnderlyingTokenSymbol,
    matchingWrapperTokenSymbol
  ] = useMemo(() => {
    const match = wrappableTokens!.find(
      (token) =>
        token.wrapperTokenAddress.toLowerCase() ===
        rewardProgramTokens.stakingToken.address.toLowerCase()
    );
    if (match) {
      return [
        match.underlyingTokenAddress,
        match.underlyingTokenSymbol,
        match.wrapperTokenSymbol
      ];
    } else {
      return [null, null, null];
    }
  }, [wrappableTokens, rewardProgramTokens.stakingToken.address]);

  const wrappableTokenAddresses = wrappableTokens!.flatMap((token) => [
    token.wrapperTokenAddress.toLowerCase()
  ]);

  const wrappable = wrappableTokenAddresses.includes(
    rewardProgramTokens.stakingToken.address.toLowerCase()
  );

  if (!wrappable) {
    return (
      <Text my={6}>
        No funds? You can get {rewardProgramTokens?.stakingToken.tokenSymbol}{' '}
        tokens from this{' '}
        <Link
          color='cyan.500'
          isExternal
          href={rewardProgram.getStakingTokenUrl}
        >
          link
        </Link>
        .
      </Text>
    );
  }

  return (
    <Alert mb={4} borderRadius='xl'>
      <Text>
        {`This program requires wrapped ${matchingUnderlyingTokenSymbol} (${matchingWrapperTokenSymbol}). You can get ${matchingUnderlyingTokenSymbol}
        tokens from`}{' '}
        <Link
          href={rewardProgram.getStakingTokenUrl}
          color='cyan.500'
          sx={{ display: 'inline' }}
        >
          here
        </Link>
        {` which can then be wrapped to ${matchingWrapperTokenSymbol} by clicking `}
        <Link
          as='button'
          color='cyan.400'
          sx={{ display: 'inline' }}
          onClick={() => {
            setSelectedWalletAsset(
              (walletAssets || []).find(
                (asset: any) =>
                  asset.address.toLowerCase() ===
                  matchingUnderlyingTokenAddress?.toLowerCase()
              ) || null
            );
            closeModal(ModalType.rewardProgramInfo);
            history.push(`/crucible/${selectedCrucible!.id}/asset-management`, {
              tab: 3
            });
          }}
        >
          here.
        </Link>
      </Text>
    </Alert>
  );
};
