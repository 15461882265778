import { FC } from 'react';
import { RewardProgram, RewardProgramTokens } from 'types';
import RewardProgramCarousel from './RewardProgramCarousel';
import RewardProgramDescription from 'components/crucible/detail/rewards/RewardProgramDescription';
import { VStack } from '@chakra-ui/react';

type Props = {
  carouselProps: any;
  rewardProgram: RewardProgram;
  rewardProgramTokens: RewardProgramTokens;
};

const RewardProgramInfoTab: FC<Props> = ({
  carouselProps,
  rewardProgram,
  rewardProgramTokens
}) => {
  return (
    <VStack alignItems='stretch' spacing={5}>
      <RewardProgramCarousel {...carouselProps} />
      <RewardProgramDescription
        rewardProgram={rewardProgram}
        rewardProgramTokens={rewardProgramTokens}
      />
    </VStack>
  );
};

export default RewardProgramInfoTab;
