import {
  PROPOSALS_QUERY,
  PROPOSAL_VOTES_QUERY,
  SPACES_QUERY
} from '../helpers/queries';
import { Proposal, Space, Vote } from 'types/snapshot';
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import snapshot from '@snapshot-labs/snapshot.js';
import cloneDeep from 'lodash/cloneDeep';
import voting from '../helpers/voting';
import client from 'libraries/snapshot';

export const SNAPSHOT_SCORE_API = 'https://score.snapshot.org/api/scores';

const fetchOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
};

// TODO: Move this somewhere else
type SpacesResponse = {
  spaces: {
    id: string;
    avatar: string;
    network: string;
    name: string;
  }[];
  proposals: {
    space: {
      id: string;
    };
  }[];
};

export async function getSpaces({
  space_in = []
}: {
  space_in: string[];
}): Promise<SpacesResponse> {
  try {
    const response = await fetch('https://hub.snapshot.org/graphql/', {
      ...fetchOptions,
      body: JSON.stringify({
        query: SPACES_QUERY,
        variables: {
          space_in
        }
      })
    });

    if (!response.ok) {
      throw new Error('Failed to get spaces');
    }
    const spacesRes = await response.json();
    const spacesResClone = cloneDeep(spacesRes);

    const spaces = spacesResClone.data.spaces;
    const proposals = spacesResClone.data.proposals;

    return {
      spaces,
      proposals
    };
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function getProposals({
  spaceName,
  first = 1000,
  skip = 0,
  state = 'all',
  space_in = [],
  author_in = []
}: {
  spaceName?: string;
  first?: number;
  skip?: number;
  state?: string;
  space_in?: string[];
  author_in?: string[];
}): Promise<any> {
  try {
    const response = await fetch('https://hub.snapshot.org/graphql/', {
      ...fetchOptions,
      body: JSON.stringify({
        query: PROPOSALS_QUERY,
        variables: {
          first,
          skip,
          state,
          space: spaceName,
          space_in,
          author_in
        }
      })
    });

    if (!response.ok) {
      throw new Error('Failed to get proposals');
    }
    const proposalsRes = await response.json();
    const proposalsResClone = cloneDeep(proposalsRes);
    const proposals = proposalsResClone.data.proposals;
    const space = proposalsResClone.data.space;

    return {
      proposals,
      space
    };
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function getProposal({
  id,
  spaceName
}: {
  id: string;
  spaceName?: string;
}): Promise<any> {
  try {
    const response = await fetch('https://hub.snapshot.org/graphql/', {
      ...fetchOptions,
      body: JSON.stringify({
        query: PROPOSAL_VOTES_QUERY,
        variables: { id, space: spaceName }
      })
    });

    if (!response.ok) {
      throw new Error('Failed to get proposal');
    }

    const proposalRes = await response.json();
    const proposalResClone = cloneDeep(proposalRes);
    const proposal = proposalResClone.data.proposal;
    const votes = proposalResClone.data.votes;
    const space = proposalResClone.data.space;

    if (proposal?.plugins?.daoModule) {
      // The Dao Module has been renamed to SafeSnap
      // Previous proposals have to be renamed
      proposal.plugins.safeSnap = proposal.plugins.daoModule;
      delete proposal.plugins.daoModule;
    }

    return {
      proposal,
      votes,
      space
    };
  } catch (e) {
    console.log(e);
    return e;
  }
}

//@ts-ignore
export async function getResults({
  space,
  proposal,
  votes
}: {
  space: Space;
  proposal: Proposal;
  votes: Vote[];
}): Promise<any> {
  try {
    const voters = votes.map((vote) => vote.voter);
    // TODO: Make provider network dynamic
    const provider = snapshot.utils.getProvider('1');
    const strategies = proposal.strategies ?? space.strategies;
    /* Get scores */
    if (proposal.state !== 'pending') {
      const scores = await getScores(
        space.id,
        strategies,
        space.network,
        provider,
        voters,
        parseInt(proposal.snapshot)
      );

      votes = votes
        .map((vote: any) => {
          vote.scores = strategies.map(
            (strategy, i) => scores[i][vote.voter] || 0
          );
          vote.balance = vote.scores.reduce((a: any, b: any) => a + b, 0);
          return vote;
        })
        .sort((a, b) => b.balance - a.balance)
        .filter((vote) => vote.balance > 0);
    }

    /* Get results */
    //@ts-ignore
    const votingClass = new voting[proposal.type](proposal, votes, strategies);
    const results = {
      resultsByVoteBalance: votingClass.resultsByVoteBalance(),
      resultsByStrategyScore: votingClass.resultsByStrategyScore(),
      sumOfResultsBalance: votingClass.sumOfResultsBalance()
    };

    return { votes, results };
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function getScores(
  space: string,
  strategies: any[],
  network: string,
  provider: StaticJsonRpcProvider | string,
  addresses: string[],
  snapshot: number | string = 'latest'
) {
  let obj: any = [];
  try {
    if (addresses && addresses.length > 0) {
      const batchSize = 200;
      for (let i = 0; i < addresses.length; i += batchSize) {
        const addressChunk = addresses.slice(i, i + batchSize);

        const params = {
          space,
          network,
          snapshot,
          strategies,
          addresses: addressChunk
        };

        // TODO: Can use snapshot.utils.getScores
        const res = await fetch(SNAPSHOT_SCORE_API, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ params })
        });

        const result = await res.json();
        result.result.scores.forEach((s: any, i: any) => {
          obj[i] = Object.assign({}, obj[i], s);
        });
      }
    }
    return obj;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function vote(
  payload: any,
  choice: any,
  provider: any,
  address: string
): Promise<any> {
  // remove vote entries which are 0 as this invalidates votes at Snapshots UI
  const filtered: number | number[] | Record<string, any> | null =
    Object.fromEntries(
      Object.entries(choice).filter(([_, v]) => v !== 0 && v !== null)
    );

  try {
    await client.vote(provider, address, {
      space: payload.space.id,
      proposal: payload.id,
      choice: filtered,
      type: payload.type,
      privacy: payload.privacy,
      app: 'crucible',
      reason: payload.reason
    });
  } catch (err: any) {
    throw new Error(
      err.message
        ? err.message
        : err.error_description
        ? err.error_description
        : 'Something went wrong'
    );
  }
}
