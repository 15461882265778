import firebase from 'firebase/app';
import 'firebase/firestore';
import { __PROD__ } from 'utils/constants';

let firebaseConfig = {};

if (__PROD__) {
  firebaseConfig = {
    apiKey: 'AIzaSyB4Q6G1HSLc3Dc0gLZcP0xhsC1ql9zut24',
    authDomain: 'alchemist-ffe27.firebaseapp.com',
    projectId: 'alchemist-ffe27',
    storageBucket: 'alchemist-ffe27.appspot.com',
    messagingSenderId: '1020342523786',
    appId: '1:1020342523786:web:7c1b1665a04d78237c9a73'
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyD7Fun2JcxHNUcW4V4oT72_T5b2wmO_AWc',
    authDomain: 'crucible-dev-4ff3c.firebaseapp.com',
    projectId: 'crucible-dev-4ff3c',
    storageBucket: 'crucible-dev-4ff3c.appspot.com',
    messagingSenderId: '139029584542',
    appId: '1:139029584542:web:930b9cc49df3e62c20398f'
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

  // experimentalForceLongPolling for firewall/connectivitiy issues
  // firebase.firestore().settings({ experimentalForceLongPolling: true, merge: true });

  if (
    window === window.parent &&
    !window.location.pathname.match('/nft-scene/[a-zA-Z0-9]*/true')
  ) {
    firebase.firestore().enablePersistence();
  }
}

export const db = firebase.firestore();
