import { FC, useEffect, useState } from 'react';
import { Box, useInterval } from '@chakra-ui/react';
import { useWeb3React } from 'hooks/web3';
import { ethers } from 'ethers';
import { flashbotsRpcAbi } from '../../abi/flashbotsRpcAbi';
import { useConfig } from '../../store/config';
import { Tooltip } from '@chakra-ui/tooltip';
import { Image } from '@chakra-ui/image';
import ProtectionLogo from '../../img/protection.svg';
const FlashbotsProtectionLabel: FC = () => {
  const { account, chainId = 1, library } = useWeb3React();
  const { config } = useConfig(chainId || 1);
  const { flashbotsRpcAddress } = config;
  const signer = library?.getSigner();
  const [flashbotsRPCDetected, setflashbotsRPCDetected] = useState(false);

  const detectFlashbotsRpc = async (
    signer: any,
    flashbotsRpcAddress: string
  ) => {
    try {
      const flasbotsRPCContract = new ethers.Contract(
        flashbotsRpcAddress,
        flashbotsRpcAbi,
        signer
      );
      const flashbotsRPCDetection = await flasbotsRPCContract.isFlashRPC();
      setflashbotsRPCDetected(flashbotsRPCDetection);
    } catch {}
  };

  useEffect(() => {
    if (!!signer && !!account && flashbotsRpcAddress !== '') {
      detectFlashbotsRpc(signer, flashbotsRpcAddress);
    }
  }, [signer, chainId, account, flashbotsRpcAddress]);

  useInterval(() => {
    if (!!signer && !!account && flashbotsRpcAddress !== '') {
      detectFlashbotsRpc(signer, flashbotsRpcAddress);
    } else {
      setflashbotsRPCDetected(false);
    }
  }, 15000);

  return (
    <div>
      {flashbotsRPCDetected && (
        <Box>
          <Tooltip label='You are connected to the flashbots RPC!'>
            <Image src={ProtectionLogo} />
          </Tooltip>
        </Box>
      )}
    </div>
  );
};

export default FlashbotsProtectionLabel;
