import { FC } from 'react';
import { useModal } from 'store/modals';
import {
  Box,
  Button,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  Flex,
  Circle
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useWeb3React } from 'hooks/web3';
import { injectedConnector } from 'connectors';
import { BsCheck } from 'react-icons/bs';
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask';
import MMLogo from 'img/wallets/metamask-logo.svg';

type Props = {
  crucibleSymbol: string;
  crucibleAddress: string;
  crucibleFactoryAddress: string;
};

const RewardProgramInfoModal: FC<Props> = ({
  crucibleSymbol,
  crucibleAddress,
  crucibleFactoryAddress
}) => {
  const { closeAll } = useModal();
  const { connector, chainId = 1 } = useWeb3React();
  const { addToken, success } = useAddTokenToMetamask(
    crucibleFactoryAddress,
    crucibleSymbol,
    0
  );

  return (
    <Modal isOpen={true} onClose={() => closeAll()} size='sm'>
      <ModalOverlay />
      <ModalContent bg='gray.800' borderRadius='xl'>
        <ModalHeader textAlign='center'>Your Crucible is ready!</ModalHeader>
        <ModalCloseButton />

        <ModalBody mb={4}>
          <Flex flexDir='column' alignItems='center'>
            <Circle
              mt={4}
              mb={12}
              bg='whiteAlpha.100'
              color='green.500'
              size='100px'
            >
              <BsCheck fontSize='40px' />
            </Circle>
            {chainId !== 1 && (
              <Text mb={4} color='gray.300' textAlign='center'>
                It may take a few minutes for your Crucible to show up on the
                website.
              </Text>
            )}
            {/* @ts-ignore */}
            {(window.web3 || window.ethereum) &&
              connector === injectedConnector && (
                <Button
                  mb={2}
                  fontSize='sm'
                  variant='ghost'
                  bg='whiteAlpha.200'
                  onClick={addToken}
                  _hover={{ bg: 'whiteAlpha.300' }}
                >
                  <Image w={6} h={6} mr={2} src={MMLogo} />
                  {success ? (
                    <Text color='orange'>
                      Added {crucibleSymbol} to MetaMask
                    </Text>
                  ) : (
                    <Text color='orange'>Add {crucibleSymbol} to MetaMask</Text>
                  )}
                </Button>
              )}
            <Box textAlign='center'>
              <ChakraLink
                as={Link}
                to={`/crucible/${crucibleAddress}`}
                onClick={() => closeAll()}
                fontWeight='semibold'
              >
                Manage your Crucible
              </ChakraLink>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RewardProgramInfoModal;
