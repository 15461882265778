import { useWeb3React } from 'hooks/web3';
import { fetchTokenBlacklist } from 'api/firebase';
import { useQuery } from 'react-query';
import { Queries } from 'types/enum';

export function useTokenBlacklist() {
  const { chainId = 1 } = useWeb3React();
  return useQuery<string[], Error>([Queries.TOKEN_BLACKLIST, chainId], () =>
    fetchTokenBlacklist(chainId)
  );
}
