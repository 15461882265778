import create from 'zustand';

export enum SortBy {
  ASC = 'asc',
  DESC = 'desc',
  RAND = 'rand'
}

type ExploreFilterState = {
  options: { value: string; label: string }[];
  sortBy: SortBy;
  setSortBy: (option: SortBy) => void;
};

export const useExploreFilter = create<ExploreFilterState>((set) => ({
  options: [
    { value: SortBy.ASC, label: 'Oldest' },
    { value: SortBy.DESC, label: 'Newest' },
    { value: SortBy.RAND, label: 'Random' }
  ],
  sortBy: SortBy.ASC,
  setSortBy: (option: SortBy) => set(() => ({ sortBy: option }))
}));
