import { useWeb3React } from 'hooks/web3';
import { StakingTokenBalance } from 'types';
import { useQuery } from 'react-query';
import { Queries } from 'types/enum';
import { fetchStakingTokenBalance } from 'api/blockchain/stakingTokenBalance';

export function useStakingTokenBalance(
  stakingTokenAddress: string,
  rewardProgramAddress: string,
  crucibleId?: string
) {
  const { account, provider } = useWeb3React();
  return useQuery<StakingTokenBalance, Error>(
    [
      Queries.STAKING_TOKEN_BALANCE,
      stakingTokenAddress,
      rewardProgramAddress,
      crucibleId,
      account
    ],
    () =>
      fetchStakingTokenBalance(
        stakingTokenAddress,
        rewardProgramAddress,
        crucibleId!,
        account!,
        provider
      ),
    {
      enabled: !!account && !!crucibleId
    }
  );
}
