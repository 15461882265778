import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { crucibleFactoryAbi } from 'abi/crucibleFactoryAbi';
import { truncate } from 'utils/address';
import parseTransactionError from 'utils/parseTransactionError';
import { getAddress } from 'ethers/lib/utils';
import TxnError from 'components/toasts/TxnError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';

export const transferCrucible = createAsyncThunk(
  THUNK_PREFIX.TRANSFER_CRUCIBLE,
  async ({
    web3React,
    config,
    updateTx,
    toast,
    modal,
    logger,
    transferTo,
    crucibleId,
  }: any) => {
    const { account, library, chainId } = web3React;
    const signer = library.getSigner();
    const { crucibleFactoryAddress } = config;
    const txnId = uuid();
    const description = `Crucible ${truncate(crucibleId)} to ${truncate(
      getAddress(transferTo)
    )}`;

    // Set transfer status to INITIATED
    updateTx({
      id: txnId,
      type: TxnType.transfer,
      status: TxnStatus.Initiated,
      description,
    });

    try {
      // Create a crucible factory contract instance
      const crucibleFactory = new ethers.Contract(
        crucibleFactoryAddress,
        crucibleFactoryAbi,
        signer
      );

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Pending wallet confirmation' />
        ),
      });

      // Set transfer status to PENDING APPROVAL
      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId,
      });

      // Ask user to confirm txn
      const tx = await crucibleFactory[
        'safeTransferFrom(address,address,uint256)'
      ](account, transferTo, ethers.BigNumber.from(crucibleId));

      // Set transfer status to PENDING ON CHAIN
      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: tx.hash,
      });

      // wait for 1 block confirmation
      await tx.wait(1);

      // Set transfer status to MINED
      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: tx.hash,
      });

      return txnId;
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        ),
      });

      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        errorMsg: errorMessage,
        error,
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
