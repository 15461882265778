import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { customMinterAbi } from 'abi/customMinterAbi';
import { truncate } from 'utils/address';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import { parseEther } from 'ethers/lib/utils';

export const unlockArtwork = createAsyncThunk(
  THUNK_PREFIX.TRANSFER_CRUCIBLE,
  async ({
    web3React,
    config,
    updateTx,
    toast,
    modal,
    logger,
    crucibleAddress,
    fee
  }: any) => {
    const { account, library, chainId } = web3React;
    const signer = library.getSigner();
    const { customMinterAddress } = config;
    const txnId = uuid();
    const description = `Upgrade Crucible ${truncate(crucibleAddress)}`;

    updateTx({
      id: txnId,
      type: TxnType.unlockArtwork,
      status: TxnStatus.Initiated,
      description
    });

    try {
      // Create a crucible factory contract instance
      const customMinterContract = new ethers.Contract(
        customMinterAddress,
        customMinterAbi,
        signer
      );

      // Set transfer status to PENDING APPROVAL
      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm upgrade Crucible transaction' />
        )
      });

      // Ask user to confirm txn
      const tx = await customMinterContract.payFee(crucibleAddress, {
        value: parseEther(fee.toString())
      });

      // Set transfer status to PENDING ON CHAIN
      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: tx.hash
      });

      // wait for 1 block confirmation
      await tx.wait(1);

      // Set transfer status to MINED
      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: tx.hash
      });

      return txnId;
    } catch (error: any) {
      let errorMessage = '';
      if (error?.data?.message?.includes('already paid')) {
        errorMessage = 'Crucible already upgraded';
      } else if (error?.data?.message?.includes('insufficient funds')) {
        errorMessage = 'Insufficient funds for transaction';
      } else {
        errorMessage = parseTransactionError(error);
      }

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });

      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        errorMsg: errorMessage,
        error
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
