import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { truncate } from 'utils/address';
import parseTransactionError from 'utils/parseTransactionError';
import TxnError from 'components/toasts/TxnError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import { crucibleAbi } from 'abi/crucibleAbi';

export const rageQuit = createAsyncThunk(
  THUNK_PREFIX.RAGE_QUIT,
  async ({
    web3React,
    config,
    updateTx,
    toast,
    modal,
    logger,
    crucibleAddress,
    currentRewardProgram,
    stakingTokenAddress
  }: any) => {
    const { account, library, chainId } = web3React;
    const signer = library.getSigner();
    const txnId = uuid();

    const description = `Force exit from ${truncate(
      currentRewardProgram.address
    )}`;

    // Set transfer status to INITIATED
    updateTx({
      id: txnId,
      type: TxnType.rageQuit,
      status: TxnStatus.Initiated,
      description,
      currentRewardProgram,
      crucibleAddress
    });

    try {
      const crucibleContract = new ethers.Contract(
        crucibleAddress,
        crucibleAbi,
        signer
      );

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm forced exit transaction' />
        )
      });

      // Set transfer status to PENDING APPROVAL
      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      // Ask user to confirm txn
      const tx = await crucibleContract.rageQuit(
        currentRewardProgram.address,
        stakingTokenAddress
      );

      // Set transfer status to PENDING ON CHAIN
      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: tx.hash
      });

      // wait for 1 block confirmation
      await tx.wait(1);

      // Set transfer status to MINED
      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: tx.hash
      });

      return txnId;
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });

      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        errorMsg: errorMessage,
        error
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
