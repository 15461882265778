import App from 'App';
import React from 'react';
import theme from 'theme';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import { Web3ReactProvider } from '@web3-react/core';
import { ChakraProvider, cookieStorageManager } from '@chakra-ui/react';
import { GlobalStyles } from 'theme/styles/global-styles';
import { Global } from '@emotion/react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store/store';
import { getLibrary } from 'helpers/library';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useError } from 'store/zustand/useError';
import { contractErrorMessages } from 'utils/contractErrors';

const { setError } = useError.getState()

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      if (!contractErrorMessages.includes(error.message)) {
        setError(Boolean(error))
      }
    }
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      staleTime: Infinity
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ReduxProvider store={store}>
          <ChakraProvider
            theme={theme}
            colorModeManager={cookieStorageManager('')}
          >
            <Global styles={GlobalStyles} />
            <App />
          </ChakraProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
