import { Alert, Text } from '@chakra-ui/react';
import { useError } from 'store/zustand/useError';

export const ServiceStatus = () => {
  const { error } = useError()


  if (!error) {
    return null;
  }

  return (
    <Alert
      status='error'
      variant='solid'
      bgColor='orange.400'
      alignContent='center'
      justifyContent='center'
    >
      <Text>Partial service outage, there may be issues with loading data.</Text>
    </Alert>
  );
};
