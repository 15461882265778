import {
  Alert,
  AlertIcon,
  Button,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react';
import { useWeb3React } from 'hooks/web3';
import CustomInput from 'components/shared/CustomInput';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useStakingTokenBalance } from 'hooks/query/useStakingTokenBalance';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { useSelectedCrucible } from 'store/selectedCrucible';
import { useModal } from 'store/modals';
import { useTransactions } from 'store/transactions/useTransactions';
import { truncate } from 'utils/address';
import { sanitize } from 'utils/sanitize';
import formatNumber from 'utils/formatNumber';
import CrucibleDropDownItem from './CrucibleDropdownItem';
import { Crucible, RewardProgram, RewardProgramTokens } from 'types';
import { useCrucibles } from 'hooks/query/useCrucibles';
import { ModalType } from 'components/modals/types';
import { Link } from 'react-router-dom';
import { ZeroBalance } from './ZeroBalance';

type Props = {
  rewardProgram: RewardProgram;
  rewardProgramTokens: RewardProgramTokens;
};

enum TabIds {
  Crucible = 0,
  Wallet = 1
}

const RewardProgramSubTab: FC<Props> = ({
  rewardProgram,
  rewardProgramTokens
}) => {
  const focusRef = useRef<HTMLInputElement>(null);
  const { account } = useWeb3React();
  const { addSubscription } = useTransactions();
  const { closeModal } = useModal();
  const { selectedCrucible, setSelectedCrucible } = useSelectedCrucible();
  const { data: crucibles, isLoading: cruciblesLoading } = useCrucibles();
  const { data: stakingTokenData } = useStakingTokenBalance(
    rewardProgramTokens.stakingToken.address,
    rewardProgram.address,
    selectedCrucible?.id
  );
  const [amountToSubscribe, setAmountToSubscribe] = useState('');
  const [tabIndex, setTabIndex] = useState(TabIds.Crucible);
  const tabValue = useMemo(() => {
    return tabIndex === TabIds.Wallet ? 'wallet' : 'crucible';
  }, [tabIndex]);

  const stakingTokenBalance =
    tabValue === 'wallet'
      ? stakingTokenData?.stakingTokenInWallet
      : stakingTokenData?.stakingTokenUnlocked;

  useEffect(() => {
    if (stakingTokenData) {
      setTabIndex(
        stakingTokenData.stakingTokenUnlocked.isZero()
          ? TabIds.Wallet
          : TabIds.Crucible
      );
    }
  }, [selectedCrucible, stakingTokenData]);

  const handleAddSubscription = () => {
    if (selectedCrucible) {
      addSubscription(
        parseUnits(
          amountToSubscribe,
          stakingTokenData?.stakingTokenDecimals || 18
        ),
        selectedCrucible.id,
        tabValue,
        rewardProgram,
        rewardProgramTokens.stakingToken
      );
      closeModal(ModalType.rewardProgramInfo);
      window.scrollTo(0, 0);
    }
  };

  const tabProps = {
    borderRadius: 'lg',
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    _selected: { color: 'black', bg: 'cyan.400' },
    marginBottom: '1px'
  };

  if (!account) {
    return (
      <Alert status='info' borderRadius='xl'>
        <AlertIcon />
        Connect your wallet to join the program
      </Alert>
    );
  }

  if (!cruciblesLoading && !crucibles?.length) {
    return (
      <Alert status='info' borderRadius='xl'>
        <AlertIcon />
        <Text>
          <ChakraLink
            to={`${process.env.PUBLIC_URL}/mint`}
            onClick={() => closeModal(ModalType.rewardProgramInfo)}
            color='cyan.500'
            as={Link}
          >
            Mint a crucible
          </ChakraLink>{' '}
          to join the program
        </Text>
      </Alert>
    );
  }

  return (
    <>
      <Text mb={2}>Select a crucible:</Text>
      <Menu matchWidth>
        <MenuButton
          variant='ghost'
          bg='gray.700'
          as={Button}
          mb={6}
          isFullWidth
          disabled={cruciblesLoading}
          rightIcon={cruciblesLoading ? <Spinner /> : <FaCaretDown />}
          _hover={{
            bg: 'gray.600'
          }}
          _active={{
            bg: 'gray.600'
          }}
        >
          {selectedCrucible ? truncate(selectedCrucible.id) : 'Select crucible'}
        </MenuButton>
        <MenuList zIndex={4}>
          {cruciblesLoading ? (
            <Spinner />
          ) : (
            crucibles?.map((crucible: Crucible) => (
              <MenuItem
                key={crucible.id}
                onClick={() => setSelectedCrucible(crucible)}
                justifyContent='space-between'
              >
                <CrucibleDropDownItem
                  crucibleId={crucible.id}
                  stakingTokenAddress={rewardProgramTokens.stakingToken.address}
                  stakingTokenSymbol={
                    rewardProgramTokens.stakingToken.tokenSymbol
                  }
                  rewardProgramAddress={rewardProgram.address}
                />
              </MenuItem>
            ))
          )}
        </MenuList>
      </Menu>

      {selectedCrucible && (
        <>
          <Text>Select your source of funds to subscribe:</Text>
          <Tabs
            index={tabIndex}
            isFitted
            defaultIndex={tabIndex}
            onChange={(index) => {
              setTabIndex(index);
              setAmountToSubscribe('');
            }}
            mb={6}
            size='md'
          >
            <TabList
              fontWeight='600'
              borderRadius='xl'
              my={2}
              border='1px solid'
              padding='5px'
            >
              <Tab {...tabProps}>Crucible</Tab>
              <Tab {...tabProps}>Wallet</Tab>
            </TabList>
          </Tabs>
          {stakingTokenBalance && stakingTokenBalance.isZero() ? (
            <ZeroBalance
              rewardProgram={rewardProgram}
              rewardProgramTokens={rewardProgramTokens}
            />
          ) : (
            <Text mb={2}>
              Available balance:{' '}
              <Skeleton
                isLoaded={!!stakingTokenBalance}
                startColor='gray.500'
                endColor='gray.600'
                width='fit-content'
              >
                <strong>
                  {formatNumber.token(
                    stakingTokenBalance ? stakingTokenBalance : 0,
                    stakingTokenData?.stakingTokenDecimals || 18
                  )}{' '}
                  {rewardProgramTokens?.stakingToken.tokenSymbol}
                </strong>
              </Skeleton>
            </Text>
          )}

          <CustomInput
            inputRef={focusRef}
            max={formatUnits(
              stakingTokenBalance ? stakingTokenBalance : 0,
              stakingTokenData?.stakingTokenDecimals || 18
            )}
            value={amountToSubscribe}
            tokenDecimals={stakingTokenData?.stakingTokenDecimals || 18}
            onUserInput={(input) => setAmountToSubscribe(input)}
            showRadioGroup
            showMaxButton
          />

          {parseUnits(
            sanitize(amountToSubscribe),
            stakingTokenData?.stakingTokenDecimals || 18
          ).gt(stakingTokenBalance ? stakingTokenBalance : 0) && (
            <Text fontSize='sm' textAlign='center' mt={4} color='red.500'>
              Not enough staking token balance.
            </Text>
          )}

          <Button
            size='lg'
            fontWeight='500'
            mt={6}
            isFullWidth
            onClick={handleAddSubscription}
            isDisabled={
              cruciblesLoading ||
              !crucibles?.length ||
              !selectedCrucible ||
              parseUnits(
                sanitize(amountToSubscribe),
                stakingTokenData?.stakingTokenDecimals || 18
              ).lte(0) ||
              parseUnits(
                sanitize(amountToSubscribe),
                stakingTokenData?.stakingTokenDecimals || 18
              ).gt(stakingTokenBalance ? stakingTokenBalance : 0)
            }
          >
            Subscribe
          </Button>
        </>
      )}
    </>
  );
};

export default RewardProgramSubTab;
