import { PayloadAction } from '@reduxjs/toolkit';

export const synchronizeFirebase = async (
  chainId: number,
  rewardProgramAddress: string,
  method?: string,
  payloadAction?: PayloadAction<any, any, {}>
) => {
  if (payloadAction?.type.includes('fulfilled') || !payloadAction) {
    await fetch(
      `/api/update-program?network=${chainId}&address=${rewardProgramAddress}${
        method ? '&method=' + method : ''
      }`
    );
  }
};
