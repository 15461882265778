import create from 'zustand';

type NetworkState = {
  localChainId: number;
  setLocalChainId: (localChainId: number) => void;
};

export const useNetworkState = create<NetworkState>((set) => ({
  localChainId: 1,
  setLocalChainId: (localChainId: number) => set(() => ({ localChainId }))
}));
