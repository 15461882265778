import { useWeb3React as useWeb3ReactCore } from '@web3-react/core';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { NetworkConnector } from '@web3-react/network-connector';
import { useModal } from 'store/modals';
import { useConnection } from 'store/connection';
import { getInfuraProvider } from 'helpers/providers';
import { Provider } from '@ethersproject/providers';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import {
  injectedConnector as injected,
  networkConnector,
  gnosisSafeConnector
} from 'connectors';
import { ModalType } from 'components/modals/types';
import { useNetworkState } from 'store/zustand/useLocalNetwork';
import { commonConfig } from 'store/initialisers/config';
import { useToast } from '@chakra-ui/react';

export type Web3React = Web3ReactContextInterface & {
  provider: Provider;
};

export function useWeb3React(): Web3React {
  const { localChainId } = useNetworkState();
  const params = new URLSearchParams(window.location.search);
  const network = params.get('network');
  const web3React = useWeb3ReactCore();

  const fallback = {
    ...web3React,
    chainId: 1,
    provider: getInfuraProvider(1)
  };

  const provider: Provider = useMemo(() => {
    if (
      web3React.account &&
      web3React.library &&
      web3React.library.getSigner().provider
    ) {
      return web3React.library.getSigner().provider;
    } else {
      return getInfuraProvider(localChainId || web3React.chainId || 1);
    }
  }, [web3React.chainId, web3React.account, web3React.library, localChainId]);

  if (web3React.connector === gnosisSafeConnector) {
    return {
      ...web3React,
      chainId: localChainId,
      provider: getInfuraProvider(localChainId) as any
    };
  }

  if (network) {
    return {
      ...web3React,
      chainId: Number(network),
      provider: getInfuraProvider(Number(network)) as any
    };
  }

  if (window === window.top) {
    return {
      ...web3React,
      chainId: web3React.account ? web3React.chainId : localChainId,
      provider
    };
  }

  //@ts-ignore
  return fallback;
}

export function useEagerConnect() {
  const { tried, setTried } = useConnection();
  const { closeModal } = useModal();
  const { activate, active, connector } = useWeb3ReactCore(); // specifically using useWeb3ReactCore because of what this hook does
  const { setLocalChainId } = useNetworkState();
  const IS_IN_IFRAME = window.parent !== window;
  const [triedSafe, setTriedSafe] = useState(!IS_IN_IFRAME);
  const { supportedChainIds } = commonConfig;
  const toast = useToast();

  useEffect(() => {
    if (!triedSafe) {
      gnosisSafeConnector.isSafeApp().then((loadedInSafe) => {
        if (loadedInSafe) {
          let safeUsingSupportedChain: boolean = true;

          gnosisSafeConnector.getChainId().then((gnosisChainId) => {
            supportedChainIds.some(
              (supportedChains) => supportedChains === gnosisChainId
            )
              ? setLocalChainId(gnosisChainId)
              : (safeUsingSupportedChain = false);

            if (safeUsingSupportedChain) {
              activate(gnosisSafeConnector, undefined, true).catch(() => {
                setTriedSafe(true);
              });
            } else {
              toast.closeAll();
              toast({
                description:
                  'Gnosis safe is on an unsupported chain, connection aborted.',
                status: 'error',
                duration: 4000,
                isClosable: true
              });

              setTriedSafe(true);
            }
          });
        } else {
          setTriedSafe(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate, triedSafe]); // intentionally only running on mount

  // Fallback to injected connector if safe not detected
  useEffect(() => {
    if (!active && triedSafe && !tried) {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          //@ts-ignore
          if (isMobile && window.ethereum) {
            activate(injected, undefined, true).catch(() => {
              setTried(true);
            });
          } else {
            setTried(true);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate, active, triedSafe, tried]); // intentionally only running on mount

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    if (tried && !active) {
      activate(networkConnector, undefined, false);
    }
  }, [tried, active, activate]);

  useEffect(() => {
    if (connector && !(connector instanceof NetworkConnector)) {
      closeModal(ModalType.connectWallet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector]);
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore(); // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    //@ts-ignore
    const { ethereum } = window;

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error);
        });
      };

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error);
          });
        }
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate]);
}
