import { useWeb3React } from 'hooks/web3';
import { fetchLPTokens } from 'api/firebase';
import { useQuery } from 'react-query';
import { Queries } from 'types/enum';
import { LpData } from 'types/firebase';

export function useLpData() {
  const { chainId = 1 } = useWeb3React();
  return useQuery<LpData, Error>(
    [Queries.LP_DATA, chainId],
    () => fetchLPTokens(chainId),
    {
      refetchInterval: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10
    }
  );
}
