import { Contract } from 'ethers';
import { Proposal, Space } from 'types/snapshot';
import { getScores } from 'api/snapshot';
import snapshot from '@snapshot-labs/snapshot.js';
import namehash from 'eth-ens-namehash';
import voting from './voting';
import dayjs from 'dayjs';

export function getChoiceString(proposal: Proposal, selected: number[]) {
  const votingClass = new voting[proposal.type](proposal, '', '', selected);
  return votingClass.getChoiceString();
}

export async function getPower({
  space,
  address,
  proposal
}: {
  space: Space;
  address: string;
  proposal: Proposal;
}) {
  try {
    const strategies = proposal.strategies ?? space.strategies;
    let scores: any = await getScores(
      space.id,
      strategies,
      space.network,
      snapshot.utils.getProvider(space.network),
      [address],
      parseInt(proposal.snapshot)
    );
    scores = scores.map((score: any) =>
      Object.values(score).reduce((a, b: any) => a + b, 0)
    );
    return {
      scores,
      totalScore: scores.reduce((a: any, b: any) => a + b, 0)
    };
  } catch (e) {
    console.log(e);
    return e;
  }
}

function ensReverseRecordRequest(addresses: string[]) {
  const network = '1';
  const provider = snapshot.utils.getProvider(network);
  const abi = [
    {
      inputs: [
        { internalType: 'address[]', name: 'addresses', type: 'address[]' }
      ],
      name: 'getNames',
      outputs: [{ internalType: 'string[]', name: 'r', type: 'string[]' }],
      stateMutability: 'view',
      type: 'function'
    }
  ];
  return call(
    provider,
    abi,
    ['0x3671aE578E63FdF66ad4F3E12CC0c0d71Ac7510C', 'getNames', [addresses]],
    { blockTag: 'latest' }
  );
}

function lookupAddresses(addresses: string[]) {
  return new Promise((resolove, reject) => {
    ensReverseRecordRequest(addresses)
      .then((reverseRecords) => {
        const validNames = reverseRecords.map((n: any) =>
          namehash.normalize(n) === n ? n : ''
        );
        const ensNames = Object.fromEntries(
          addresses.map((address, index) => {
            return [address.toLowerCase(), validNames[index]];
          })
        );

        resolove(ensNames);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getProfiles(addresses: string[]) {
  addresses = addresses.slice(0, 1000);
  let ensNames: any = {};
  try {
    [ensNames] = await Promise.all([lookupAddresses(addresses)]);
  } catch (e) {
    console.log(e);
  }

  const profiles = Object.fromEntries(
    addresses.map((address) => [address, {}])
  );
  return Object.fromEntries(
    Object.entries(profiles).map(([address, profile]: any) => {
      profile.ens = ensNames[address.toLowerCase()] || '';
      return [address, profile];
    })
  );
}

async function call(provider: any, abi: any[], call: any[], options?: any) {
  const contract = new Contract(call[0], abi, provider);
  try {
    const params = call[2] || [];
    return await contract[call[1]](...params, options || {});
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getDate = (start: number, end: number, state: string) => {
  switch (state) {
    case 'active':
      return `ends ${dayjs(dayjs.unix(end)).fromNow()}`;
    case 'closed':
      return `ended ${dayjs(dayjs.unix(end)).fromNow()}`;
    case 'pending':
      return `starts ${dayjs(dayjs.unix(start)).fromNow()}`;
    default:
      return 'gray';
  }
};

export const getFullDate = (unixDate: number) => {
  return dayjs(dayjs.unix(unixDate)).format('MMM DD YYYY, HH:mm A');
};

export const stateToColor = (state: string) => {
  switch (state) {
    case 'active':
      return 'green';
    case 'closed':
      return 'red';
    case 'pending':
      return 'blue';
    default:
      return 'gray';
  }
};

export const typeToVotingSystem = (type: string) => {
  switch (type) {
    case 'single-choice':
      return 'Single choice voting';
    case 'approval':
      return 'Approval voting';
    case 'ranked-choice':
      return 'Ranked choice voting';
    case 'weighted':
      return 'Weighted voting';
    case 'quadratic':
      return 'Quadratic voting';
  }
};

export const proposalFilters = ['all', 'active', 'pending', 'closed', 'core'];
