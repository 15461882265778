import { ContractReceipt, ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TxnStatus, TxnType } from 'store/transactions/types';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import { aludelFactoryAbi } from 'abi/aludelFactoryAbi';
import { defaultAbiCoder, parseEther } from 'ethers/lib/utils';
import { config } from 'store/initialisers/config';
import { TemplateName } from 'types';
import {
  mapTemplateNameToAludelAbi,
  mapTemplateNameToDataMethod,
  mapTemplateNameToFundMethod
} from 'utils/aludelMappings';
import { BonusToken } from 'components/onboarding/deployment';

export const deployAludel = createAsyncThunk(
  THUNK_PREFIX.DEPLOY_ALUDEL,
  async ({
    web3React,
    updateTx,
    toast,
    modal,
    logger,
    name,
    stakingTokenUrl,
    stakingToken,
    rewardToken,
    bonusTokens,
    crucibleFactoryAddress,
    startTime,
    scalingFloor,
    scalingCeiling,
    scalingDays,
    templateId,
    templateName
  }: any) => {
    // create UUID for tracking this tx
    const txnId = uuid();

    updateTx({
      id: txnId,
      type: TxnType.deployAludel,
      status: TxnStatus.Initiated,
      description: `Deploy reward program`
    });

    try {
      const { account, chainId, library } = web3React;

      const aludelFactoryAddress = config[chainId].aludelFactory;
      const rewardPoolFactory = config[chainId].rewardPoolFactory;
      const powerSwitchFactory = config[chainId].powerSwitchFactory;

      const aludelFactory = new ethers.Contract(
        aludelFactoryAddress,
        aludelFactoryAbi,
        library.getSigner()
      );

      const params = defaultAbiCoder.encode(
        [
          'address',
          'address',
          'address',
          'address',
          'uint256',
          'uint256',
          'uint256'
        ],
        [
          rewardPoolFactory,
          powerSwitchFactory,
          stakingToken,
          rewardToken,
          parseEther(scalingFloor.toString()),
          parseEther(scalingCeiling.toString()),
          scalingDays * 60 * 60 * 24
        ]
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm program creation transaction' />
        )
      });

      if (!stakingTokenUrl) {
        throw new Error(
          'Error generating short URL for staking token, please try again.'
        );
      }

      let bonusTokenAddresses: [] = bonusTokens.flatMap(
        (token: BonusToken) => token.address
      );

      const deployTx = await aludelFactory.launch(
        templateId,
        name,
        stakingTokenUrl,
        startTime,
        crucibleFactoryAddress,
        bonusTokenAddresses,
        account,
        params
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: deployTx.hash
      });

      const txReceipt: ContractReceipt = await deployTx.wait(1);

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: deployTx.hash
      });

      const aludelAddress = txReceipt.events?.filter(
        (e: ethers.Event) =>
          e.address.toLowerCase() === aludelFactory.address.toLowerCase()
      )[0].args?.program;

      toast.closeAll();
      if (aludelAddress) {
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Successfully deployed, please wait...' />
          )
        });
      } else {
        throw new Error(
          'Error retrieving deployed reward program address. Please reach out to team for further support.'
        );
      }

      return {
        aludelAddress,
        aludelDataMethod:
          mapTemplateNameToDataMethod[templateName as TemplateName],
        aludelFundMethod:
          mapTemplateNameToFundMethod[templateName as TemplateName],
        aludelAbi: mapTemplateNameToAludelAbi[templateName as TemplateName]
      };
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });
      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error: errorMessage
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
