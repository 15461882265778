import { FC } from 'react';
import { Button, HStack, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import { useModal } from 'store/modals';
import { ModalType } from './types';

type Props = {
  rewards: any[];
};

const LeaderboardRewardsModal: FC<Props> = ({ rewards }) => {
  const { closeModal } = useModal();

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.leaderboardRewards)}
    >
      <ModalOverlay />
      <ModalContent borderRadius='xl'>
        <ModalHeader textAlign='center'>Claimed rewards</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Wrap spacing={'12px'}>
            {rewards.map((reward) => (
              <WrapItem key={reward.tokenSymbol} flexGrow={1}>
                <HStack
                  key={reward.tokenSymbol}
                  p={4}
                  bgColor='gray.600'
                  rounded={12}
                  width='100%'
                >
                  <span style={{ marginRight: 8 }}>{reward.tokenSymbol}</span>{' '}
                  <Tooltip
                    placement='bottom'
                    hasArrow
                    label={Number(reward.amount)}
                  >
                    <span>{Number(reward.amount).toFixed(4)}</span>
                  </Tooltip>
                </HStack>
              </WrapItem>
            ))}
          </Wrap>
        </ModalBody>
        <ModalFooter>
          <Button
            isFullWidth
            onClick={() => closeModal(ModalType.leaderboardRewards)}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeaderboardRewardsModal;
