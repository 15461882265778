import { useWeb3React } from 'hooks/web3';
import { fetchUserBalances } from 'api/blockchain/userBalances';
import { useQuery } from 'react-query';
import { useConfig } from 'store/config';
import { UserBalances } from 'types';
import { Queries } from 'types/enum';

export function useUserBalances() {
  const web3React = useWeb3React();
  const config = useConfig(web3React.chainId || 1).config;

  return useQuery<UserBalances, Error>(
    [Queries.USER_BALANCES, web3React.chainId, web3React.account],
    () => fetchUserBalances(web3React, config),
    {
      enabled: !!web3React.account,
      refetchInterval: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10
    }
  );
}
