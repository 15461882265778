import { FC, useEffect } from 'react';
import { Flex, Box, Container, Text } from '@chakra-ui/layout';
import { BrowserRouter } from 'react-router-dom';
import { useAccountTracker } from 'hooks/useAccountTracker';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import ModalRoot from 'components/modals/ModalRoot';
import useTransactionPoller from 'store/transactions/useTransactionPoller';
import DrawerRoot from 'components/drawers/DrawerRoot';
import BackgroundAssets from 'components/landing/BackgroundAssets';
import GlobalLoadingIndicator from 'components/shared/GlobalLoadingIndicator';
import { useEagerConnect } from 'hooks/web3';
import { useUsdValues } from 'hooks/query/useUsdValues';
import { useLpData } from 'hooks/query/useLpData';
import { useBlockTimestamp } from 'hooks/query/useBlockTimestamp';
import { useWeb3React } from 'hooks/web3';
import { useTokenBlacklist } from 'hooks/query/useTokenBlacklist';
import { ServiceStatus } from 'components/status/ServiceStatus';
import Routes from 'Routes';
import { useCrucibleOffset } from 'store/zustand/useCrucibleOffset';
import { useNetworkState } from 'store/zustand/useLocalNetwork';
import { SortBy, useExploreFilter } from 'store/zustand/useExploreFilter';
import { useRewardPrograms } from 'store/zustand/useRewardPrograms';
import { usePrograms } from 'hooks/query/usePrograms';
import { queryClient } from 'index';
import { Queries } from 'types/enum';
import { useFirebaseListener } from 'hooks/useFirebaseListener';
import { useMaintainerBlacklist } from 'hooks/query/useMaintainerBlacklist';
import { useBlockedPrograms } from 'hooks/query/useBlockedPrograms';
import { SlideFade, Spinner } from '@chakra-ui/react';

const App: FC = () => {
  useTransactionPoller();
  useFirebaseListener();
  useMaintainerBlacklist();
  useBlockedPrograms();
  useAccountTracker();
  useBlockTimestamp();
  useTokenBlacklist();
  useEagerConnect();
  useUsdValues();
  useLpData();

  const { chainId = 1 } = useWeb3React();
  const { localChainId } = useNetworkState();
  const { setOffset } = useCrucibleOffset();
  const { setSortBy } = useExploreFilter();
  const { isFetching } = usePrograms();
  const { currentRewardProgram, rewardPrograms } = useRewardPrograms();

  useEffect(() => {
    setOffset(0);
    setSortBy(SortBy.ASC);
    queryClient.removeQueries([Queries.ALL_CRUCIBLES]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, localChainId]);

  if (
    !currentRewardProgram ||
    (isFetching &&
      !rewardPrograms.find(
        (p) =>
          p.address.toLowerCase() === currentRewardProgram.address.toLowerCase()
      ))
  ) {
    return (
      <Flex
        justifyContent='center'
        alignItems='center'
        height='100vh'
        direction={'column'}
      >
        <Spinner />
        <Text mt={3}>Loading Data</Text>
      </Flex>
    );
  }

  return (
    <SlideFade in={currentRewardProgram && !isFetching} offsetY='20px'>
      <Flex
        minHeight='100vh'
        flexDirection='column'
        bg='gray.900'
        overflow='hidden'
      >
        <ServiceStatus />
        <BrowserRouter>
          <GlobalLoadingIndicator />
          <ModalRoot />
          <DrawerRoot />
          <Header />
          <Box flexGrow={1}>
            <Container maxW='container.xl' position='relative'>
              <Box zIndex={3} position='relative'>
                <Routes />
              </Box>
              <BackgroundAssets />
            </Container>
          </Box>
          <Footer />
        </BrowserRouter>
      </Flex>
    </SlideFade>
  );
};

export default App;
