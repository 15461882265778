export enum Queries {
  TOKEN_ICON = 'tokenIcon',
  LANDING_STATS = 'landingStats',
  USD_VALUES = 'usdValues',
  LP_DATA = 'lpData',
  GAS_PRICE = 'gasPrice',
  OWNED_CRUCIBLES = 'ownedCrucibles',
  ALL_CRUCIBLES = 'allCrucibles',
  SINGLE_CRUCIBLE = 'singleCrucible',
  CRUCIBLES_LOCKS = 'cruciblesLocks',
  SINGLE_CRUCIBLE_LOCKS = 'singleCruciblesLocks',
  CRUCIBLE_ASSETS = 'crucibleAssets',
  CRUCIBLE_INDEX = 'crucibleIndex',
  WALLET_ASSETS = 'walletAssets',
  REWARD_PROGRAMS = 'rewardPrograms',
  PROGRAMS_REWARDS_USD = 'programsRewardsUsd',
  PROGRAMS_STAKED_USD = 'programsStakedUsd',
  USER_BALANCES = 'userBalances',
  SUBSCRIPTIONS = 'subscriptions',
  BLOCK_TIMESTAMP = 'blockTimestamp',
  CRUCIBLE_NFT_DATA = 'nftData',
  TOKEN_BLACKLIST = 'tokenBlacklist',
  SPACES = 'spaces',
  PROPOSALS = 'proposals',
  STAKING_TOKEN_BALANCE = 'stakingTokenBalance',
  BLOCK_NUMBER = 'blockNumber',
  REWARD_PROGRAM_STATE = 'rewardProgramState',
  INFURA_STATUS = 'infuraStatus',
  THE_GRAPH_STATUS = 'theGraphStatus',
  MAINTAINER_BLACKLIST = 'maintainerBlacklist',
  BLOCKED_PROGRAMS = 'blockedPrograms',
  TOKEN_LIST = 'tokenList',
  LEADERBOARD = 'leaderboard',
  ALUDEL_TEMPLATE = 'aludelTemplate'
}
