import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { TxnStatus, TxnType } from 'store/transactions/types';
import parseTransactionError from 'utils/parseTransactionError';
import TxnError from 'components/toasts/TxnError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import { wrapperERC20Abi } from 'abi/wrapperERC20';
import { buttonWrapperERC20Abi } from 'abi/buttonWrapperERC20';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';

export const handleWrapping = createAsyncThunk(
  THUNK_PREFIX.HANDLE_WRAPPING,
  async ({
    web3React,
    config,
    updateTx,
    toast,
    modal,
    logger,
    crucibleAddress,
    wrapperToken,
    underlyingToken,
    toAddress,
    amount,
    isWrap,
    autoDeposit
  }: any) => {
    const { account, library, chainId } = web3React;
    const signer = library.getSigner();
    const txnId = uuid();

    const { wrapperTokenSymbol, wrapperTokenAddress } = wrapperToken;
    const { underlyingTokenSymbol, underlyingTokenAddress } = underlyingToken;

    const description = isWrap
      ? `Wrapped ${wrapperTokenSymbol} to ${underlyingTokenSymbol}`
      : `Unwrapped ${underlyingTokenSymbol} to ${wrapperTokenSymbol}`;

    updateTx({
      id: txnId,
      type: TxnType.handleWrapping,
      status: TxnStatus.Initiated,
      description,
      crucibleAddress
    });

    if (isWrap) {
      try {
        let wrapper;

        if (underlyingTokenSymbol === 'aAMPL') {
          wrapper = new ethers.Contract(
            wrapperTokenAddress,
            buttonWrapperERC20Abi,
            signer
          );
        } else {
          wrapper = new ethers.Contract(
            wrapperTokenAddress,
            wrapperERC20Abi,
            signer
          );
        }

        const underlying = new ethers.Contract(
          underlyingTokenAddress,
          IUniswapV2ERC20.abi,
          signer
        );

        const sender = web3React.account;

        if (
          !(
            underlyingTokenAddress ===
              '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' && isWrap === true
          )
        ) {
          const allowance = await underlying.allowance(
            sender,
            wrapperTokenAddress
          );

          if (allowance.lt(amount)) {
            toast.closeAll();
            toast({
              duration: null,
              isClosable: true,
              position: 'bottom-right',
              render: () => (
                <TxnPendingApprovals description='Confirm approval for token wrapping transaction' />
              )
            });

            updateTx({
              id: txnId,
              status: TxnStatus.PendingApproval,
              account,
              chainId
            });

            const tx = await underlying.approve(
              wrapperTokenAddress,
              '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            );
            await tx.wait(1);
          }
        }

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm wrapping token transaction' />
          )
        });

        updateTx({
          id: txnId,
          status: TxnStatus.PendingApproval,
          account,
          chainId
        });

        let tx;

        if (underlyingTokenSymbol === 'aAMPL') {
          if (autoDeposit) {
            tx = await wrapper.depositFor(crucibleAddress, amount);
          } else {
            tx = await wrapper.deposit(amount);
          }
        } else {
          tx = await wrapper.deposit({
            value: amount
          });
        }

        updateTx({
          id: txnId,
          status: TxnStatus.PendingOnChain,
          hash: tx.hash
        });

        await tx.wait(1);

        updateTx({
          id: txnId,
          status: TxnStatus.Mined,
          hash: tx.hash
        });

        return txnId;
      } catch (error) {
        const errorMessage = parseTransactionError(error);

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          // @ts-ignore
          render: ({ onClose }) => (
            <TxnError
              onClose={onClose}
              modal={modal}
              errorMessage={errorMessage}
              error={error}
            />
          )
        });

        updateTx({
          id: txnId,
          status: TxnStatus.Failed,
          errorMsg: errorMessage,
          error
        });

        logger.push(error);

        throw error;
      }
    } else {
      try {
        let wrapper;

        if (underlyingTokenSymbol === 'aAMPL') {
          wrapper = new ethers.Contract(
            wrapperTokenAddress,
            buttonWrapperERC20Abi,
            signer
          );
        } else {
          wrapper = new ethers.Contract(
            wrapperTokenAddress,
            wrapperERC20Abi,
            signer
          );
        }

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm unwrapping token transaction' />
          )
        });

        updateTx({
          id: txnId,
          status: TxnStatus.PendingApproval,
          account,
          chainId
        });

        let tx;

        if (underlyingTokenSymbol === 'aAMPL') {
          tx = await wrapper.burn(amount);
        } else {
          tx = await wrapper.withdraw(amount);
        }

        updateTx({
          id: txnId,
          status: TxnStatus.PendingOnChain,
          hash: tx.hash
        });

        await tx.wait(1);

        updateTx({
          id: txnId,
          status: TxnStatus.Mined,
          hash: tx.hash
        });

        return txnId;
      } catch (error) {
        const errorMessage = parseTransactionError(error);

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          // @ts-ignore
          render: ({ onClose }) => (
            <TxnError
              onClose={onClose}
              modal={modal}
              errorMessage={errorMessage}
              error={error}
            />
          )
        });

        updateTx({
          id: txnId,
          status: TxnStatus.Failed,
          errorMsg: errorMessage,
          error
        });

        logger.push(error);

        throw error;
      }
    }
  }
);
