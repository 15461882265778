import { BigNumber, ethers } from 'ethers';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import {
  ERC20Token,
  RewardProgram,
  RewardProgramData,
  RewardProgramVaultData,
  Subscription,
  SubscriptionsSummary
} from 'types';
import { formatTokenSymbol } from 'helpers/formatTokenSymbol';
import getLpTokenBreakdown from 'helpers/getLpTokenBreakdown';
import bigNumberishToNumber from 'utils/bigNumberishToNumber';
import { queryClient } from 'index';
import { Queries } from 'types/enum';
import { UsdValues } from 'types/firebase';
import { Provider } from '@ethersproject/providers';
import { fetchBlockTimestamp } from 'api/blockchain/blockTimestamp';

export const fetchSubscriptions = async (
  chainId: number,
  crucibleId: string,
  provider: Provider,
  rewardProgram: RewardProgram
) => {
  try {
    /* create the contract instances for fetching subscription data */
    const rewardProgramContract = new ethers.Contract(
      rewardProgram.address,
      rewardProgram.abi,
      provider
    );

    const rewardProgramData: RewardProgramData = await rewardProgramContract[
      rewardProgram.getRewardProgramDataMethod
    ].call();

    const {
      stakingToken: stakingTokenAddress,
      rewardToken: rewardTokenAddress
    } = rewardProgramData;

    const rewardTokenContract = new ethers.Contract(
      rewardTokenAddress,
      IUniswapV2ERC20.abi,
      provider
    );

    const stakingTokenContract = new ethers.Contract(
      stakingTokenAddress,
      IUniswapV2ERC20.abi,
      provider
    );

    const [
      stakingTokenDecimals,
      rewardTokenDecimals,
      stakingTokenDefaultSymbol,
      rewardTokenDefaultSymbol
    ] = await Promise.all([
      stakingTokenContract.decimals().catch(() => 0),
      rewardTokenContract.decimals().catch(() => 0),
      stakingTokenContract.symbol().catch(() => ''),
      rewardTokenContract.symbol().catch(() => '')
    ]);

    const stakingTokenSymbol = await formatTokenSymbol(
      stakingTokenAddress,
      stakingTokenDefaultSymbol,
      provider
    );

    const rewardTokenSymbol = await formatTokenSymbol(
      rewardTokenAddress,
      rewardTokenDefaultSymbol,
      provider
    );

    /* get usd conversion rates from cache */
    const usdValues = queryClient.getQueryData<UsdValues>([
      Queries.USD_VALUES,
      chainId
    ]) as UsdValues;

    /* get all bonus tokens */
    const bonusTokensLength =
      (await rewardProgramContract.getBonusTokenSetLength()) as BigNumber;
    const bonusTokensLengthNumber = bonusTokensLength.toNumber();
    const bonusTokens = await Promise.all(
      Array.from(Array(bonusTokensLengthNumber)).map(async (_, idx) => {
        const tokenAddress = await rewardProgramContract.getBonusTokenAtIndex(
          idx
        );
        const tokenContract = new ethers.Contract(
          tokenAddress,
          IUniswapV2ERC20.abi,
          provider
        );
        const curTokenSymbol = await tokenContract.symbol().catch(() => '');
        const bonusTokenDecimals = await tokenContract
          .decimals()
          .catch(() => 0);
        const tokenSymbol = await formatTokenSymbol(
          tokenAddress,
          curTokenSymbol,
          provider
        );
        return {
          address: tokenAddress,
          tokenSymbol: tokenSymbol,
          decimals: bonusTokenDecimals
        };
      })
    );

    /* request updated blocktimestamp */
    const blockTimestamp = await fetchBlockTimestamp(chainId);

    /* get vault data from the reward program contract */
    const [vaultData, unlockedRewards, totalStakeUnits]: [
      RewardProgramVaultData,
      any,
      any
    ] = await Promise.all([
      rewardProgramContract.getVaultData(crucibleId),
      rewardProgramContract
        .getFutureUnlockedRewards(blockTimestamp)
        .catch(() => 0),
      rewardProgramContract.getFutureTotalStakeUnits(blockTimestamp)
    ]);

    /* initialize the subscriptions summary object */
    const subscriptionsSummary: SubscriptionsSummary = {
      totalValue: 0,
      totalRewardsValue: 0,
      percentageOfRewardPool: 0
    };

    const subscriptions: Subscription[] = await Promise.all(
      vaultData[1].map(async (data) => {
        const [stakedAmount, timestamp] = data;

        /* calculate time elapsed for the subscription */
        const timeElapsed = blockTimestamp - timestamp.toNumber();

        /* fetch the value of the rewardToken's reward from the rewardProgramContract */
        const rewardTokenRewardValue: BigNumber =
          await rewardProgramContract.calculateReward(
            unlockedRewards,
            stakedAmount,
            timeElapsed,
            totalStakeUnits,
            rewardProgramData.rewardScaling
          );

        /* Get staking token LP breakdown if applicable */
        const { lpTokenData } = await getLpTokenBreakdown(
          rewardProgramData.stakingToken,
          stakedAmount,
          chainId
        );

        /* calculate amounts for bonus tokens */
        const bonusTokenRewards: ERC20Token[] = await Promise.all(
          bonusTokens.map(async (token) => {
            const bonusTokenContract = new ethers.Contract(
              token.address,
              IUniswapV2ERC20.abi,
              provider
            );

            const amount = await bonusTokenContract
              .balanceOf(rewardProgramData.rewardPool)
              .catch(() => BigNumber.from(0));

            /* determine the reward pool's balance of that specific bonus token */
            const balanceInRewardPool = await rewardTokenContract
              .balanceOf(rewardProgramData.rewardPool)
              .catch(() => BigNumber.from(0));

            const decimals = await bonusTokenContract.decimals().catch(() => 0);

            /* compute a weighted calculation using the rewardToken reward that you are due */
            const finalAmount: BigNumber = balanceInRewardPool.gt(0)
              ? amount.mul(rewardTokenRewardValue).div(balanceInRewardPool)
              : BigNumber.from(0);

            const bonusTokenAmountUSD =
              usdValues[token.address] *
              bigNumberishToNumber(finalAmount, decimals);

            subscriptionsSummary.totalValue += bonusTokenAmountUSD;
            subscriptionsSummary.totalRewardsValue += bonusTokenAmountUSD;

            return {
              value: {
                amount: finalAmount,
                amountUSD: bonusTokenAmountUSD
              },
              address: token.address,
              symbol: token.tokenSymbol,
              decimals
            };
          })
        );
        const stakingTokenAmountUSD =
          usdValues[stakingTokenAddress] *
          bigNumberishToNumber(stakedAmount, stakingTokenDecimals);

        const rewardTokenAmountUSD =
          usdValues[rewardTokenAddress] *
          bigNumberishToNumber(rewardTokenRewardValue, rewardTokenDecimals);

        /* get reward share */
        const currentVaultStakeUnits =
          await rewardProgramContract.getFutureVaultStakeUnits(
            crucibleId,
            blockTimestamp
          );

        const currentVaultStakeUnitsNumber = bigNumberishToNumber(
          currentVaultStakeUnits
        );

        const currentTotalStakeUnitsNumber =
          bigNumberishToNumber(totalStakeUnits);

        const rewardsShare =
          currentVaultStakeUnitsNumber / currentTotalStakeUnitsNumber;

        subscriptionsSummary.totalValue += stakingTokenAmountUSD;
        subscriptionsSummary.totalValue += rewardTokenAmountUSD;
        subscriptionsSummary.totalRewardsValue += rewardTokenAmountUSD;
        subscriptionsSummary.percentageOfRewardPool = rewardsShare;

        return {
          rewardProgramAddress: rewardProgram.address,
          subscriptionDate: timestamp.toNumber(),
          timeElapsed,
          stakingToken: {
            value: {
              amount: stakedAmount,
              amountUSD: stakingTokenAmountUSD
            },
            address: stakingTokenAddress,
            symbol: stakingTokenSymbol,
            decimals: stakingTokenDecimals,
            lpTokenData
          },
          rewards: {
            rewardToken: {
              value: {
                amount: rewardTokenRewardValue,
                amountUSD: rewardTokenAmountUSD
              },
              address: rewardTokenAddress,
              symbol: rewardTokenSymbol,
              decimals: rewardTokenDecimals
            },
            bonusTokens: bonusTokenRewards
          }
        };
      })
    );

    /* get scaling time */
    const secondsInDay = 60 * 60 * 24;
    const rewardScalingTimestamp =
      rewardProgramData.rewardScaling.time.toNumber();
    const rewardScalingTime = rewardScalingTimestamp / secondsInDay;

    /* create subscriptions meta object  */
    const subscriptionsMeta = {
      rewardToken: {
        address: rewardTokenAddress,
        tokenSymbol: rewardTokenSymbol,
        decimals: rewardTokenDecimals
      },
      stakingToken: {
        address: stakingTokenAddress,
        tokenSymbol: stakingTokenSymbol,
        decimals: stakingTokenDecimals
      },
      bonusTokens,
      rewardProgramData,
      rewardScalingTime,
      rewardScalingTimestamp
    };

    return {
      subscriptions,
      subscriptionsSummary,
      subscriptionsMeta
    };
  } catch (e) {
    console.log(e);
    throw e;
  }
};
