import { useWeb3React } from 'hooks/web3';
import { fetchUsdValues } from 'api/firebase';
import { useQuery } from 'react-query';
import { Queries } from 'types/enum';
import { UsdValues } from 'types/firebase';

export function useUsdValues() {
  const { chainId = 1 } = useWeb3React();
  return useQuery<UsdValues, Error>(
    [Queries.USD_VALUES, chainId],
    () => fetchUsdValues(chainId),
    {
      refetchInterval: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10
    }
  );
}
