import { FC } from 'react';
import { DrawerType, useSideDrawer } from 'store/sideDrawer';
import SideDrawer from 'components/layout/SideDrawer/Overview';
import CruciblesDrawer from 'components/layout/SideDrawer/Crucibles';

const DrawerRoot: FC = () => {
  const { drawerType } = useSideDrawer();

  switch (drawerType) {
    case DrawerType.overview:
      return <SideDrawer />;
    case DrawerType.crucibles:
      return <CruciblesDrawer />;
  }

  return null;
};

export default DrawerRoot;
