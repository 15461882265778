import { useWeb3React } from 'hooks/web3';
import { fetchStats } from 'api/firebase';
import { useQuery } from 'react-query';
import { useConnection } from 'store/connection';
import { Queries } from 'types/enum';
import { Stats } from 'types/firebase';

export function useStats() {
  const { chainId = 1 } = useWeb3React();
  const { tried } = useConnection();
  return useQuery<Stats, Error>([Queries.LANDING_STATS, chainId], () =>
    fetchStats(chainId), { 
      enabled: tried
    }
  );
}
