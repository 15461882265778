import { FC } from 'react';
import { truncate } from 'utils/address';
import { Box, Icon, Link, Text, Tooltip, VStack } from '@chakra-ui/react';
import { RiExternalLinkLine } from 'react-icons/ri';
import { EtherscanLinkType, getEtherscanLink } from 'utils/getEtherscanLink';
import { useWeb3React } from 'hooks/web3';
import { IoWarningOutline } from 'react-icons/io5';

type Props = {
  maintainer: string;
  owner: string;
  link?: boolean;
};

export const Maintainer: FC<Props> = ({ maintainer, owner, link = false }) => {
  const { chainId: signerChainId = 1 } = useWeb3React();
  const params = new URLSearchParams(window.location.search);
  const network = params.get('network');
  const chainId = network ? Number(network) : signerChainId;

  let displayedMaintainer;
  let unverifiedMaintainer;

  if (maintainer === 'Unverified') {
    displayedMaintainer = (
      <Box as='span' color='red.400'>
        {truncate(owner)} <Icon as={IoWarningOutline} />
      </Box>
    );
    unverifiedMaintainer = true;
  } else {
    displayedMaintainer = <Box as='span'>{maintainer}</Box>;
  }

  return (
    <Tooltip
      label={
        unverifiedMaintainer ? (
          <VStack>
            <Text>This maintainer is Unverified.</Text>
            <Text>
              <strong>Proceed with caution</strong>
            </Text>
            <Text fontSize='xs'>{owner}</Text>
          </VStack>
        ) : (
          owner
        )
      }
      hasArrow
    >
      {link ? (
        <Link
          href={getEtherscanLink(chainId, owner, EtherscanLinkType.ADDRESS)}
          isExternal
          textColor={unverifiedMaintainer ? 'red.400' : ''}
        >
          {displayedMaintainer} <Icon as={RiExternalLinkLine} />
        </Link>
      ) : (
        displayedMaintainer
      )}
    </Tooltip>
  );
};
