import { FC } from 'react';
import { Button, Text } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import { useModal } from 'store/modals';
import { ModalType } from './types';
import { useTransactions } from 'store/transactions/useTransactions';
import { ethers } from 'ethers';

type Props = {
  rewardProgramAddress: string;
  address: string;
  method: string;
  aludelAbi: ethers.ContractInterface;
};

const RemoveVaultFactoryModal: FC<Props> = ({
  rewardProgramAddress,
  address,
  method,
  aludelAbi
}) => {
  const { closeModal } = useModal();
  const { updateVaultFactory } = useTransactions();

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.removeVaultFactory)}
    >
      <ModalOverlay />
      <ModalContent borderRadius='xl'>
        <ModalHeader textAlign='center'>Removing Crucible Factory</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={5}>
            Removing the Crucible vault factory will prevent users from being
            able to join your program resulting in its status being set as
            'ended'.
          </Text>
          <Text>
            <strong>Note</strong>: You can still manage your program and re-add
            the factory back if you wish to be relisted.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            isFullWidth
            onClick={async () => {
              updateVaultFactory(
                rewardProgramAddress,
                address,
                method,
                aludelAbi
              );
              closeModal(ModalType.removeVaultFactory);
            }}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RemoveVaultFactoryModal;
