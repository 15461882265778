import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { truncate } from 'utils/address';
import { THUNK_PREFIX } from 'store/enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TxnStatus, TxnType } from 'store/transactions/types';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import formatNumber from 'utils/formatNumber';

const getTx = async (
  library: any,
  crucibleAddress: string,
  amount: string,
  tokenAddress: string
) => {
  const signer = library.getSigner();
  if (tokenAddress === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
    return signer.sendTransaction({
      to: crucibleAddress,
      value: amount
    });
  } else {
    const tokenContract = new ethers.Contract(
      tokenAddress,
      IUniswapV2ERC20.abi,
      library.getSigner()
    );
    const gasLimit = await tokenContract.estimateGas.transfer(
      crucibleAddress,
      amount
    );
    const gasPrice = await signer.getGasPrice();

    return tokenContract.transfer(crucibleAddress, amount, {
      gasLimit: gasLimit,
      gasPrice: gasPrice
    });
  }
};

export const depositToCrucible = createAsyncThunk(
  THUNK_PREFIX.DEPOSIT_TO_CRUCIBLE,
  async ({
    web3React,
    updateTx,
    toast,
    modal,
    logger,
    crucibleAddress,
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    amount
  }: any) => {
    // create UUID for tracking this tx
    const txnId = uuid();

    const description = `${formatNumber.token(
      amount,
      tokenDecimals
    )} ${tokenSymbol} into Crucible ${truncate(crucibleAddress)}`;

    updateTx({
      id: txnId,
      type: TxnType.depositToCrucible,
      status: TxnStatus.Initiated,
      description
    });

    try {
      const { account, chainId, library } = web3React;

      const depositTx = await getTx(
        library,
        crucibleAddress,
        amount,
        tokenAddress
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm deposit request to Crucible transaction' />
        )
      });

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: depositTx.hash
      });

      await depositTx.wait(1);

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: depositTx.hash
      });
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });
      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error: errorMessage
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
