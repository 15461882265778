export const ButtonStyles = {
  baseStyle: {
    fontWeight: 'semibold',
    borderRadius: 'xl'
  },
  variants: {
    solid: {
      bgGradient: 'linear(to-tr, cyan.600, cyan.400 )',
      color: 'cyan.900',
      _hover: {
        bgGradient: 'linear(10deg, purple.700, purple.500 )',
        color: 'purple.100',
        _disabled: {
          bg: 'gray.300',
          color: 'gray.600'
        }
      },
      _disabled: {
        bgGradient: 'linear(to-tr, gray.400, gray.100)',
        color: 'gray.600'
      },
      _active: {
        bg: 'purple.600'
      }
    }
  }
};
