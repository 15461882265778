import sleep from './sleep';

export const fetchAutoRetry: any = async (
  url: string,
  options = {},
  retries: number,
  fetch: any
) =>
  await fetch(url, options).then(async (res: any) => {
    if (res.ok) {
      return res.json();
    }

    if (res.status !== 404 && retries > 0) {
      console.log(
        `[Warning] Fetch failed for ${url}. Retrying... (attempts remaining ${
          retries - 1
        })`
      );
      await sleep(10e3);
      return fetchAutoRetry(url, options, retries - 1, fetch);
    }

    throw new Error(`[ERROR] Fetch failed for ${url}`);
  });
