import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {
  type: 'basic' | 'pro' | 'platinum';
}

const MintBall: React.FC<Props> = (props: Props) => {
  if (props.type === 'basic') return <Base {...props} />;

  if (props.type === 'pro')
    return <Base bgGradient='linear(to-tr, cyan.600, cyan.500)' {...props} />;

  if (props.type === 'platinum')
    return <Base bgGradient='linear(to-br, cyan.600, purple.500)' {...props} />;

  return null;
};

const Base = (props: BoxProps) => (
  <Box
    w='43px'
    h='43px'
    borderRadius='3xl'
    bgGradient='linear(to-tr, gray.50, gray.400)'
    {...props}
  />
);

export default MintBall;
