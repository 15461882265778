import { useStats } from './useStats';
import { useWeb3React } from 'hooks/web3';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useConfig } from 'store/config';
import { SortBy } from 'store/zustand/useExploreFilter';
import { useRewardPrograms } from 'store/zustand/useRewardPrograms';
import { Crucible, CrucibleLockObject } from 'types';
import { useConnection } from 'store/connection';
import { Queries } from 'types/enum';
import {
  getAllCrucibles,
  getLocksForAllCrucibles,
  getOwnedCrucibles
} from 'api/blockchain/crucibles';

export function useCrucibles() {
  const { tried } = useConnection();
  const web3React = useWeb3React();
  const config = useConfig(web3React.chainId || 1).config;

  return useQuery<Crucible[], Error>(
    [Queries.OWNED_CRUCIBLES, web3React.chainId, web3React.account],
    () =>
      getOwnedCrucibles(
        config.crucibleFactoryAddress,
        config.crucibleFactoryCreationBlock,
        config.crucibleSubgraphUrl,
        config.customMinterAddress,
        config.transmuterAddress,
        config.mintingTokenAddress,
        web3React.provider,
        web3React.account!
      ),
    {
      enabled: !!web3React.account && !!web3React.provider && tried
    }
  );
}

export function useAllCrucibles(
  chainId: number,
  sortBy: SortBy,
  shiftBy: number
) {
  const { data: stats } = useStats();
  const web3React = useWeb3React();
  const config = useConfig(chainId).config;

  return useInfiniteQuery<
    {
      crucibles: { crucibleAddress: string; idx: number }[];
      totalCount: number;
    },
    Error
  >(
    [Queries.ALL_CRUCIBLES, chainId],
    ({ pageParam }) =>
      getAllCrucibles(
        config.crucibleFactoryAddress,
        web3React.provider,
        pageParam || 1,
        sortBy,
        shiftBy
      ),
    {
      enabled: !!web3React.provider && !!chainId && !!stats,
      getNextPageParam: (lastPage, pages) => {
        const totalCount = lastPage.totalCount;
        const chunks = 12;
        const maxPages = Math.ceil(totalCount / chunks);

        if (pages.length < maxPages) {
          return pages.length + 1;
        }
      }
    }
  );
}

// Pass in crucible when getting locks for single crucible from explore
export function useCruciblesLocks(crucible?: Crucible) {
  const { currentRewardProgram, rewardPrograms } = useRewardPrograms();
  const { data: crucibles, dataUpdatedAt: crucibleDateUpdatedAt } =
    useCrucibles();
  const web3React = useWeb3React();

  return useQuery<CrucibleLockObject, Error>(
    [
      Queries.CRUCIBLES_LOCKS,
      web3React.chainId,
      web3React.account,
      crucibleDateUpdatedAt,
      crucible?.id
    ],
    () =>
      getLocksForAllCrucibles(
        web3React.chainId || 1,
        web3React.provider,
        crucible ? [crucible] : crucibles!,
        rewardPrograms,
        currentRewardProgram
      ),
    {
      enabled: crucible ? true : !!crucibles
    }
  );
}
