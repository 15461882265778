import { FC } from 'react';
import { HStack } from '@chakra-ui/layout';
import { truncate } from 'utils/address';
import { Button } from '@chakra-ui/react';
import { VscLink } from 'react-icons/vsc';
import { useWeb3React } from 'hooks/web3';
import { useModal } from 'store/modals';
import { ModalType } from 'components/modals/types';
import { getAddress } from 'ethers/lib/utils';
import NetworkPill from 'components/connectWallet/NetworkPill';
import GradientBackground from 'components/shared/GradientBackground';
const UserWallet: FC = () => {
  const { account } = useWeb3React();
  const { openModal } = useModal();

  const openWalletModal = () => {
    openModal(ModalType.connectWallet);
  };

  const buttonProps = {
    bg: 'gray.900',
    variant: 'ghost',
    _focus: { bg: 'gray.900' },
    _hover: { bg: 'gray.800' },
    _active: { bg: 'gray.800' }
  };

  if (account) {
    return (
      <HStack spacing={4}>
        <NetworkPill />
        <GradientBackground visible borderRadius='xl'>
          <Button onClick={openWalletModal} {...buttonProps}>
            {truncate(getAddress(account))}
          </Button>
        </GradientBackground>
      </HStack>
    );
  }

  return (
    <HStack spacing={4}>
      <NetworkPill />
      <GradientBackground visible borderRadius='xl'>
        <Button
          rightIcon={<VscLink />}
          onClick={openWalletModal}
          {...buttonProps}
        >
          Connect Wallet
        </Button>
      </GradientBackground>
    </HStack>
  );
};

export default UserWallet;
