import { Box, Text, Link, HStack, Heading, SimpleGrid } from '@chakra-ui/react';
import { RiExternalLinkLine } from 'react-icons/ri';
import { EtherscanLinkType, getEtherscanLink } from 'utils/getEtherscanLink';
import { useWeb3React } from 'hooks/web3';
import { truncate } from 'utils/address';
import { Tooltip } from '@chakra-ui/tooltip';
import { usePrograms } from 'hooks/query/usePrograms';
import { useMemo } from 'react';
import { useUsdValues } from 'hooks/query/useUsdValues';
import { RewardProgram, RewardProgramTokens } from 'types';
import { Maintainer } from 'components/rewardPrograms/Maintainer';

type Props = {
  rewardProgram: RewardProgram;
  rewardProgramTokens: RewardProgramTokens;
};

const RewardProgramDescription: React.FC<Props> = ({
  rewardProgram,
  rewardProgramTokens
}) => {
  const { chainId = 1 } = useWeb3React();
  const { data: programsData } = usePrograms();
  const { data: tokenUsdValues } = useUsdValues();
  const { stakingToken, rewardToken, bonusTokens } = rewardProgramTokens;
  const currentRewardProgramData = useMemo(() => {
    if (programsData) {
      return programsData.find(
        (program) => program.rewardProgramAddress === rewardProgram.address
      );
    }
  }, [programsData, rewardProgram.address]);

  type TokenBadgeProps = {
    symbol: string | undefined;
    tokenAddress: string | undefined;
  };
  const TokenBadge: React.FC<TokenBadgeProps> = ({ symbol, tokenAddress }) => (
    <Tooltip
      label={
        tokenUsdValues &&
        tokenAddress &&
        `Current Price: $${tokenUsdValues[tokenAddress]}`
      }
      placement='top'
      hasArrow
    >
      <Box
        key={tokenAddress}
        width='full'
        minWidth={'min-content'}
        maxWidth={'200'}
        align='center'
        py={2}
        px={4}
        bg='gray.600'
        borderRadius='xl'
        _hover={{
          cursor: 'pointer'
        }}
      >
        {truncate(symbol, 16, 0)}
      </Box>
    </Tooltip>
  );

  return (
    <Box p={5} bg='gray.700' borderRadius='xl'>
      <Text textAlign='left' fontSize='lg' mb={1} fontWeight='600'>
        Description
      </Text>
      <Text textAlign='left' mb={4} color='gray.200' wordBreak='break-word'>
        {rewardProgram.description}
      </Text>
      <Heading textAlign='left' size='sm' mb={1}>
        Maintainer
      </Heading>
      <Text textAlign='left' mb={4}>
        <Maintainer
          maintainer={rewardProgram.maintainer}
          owner={rewardProgram.owner}
          link={true}
        />
      </Text>
      <Heading textAlign='left' size='sm' mb={2}>
        <Link href={rewardProgram.getStakingTokenUrl} isExternal>
          <HStack>
            <Text fontWeight='600'>Staking token</Text> <RiExternalLinkLine />
          </HStack>
        </Link>
      </Heading>
      <Box textAlign='left' mb={4}>
        <TokenBadge
          symbol={stakingToken?.tokenSymbol}
          tokenAddress={stakingToken?.address}
        />
      </Box>
      <Heading textAlign='left' size='sm' mb={1}>
        Leaderboard
      </Heading>
      <Link href={`/leaderboard/${rewardProgram.address}`} isExternal>
        <HStack mb={4}>
          <Text>View leaderboard</Text> <RiExternalLinkLine />
        </HStack>
      </Link>
      <Text fontWeight='600' textAlign='left' mb={1}>
        Contract address
      </Text>
      <Link
        href={getEtherscanLink(
          chainId,
          rewardProgram.address,
          EtherscanLinkType.ADDRESS
        )}
        isExternal
      >
        <HStack alignItems={'top'} mt={2}>
          <Tooltip
            label={rewardProgram.address}
            fontSize='md'
            placement='bottom-end'
            mb={2}
            hasArrow
          >
            <Text textAlign='left' mb={4} mt={-1}>
              {truncate(rewardProgram.address)}
            </Text>
          </Tooltip>
          <RiExternalLinkLine />
        </HStack>
      </Link>

      <Text fontWeight='600' textAlign='left' mb={1}>
        Pool address
      </Text>
      <Link
        href={getEtherscanLink(
          chainId,
          currentRewardProgramData?.poolAddress || '',
          EtherscanLinkType.ADDRESS
        )}
        isExternal
      >
        <HStack alignItems={'top'} mt={2}>
          <Tooltip
            label={currentRewardProgramData?.poolAddress}
            fontSize='md'
            placement='bottom-end'
            mb={2}
            hasArrow
          >
            <Text textAlign='left' mb={4} mt={-1}>
              {truncate(currentRewardProgramData?.poolAddress)}
            </Text>
          </Tooltip>
          <RiExternalLinkLine />
        </HStack>
      </Link>

      <Text fontWeight='600' textAlign='left' mb={2}>
        Reward tokens
      </Text>
      <SimpleGrid columns={[1, 4]} spacing={4}>
        <TokenBadge
          symbol={rewardToken?.tokenSymbol}
          tokenAddress={rewardToken?.address}
        />
        {bonusTokens?.map((token) => (
          <TokenBadge symbol={token.tokenSymbol} tokenAddress={token.address} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default RewardProgramDescription;
