import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react';
import { FC } from 'react';
import { useModal } from 'store/modals';
import { isMobile } from 'react-device-detect';
import { ModalType } from './types';
import { useTransactions } from 'store/transactions/useTransactions';
import { PowerSwitchStatus } from 'api/blockchain/rewardProgram';
import { RewardProgram } from 'types';

type Props = {
  type: PowerSwitchStatus;
  crucibleId: string;
  currentRewardProgram: RewardProgram;
  stakingTokenAddress: string;
};

const RageQuitModal: FC<Props> = ({
  type,
  crucibleId,
  currentRewardProgram,
  stakingTokenAddress
}) => {
  const { closeModal } = useModal();
  const { rageQuit } = useTransactions();

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.rageQuit)}
      size={isMobile ? 'full' : 'md'}
      scrollBehavior={isMobile ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        bg='gray.800'
        mt={isMobile ? 0 : undefined}
        borderRadius={isMobile ? 'none' : 'xl'}
      >
        <ModalHeader>
          <Text textAlign='center'>Exit from Reward Program</Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Text>
            {(type === PowerSwitchStatus.ONLINE ||
              type === PowerSwitchStatus.NOTSTARTED) && (
              <Stack direction='column' spacing={6}>
                <Text color='gray.200'>
                  This method is used to force exit from the current reward
                  program in order to release your staked tokens.
                </Text>
                <Text color='gray.200'>
                  You can use this option if there is a problem with the reward
                  program contract.
                </Text>
                <Text color='red.500'>
                  Using this method will <strong>forfeit all rewards</strong>{' '}
                  for this program.
                </Text>
                <Text color='gray.200'>
                  Use this method as a last resort as the process is
                  irreversible.
                </Text>
              </Stack>
            )}
            {type === PowerSwitchStatus.SHUTDOWN && (
              <Stack direction='column' spacing={6}>
                <Text color='gray.200'>
                  This reward program has been{' '}
                  <strong>permanently shutdown</strong>, the only way to release
                  your staked tokens is by a forced exit method.
                </Text>
                <Text color='red.500'>
                  By using this method you will be{' '}
                  <strong>forfeiting your rewards</strong> for this program as
                  they are no longer claimable.
                </Text>
              </Stack>
            )}
            {type === PowerSwitchStatus.OFFLINE && (
              <Stack direction='column' spacing={6}>
                <Text color='gray.200'>
                  This reward program has been <strong>set to Offline</strong>,
                  which usually occurs when a reward program is under
                  maintenance by the maintainer.
                </Text>
                <Text color='gray.200'>
                  During this time you are unable to claim your rewards and
                  leave normally, however if you wish to force exit, you can do
                  so by using this feature.
                </Text>
                <Text color='red.500'>
                  Proceeding will result in the{' '}
                  <strong>forfeit of your rewards</strong> for this program in
                  order to unlock your staked tokens.
                </Text>
              </Stack>
            )}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            isFullWidth
            bgGradient='linear(to-tr, red, red.400 )'
            onClick={() => {
              rageQuit(crucibleId, currentRewardProgram, stakingTokenAddress);
              closeModal(ModalType.rageQuit);
              window.scrollTo(0, 0);
            }}
          >
            Force Exit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RageQuitModal;
