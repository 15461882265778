export const balancerBPT = [
  {
    inputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'src', type: 'address' },
      { indexed: true, internalType: 'address', name: 'dst', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amt', type: 'uint256' }
    ],
    name: 'Approval',
    type: 'event'
  },
  {
    anonymous: true,
    inputs: [
      { indexed: true, internalType: 'bytes4', name: 'sig', type: 'bytes4' },
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      },
      { indexed: false, internalType: 'bytes', name: 'data', type: 'bytes' }
    ],
    name: 'LOG_CALL',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenAmountOut',
        type: 'uint256'
      }
    ],
    name: 'LOG_EXIT',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenAmountIn',
        type: 'uint256'
      }
    ],
    name: 'LOG_JOIN',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenAmountIn',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenAmountOut',
        type: 'uint256'
      }
    ],
    name: 'LOG_SWAP',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'src', type: 'address' },
      { indexed: true, internalType: 'address', name: 'dst', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amt', type: 'uint256' }
    ],
    name: 'Transfer',
    type: 'event'
  },
  {
    constant: true,
    inputs: [],
    name: 'BONE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'BPOW_PRECISION',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'EXIT_FEE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'INIT_POOL_SUPPLY',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_BOUND_TOKENS',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_BPOW_BASE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_FEE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_IN_RATIO',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_OUT_RATIO',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_TOTAL_WEIGHT',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_WEIGHT',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MIN_BALANCE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MIN_BOUND_TOKENS',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MIN_BPOW_BASE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MIN_FEE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MIN_WEIGHT',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'address', name: 'src', type: 'address' },
      { internalType: 'address', name: 'dst', type: 'address' }
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'amt', type: 'uint256' }
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ internalType: 'address', name: 'whom', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'balance', type: 'uint256' },
      { internalType: 'uint256', name: 'denorm', type: 'uint256' }
    ],
    name: 'bind',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'uint256', name: 'tokenBalanceIn', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightIn', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenBalanceOut', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightOut', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'swapFee', type: 'uint256' }
    ],
    name: 'calcInGivenOut',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'uint256', name: 'tokenBalanceIn', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightIn', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenBalanceOut', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightOut', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'swapFee', type: 'uint256' }
    ],
    name: 'calcOutGivenIn',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'uint256', name: 'tokenBalanceOut', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightOut', type: 'uint256' },
      { internalType: 'uint256', name: 'poolSupply', type: 'uint256' },
      { internalType: 'uint256', name: 'totalWeight', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'swapFee', type: 'uint256' }
    ],
    name: 'calcPoolInGivenSingleOut',
    outputs: [
      { internalType: 'uint256', name: 'poolAmountIn', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'uint256', name: 'tokenBalanceIn', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightIn', type: 'uint256' },
      { internalType: 'uint256', name: 'poolSupply', type: 'uint256' },
      { internalType: 'uint256', name: 'totalWeight', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'swapFee', type: 'uint256' }
    ],
    name: 'calcPoolOutGivenSingleIn',
    outputs: [
      { internalType: 'uint256', name: 'poolAmountOut', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'uint256', name: 'tokenBalanceIn', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightIn', type: 'uint256' },
      { internalType: 'uint256', name: 'poolSupply', type: 'uint256' },
      { internalType: 'uint256', name: 'totalWeight', type: 'uint256' },
      { internalType: 'uint256', name: 'poolAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'swapFee', type: 'uint256' }
    ],
    name: 'calcSingleInGivenPoolOut',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'uint256', name: 'tokenBalanceOut', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightOut', type: 'uint256' },
      { internalType: 'uint256', name: 'poolSupply', type: 'uint256' },
      { internalType: 'uint256', name: 'totalWeight', type: 'uint256' },
      { internalType: 'uint256', name: 'poolAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'swapFee', type: 'uint256' }
    ],
    name: 'calcSingleOutGivenPoolIn',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'uint256', name: 'tokenBalanceIn', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightIn', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenBalanceOut', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenWeightOut', type: 'uint256' },
      { internalType: 'uint256', name: 'swapFee', type: 'uint256' }
    ],
    name: 'calcSpotPrice',
    outputs: [{ internalType: 'uint256', name: 'spotPrice', type: 'uint256' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'amt', type: 'uint256' }
    ],
    name: 'decreaseApproval',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'uint256', name: 'poolAmountIn', type: 'uint256' },
      { internalType: 'uint256[]', name: 'minAmountsOut', type: 'uint256[]' }
    ],
    name: 'exitPool',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'tokenOut', type: 'address' },
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'maxPoolAmountIn', type: 'uint256' }
    ],
    name: 'exitswapExternAmountOut',
    outputs: [
      { internalType: 'uint256', name: 'poolAmountIn', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'tokenOut', type: 'address' },
      { internalType: 'uint256', name: 'poolAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'minAmountOut', type: 'uint256' }
    ],
    name: 'exitswapPoolAmountIn',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'finalize',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getBalance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getColor',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getController',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getCurrentTokens',
    outputs: [{ internalType: 'address[]', name: 'tokens', type: 'address[]' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getDenormalizedWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getFinalTokens',
    outputs: [{ internalType: 'address[]', name: 'tokens', type: 'address[]' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getNormalizedWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getNumTokens',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'address', name: 'tokenIn', type: 'address' },
      { internalType: 'address', name: 'tokenOut', type: 'address' }
    ],
    name: 'getSpotPrice',
    outputs: [{ internalType: 'uint256', name: 'spotPrice', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { internalType: 'address', name: 'tokenIn', type: 'address' },
      { internalType: 'address', name: 'tokenOut', type: 'address' }
    ],
    name: 'getSpotPriceSansFee',
    outputs: [{ internalType: 'uint256', name: 'spotPrice', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getSwapFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getTotalDenormalizedWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'gulp',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'amt', type: 'uint256' }
    ],
    name: 'increaseApproval',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ internalType: 'address', name: 't', type: 'address' }],
    name: 'isBound',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'isFinalized',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'isPublicSwap',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'uint256', name: 'poolAmountOut', type: 'uint256' },
      { internalType: 'uint256[]', name: 'maxAmountsIn', type: 'uint256[]' }
    ],
    name: 'joinPool',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'tokenIn', type: 'address' },
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'minPoolAmountOut', type: 'uint256' }
    ],
    name: 'joinswapExternAmountIn',
    outputs: [
      { internalType: 'uint256', name: 'poolAmountOut', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'tokenIn', type: 'address' },
      { internalType: 'uint256', name: 'poolAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'maxAmountIn', type: 'uint256' }
    ],
    name: 'joinswapPoolAmountOut',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'balance', type: 'uint256' },
      { internalType: 'uint256', name: 'denorm', type: 'uint256' }
    ],
    name: 'rebind',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ internalType: 'address', name: 'manager', type: 'address' }],
    name: 'setController',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ internalType: 'bool', name: 'public_', type: 'bool' }],
    name: 'setPublicSwap',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ internalType: 'uint256', name: 'swapFee', type: 'uint256' }],
    name: 'setSwapFee',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'tokenIn', type: 'address' },
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' },
      { internalType: 'address', name: 'tokenOut', type: 'address' },
      { internalType: 'uint256', name: 'minAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'maxPrice', type: 'uint256' }
    ],
    name: 'swapExactAmountIn',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'spotPriceAfter', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'tokenIn', type: 'address' },
      { internalType: 'uint256', name: 'maxAmountIn', type: 'uint256' },
      { internalType: 'address', name: 'tokenOut', type: 'address' },
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'maxPrice', type: 'uint256' }
    ],
    name: 'swapExactAmountOut',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'spotPriceAfter', type: 'uint256' }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'amt', type: 'uint256' }
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'src', type: 'address' },
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'amt', type: 'uint256' }
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'unbind',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  }
];

export const balancerSmartPool = [
  {
    inputs: [
      { internalType: 'address', name: 'factoryAddress', type: 'address' },
      {
        components: [
          { internalType: 'string', name: 'poolTokenSymbol', type: 'string' },
          { internalType: 'string', name: 'poolTokenName', type: 'string' },
          {
            internalType: 'address[]',
            name: 'constituentTokens',
            type: 'address[]'
          },
          {
            internalType: 'uint256[]',
            name: 'tokenBalances',
            type: 'uint256[]'
          },
          {
            internalType: 'uint256[]',
            name: 'tokenWeights',
            type: 'uint256[]'
          },
          { internalType: 'uint256', name: 'swapFee', type: 'uint256' }
        ],
        internalType: 'struct ConfigurableRightsPool.PoolParams',
        name: 'poolParams',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'bool', name: 'canPauseSwapping', type: 'bool' },
          { internalType: 'bool', name: 'canChangeSwapFee', type: 'bool' },
          { internalType: 'bool', name: 'canChangeWeights', type: 'bool' },
          { internalType: 'bool', name: 'canAddRemoveTokens', type: 'bool' },
          { internalType: 'bool', name: 'canWhitelistLPs', type: 'bool' },
          { internalType: 'bool', name: 'canChangeCap', type: 'bool' }
        ],
        internalType: 'struct RightsManager.Rights',
        name: 'rightsStruct',
        type: 'tuple'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256'
      }
    ],
    name: 'Approval',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'oldCap',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newCap',
        type: 'uint256'
      }
    ],
    name: 'CapChanged',
    type: 'event'
  },
  {
    anonymous: true,
    inputs: [
      { indexed: true, internalType: 'bytes4', name: 'sig', type: 'bytes4' },
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      },
      { indexed: false, internalType: 'bytes', name: 'data', type: 'bytes' }
    ],
    name: 'LogCall',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenAmountOut',
        type: 'uint256'
      }
    ],
    name: 'LogExit',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenAmountIn',
        type: 'uint256'
      }
    ],
    name: 'LogJoin',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      { indexed: true, internalType: 'address', name: 'pool', type: 'address' },
      {
        indexed: true,
        internalType: 'address',
        name: 'caller',
        type: 'address'
      }
    ],
    name: 'NewTokenCommitted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256'
      }
    ],
    name: 'Transfer',
    type: 'event'
  },
  {
    inputs: [],
    name: 'DECIMALS',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'DEFAULT_ADD_TOKEN_TIME_LOCK_IN_BLOCKS',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'DEFAULT_MIN_WEIGHT_CHANGE_BLOCK_PERIOD',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'NAME',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'addTokenTimeLockInBlocks',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' }
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'applyAddToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'bFactory',
    outputs: [
      { internalType: 'contract IBFactory', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'bPool',
    outputs: [{ internalType: 'contract IBPool', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'bspCap',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'burnPoolShareFromLib',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'provider', type: 'address' }],
    name: 'canProvideLiquidity',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'balance', type: 'uint256' },
      { internalType: 'uint256', name: 'denormalizedWeight', type: 'uint256' }
    ],
    name: 'commitAddToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'initialSupply', type: 'uint256' }
    ],
    name: 'createPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'initialSupply', type: 'uint256' },
      {
        internalType: 'uint256',
        name: 'minimumWeightChangeBlockPeriodParam',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'addTokenTimeLockInBlocksParam',
        type: 'uint256'
      }
    ],
    name: 'createPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'decreaseApproval',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolAmountIn', type: 'uint256' },
      { internalType: 'uint256[]', name: 'minAmountsOut', type: 'uint256[]' }
    ],
    name: 'exitPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'tokenOut', type: 'address' },
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'maxPoolAmountIn', type: 'uint256' }
    ],
    name: 'exitswapExternAmountOut',
    outputs: [
      { internalType: 'uint256', name: 'poolAmountIn', type: 'uint256' }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'tokenOut', type: 'address' },
      { internalType: 'uint256', name: 'poolAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'minAmountOut', type: 'uint256' }
    ],
    name: 'exitswapPoolAmountIn',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountOut', type: 'uint256' }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getBalancerSafeMathVersion',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getController',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getDenormalizedWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getRightsManagerVersion',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getSmartPoolManagerVersion',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [],
    name: 'gradualUpdate',
    outputs: [
      { internalType: 'uint256', name: 'startBlock', type: 'uint256' },
      { internalType: 'uint256', name: 'endBlock', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'enum RightsManager.Permissions',
        name: 'permission',
        type: 'uint8'
      }
    ],
    name: 'hasPermission',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'increaseApproval',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'isPublicSwap',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolAmountOut', type: 'uint256' },
      { internalType: 'uint256[]', name: 'maxAmountsIn', type: 'uint256[]' }
    ],
    name: 'joinPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'tokenIn', type: 'address' },
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'minPoolAmountOut', type: 'uint256' }
    ],
    name: 'joinswapExternAmountIn',
    outputs: [
      { internalType: 'uint256', name: 'poolAmountOut', type: 'uint256' }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'tokenIn', type: 'address' },
      { internalType: 'uint256', name: 'poolAmountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'maxAmountIn', type: 'uint256' }
    ],
    name: 'joinswapPoolAmountOut',
    outputs: [
      { internalType: 'uint256', name: 'tokenAmountIn', type: 'uint256' }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'minimumWeightChangeBlockPeriod',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'mintPoolShareFromLib',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'newToken',
    outputs: [
      { internalType: 'address', name: 'addr', type: 'address' },
      { internalType: 'bool', name: 'isCommitted', type: 'bool' },
      { internalType: 'uint256', name: 'commitBlock', type: 'uint256' },
      { internalType: 'uint256', name: 'denorm', type: 'uint256' },
      { internalType: 'uint256', name: 'balance', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'pokeWeights',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'pullPoolShareFromLib',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'pushPoolShareFromLib',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'removeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'provider', type: 'address' }],
    name: 'removeWhitelistedLiquidityProvider',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'resyncWeight',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'rights',
    outputs: [
      { internalType: 'bool', name: 'canPauseSwapping', type: 'bool' },
      { internalType: 'bool', name: 'canChangeSwapFee', type: 'bool' },
      { internalType: 'bool', name: 'canChangeWeights', type: 'bool' },
      { internalType: 'bool', name: 'canAddRemoveTokens', type: 'bool' },
      { internalType: 'bool', name: 'canWhitelistLPs', type: 'bool' },
      { internalType: 'bool', name: 'canChangeCap', type: 'bool' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newCap', type: 'uint256' }],
    name: 'setCap',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'setController',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bool', name: 'publicSwap', type: 'bool' }],
    name: 'setPublicSwap',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'swapFee', type: 'uint256' }],
    name: 'setSwapFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'newWeight', type: 'uint256' }
    ],
    name: 'updateWeight',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'newWeights', type: 'uint256[]' },
      { internalType: 'uint256', name: 'startBlock', type: 'uint256' },
      { internalType: 'uint256', name: 'endBlock', type: 'uint256' }
    ],
    name: 'updateWeightsGradually',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'provider', type: 'address' }],
    name: 'whitelistLiquidityProvider',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
];

export const BalancerWeightedBPT = [
  {
    inputs: [
      { internalType: 'contract IVault', name: 'vault', type: 'address' },
      { internalType: 'string', name: 'name', type: 'string' },
      { internalType: 'string', name: 'symbol', type: 'string' },
      { internalType: 'contract IERC20[]', name: 'tokens', type: 'address[]' },
      {
        internalType: 'uint256[]',
        name: 'normalizedWeights',
        type: 'uint256[]'
      },
      { internalType: 'uint256', name: 'swapFeePercentage', type: 'uint256' },
      { internalType: 'uint256', name: 'pauseWindowDuration', type: 'uint256' },
      {
        internalType: 'uint256',
        name: 'bufferPeriodDuration',
        type: 'uint256'
      },
      { internalType: 'address', name: 'owner', type: 'address' }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256'
      }
    ],
    name: 'Approval',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bool', name: 'paused', type: 'bool' }
    ],
    name: 'PausedStateChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'swapFeePercentage',
        type: 'uint256'
      }
    ],
    name: 'SwapFeePercentageChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256'
      }
    ],
    name: 'Transfer',
    type: 'event'
  },
  {
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' }
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'decreaseApproval',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'selector', type: 'bytes4' }],
    name: 'getActionId',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getAuthorizer',
    outputs: [
      { internalType: 'contract IAuthorizer', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getInvariant',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getLastInvariant',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getNormalizedWeights',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getOwner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getPausedState',
    outputs: [
      { internalType: 'bool', name: 'paused', type: 'bool' },
      { internalType: 'uint256', name: 'pauseWindowEndTime', type: 'uint256' },
      { internalType: 'uint256', name: 'bufferPeriodEndTime', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getPoolId',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getRate',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getSwapFeePercentage',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getVault',
    outputs: [{ internalType: 'contract IVault', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'increaseApproval',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'nonces',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
      { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
      {
        internalType: 'uint256',
        name: 'protocolSwapFeePercentage',
        type: 'uint256'
      },
      { internalType: 'bytes', name: 'userData', type: 'bytes' }
    ],
    name: 'onExitPool',
    outputs: [
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
      { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
      {
        internalType: 'uint256',
        name: 'protocolSwapFeePercentage',
        type: 'uint256'
      },
      { internalType: 'bytes', name: 'userData', type: 'bytes' }
    ],
    name: 'onJoinPool',
    outputs: [
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'enum IVault.SwapKind', name: 'kind', type: 'uint8' },
          { internalType: 'contract IERC20', name: 'tokenIn', type: 'address' },
          {
            internalType: 'contract IERC20',
            name: 'tokenOut',
            type: 'address'
          },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
          { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'bytes', name: 'userData', type: 'bytes' }
        ],
        internalType: 'struct IPoolSwapStructs.SwapRequest',
        name: 'request',
        type: 'tuple'
      },
      { internalType: 'uint256', name: 'balanceTokenIn', type: 'uint256' },
      { internalType: 'uint256', name: 'balanceTokenOut', type: 'uint256' }
    ],
    name: 'onSwap',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
      { internalType: 'uint8', name: 'v', type: 'uint8' },
      { internalType: 'bytes32', name: 'r', type: 'bytes32' },
      { internalType: 'bytes32', name: 's', type: 'bytes32' }
    ],
    name: 'permit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
      { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
      {
        internalType: 'uint256',
        name: 'protocolSwapFeePercentage',
        type: 'uint256'
      },
      { internalType: 'bytes', name: 'userData', type: 'bytes' }
    ],
    name: 'queryExit',
    outputs: [
      { internalType: 'uint256', name: 'bptIn', type: 'uint256' },
      { internalType: 'uint256[]', name: 'amountsOut', type: 'uint256[]' }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
      { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
      {
        internalType: 'uint256',
        name: 'protocolSwapFeePercentage',
        type: 'uint256'
      },
      { internalType: 'bytes', name: 'userData', type: 'bytes' }
    ],
    name: 'queryJoin',
    outputs: [
      { internalType: 'uint256', name: 'bptOut', type: 'uint256' },
      { internalType: 'uint256[]', name: 'amountsIn', type: 'uint256[]' }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bool', name: 'paused', type: 'bool' }],
    name: 'setPaused',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'swapFeePercentage', type: 'uint256' }
    ],
    name: 'setSwapFeePercentage',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' }
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function'
  }
];
export const BalancerVault = [
  {
    inputs: [
      {
        internalType: 'contract IAuthorizer',
        name: 'authorizer',
        type: 'address'
      },
      { internalType: 'contract IWETH', name: 'weth', type: 'address' },
      { internalType: 'uint256', name: 'pauseWindowDuration', type: 'uint256' },
      { internalType: 'uint256', name: 'bufferPeriodDuration', type: 'uint256' }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract IAuthorizer',
        name: 'newAuthorizer',
        type: 'address'
      }
    ],
    name: 'AuthorizerChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract IERC20',
        name: 'token',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'recipient',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'ExternalBalanceTransfer',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract IFlashLoanRecipient',
        name: 'recipient',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'contract IERC20',
        name: 'token',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'feeAmount',
        type: 'uint256'
      }
    ],
    name: 'FlashLoan',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      {
        indexed: true,
        internalType: 'contract IERC20',
        name: 'token',
        type: 'address'
      },
      { indexed: false, internalType: 'int256', name: 'delta', type: 'int256' }
    ],
    name: 'InternalBalanceChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bool', name: 'paused', type: 'bool' }
    ],
    name: 'PausedStateChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'poolId',
        type: 'bytes32'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'liquidityProvider',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'contract IERC20[]',
        name: 'tokens',
        type: 'address[]'
      },
      {
        indexed: false,
        internalType: 'int256[]',
        name: 'deltas',
        type: 'int256[]'
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'protocolFeeAmounts',
        type: 'uint256[]'
      }
    ],
    name: 'PoolBalanceChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'poolId',
        type: 'bytes32'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'assetManager',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'contract IERC20',
        name: 'token',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'int256',
        name: 'cashDelta',
        type: 'int256'
      },
      {
        indexed: false,
        internalType: 'int256',
        name: 'managedDelta',
        type: 'int256'
      }
    ],
    name: 'PoolBalanceManaged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'poolId',
        type: 'bytes32'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'poolAddress',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'enum IVault.PoolSpecialization',
        name: 'specialization',
        type: 'uint8'
      }
    ],
    name: 'PoolRegistered',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'relayer',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' }
    ],
    name: 'RelayerApprovalChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'poolId',
        type: 'bytes32'
      },
      {
        indexed: true,
        internalType: 'contract IERC20',
        name: 'tokenIn',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'contract IERC20',
        name: 'tokenOut',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountOut',
        type: 'uint256'
      }
    ],
    name: 'Swap',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'poolId',
        type: 'bytes32'
      },
      {
        indexed: false,
        internalType: 'contract IERC20[]',
        name: 'tokens',
        type: 'address[]'
      }
    ],
    name: 'TokensDeregistered',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'poolId',
        type: 'bytes32'
      },
      {
        indexed: false,
        internalType: 'contract IERC20[]',
        name: 'tokens',
        type: 'address[]'
      },
      {
        indexed: false,
        internalType: 'address[]',
        name: 'assetManagers',
        type: 'address[]'
      }
    ],
    name: 'TokensRegistered',
    type: 'event'
  },
  {
    inputs: [],
    name: 'WETH',
    outputs: [{ internalType: 'contract IWETH', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'enum IVault.SwapKind', name: 'kind', type: 'uint8' },
      {
        components: [
          { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
          { internalType: 'uint256', name: 'assetInIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'assetOutIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'bytes', name: 'userData', type: 'bytes' }
        ],
        internalType: 'struct IVault.BatchSwapStep[]',
        name: 'swaps',
        type: 'tuple[]'
      },
      { internalType: 'contract IAsset[]', name: 'assets', type: 'address[]' },
      {
        components: [
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'bool', name: 'fromInternalBalance', type: 'bool' },
          {
            internalType: 'address payable',
            name: 'recipient',
            type: 'address'
          },
          { internalType: 'bool', name: 'toInternalBalance', type: 'bool' }
        ],
        internalType: 'struct IVault.FundManagement',
        name: 'funds',
        type: 'tuple'
      },
      { internalType: 'int256[]', name: 'limits', type: 'int256[]' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' }
    ],
    name: 'batchSwap',
    outputs: [
      { internalType: 'int256[]', name: 'assetDeltas', type: 'int256[]' }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'contract IERC20[]', name: 'tokens', type: 'address[]' }
    ],
    name: 'deregisterTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address payable', name: 'recipient', type: 'address' },
      {
        components: [
          {
            internalType: 'contract IAsset[]',
            name: 'assets',
            type: 'address[]'
          },
          {
            internalType: 'uint256[]',
            name: 'minAmountsOut',
            type: 'uint256[]'
          },
          { internalType: 'bytes', name: 'userData', type: 'bytes' },
          { internalType: 'bool', name: 'toInternalBalance', type: 'bool' }
        ],
        internalType: 'struct IVault.ExitPoolRequest',
        name: 'request',
        type: 'tuple'
      }
    ],
    name: 'exitPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract IFlashLoanRecipient',
        name: 'recipient',
        type: 'address'
      },
      { internalType: 'contract IERC20[]', name: 'tokens', type: 'address[]' },
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
      { internalType: 'bytes', name: 'userData', type: 'bytes' }
    ],
    name: 'flashLoan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'selector', type: 'bytes4' }],
    name: 'getActionId',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getAuthorizer',
    outputs: [
      { internalType: 'contract IAuthorizer', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getDomainSeparator',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'contract IERC20[]', name: 'tokens', type: 'address[]' }
    ],
    name: 'getInternalBalance',
    outputs: [
      { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getNextNonce',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getPausedState',
    outputs: [
      { internalType: 'bool', name: 'paused', type: 'bool' },
      { internalType: 'uint256', name: 'pauseWindowEndTime', type: 'uint256' },
      { internalType: 'uint256', name: 'bufferPeriodEndTime', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'poolId', type: 'bytes32' }],
    name: 'getPool',
    outputs: [
      { internalType: 'address', name: '', type: 'address' },
      {
        internalType: 'enum IVault.PoolSpecialization',
        name: '',
        type: 'uint8'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'contract IERC20', name: 'token', type: 'address' }
    ],
    name: 'getPoolTokenInfo',
    outputs: [
      { internalType: 'uint256', name: 'cash', type: 'uint256' },
      { internalType: 'uint256', name: 'managed', type: 'uint256' },
      { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' },
      { internalType: 'address', name: 'assetManager', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'poolId', type: 'bytes32' }],
    name: 'getPoolTokens',
    outputs: [
      { internalType: 'contract IERC20[]', name: 'tokens', type: 'address[]' },
      { internalType: 'uint256[]', name: 'balances', type: 'uint256[]' },
      { internalType: 'uint256', name: 'lastChangeBlock', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getProtocolFeesCollector',
    outputs: [
      {
        internalType: 'contract ProtocolFeesCollector',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'address', name: 'relayer', type: 'address' }
    ],
    name: 'hasApprovedRelayer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      {
        components: [
          {
            internalType: 'contract IAsset[]',
            name: 'assets',
            type: 'address[]'
          },
          {
            internalType: 'uint256[]',
            name: 'maxAmountsIn',
            type: 'uint256[]'
          },
          { internalType: 'bytes', name: 'userData', type: 'bytes' },
          { internalType: 'bool', name: 'fromInternalBalance', type: 'bool' }
        ],
        internalType: 'struct IVault.JoinPoolRequest',
        name: 'request',
        type: 'tuple'
      }
    ],
    name: 'joinPool',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum IVault.PoolBalanceOpKind',
            name: 'kind',
            type: 'uint8'
          },
          { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
          { internalType: 'contract IERC20', name: 'token', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' }
        ],
        internalType: 'struct IVault.PoolBalanceOp[]',
        name: 'ops',
        type: 'tuple[]'
      }
    ],
    name: 'managePoolBalance',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum IVault.UserBalanceOpKind',
            name: 'kind',
            type: 'uint8'
          },
          { internalType: 'contract IAsset', name: 'asset', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'address', name: 'sender', type: 'address' },
          {
            internalType: 'address payable',
            name: 'recipient',
            type: 'address'
          }
        ],
        internalType: 'struct IVault.UserBalanceOp[]',
        name: 'ops',
        type: 'tuple[]'
      }
    ],
    name: 'manageUserBalance',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'enum IVault.SwapKind', name: 'kind', type: 'uint8' },
      {
        components: [
          { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
          { internalType: 'uint256', name: 'assetInIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'assetOutIndex', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'bytes', name: 'userData', type: 'bytes' }
        ],
        internalType: 'struct IVault.BatchSwapStep[]',
        name: 'swaps',
        type: 'tuple[]'
      },
      { internalType: 'contract IAsset[]', name: 'assets', type: 'address[]' },
      {
        components: [
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'bool', name: 'fromInternalBalance', type: 'bool' },
          {
            internalType: 'address payable',
            name: 'recipient',
            type: 'address'
          },
          { internalType: 'bool', name: 'toInternalBalance', type: 'bool' }
        ],
        internalType: 'struct IVault.FundManagement',
        name: 'funds',
        type: 'tuple'
      }
    ],
    name: 'queryBatchSwap',
    outputs: [{ internalType: 'int256[]', name: '', type: 'int256[]' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'enum IVault.PoolSpecialization',
        name: 'specialization',
        type: 'uint8'
      }
    ],
    name: 'registerPool',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
      { internalType: 'contract IERC20[]', name: 'tokens', type: 'address[]' },
      { internalType: 'address[]', name: 'assetManagers', type: 'address[]' }
    ],
    name: 'registerTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract IAuthorizer',
        name: 'newAuthorizer',
        type: 'address'
      }
    ],
    name: 'setAuthorizer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bool', name: 'paused', type: 'bool' }],
    name: 'setPaused',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'relayer', type: 'address' },
      { internalType: 'bool', name: 'approved', type: 'bool' }
    ],
    name: 'setRelayerApproval',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'poolId', type: 'bytes32' },
          { internalType: 'enum IVault.SwapKind', name: 'kind', type: 'uint8' },
          { internalType: 'contract IAsset', name: 'assetIn', type: 'address' },
          {
            internalType: 'contract IAsset',
            name: 'assetOut',
            type: 'address'
          },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'bytes', name: 'userData', type: 'bytes' }
        ],
        internalType: 'struct IVault.SingleSwap',
        name: 'singleSwap',
        type: 'tuple'
      },
      {
        components: [
          { internalType: 'address', name: 'sender', type: 'address' },
          { internalType: 'bool', name: 'fromInternalBalance', type: 'bool' },
          {
            internalType: 'address payable',
            name: 'recipient',
            type: 'address'
          },
          { internalType: 'bool', name: 'toInternalBalance', type: 'bool' }
        ],
        internalType: 'struct IVault.FundManagement',
        name: 'funds',
        type: 'tuple'
      },
      { internalType: 'uint256', name: 'limit', type: 'uint256' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' }
    ],
    name: 'swap',
    outputs: [
      { internalType: 'uint256', name: 'amountCalculated', type: 'uint256' }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  { stateMutability: 'payable', type: 'receive' }
];
