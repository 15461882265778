import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useModal } from 'store/modals';
import { useWeb3React } from 'hooks/web3';
import { isMobile } from 'react-device-detect';
import { useTransactions } from 'store/transactions/useTransactions';
import { TxnStatus, TxnType } from 'store/transactions/types';
import MintBall from 'components/minting/MintBall';
import MintingForm from 'components/minting/MintingForm';
import { ModalType } from './types';
import { convertChainIdToNativeTokenSymbol } from 'utils/convertChainIdToNetworkName';
import { useCustomMinterFee } from 'hooks/useCustomMinterFee';

export type MintModalProps = {
  type: 'basic' | 'pro' | 'platinum';
  upgrade?: {
    crucibleAddress: string;
  };
};

const MintModal: FC<MintModalProps> = ({ type, upgrade }) => {
  const { closeModal, openModal } = useModal();
  const { account } = useWeb3React();
  const { mintCrucibleCustom, unlockArtwork, transactions } = useTransactions();
  const { fee } = useCustomMinterFee();

  const handleMint = () => mintCrucibleCustom(type === 'pro', fee);
  const getTxnType = () => {
    if (type === 'basic') return TxnType.mintBasic;
    if (type === 'pro') return TxnType.mintPro;
  };

  const handleUpgrade = async () => {
    if (!upgrade?.crucibleAddress) return;
    await unlockArtwork(upgrade.crucibleAddress, fee);
    closeModal(ModalType.mint);
  };

  const isMinting = useMemo(() => {
    return (
      transactions.filter(
        (txn) =>
          txn.status === TxnStatus.PendingOnChain && txn.type === getTxnType()
      ).length > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  const isUpgrading = useMemo(() => {
    return (
      transactions.filter(
        (txn) =>
          txn.status === TxnStatus.PendingOnChain &&
          txn.type === TxnType.unlockArtwork
      ).length > 0
    );
  }, [transactions]);

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.mint)}
      size={isMobile ? 'full' : 'md'}
      scrollBehavior={isMobile ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        bg='gray.800'
        mt={isMobile ? 0 : undefined}
        borderRadius={isMobile ? 'none' : 'xl'}
      >
        <ModalHeader fontSize='24px' as={Flex} alignItems='center'>
          <MintBall type={type} w='24px' h='24px' mr={3} />
          <Text textTransform='capitalize'>
            {upgrade ? `Upgrade Crucible - ${type}` : `Mint Crucible - ${type}`}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {type === 'basic' && <BasicContent />}
          {type === 'pro' && <ProContent />}
          {type === 'platinum' && <PlatinumContent />}
        </ModalBody>
        {type !== 'platinum' && (
          <ModalFooter>
            <Button
              isFullWidth
              isLoading={isMinting || isUpgrading}
              loadingText='Pending'
              disabled={isMinting || isUpgrading || !fee}
              onClick={
                !account
                  ? () => openModal(ModalType.connectWallet)
                  : upgrade
                  ? handleUpgrade
                  : handleMint
              }
            >
              {!account
                ? 'Connect wallet'
                : upgrade
                ? 'Upgrade to Pro'
                : 'Mint a Crucible'}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

const BasicContent = () => {
  return (
    <Text color='whiteAlpha.700'>
      Grants access to subscribe to all our Reward Programs. You can upgrade
      your plan at any time.
    </Text>
  );
};
const ProContent = () => {
  const { chainId = 1 } = useWeb3React();
  const { fee } = useCustomMinterFee();

  return (
    <Text color='whiteAlpha.700'>
      Grants access to subscribe to all our Reward Programs and unlocks the
      Crucible Artwork and exclusive benefits for{' '}
      <strong>
        {fee} {convertChainIdToNativeTokenSymbol(chainId)}
      </strong>
      .
      {chainId === 1 &&
        ' 50% of fees are used to buy back MIST which is then deposited into Aludel v1.5.'}
    </Text>
  );
};

const PlatinumContent = () => {
  return (
    <VStack>
      <Text color='whiteAlpha.700' mb={2}>
        Grants access to subscribe to all our Reward Programs as well as
        automatically subscribing your MIST-ETH LP to Aludel v1.5. You will also
        unlock the Crucible Artwork and exclusive future benefits.
      </Text>
      <MintingForm />
    </VStack>
  );
};

export default MintModal;
