import { fetchMaintainerBlacklist } from 'api/firebase';
import { useWeb3React } from 'hooks/web3';
import { useQuery } from 'react-query';
import { Queries } from 'types/enum';

export function useMaintainerBlacklist() {
  const { chainId = 1 } = useWeb3React();
  return useQuery<string[], Error>([Queries.MAINTAINER_BLACKLIST, chainId], () =>
    fetchMaintainerBlacklist(chainId)
  );
}
