import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react';
import { store } from 'store/store';

const { config, commonConfig } = store.getState().config;

const { supportedChainIds, appName, portisApiKey } = commonConfig;

export const injectedConnector = new InjectedConnector({
  supportedChainIds
});

export const networkConnector = new NetworkConnector({
  urls: {
    1: config[1].rpcUrl,
    5: config[5].rpcUrl,
    137: config[137].rpcUrl,
    80001: config[80001].rpcUrl,
    43114: config[43114].rpcUrl,
    43113: config[43113].rpcUrl
  },
  defaultChainId: 1
});

export const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    1: config[1].rpcUrl,
    5: config[5].rpcUrl,
    137: config[137].rpcUrl,
    80001: config[80001].rpcUrl,
    43114: config[43114].rpcUrl,
    43113: config[43113].rpcUrl
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 12000
});

export const portisConnector = new PortisConnector({
  dAppId: portisApiKey,
  networks: [1, 5]
});

export const walletLinkConnector = new WalletLinkConnector({
  url: config[1].rpcUrl,
  appName
});

export const gnosisSafeConnector = new SafeAppConnector();
