import { FC, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { BigNumber } from 'ethers';
import {
  Alert,
  Button,
  Circle,
  Flex,
  HStack,
  Text,
  Tooltip,
  Spinner,
  Skeleton,
  AlertIcon
} from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import { Box } from '@chakra-ui/layout';
import formatNumber from 'utils/formatNumber';
import bigNumberishToNumber from 'utils/bigNumberishToNumber';
import { useModal } from 'store/modals';
import { useTransactions } from 'store/transactions/useTransactions';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { useRewardPrograms } from 'store/zustand/useRewardPrograms';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { FiInfo } from 'react-icons/fi';
import { StakingPosition } from 'types';
import { useStakingTokenBalance } from 'hooks/query/useStakingTokenBalance';
import CustomInput from 'components/shared/CustomInput';
import { sanitize } from 'utils/sanitize';
import { isMobile } from 'react-device-detect';
import { useSubscriptions } from 'hooks/query/useSubscriptions';
import { ModalType } from './types';

type Props = {
  crucibleId: string;
  subscriptionIdx: number;
  stakingTokenSymbol: string;
};

interface SubscriptionWithAccumulatedBal extends StakingPosition {
  accumulatedBalance: BigNumber;
}

const ClaimRewardsModal: FC<Props> = ({
  crucibleId,
  subscriptionIdx,
  stakingTokenSymbol
}) => {
  const { closeModal } = useModal();
  const { claimRewards, transactions } = useTransactions();
  const { currentRewardProgram: rewardProgram } = useRewardPrograms();
  const { data: subscriptionsData, isLoading: subscriptionsLoading } =
    useSubscriptions();
  const [unsubscribeAmount, setUnsubscribeAmount] = useState('');

  const isPendingTx = useMemo(() => {
    return (
      transactions.filter(
        (txn) =>
          txn.status === TxnStatus.PendingOnChain &&
          (txn.type === TxnType.unsubscribe ||
            txn.type === TxnType.addSubscription) &&
          txn.crucibleAddress === crucibleId
      ).length > 0
    );
  }, [transactions, crucibleId]);

  const subscriptions = useMemo(() => {
    return subscriptionsData?.subscriptions || [];
  }, [subscriptionsData]);

  const stakingTokenDecimals = useMemo(() => {
    if (subscriptions.length > 0) {
      return subscriptions[0].stakingToken.decimals || 18;
    }
    return 18;
  }, [subscriptions]);

  const unsubscribeAmountBN = parseUnits(
    sanitize(unsubscribeAmount),
    stakingTokenDecimals
  );

  const focusRef = useRef<HTMLInputElement>(null);

  // in order to build the "DJ ui" with progress bar for each subscription we need add an accumulated balance for each subscription.
  // subscriptions have to be unsubscribed in FILO order, so we reverse the array before accumulating
  const subscriptionsWithAccumulatedBalances: SubscriptionWithAccumulatedBal[] =
    useMemo(() => {
      if (subscriptions && subscriptions.length > 0) {
        let accumulatedBalance = BigNumber.from(0);
        return [...subscriptions]
          .reverse()
          .map((subscription) => {
            // attach the accumulated balance, so each subscription has a record of it's own balance + all previous balances
            const newThing = {
              ...subscription,
              accumulatedBalance
            };

            accumulatedBalance = accumulatedBalance.add(
              subscription?.stakingToken.value.amount
            );

            return newThing;
          })
          .reverse();
      }
      return [];
    }, [subscriptions]);

  const subscription = useMemo(() => {
    return subscriptionsWithAccumulatedBalances[subscriptionIdx] || {};
  }, [subscriptionsWithAccumulatedBalances, subscriptionIdx]);

  // StakingTokenLocked relates to the total LP locked in the reward program (sum of all locks)
  // TODO: We should pass in the stakingTokenLocked
  const { data: stakingTokenData } = useStakingTokenBalance(
    subscription.stakingToken.address,
    rewardProgram.address,
    crucibleId
  );

  const isMax = useMemo(() => {
    if (stakingTokenData) {
      return parseUnits(sanitize(unsubscribeAmount), stakingTokenDecimals).eq(
        stakingTokenData.stakingTokenLocked
      );
    }
    return false;
  }, [unsubscribeAmount, stakingTokenData, stakingTokenDecimals]);

  const isUnstakeTxnPending =
    transactions.filter((txn) => {
      return (
        txn.type === TxnType.unsubscribe &&
        txn.status === TxnStatus.PendingOnChain &&
        txn.crucibleAddress === crucibleId
      );
    }).length > 0;

  useEffect(() => {
    // initialize the input value as the value of the intended subscription to unsubscribe
    setUnsubscribeAmount(
      formatUnits(
        (subscription?.accumulatedBalance || BigNumber.from(0)).add(
          subscription?.stakingToken?.value?.amount || BigNumber.from(0)
        ),
        stakingTokenDecimals
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  // subscription "boundaries" are used to prevent a bug when the user tries to unsubscribe exact values:
  // if user is unsubscribing AT a boundry level, we add 1 WEI to prevent a contract error
  // note that we cannot add this 1 wei adjustment if it's a max unsuubscription because they wont have enough

  // the boundary array is an array of accumulated rewards for each subscription
  // if you have 3 subs:
  // [10,12,8]
  // boundaries example:
  // [10,22,30]
  const subscriptionBoundaries: BigNumber[] = useMemo(() => {
    return subscriptionsWithAccumulatedBalances.map(
      ({ accumulatedBalance }) => accumulatedBalance
    );
  }, [subscriptionsWithAccumulatedBalances]);

  const handleUnstakeAndClaim = async () => {
    const adjust = (value: BigNumber) => {
      if (
        subscriptionBoundaries.some((boundary) =>
          boundary.eq(unsubscribeAmountBN)
        )
      ) {
        return value.add(parseUnits('1', 'wei'));
      }
      return value;
    };

    claimRewards(
      isMax && stakingTokenData
        ? stakingTokenData.stakingTokenLocked
        : adjust(unsubscribeAmountBN),
      crucibleId,
      rewardProgram,
      stakingTokenSymbol,
      stakingTokenDecimals
    );
    closeModal(ModalType.claimRewards);
    window.scrollTo(0, 0);
  };

  const getProgressValue = useCallback(
    (subscription: SubscriptionWithAccumulatedBal): number => {
      const diff = unsubscribeAmountBN.sub(subscription.accumulatedBalance);
      const progress =
        bigNumberishToNumber(diff) /
        bigNumberishToNumber(subscription?.stakingToken.value.amount);

      return Math.max(0, Math.min(progress, 1));
    },
    [unsubscribeAmountBN]
  );

  const rewardsLabel = (subscription: SubscriptionWithAccumulatedBal) => {
    return (
      <Box>
        <Text>Total rewards</Text>
        <Text>
          {formatNumber.tokenFull(
            subscription.rewards.rewardToken.value.amount,
            subscription.rewards.rewardToken.decimals
          )}{' '}
          {subscription.rewards.rewardToken.symbol}
        </Text>
        {subscription.rewards.bonusTokens.map((bonusToken) => (
          <Text key={bonusToken.address}>
            {formatNumber.tokenFull(
              bonusToken.value.amount,
              bonusToken.decimals
            )}{' '}
            {bonusToken.symbol}
          </Text>
        ))}
      </Box>
    );
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.claimRewards)}
      initialFocusRef={focusRef}
      size={isMobile ? 'full' : 'md'}
      scrollBehavior={isMobile ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={isMobile ? 'none' : 'xl'}
        mt={isMobile ? 0 : undefined}
        bg='gray.800'
      >
        <ModalHeader>
          Claim {rewardProgram.name} rewards and unsubscribe
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {subscriptionsLoading ? (
            <Flex justifyContent='center' mt={5}>
              <Spinner />
            </Flex>
          ) : (
            <Box>
              <Box mb={2}>
                {isPendingTx && (
                  <Alert status='error' mb={4} borderRadius='xl'>
                    <AlertIcon marginTop={-16} />
                    There is a pending transaction on this Crucible. Please wait
                    until it has finished before unsubscribing again to avoid
                    the transaction failing.
                  </Alert>
                )}
                <Text mb={4} color='gray.300'>
                  You are unsubscribing {subscription?.stakingToken?.symbol}{' '}
                  tokens from the {rewardProgram.name}. This will reset your
                  multiplier and claim rewards for the portion you are
                  unsubscribing.
                </Text>
                <Text fontWeight='bold' mb={6}>
                  We recommend using Metamask for this process.
                </Text>
                <Alert borderRadius='xl' bg='gray.600' mb={6}>
                  Subscriptions are stacked in a last in first out order. You
                  can select any of the subscriptions below by clicking on it.
                </Alert>
              </Box>
              <Box mb={4}>
                {subscriptionsWithAccumulatedBalances.map(
                  (subscription, idx) => (
                    <Box mb={2} key={idx}>
                      <Flex
                        justifyContent='space-between'
                        _hover={{ cursor: 'pointer' }}
                        onClick={() =>
                          setUnsubscribeAmount(
                            formatUnits(
                              subscription.accumulatedBalance.add(
                                subscription?.stakingToken?.value?.amount
                              ),
                              subscription.stakingToken.decimals
                            )
                          )
                        }
                        py='5px'
                      >
                        <HStack>
                          <Circle
                            bg={
                              unsubscribeAmountBN.lte(
                                subscription.accumulatedBalance
                              )
                                ? 'gray.500'
                                : 'gray.300'
                            }
                            _hover={{ cursor: 'pointer' }}
                            color='white'
                            size='20px'
                          >
                            {idx + 1}
                          </Circle>
                          <Text>
                            {formatNumber.token(
                              subscription?.stakingToken?.value?.amount,
                              subscription?.stakingToken?.decimals
                            )}{' '}
                            {subscription?.stakingToken?.symbol}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text>
                            {formatNumber.date(
                              subscription.subscriptionDate * 1000
                            )}
                          </Text>
                          <Tooltip
                            label={rewardsLabel(subscription)}
                            fontSize='md'
                            placement='bottom-end'
                            hasArrow
                          >
                            <span>
                              <FiInfo />
                            </span>
                          </Tooltip>
                        </HStack>
                      </Flex>
                      <Box width='100%' height='4px' bg='gray.600'>
                        <Box
                          width={
                            isMax
                              ? '100'
                              : formatNumber.percent(
                                  getProgressValue(subscription)
                                )
                          }
                          bg='cyan.500'
                          height='100%'
                        />
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          )}
          <Flex
            mb={2}
            mt={8}
            justifyContent='space-between'
            alignItems='center'
            color='gray.100'
          >
            <Text>Select amount</Text>
            <Text>
              Balance:{' '}
              <Skeleton
                isLoaded={!!stakingTokenData}
                startColor='gray.500'
                endColor='gray.600'
              >
                <strong>
                  {formatNumber.token(
                    stakingTokenData?.stakingTokenLocked as BigNumber,
                    stakingTokenData?.stakingTokenDecimals
                  )}{' '}
                  {subscription?.stakingToken?.symbol}
                </strong>
              </Skeleton>
            </Text>
          </Flex>
          <CustomInput
            inputRef={focusRef}
            max={formatUnits(
              stakingTokenData
                ? stakingTokenData.stakingTokenLocked
                : BigNumber.from(0),
              stakingTokenDecimals
            )}
            value={unsubscribeAmount}
            tokenDecimals={stakingTokenDecimals}
            onUserInput={(input) => setUnsubscribeAmount(input)}
            showMaxButton
            showRadioGroup
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isFullWidth
            onClick={handleUnstakeAndClaim}
            isDisabled={
              parseUnits(sanitize(unsubscribeAmount), stakingTokenDecimals).lte(
                0
              ) ||
              parseUnits(sanitize(unsubscribeAmount), stakingTokenDecimals).gt(
                stakingTokenData
                  ? stakingTokenData.stakingTokenLocked
                  : BigNumber.from(0)
              )
            }
          >
            {!isUnstakeTxnPending && 'Claim rewards and unsubscribe'}
            {isUnstakeTxnPending && (
              <span>
                Pending <Spinner ml={2} size='sm' />
              </span>
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClaimRewardsModal;
