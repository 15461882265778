export const groupByKey = (list: any[], key: string) =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );

export const groupBy = (arr: any[], prop: string) => {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    // @ts-ignore
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  }
