import {
  Button,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useToast,
  VStack,
  Tooltip
} from '@chakra-ui/react';
import { FC } from 'react';
import { useModal } from 'store/modals';
import { isMobile } from 'react-device-detect';
import { Link as ChakraLink } from '@chakra-ui/react';
import { RiExternalLinkLine } from 'react-icons/ri';
import { vote } from 'api/snapshot';
import { useWeb3React } from 'hooks/web3';
import { useState } from 'react';
import { queryClient } from 'index';
import { Proposal } from 'types/snapshot';
import { getChoiceString, getPower } from 'helpers/snapshot';
import { useEffect } from 'react';
import { formatLongNumbers } from 'utils/formatLongNumbers';
import { ModalType } from './types';

type Props = {
  proposal: Proposal;
  data: any;
};

type Power = {
  scores: number[];
  totalScore: number;
};

const VoteModal: FC<Props> = ({ proposal, data }) => {
  const { closeModal } = useModal();
  const { account, provider, chainId = 1 } = useWeb3React();
  const [voteLoading, setVoteLoading] = useState(false);
  const [votePower, setVotePower] = useState<Power>();
  const toast = useToast();

  useEffect(() => {
    if (account) {
      getVotePower();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const getVotePower = async () => {
    const power = await getPower({
      space: proposal.space,
      address: account!,
      proposal: proposal
    });
    setVotePower(power as Power);
  };

  const handleVote = async () => {
    if (chainId !== 1) {
      try {
        //@ts-ignore
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }]
        });
      } catch (switchError) {
        toast({
          title: 'Please switch to Ethereum Mainnet to vote',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    setVoteLoading(true);
    try {
      await vote(proposal, data, provider, account!);
      await queryClient.invalidateQueries(['proposal', proposal.id]);
      setVoteLoading(false);
      closeModal(ModalType.vote);
      toast({
        title: `Vote submitted`,
        status: 'success',
        duration: 4000
      });
    } catch (err: any) {
      setVoteLoading(false);
      toast({
        title: err.message,
        status: 'error',
        duration: 4000
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.vote)}
      size={isMobile ? 'full' : 'md'}
      scrollBehavior={isMobile ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        bg='gray.800'
        mt={isMobile ? 0 : undefined}
        borderRadius={isMobile ? 'none' : 'xl'}
      >
        <ModalHeader fontSize='24px'>
          <Text textAlign='center'>Confirm Vote</Text>
          <Divider pt={4} />
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Text textAlign='center'>
            Are you sure you want to confirm your vote?
          </Text>
          <Text textAlign='center'>This action cannot be undone.</Text>
          <VStack mt={6}>
            <VStack
              borderWidth={1}
              borderRadius='xl'
              p={4}
              alignItems='flex-start'
              w='100%'
            >
              <Flex
                justifyContent='space-between'
                borderRadius='md'
                p={1}
                gridGap={4}
                w='100%'
              >
                <Text textColor='gray.300'>Option(s)</Text>
                <Tooltip label={getChoiceString(proposal, data)}>
                  <Text isTruncated>{getChoiceString(proposal, data)}</Text>
                </Tooltip>
              </Flex>
              <Flex
                justifyContent='space-between'
                w='100%'
                borderRadius='md'
                p={1}
              >
                <Text textColor='gray.300'>Snapshot</Text>
                <HStack>
                  <Text>{Number(proposal.snapshot).toLocaleString()}</Text>
                  <ChakraLink
                    href={`https://etherscan.io/block/${proposal.snapshot}`}
                    isExternal
                    fontSize='lg'
                  >
                    <RiExternalLinkLine />
                  </ChakraLink>
                </HStack>
              </Flex>
              <Flex
                justifyContent='space-between'
                borderRadius='md'
                p={1}
                w='100%'
              >
                <Text textColor='gray.300'>Your voting power</Text>
                <Skeleton isLoaded={!!votePower}>
                  <Tooltip
                    hasArrow
                    label={
                      votePower &&
                      votePower.scores.map((v, i) => (
                        <div key={i}>
                          {Number(v.toFixed(3)).toLocaleString()}{' '}
                          {proposal.strategies[i].params.symbol}
                        </div>
                      ))
                    }
                    fontSize='md'
                    placement='top'
                  >
                    <Text>
                      {votePower && formatLongNumbers(votePower.totalScore)}{' '}
                      {proposal.space.symbol}
                    </Text>
                  </Tooltip>
                </Skeleton>
              </Flex>
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <VStack w='100%' spacing='5px' mb={0}>
            <Button isFullWidth onClick={handleVote} isLoading={voteLoading}>
              Vote
            </Button>
            <Button
              isFullWidth
              variant='ghost'
              onClick={() => closeModal(ModalType.vote)}
            >
              Cancel
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VoteModal;
