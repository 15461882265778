import { ethers } from 'ethers';
import { convertTokenIdToAddress } from './address';
import { customMinterAbi } from 'abi/customMinterAbi';
import { Provider } from '@ethersproject/providers';
import { OWNED_CRUCIBLES, SINGLE_CRUCIBLE } from 'helpers/queries';

export function getCrucibleIdsFromEvents(events: ethers.Event[]) {
  const handledIds = new Set();
  const crucibleIds = [];
  for (const event of events) {
    if (!event.args || !event.args.tokenId) {
      console.error(`Missing tokenId arg`, event);
      continue;
    }
    const id = convertTokenIdToAddress(event.args.tokenId);
    if (!handledIds.has(id)) {
      handledIds.add(id);
      crucibleIds.push({ id, event });
    }
  }
  return crucibleIds;
}

export async function getCrucibleMembership(
  crucibleAddress: string,
  customMinterAddress: string,
  transmuterAddress: string,
  mintingTokenAddress: string,
  crucibleFactory: ethers.Contract,
  crucibleFactoryCreationBlock: number,
  provider: Provider,
  crucibleCreationBlockNumber?: number
) {
  const network = await provider.getNetwork();
  const chainId = network.chainId;

  if (chainId !== 1) {
    const customMinter = new ethers.Contract(
      customMinterAddress,
      customMinterAbi,
      provider
    );

    const hasPaid = await customMinter.isPaid(crucibleAddress);

    if (hasPaid) {
      return 'pro';
    }

    return 'basic';
  }

  // Check if the crucible has been minted through the transmuter or custom minter
  const [viaTransmuter, viaCustomMinter] = await Promise.all(
    [transmuterAddress, customMinterAddress].map(async (address) => {
      const filter = crucibleFactory.filters.Transfer(
        '0x0000000000000000000000000000000000000000',
        address,
        crucibleAddress
      );
      const crucibleEvents = await crucibleFactory.queryFilter(
        filter,
        crucibleCreationBlockNumber
          ? Number(crucibleCreationBlockNumber)
          : crucibleFactoryCreationBlock,
        crucibleCreationBlockNumber
          ? Number(crucibleCreationBlockNumber)
          : 'latest'
      );

      if (address === transmuterAddress && crucibleEvents.length > 0) {
        const txHash = crucibleEvents[0].transactionHash;
        const tx = await provider.getTransactionReceipt(txHash);
        const transferABI = [
          'event Transfer(address indexed from, address indexed to, uint amount)'
        ];
        const transmuterInterface = new ethers.utils.Interface(transferABI);
        const decodedInput = transmuterInterface.parseLog(tx.logs[5]);

        const lpAmount = decodedInput.args.amount;
        const lpAddress = ethers.utils.getAddress(tx.logs[5].address);

        if (
          ethers.utils.getAddress(mintingTokenAddress) !== lpAddress ||
          !lpAmount.gt(0)
        ) {
          return false;
        }
      }

      return crucibleEvents.length > 0;
    })
  );

  if (viaTransmuter) {
    return 'platinum';
  }

  if (viaCustomMinter) {
    return 'pro';
  }

  // Check if a fee was paid to unlock the NFT
  const customMinter = new ethers.Contract(
    customMinterAddress,
    customMinterAbi,
    provider
  );

  const hasPaid = await customMinter.isPaid(crucibleAddress);

  if (hasPaid) {
    return 'pro';
  }

  return 'basic';
}

export async function getSingleCrucibleEntities(
  crucibleAddress: string,
  crucibleSubgraphUrl: string,
  customFetcher: any = fetch
) {
  const response = await customFetcher(crucibleSubgraphUrl, {
    method: 'POST',
    body: JSON.stringify({
      query: SINGLE_CRUCIBLE,
      variables: {
        crucibleAddress: crucibleAddress.toLowerCase()
      }
    }),
    headers: {
      'content-type': 'application/json'
    }
  });

  const { data } = (await response.json()) as {
    data: {
      crucibleEntities: {
        id: string;
        timestamp: string;
        owner: string;
        blockNumber: number;
      }[];
    };
  };

  return data.crucibleEntities;
}

export async function getOwnedCrucibleEntities(
  account: string,
  crucibleSubgraphUrl: string
) {
  const response = await fetch(crucibleSubgraphUrl, {
    method: 'POST',
    body: JSON.stringify({
      query: OWNED_CRUCIBLES,
      variables: {
        account
      }
    }),
    headers: {
      'content-type': 'application/json'
    }
  });

  const { data } = (await response.json()) as {
    data: {
      crucibleEntities: {
        id: string;
        timestamp: string;
        blockNumber: number;
      }[];
    };
  };

  return data.crucibleEntities;
}
