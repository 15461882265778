import { FC } from 'react';
import {
  Box,
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
  VStack
} from '@chakra-ui/react';
import { useSideDrawer } from 'store/sideDrawer';
import { useTransactions } from 'store/transactions/useTransactions';
import UserBalancesPill from './UserBalancesPill';
import TransactionList from './TrasnsactionList';
import MobileNavLinks from '../MobileNavLinks';
import { useGasPrice } from 'hooks/query/useGasPrice';
import { FaGasPump } from 'react-icons/fa';
import { useBlockNumber } from 'hooks/query/useBlockNumber';
import { convertChainIdToNativeTokenSymbolForPrice } from 'utils/convertChainIdToNetworkName';
import { useWeb3React } from 'hooks/web3';

const SideDrawerOverview: FC = () => {
  const { chainId = 1 } = useWeb3React();
  const { data: blockNumber } = useBlockNumber();
  const { isOpen, closeDrawer } = useSideDrawer();
  const { clearSavedTransactions } = useTransactions();
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const { isLoading, data: gasPrice } = useGasPrice();

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={closeDrawer} size='xs'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody bg='gray.800' px={0}>
          <VStack alignItems='stretch' spacing={6} py={4} height='100%'>
            {!isLargerThan992 && (
              <Box>
                <Text fontSize='lg' fontWeight='500' mb={3} px={6}>
                  Navigation
                </Text>
                <MobileNavLinks />
              </Box>
            )}
            <Box px={6}>
              <Text fontSize='lg' fontWeight='500' mb={3}>
                Balances
              </Text>
              <UserBalancesPill />
            </Box>
            <Box px={6} flexGrow={1}>
              <Flex justifyContent='space-between' mb={3}>
                <Text fontSize='lg' fontWeight='500'>
                  Transactions
                </Text>
                <Text
                  color='gray.400'
                  onClick={clearSavedTransactions}
                  _hover={{
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                >
                  Clear
                </Text>
              </Flex>
              <TransactionList />
            </Box>
            <Flex
              pt={6}
              px={6}
              borderTopWidth={1}
              width='100%'
              alignItems='center'
              justifyContent='space-between'
            >
              <Skeleton isLoaded={!isLoading}>
                <Box
                  px={2}
                  py={1}
                  borderRadius='md'
                  display='flex'
                  alignItems='space-between'
                  color={getColor(gasPrice)}
                >
                  <FaGasPump size='20' style={{ paddingRight: 5 }} />
                  {gasPrice}{' '}
                  {convertChainIdToNativeTokenSymbolForPrice(chainId)}
                </Box>
              </Skeleton>
              <Tooltip label='Block number' placement='top' hasArrow>
                <Stack spacing={2} direction='row' alignItems='center'>
                  <Circle width='8px' height='8px' bg='green.400' />
                  <Text color='gray.300'>{blockNumber}</Text>
                </Stack>
              </Tooltip>
            </Flex>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const getColor = (price: number = 0): string => {
  if (price > 149) return 'red.500';
  if (price > 80) return 'orange.500';
  return 'green.500';
};

export default SideDrawerOverview;
