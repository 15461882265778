import { RiMenuLine } from 'react-icons/ri';
import { TxnStatus } from 'store/transactions/types';
import { DrawerType, useSideDrawer } from 'store/sideDrawer';
import { Box, Circle, IconButton, Text } from '@chakra-ui/react';
import { useTransactions } from 'store/transactions/useTransactions';

const SideDrawerIcon = () => {
  const { transactions } = useTransactions();
  const { openDrawer } = useSideDrawer();

  const pendingTransactions = transactions.filter(
    (txn) => txn.status === TxnStatus.PendingOnChain
  );

  return (
    <Box position='relative'>
      {pendingTransactions.length > 0 && (
        <Circle
          width='24px'
          height='24px'
          position='absolute'
          borderRadius='full'
          right='-5px'
          top='-5px'
          zIndex={1}
          borderWidth={2}
          borderColor='gray.900'
          bgColor='red.600'
          cursor='pointer'
          onClick={() => openDrawer(DrawerType.overview)}
        >
          <Text fontSize='xs' fontWeight='600'>
            {pendingTransactions.length}
          </Text>
        </Circle>
      )}
      <IconButton
        onClick={() => openDrawer(DrawerType.overview)}
        variant='ghost'
        icon={<RiMenuLine />}
        aria-label='Menu'
        fontSize='x-large'
        bg='none'
      />
    </Box>
  );
};

export default SideDrawerIcon;
