import { RewardProgram } from 'types';
import create from 'zustand';

type RewardProgramState = {
  rewardPrograms: RewardProgram[],
  currentRewardProgram: RewardProgram;
  setRewardPrograms: (rewardPrograms: RewardProgram[]) => void;
  setCurrentRewardProgram: (chainId: number, rewardProgram: RewardProgram) => void;
};

export const useRewardPrograms = create<RewardProgramState>(
  (set) => ({
    rewardPrograms: [] as RewardProgram[],
    currentRewardProgram: null as unknown as RewardProgram,
    setRewardPrograms: (rewardPrograms: RewardProgram[]) => set({ rewardPrograms }),
    setCurrentRewardProgram: (chainId: number, rewardProgram: RewardProgram) =>
      set({ currentRewardProgram: rewardProgram })
  })
);
