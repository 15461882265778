import { ethers, BigNumber } from 'ethers';
import { Queries } from 'types/enum';
import { useQuery } from 'react-query';
import { useWeb3React } from 'hooks/web3';
export function useGasPrice() {
  const { provider } = useWeb3React();

  const getPrice = async () => {
    const price: BigNumber = await provider.getGasPrice();
    const formatedPrice = Number(ethers.utils.formatUnits(price, 'gwei'));
    return Number(formatedPrice.toFixed(0));
  };

  return useQuery(Queries.GAS_PRICE, () => getPrice(), {
    enabled: true,
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
}
