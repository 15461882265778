import create from 'zustand';

type ErrorState = {
  error: boolean;
  setError: (error: boolean) => void;
};

export const useError = create<ErrorState>((set) => ({
  error: false,
  setError: (error: boolean) => set(() => ({ error }))
}));
