import React, { lazy, Suspense } from 'react';

const DynamicLoader = (
  path: () => Promise<any>,
  CustomLoader?: React.Component
): React.FC => {
  const AsyncComponent: React.FC = lazy(path);

  return (props) => (
    <Suspense fallback={CustomLoader || null}>
      <AsyncComponent {...props} />
    </Suspense>
  );
};

export default DynamicLoader;
