import { queryClient } from 'index';
import { useEffect } from 'react';
import { Queries } from 'types/enum';
import { useWeb3React } from './web3';
import { db } from 'libraries/firebasePublic';
import { useMaintainerBlacklist } from './query/useMaintainerBlacklist';
import { useBlockedPrograms } from './query/useBlockedPrograms';

export function useFirebaseListener() {
  const { chainId = 1 } = useWeb3React();

  const { data: blockedPrograms } = useBlockedPrograms();
  const { data: maintainerBlacklist } = useMaintainerBlacklist();

  // Listen to blocked programs
  useEffect(() => {
    const unsubscribe = db
      .collection('blockedPrograms')
      .doc(chainId.toString())
      .onSnapshot(() => {
        queryClient.refetchQueries([Queries.BLOCKED_PROGRAMS, chainId]);
      });

    return () => unsubscribe();
  }, [chainId]);

  // Listen to blacklisted maintainers
  useEffect(() => {
    const unsubscribe = db
      .collection('maintainerBlacklist')
      .doc(chainId.toString())
      .onSnapshot(() => {
        queryClient.refetchQueries([Queries.MAINTAINER_BLACKLIST, chainId]);
      });

    return () => unsubscribe();
  }, [chainId]);

  // Listen to token usd values
  useEffect(() => {
    const unsubscribe = db
      .collection('tokenUSDValues')
      .doc(chainId.toString())
      .onSnapshot(() => {
        queryClient.refetchQueries([Queries.USD_VALUES, chainId]);
      });

    return () => unsubscribe();
  }, [chainId]);

  // Listen to LP data changes
  useEffect(() => {
    const unsubscribe = db
      .collection('lpData')
      .doc(chainId.toString())
      .onSnapshot(() => {
        queryClient.refetchQueries([Queries.LP_DATA, chainId]);
      });

    return () => unsubscribe();
  }, [chainId]);

  // Listen to landing page stat changes
  useEffect(() => {
    const unsubscribe = db
      .collection('landingStats')
      .doc(chainId.toString())
      .onSnapshot(() => {
        queryClient.refetchQueries([Queries.LANDING_STATS, chainId]);
      });

    return () => unsubscribe();
  }, [chainId]);

  // Listen to reward program data changes
  useEffect(() => {
    if (maintainerBlacklist && blockedPrograms) {
      const rewardprograms = db
        .collection('rewardPrograms')
        .doc(chainId.toString())
        .onSnapshot(() => {
          queryClient.refetchQueries([
            Queries.REWARD_PROGRAMS,
            chainId,
            blockedPrograms,
            maintainerBlacklist
          ]);
        });

      return () => rewardprograms();
    }
  }, [chainId, blockedPrograms, maintainerBlacklist]);
}
