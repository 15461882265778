import { FC } from 'react';
import {
  Box,
  HStack,
  Skeleton,
  StackDivider,
  Text,
  useMediaQuery
} from '@chakra-ui/react';
import { useConfig } from 'store/config';
import { useWeb3React } from 'hooks/web3';
import { getTokenBalance } from 'helpers/tokenBalance';
import { useUserBalances } from 'hooks/query/useUserBalances';
import { convertChainIdToNativeTokenSymbol } from 'utils/convertChainIdToNetworkName';
import formatNumber from 'utils/formatNumber';

const UserBalancesPill: FC = () => {
  const [isLargerThan375] = useMediaQuery('(min-width: 376px)');
  const { chainId = 1, account } = useWeb3React();
  const { mistTokenAddress } = useConfig(chainId).config;
  const { isLoading, isFetching } = useUserBalances();
  const decimalsToDisplay = isLargerThan375 ? 4 : 3;

  const skeletonProps = {
    startColor: 'gray.500',
    endColor: 'gray.600'
  };

  return (
    <HStack
      height='90px'
      bg='gray.700'
      borderRadius='xl'
      padding={5}
      divider={<StackDivider />}
    >
      <Box width='50%'>
        <Text paddingRight={5} color='gray.300'>
          {convertChainIdToNativeTokenSymbol(chainId)}
        </Text>
        <Skeleton isLoaded={!isLoading && !isFetching} {...skeletonProps}>
          <Text fontWeight='500' isTruncated>
            {formatNumber.tokenCustom(
              getTokenBalance('', chainId, account),
              18,
              decimalsToDisplay,
              decimalsToDisplay
            )}
          </Text>
        </Skeleton>
      </Box>
      {convertChainIdToNativeTokenSymbol(chainId) === 'ETH' && (
        <Box paddingLeft={5} width='50%'>
          <Text color='gray.300'>MIST</Text>
          <Skeleton isLoaded={!isLoading && !isFetching} {...skeletonProps}>
            <Text fontWeight='500' isTruncated>
              {formatNumber.tokenCustom(
                getTokenBalance(mistTokenAddress, chainId, account),
                18,
                decimalsToDisplay,
                decimalsToDisplay
              )}
            </Text>
          </Skeleton>
        </Box>
      )}
    </HStack>
  );
};

export default UserBalancesPill;
