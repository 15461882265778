import { InfuraProvider, JsonRpcProvider } from '@ethersproject/providers';

const infuraProviders: { [key: number]: InfuraProvider | JsonRpcProvider } = {
  1: new InfuraProvider(1, process.env.REACT_APP_INFURA_API_KEY),
  5: new InfuraProvider(5, process.env.REACT_APP_INFURA_API_KEY),
  137: new InfuraProvider(137, process.env.REACT_APP_INFURA_API_KEY),
  80001: new InfuraProvider(80001, process.env.REACT_APP_INFURA_API_KEY),
  43113: new JsonRpcProvider('https://api.avax-test.network/ext/bc/C/rpc'),
  43114: new JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc')
};

export const getInfuraProvider = (network: number) => {
  return infuraProviders[network];
};
