import { PowerSwitchStatus } from 'api/blockchain/rewardProgram';
import { BigNumber, ethers } from 'ethers';

export type Crucible = {
  id: string;
  owner: string;
  mintTimestamp: number;
  mintBlockNumber: number;
  membership: 'basic' | 'pro' | 'platinum';
};

export type CrucibleLockObject = { [crucibleAddress: string]: CrucibleLock[] };

export type RewardSchedule = [
  duration: BigNumber,
  start: BigNumber,
  shares: BigNumber
];

// note that this is actually a tuple type, but the interface also works as an object
export type RewardProgramData = {
  stakingToken: string;
  rewardToken: string;
  rewardPool: string;
  rewardScaling: {
    floor: BigNumber;
    ceiling: BigNumber;
    time: BigNumber;
  };
  rewardSharesOutstanding: BigNumber;
  totalStake: BigNumber;
  totalStakeUnits: BigNumber;
  lastUpdate: BigNumber;
  rewardSchedules: RewardSchedule[];
  rewardBalance: BigNumber;
  sharesOutstanding: BigNumber;
  timestamp: BigNumber;
};

export interface CrucibleLock {
  crucibleAddress: string;
  rewardProgramAddress: string;
  subscribedToken: ERC20Token;
  subscriptionCount: number;
}

export type LockData = {
  delegate: string; //Reward program the lock belongs to
  token: string; //Staking token address
  balance: BigNumber; //Total amount staked in reward program
};

export type StakeData = [
  BigNumber, // amount in this particular staking position
  BigNumber // start time timestamp
];

export type RewardProgramVaultData = [
  BigNumber, // total amount staked
  StakeData[]
];

export type TokenValue = {
  amount: BigNumber;
  amountNumber?: number;
  amountUSD?: number;
};

export type ERC20Token = {
  value: TokenValue;
  address: string;
  symbol: string;
  // TODO: Make this required
  decimals?: number;
  imageUrl?: string;
};

export type simpleLPToken = {
  amount: number;
  valueUSD?: number;
  address: string;
  symbol: string;
  decimals: number;
};

export interface LPToken extends ERC20Token {
  lpTokenData?: simpleLPToken[];
}

// TODO: Remove below
export type StakingPosition = {
  rewardProgramAddress: string;
  subscriptionDate: number;
  timeElapsed: number;
  stakingToken: LPToken | ERC20Token;
  rewards: {
    rewardToken: ERC20Token | LPToken;
    bonusTokens: ERC20Token[];
  };
};

export type Subscription = {
  rewardProgramAddress: string;
  subscriptionDate: number;
  timeElapsed: number;
  stakingToken: LPToken | ERC20Token;
  rewards: {
    rewardToken: ERC20Token | LPToken;
    bonusTokens: ERC20Token[];
  };
};

export type SubscriptionsSummary = {
  totalValue: number;
  totalRewardsValue: number;
  percentageOfRewardPool: number;
};

export type SubscriptionsMeta = {
  rewardToken: ProgramToken;
  stakingToken: ProgramToken;
  bonusTokens: ProgramToken[];
  rewardProgramData: RewardProgramData;
  rewardScalingTime: number;
  rewardScalingTimestamp: number;
};

export enum STATUS {
  IDLE = 'idle',
  PENDING = 'pending',
  UPDATING = 'updating',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed'
}

// TODO: Maybe remove below?
export type RewardProgramTokens = {
  rewardToken: ProgramToken;
  stakingToken: ProgramToken;
  bonusTokens: ProgramToken[];
};

export type ProgramToken = {
  address: string;
  tokenSymbol: string;
  decimals: number;
  imageUrl?: string;
  value?: TokenValue;
};

// Start of Update Function Types

export type chainToTokenUSDValue = {
  [chainId: string]: tokenToUSDValue;
};

export type chainToLPTokenUSDValue = {
  [chainId: string]: LPTokenToUSDValue;
};

export type LPTokenToUSDValue = {
  [tokenAddress: string]: simpleLPToken[];
};

export type tokenToUSDValue = {
  [tokenAddress: string]: number;
};

export type balancerVaultTokens = {
  tokens: string[];
  balances: number[];
  lastChangeBlock: number;
};

// End of Update Function Types

export type RewardProgramVersion = 1 | 1.5;

export type RewardProgram = {
  name: string;
  version: RewardProgramVersion;
  shortName: string;
  address: string;
  templateName: string;
  templateId: string;
  getRewardProgramDataMethod: string;
  getRewardProgramFundMethod: string;
  getStakingTokenUrl: string;
  expiryTimestamp?: number;
  abi: ethers.ContractInterface;
  startTime?: number;
  maintainer: string;
  owner: string;
  powerSwitchAddress: string;
  powerSwitchController: string;
  rewardProgramState: PowerSwitchStatus;
  description: string;
  featured: boolean;
  transmuter?: string;
  royalty?: boolean;
  preventProgramExpiry?: boolean;
  tags?: string[];
  isProgramBlocked?: boolean;
  isMaintainerBlacklisted?: boolean;
  vaultFactories: { id: string }[];
};

export type ExtendedRewardProgram = {
  apy: string | number;
  abi: ethers.ContractInterface;
  rewardScalingDays: number;
  rewardScalingFloor: number;
  rewardScalingCeil: number;
  rewardMultiplier: number;
  rewardProgram: RewardProgram;
  rewardProgramAddress: string;
  rewardProgramTokens: RewardProgramTokens;
  poolAddress: string;
};

export type RewardProgramDetails = {
  [rewardProgramAddress: string]: ExtendedRewardProgram;
};

export type UserBalances = { [address: string]: ERC20Token };

export type StakingTokenBalance = {
  stakingTokenUnlocked: BigNumber;
  stakingTokenLocked: BigNumber;
  stakingTokenInWallet: BigNumber;
  stakingTokenDecimals: number;
};

export type SubgraphERC20Token = {
  id: string;
  address: string;
  symbol: string;
  name: string;
  decimals: string;
};

export type SubgraphLock = {
  id: string;
  delegate: string;
  token: SubgraphERC20Token;
  balance: string;
  stakesLength: string;
  stakes: any[];
  unstakedLength: string;
  unstakes: any[];
};

export type SubgraphCrucibleEntity = {
  id: string;
  owner: string;
  timestamp: string;
  blockNumber: string;
  index: string;
  locks: SubgraphLock[];
  rewardsLength: string;
  factory: string;
};

export type ScreenshotRecord = {
  crucibleId: string;
  imageUrl: string;
  lastUpdated: {
    _seconds: number;
    _nanoseconds: number;
  };
};

export type AludelTemplate = {
  id: string;
  name: string;
  disabled: boolean;
};

export type AludelTemplateKeyed = {
  [id: string]: AludelTemplate;
};

export enum TemplateName {
  AludelV1 = 'AludelV1',
  GeyserV2 = 'GeyserV2',
  AludelV2 = 'AludelV2'
}
