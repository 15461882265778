import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import { ethers } from 'ethers';

const getTokenDecimals = async (
  tokenAddress: string,
  provider: ethers.providers.Provider
): Promise<number> => {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    IUniswapV2ERC20.abi,
    provider
  );

  const decimals = (await tokenContract.decimals()) || 0;
  return decimals;
};

export default getTokenDecimals;
