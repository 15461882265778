import { ethers } from 'ethers';
import { Provider } from '@ethersproject/providers';
import { RewardProgram, TemplateName } from 'types';
import { mapTemplateNameToPowerswitchAbi } from 'utils/aludelMappings';

export enum PowerSwitchStatus {
  ONLINE = 0,
  OFFLINE = 1,
  SHUTDOWN = 2,
  NOTSTARTED = 3
}

export const mapPowerSwitchStatusToDescription: {
  [key in PowerSwitchStatus]: string;
} = {
  [PowerSwitchStatus.ONLINE]: 'Online',
  [PowerSwitchStatus.OFFLINE]: 'Offline',
  [PowerSwitchStatus.SHUTDOWN]: 'Permanently Shutdown',
  [PowerSwitchStatus.NOTSTARTED]: 'Not started'
};

export async function fetchRewardProgramState(
  rewardProgram: RewardProgram,
  provider: Provider
): Promise<PowerSwitchStatus> {
  const rewardProgramContract = new ethers.Contract(
    rewardProgram.address,
    rewardProgram.abi,
    provider
  );

  const powerSwitchAbi =
    mapTemplateNameToPowerswitchAbi[rewardProgram.templateName as TemplateName];

  const powerSwitchFactoryAddress =
    await rewardProgramContract.getPowerSwitch();

  const powerSwitchFactory = new ethers.Contract(
    powerSwitchFactoryAddress,
    powerSwitchAbi,
    provider
  );

  const status = (await powerSwitchFactory.getStatus()) as PowerSwitchStatus;

  return status;
}
