import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TxnStatus, TxnType } from 'store/transactions/types';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import { parseUnits } from 'ethers/lib/utils';
import { truncate } from 'utils/address';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';

export const fundAludel = createAsyncThunk(
  THUNK_PREFIX.FUND_ALUDEL,
  async ({
    web3React,
    updateTx,
    toast,
    modal,
    logger,
    aludelAddress,
    rewardTokenAmount,
    unlockDays,
    rewardProgramDataMethod,
    rewardProgramFundMethod,
    aludelAbi
  }: any) => {
    // create UUID for tracking these tx
    let txnId = uuid();

    try {
      const { account, library } = web3React;

      const aludel = new ethers.Contract(
        aludelAddress,
        aludelAbi,
        library.getSigner()
      );

      const aludelData = await aludel[rewardProgramDataMethod].call();
      const rewardTokenAddress = aludelData[1];

      const rewardToken = new ethers.Contract(
        rewardTokenAddress,
        IUniswapV2ERC20.abi,
        library.getSigner()
      );

      const rewardTokenDecimals = await rewardToken.decimals();

      const parsedAmount = parseUnits(
        rewardTokenAmount.toString(),
        rewardTokenDecimals
      );

      const rewardTokenSymbol = (await rewardToken.symbol()).toUpperCase();

      const allowance = await rewardToken.allowance(account, aludelAddress);

      let description: string;

      if (allowance.lt(parsedAmount)) {
        description = `Approve Aludel ${truncate(aludelAddress)}
      to spend ${rewardTokenAmount} ${rewardTokenSymbol}`;

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => <TxnPendingApprovals description={description} />
        });

        updateTx({
          id: txnId,
          type: TxnType.approveErc20,
          status: TxnStatus.Initiated,
          description
        });

        const approveTx = await rewardToken.approve(
          aludelAddress,
          parsedAmount
        );

        updateTx({
          id: txnId,
          status: TxnStatus.PendingOnChain,
          hash: approveTx.hash
        });

        await approveTx.wait(1);

        updateTx({
          id: txnId,
          status: TxnStatus.Mined,
          hash: approveTx.hash
        });
      }

      txnId = uuid();

      description = `${rewardTokenAmount} ${rewardTokenSymbol} to Aludel ${truncate(
        aludelAddress
      )}`;

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => <TxnPendingApprovals description={description} />
      });

      updateTx({
        id: txnId,
        type: TxnType.fundAludel,
        status: TxnStatus.Initiated,
        description
      });

      const fundTx = await aludel[rewardProgramFundMethod](
        parsedAmount,
        Math.round(unlockDays * 24 * 60 * 60)
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: fundTx.hash
      });

      await fundTx.wait(1);

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: fundTx.hash
      });
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });
      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error: errorMessage
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
