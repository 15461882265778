import { queryClient } from 'index';
import { Queries } from 'types/enum';
import { fetchCoingeckoSingleToken } from './coingecko';

export async function getTokenIcon(tokenAddress: string, chainId: number) {
  const cachedResult = queryClient
    .getQueryCache()
    .find([Queries.TOKEN_ICON, tokenAddress.toLowerCase(), chainId]);

  if (cachedResult) {
    return cachedResult.state.data as string;
  }

  try {
    const token = await fetchCoingeckoSingleToken(chainId, tokenAddress, fetch);

    const url = queryClient.setQueryData(
      [Queries.TOKEN_ICON, tokenAddress.toLowerCase(), chainId],
      token?.imageUrl
    );

    return url;
  } catch {
    const url = queryClient.setQueryData(
      [Queries.TOKEN_ICON, tokenAddress.toLowerCase(), chainId],
      `${window.location.origin}/token.svg`
    );
    return url;
  }
}
