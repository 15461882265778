import { useCallback, useState } from 'react';
import { useWeb3React } from 'hooks/web3';

export default function useAddTokenToMetamask(
  address: string,
  symbol: string,
  decimals: number
): {
  addToken: () => void;
  success: boolean | undefined;
} {
  const { library } = useWeb3React();

  const [success, setSuccess] = useState<boolean | undefined>();

  const addToken = useCallback(() => {
    if (
      library &&
      library.provider.isMetaMask &&
      library.provider.request
    ) {
      library.provider
        .request({
          method: 'wallet_watchAsset',
          params: {
            //@ts-ignore // need this for incorrect ethers provider type
            type: 'ERC20',
            options: {
              address: address,
              symbol: symbol,
              decimals: decimals,
            }
          }
        })
        .then((success: any) => {
          setSuccess(success);
        })
        .catch(() => setSuccess(false));
    } else {
      setSuccess(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [library, symbol]);

  return { addToken, success };
}
