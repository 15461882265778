import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  Box,
  InputGroup,
  InputRightElement,
  IconButton,
  Badge,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  SimpleGrid,
  HStack,
  Heading
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { isMobile } from 'react-device-detect';
import { groupByKey } from 'utils/groupBy';
import { useWeb3React } from 'hooks/web3';
import { useModal } from 'store/modals';
import { ModalType } from './types';
import {
  Crucible,
  CrucibleLockObject,
  ExtendedRewardProgram,
  RewardProgram
} from 'types';
import RewardProgramSearchCard from 'components/crucible/detail/rewards/RewardProgramSearchCard';
import { useConfig } from 'store/config';
import { PowerSwitchStatus } from 'api/blockchain/rewardProgram';

type Props = {
  blockTimestamp: number;
  extendedRewardPrograms: ExtendedRewardProgram[];
  crucibleLocks?: CrucibleLockObject;
  selectedCrucible?: Crucible;
  isReadOnly: boolean;
  isLeaderboard: boolean;
};

const filterSearch = (program: RewardProgram, search: string) => {
  return (
    program.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
    program.maintainer.toLowerCase().includes(search.toLocaleLowerCase()) ||
    program.tags?.some((a) => a.includes(search.toLocaleLowerCase()))
  );
};

const sortExpiryTime = (
  a: RewardProgram,
  b: RewardProgram,
  blockTimestamp: number
) => {
  return !a.expiryTimestamp ? -1 : a.expiryTimestamp > blockTimestamp ? -1 : 1;
};

const SelectProgramModal: FC<Props> = ({
  blockTimestamp,
  extendedRewardPrograms,
  crucibleLocks,
  selectedCrucible,
  isReadOnly,
  isLeaderboard
}) => {
  const rewardPrograms = useMemo(() => {
    return extendedRewardPrograms.map((rp) => rp.rewardProgram);
  }, [extendedRewardPrograms]);

  const { chainId = 1 } = useWeb3React();
  const { closeModal } = useModal();
  const [search, setSearch] = useState('');
  const [accordionIndexes, setAccordionIndexes] = useState<number[]>([]);
  const [filteredRewardPrograms, setFilteredRewardPrograms] =
    useState(rewardPrograms);
  const { crucibleFactoryAddress } = useConfig(chainId).config;

  useEffect(() => {
    setFilteredRewardPrograms(() =>
      rewardPrograms
        .filter((program) => filterSearch(program, search))
        .sort((a, b) => sortExpiryTime(a, b, blockTimestamp))
    );
  }, [search, chainId, rewardPrograms, blockTimestamp]);

  const grouped = groupByKey(filteredRewardPrograms, 'maintainer');
  const groupedLength = Object.keys(grouped).length;

  const isShutdownOrOffline = (rp: RewardProgram) => {
    return (
      rp.rewardProgramState === PowerSwitchStatus.OFFLINE ||
      rp.rewardProgramState === PowerSwitchStatus.SHUTDOWN
    );
  };

  const rpHasVaultFactory = (rp: RewardProgram) => {
    return !!rp.vaultFactories.find(
      (vf) =>
        vf.id.split('-')[1].toLowerCase() ===
        crucibleFactoryAddress.toLowerCase()
    );
  };

  const isExpired = (rp: RewardProgram) => {
    if (
      isShutdownOrOffline(rp) ||
      !rpHasVaultFactory(rp) ||
      rp.isMaintainerBlacklisted ||
      rp.isProgramBlocked
    ) {
      return true;
    }

    return rp?.expiryTimestamp
      ? dayjs
          .unix(rp.expiryTimestamp as number)
          .isBefore(dayjs.unix(blockTimestamp))
      : false;
  };

  const daysLeft = (expiryTimestamp?: number) => {
    if (expiryTimestamp) {
      return (
        dayjs
          .unix(expiryTimestamp as number)
          .diff(dayjs.unix(blockTimestamp), 'days') + ' days left'
      );
    }
    return '∞';
  };

  const rpState = (group: string) => {
    let totalActive = 0;
    let totalEnded = 0;
    let totalLocks = 0;

    grouped[group].forEach((rp: RewardProgram) => {
      const hasNoSearch = !search;
      const hasRpExpired = isExpired(rp);
      const hasLocks =
        crucibleLocks &&
        crucibleLocks[rp.address] &&
        crucibleLocks[rp.address].length > 0;

      const hasRpExpiredWithParticipation =
        hasRpExpired && hasLocks && hasNoSearch;

      if (!hasRpExpired) {
        totalActive++;
      }

      if (search && hasRpExpired) {
        totalEnded++;
      }

      if (!search && hasRpExpiredWithParticipation) {
        totalEnded++;
      }

      if (hasLocks) {
        totalLocks++;
      }
    });

    return {
      totalLocks,
      totalActive,
      totalEnded
    };
  };

  const subscribedPrograms = useMemo(() => {
    if (selectedCrucible && crucibleLocks) {
      const subscribedProgramsList = crucibleLocks[selectedCrucible.id].map(
        (lock) => lock.rewardProgramAddress.toLocaleLowerCase()
      );
      return rewardPrograms
        .filter((program) =>
          subscribedProgramsList.includes(program.address.toLocaleLowerCase())
        )
        .sort((a, b) => sortExpiryTime(a, b, blockTimestamp));
    }
  }, [rewardPrograms, crucibleLocks, selectedCrucible, blockTimestamp]);

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.selectProgram)}
      size={isMobile ? 'full' : 'xl'}
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent
        bg='gray.800'
        mt={isMobile ? 0 : undefined}
        borderRadius={isMobile ? 'none' : 'xl'}
      >
        <ModalHeader fontSize='24px'>
          <Text textAlign='center'>Select reward program</Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <InputGroup>
            <Input
              variant='filled'
              placeholder='Search'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value) {
                  setAccordionIndexes(Array.from(Array(groupedLength).keys()));
                } else {
                  setAccordionIndexes([]);
                }
              }}
            />
            <InputRightElement>
              <IconButton
                borderRadius='md'
                color='whiteAlpha.500'
                variant='ghost'
                icon={<AiFillCloseCircle />}
                onClick={() => {
                  if (search) {
                    setSearch('');
                    setAccordionIndexes([]);
                  }
                }}
                aria-label='clear input'
              />
            </InputRightElement>
          </InputGroup>
          {subscribedPrograms &&
            subscribedPrograms.length > 0 &&
            !search &&
            selectedCrucible && (
              <Box my={4}>
                <Heading size='sm' mb={3}>
                  {isReadOnly
                    ? 'Active subscriptions'
                    : 'Your active subscriptions'}
                </Heading>
                <SimpleGrid columns={2} spacing={4}>
                  {subscribedPrograms
                    .sort((a: RewardProgram, b: RewardProgram) => {
                      return isExpired(b) ? -1 : 1;
                    })
                    .map((rewardProgram) => (
                      <RewardProgramSearchCard
                        key={rewardProgram.address}
                        rewardProgram={rewardProgram}
                        isExpired={isExpired}
                        daysLeft={daysLeft}
                        isLeaderboard={isLeaderboard}
                      />
                    ))}
                </SimpleGrid>
              </Box>
            )}
          <Box my={4}>
            <Heading size='sm' mb={3}>
              All programs
            </Heading>
            {filteredRewardPrograms.length > 0 && (
              <Accordion
                index={accordionIndexes}
                onChange={(indexes: number[]) => setAccordionIndexes(indexes)}
                allowMultiple
              >
                {Object.keys(grouped)
                  .filter(
                    search
                      ? () => true
                      : (group) =>
                          isLeaderboard ||
                          rpState(group).totalActive > 0 ||
                          rpState(group).totalLocks > 0
                  )
                  .sort((a, b) => a.localeCompare(b))
                  .map((group, idx) => (
                    <AccordionItem>
                      <h2>
                        <AccordionButton px={0}>
                          <HStack flex='1' textAlign='left'>
                            <Text>{group}</Text>
                            {!!rpState(group).totalActive && (
                              <Badge colorScheme='green'>
                                {rpState(group).totalActive} active
                              </Badge>
                            )}
                            {!!rpState(group).totalEnded && (
                              <Badge colorScheme='red'>
                                {rpState(group).totalEnded} ended
                              </Badge>
                            )}
                          </HStack>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} px={0}>
                        <SimpleGrid columns={2} spacing={4}>
                          {grouped[group]
                            .sort((a: RewardProgram, b: RewardProgram) => {
                              return isExpired(b) ? -1 : 1;
                            })
                            .filter(
                              search
                                ? () => true
                                : (rewardProgram: RewardProgram) =>
                                    isLeaderboard ||
                                    !(
                                      isExpired(rewardProgram) &&
                                      selectedCrucible &&
                                      crucibleLocks &&
                                      !crucibleLocks[selectedCrucible.id].find(
                                        (l) =>
                                          l.rewardProgramAddress ===
                                          rewardProgram.address
                                      )
                                    )
                            )
                            .map((rewardProgram: RewardProgram) => (
                              <RewardProgramSearchCard
                                key={rewardProgram.address}
                                rewardProgram={rewardProgram}
                                isExpired={isExpired}
                                daysLeft={daysLeft}
                                isLeaderboard={isLeaderboard}
                              />
                            ))}
                        </SimpleGrid>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
              </Accordion>
            )}

            {filteredRewardPrograms.length === 0 && (
              <Text textAlign='center'>
                No reward programs found for <strong>{search}</strong>
              </Text>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectProgramModal;
