import { BigNumber } from 'ethers';
import { queryClient } from '../index';
import { simpleLPToken } from 'types';
import { Queries } from 'types/enum';
import { LpData } from 'types/firebase';
import bigNumberishToNumber from 'utils/bigNumberishToNumber';

const getLpTokenBreakdown = async (
  tokenAddress: string,
  tokenBalance: BigNumber,
  chainId: number
): Promise<{ lpTokenData?: simpleLPToken[] }> => {
  const lpTokenObject = queryClient.getQueryData([Queries.LP_DATA, chainId]) as LpData || {};

  const isLp = lpTokenObject[tokenAddress] !== undefined;

  if (isLp) {
    try {
      const lpTokenData: simpleLPToken[] = await lpTokenObject[
        tokenAddress
      ].map((token: simpleLPToken) => {
        return {
          amount: token.amount * bigNumberishToNumber(tokenBalance),
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals
        };
      });
      return { lpTokenData };
    } catch (e) {
      console.log(e);
      return {};
    }
  }
  return {};
};

export default getLpTokenBreakdown;
