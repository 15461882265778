import { getInfuraProvider } from 'helpers/providers';
import { db } from 'libraries/firebasePublic';
import { ExtendedRewardProgram, TemplateName } from 'types';
import {
  LpData,
  ProgramRewards,
  ProgramStaked,
  UsdValues,
  Stats,
  AludelTemplates
} from 'types/firebase';
import { mapTemplateNameToAludelAbi } from 'utils/aludelMappings';

export const fetchUsdValues = async (chainId: number): Promise<UsdValues> => {
  try {
    const usdValuesRef = db
      .collection('tokenUSDValues')
      .doc(chainId.toString());
    const doc = await usdValuesRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] Token USD Values: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data() as UsdValues;
    return response;
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get token USD values';
    throw new Error(errMessage);
  }
};

export const fetchLPTokens = async (chainId: number) => {
  try {
    const lpTokensRef = db.collection('lpData').doc(chainId.toString());
    const doc = await lpTokensRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] LP Data: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data() as LpData;
    return response;
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get lp token data';
    throw new Error(errMessage);
  }
};

export const fetchStats = async (chainId: number): Promise<Stats> => {
  try {
    const statsRef = db.collection('landingStats').doc(chainId.toString());
    const doc = await statsRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] Landing Stats: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data() as Stats;
    return response;
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get landing page stats';
    throw new Error(errMessage);
  }
};

export const fetchPrograms = async (
  chainId: number,
  blockedPrograms: string[],
  maintainerBlacklist: string[]
): Promise<ExtendedRewardProgram[]> => {
  try {
    let programsRef;

    programsRef = db.collection('rewardPrograms').doc(chainId.toString());

    const doc = await programsRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] Reward Programs: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data();
    const values = Object.values(response as {});
    return values.map((v: any) => ({
      ...v,
      rewardProgram: {
        ...v.rewardProgram,
        abi: mapTemplateNameToAludelAbi[
          v.rewardProgram.templateName as TemplateName
        ],
        isMaintainerBlacklisted: !!maintainerBlacklist.find(
          (maintainer) =>
            maintainer.toLowerCase() === v.rewardProgram.owner.toLowerCase()
        ),
        isProgramBlocked: !!blockedPrograms.find(
          (program) =>
            program.toLowerCase() === v.rewardProgram.address.toLowerCase()
        )
      }
    }));
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get reward programs';
    throw new Error(errMessage);
  }
};

export const fetchRewards = async (
  chainId: number
): Promise<ProgramRewards> => {
  try {
    const rewardsRef = db
      .collection('programRewardsUSD')
      .doc(chainId.toString());
    const doc = await rewardsRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] Program Rewards USD: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data() as ProgramRewards;
    return response;
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get rewards usd';
    throw new Error(errMessage);
  }
};

export const fetchStaked = async (chainId: number): Promise<ProgramStaked> => {
  try {
    const stakedRef = db.collection('programStakedUSD').doc(chainId.toString());
    const doc = await stakedRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] Program Staked USD: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data() as ProgramStaked;
    return response;
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get staked usd';
    throw new Error(errMessage);
  }
};

export const fetchTokenBlacklist = async (
  chainId: number
): Promise<string[]> => {
  try {
    const blackListRef = db
      .collection('tokenBlacklist')
      .doc(chainId.toString());
    const doc = await blackListRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] Token Blacklist: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data();
    return response?.tokenBlacklist || [];
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get token blacklist';
    throw new Error(errMessage);
  }
};

export const fetchLeaderboard = async (
  chainId: number,
  rewardProgramAddress: string,
  search: string
): Promise<{ leaderboard: any[]; lastUpdated: number; maxRank: number }> => {
  try {
    const lastUpdatedRef = db
      .collection('crucibleLeaderboard')
      .doc(chainId.toString());
    const lastUpdatedDoc = await lastUpdatedRef.get();

    if (!lastUpdatedDoc.data()) {
      throw new Error(
        '[ERROR] Crucible Leaderboard: Firebase document not found'
      );
    }

    const { lastUpdated } = lastUpdatedDoc.data() as any;

    let docs;

    if (search) {
      const documentRef = await db
        .collection(
          `crucibleLeaderboard/${chainId.toString()}/${rewardProgramAddress.toLowerCase()}`
        )
        .doc(search.toLowerCase());

      const doc = await documentRef.get();

      if (!doc) {
        throw new Error(
          '[ERROR] Crucible Leaderboard: Firebase documents not found'
        );
      }
      docs = [doc];
    } else {
      const snapshot = await db
        .collection(
          `crucibleLeaderboard/${chainId.toString()}/${rewardProgramAddress.toLowerCase()}`
        )
        .orderBy('level', 'desc')
        .limit(100)
        .get();

      const documents = snapshot.docs;

      if (!documents) {
        throw new Error(
          '[ERROR] Crucible Leaderboard: Firebase documents not found'
        );
      }

      docs = documents;
    }

    const totalCount = await db
      .collection(
        `crucibleLeaderboard/${chainId.toString()}/${rewardProgramAddress.toLowerCase()}`
      )
      .orderBy('rank', 'desc')
      .limit(1)
      .get();

    const totalCountDocs = totalCount.docs;

    if (!totalCountDocs) {
      throw new Error('max level record not found');
    }

    const maxRank = Number(totalCountDocs.map((a) => a.data().rank));

    const response = docs.map((d) => ({ ...d.data() }));

    const withEns = await Promise.all(
      response.map(async (r) => {
        const provider = getInfuraProvider(chainId);
        return provider.lookupAddress((r as any).owner).then(
          (v) => ({ ...r, ens: v }),
          (err) => ({ ...r })
        );
      })
    );

    const filtered = withEns.filter((d: any) => d.crucibleAddress);

    return { leaderboard: filtered, lastUpdated, maxRank };
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get leaderboard data';
    throw new Error(errMessage);
  }
};

export const fetchBlockedPrograms = async (
  chainId: number
): Promise<string[]> => {
  try {
    const blockedProgramsRef = db
      .collection('blockedPrograms')
      .doc(chainId.toString());
    const doc = await blockedProgramsRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] Blocked Programs: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data();
    return response?.blockedPrograms || [];
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get blocked programs';
    throw new Error(errMessage);
  }
};

export const fetchMaintainerBlacklist = async (
  chainId: number
): Promise<string[]> => {
  try {
    const maintainerBlacklistRef = db
      .collection('maintainerBlacklist')
      .doc(chainId.toString());
    const doc = await maintainerBlacklistRef.get();
    if (!doc.data()) {
      throw new Error(
        `[ERROR] Maintainer Blacklist: Firebase document not found on chain ${chainId}`
      );
    }
    const response = doc.data();
    return response?.maintainerBlacklist || [];
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get maintainer blacklist';
    throw new Error(errMessage);
  }
};

export const fetchAludelTemplates = async (
  chainId: number
): Promise<AludelTemplates> => {
  try {
    const aludelTemplatesRef = db
      .collection('aludelTemplates')
      .doc(chainId.toString());
    const doc = await aludelTemplatesRef.get();

    if (!doc.data()) {
      throw new Error(
        `[ERROR] Aludel Templates: Firebase document not found on chain ${chainId}`
      );
    }

    const response = doc.data() as AludelTemplates;

    let filteredResponse: AludelTemplates = {};

    Object.keys(response).forEach((key) => {
      if (!response[key].disabled) {
        filteredResponse[key] = response[key];
      }
    });

    return filteredResponse;
  } catch (err: any) {
    const errMessage =
      err && err.message ? err.message : 'Failed to get aludel templates';
    throw new Error(errMessage);
  }
};
