import { getInfuraProvider } from 'helpers/providers';

async function fetchBlockTimestampInfura(chainId: number) {
  const provider = getInfuraProvider(chainId);
  const blockNumber = await provider.getBlockNumber();
  const block = await provider.getBlock(blockNumber);
  return block.timestamp;
}

export async function fetchBlockTimestamp(chainId: number) {
  try {
    const blockTimeRes = await fetch('/api/get-timestamp');
    const data = (await blockTimeRes.json()) as number;
    return data;
  } catch (e) {
    console.log(e);
    const timeSeconds = await fetchBlockTimestampInfura(chainId);
    return timeSeconds;
  }
}
