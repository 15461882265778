import { FC } from 'react';
import { Spinner, Text } from '@chakra-ui/react';
import { useStakingTokenBalance } from 'hooks/query/useStakingTokenBalance';
import { truncate } from 'utils/address';
import formatNumber from 'utils/formatNumber';

type Props = {
  crucibleId: string | undefined;
  stakingTokenAddress: string;
  stakingTokenSymbol: string;
  rewardProgramAddress: string;
};

const CrucibleDropDownItem: FC<Props> = ({
  crucibleId,
  stakingTokenAddress,
  stakingTokenSymbol,
  rewardProgramAddress
}) => {
  const { data: stakingTokenData } = useStakingTokenBalance(
    stakingTokenAddress,
    rewardProgramAddress,
    crucibleId
  );

  return (
    <>
      <Text>ID: {truncate(crucibleId)}</Text>
      {!stakingTokenData ? (
        <Spinner />
      ) : (
        <Text>
          {formatNumber.token(
            stakingTokenData.stakingTokenUnlocked,
            stakingTokenData.stakingTokenDecimals
          )}{' '}
          {stakingTokenSymbol}
        </Text>
      )}
    </>
  );
};

export default CrucibleDropDownItem;
