import { buttonWrapperERC20Abi } from 'abi/buttonWrapperERC20';
import { CommonConfig, ConfigOptions } from 'store/config';

export const config: ConfigOptions = {
  1: {
    crucibleFactoryAddress: '0x54e0395CFB4f39beF66DBCd5bD93Cca4E9273D56',
    crucibleFactoryCreationBlock: 11881064,
    universalVaultAddress: '0x8A09fFA4d4310c7F59DC538a1481D8Ba2214Cef0',
    mistTokenAddress: '0x88ACDd2a6425c3FaAE4Bc9650Fd7E27e0Bebb7aB',
    mintingTokenAddress: '0xCD6bcca48069f8588780dFA274960F15685aEe0e',
    aludelAddress: '0x93c31fc68E613f9A89114f10B38F9fd2EA5de6BC',
    aludelFactory: '0xFe32d8c1Dcc3E31D2286C4Ed467C16e213e2d9B7',
    rewardPoolFactory: '0xf016fa84d5f3a252409a63b5cb89b555a0d27ccf',
    powerSwitchFactory: '0x66A89d49A6DA8338EFf273778ef90f8f233DFE83',
    transmuterAddress: '0xB772ce9f14FC7C7db0D4525aDb9349FBD7ce456a',
    transmuterAddressAmpleforth: '0x90013fB4D3f9844f930f5dB8DD53CfF38824e3CF',
    customMinterAddress: '0x075d940Fa6878c6164f3F44CFc584923c4F5654C',
    rpcUrl: 'https://mainnet.infura.io/v3/00a5b13ef0cf467698571093487743e6',
    uniswapPoolUrl: 'http://lp.mist.alchemist.wtf',
    crucibleSubgraphUrl:
      'https://api.studio.thegraph.com/query/81146/crucible-mainnet/version/latest',
    flashbotsRpcAddress: '0xf1a54b0759b58661cea17cff19dd37940a9b5f1a',
    seasonalNFTAddress: '0x22bF9DB5a63f2c41A0A0Eb46EDE5293AeEb97701',
    christmasTokenId:
      '52262759087272093323486027416674203088267292735569410576982151531453000712193',
    wrappableTokens: [
      {
        underlyingTokenAddress: '0x1E6bb68Acec8fefBD87D192bE09bb274170a0548',
        wrapperTokenAddress: '0xf03387d8d0ff326ab586a58e0ab4121d106147df',
        underlyingTokenSymbol: 'aAMPL',
        wrapperTokenSymbol: 'ubAAMPL',
        supportsAutoDeposit: true,
        wrapperTokenAbi: buttonWrapperERC20Abi,
        wrapperToUnderlyingFunction: 'wrapperToUnderlying'
      }
    ],
    aaveTokens: ['0x1E6bb68Acec8fefBD87D192bE09bb274170a0548']
  },
  5: {
    crucibleFactoryAddress: '0xB54AaD626C2B93878d82d7B5AB9704de10D9E816',
    crucibleFactoryCreationBlock: 4901822,
    mistTokenAddress: '0xDb435816E41eADa055750369Bc2662EFbD465D72',
    mintingTokenAddress: '0x652b89e435a4e3e737ef844f510b3832da6b27ac',
    aludelAddress: '0x33c64c46dC69C1bFd40665AB0cE20BdDb0D589Af',
    aludelFactory: '0x0C5567a2d52841141AA18e03Af4ab32119edCE6C',
    rewardPoolFactory: '0xfeccbcbefdf05397f522605774830a259139659a',
    powerSwitchFactory: '0xcEFf02b28F9b7af6E55EB8f2bAe126155a186Ee0',
    transmuterAddress: '0xe6726685F14cA9d6EBE6cBb25A4CA6Fa3Ab56E77',
    customMinterAddress: '0x2d2703c370ebe8219Cb941fdc52edb238D871590',
    rpcUrl: 'https://goerli.infura.io/v3/00a5b13ef0cf467698571093487743e6',
    uniswapPoolUrl:
      'https://app.uniswap.org/#/add/v2/0xDb435816E41eADa055750369Bc2662EFbD465D72/ETH',
    crucibleSubgraphUrl:
      'https://api.thegraph.com/subgraphs/name/alchemistcoin/crucible-goerli',
    flashbotsRpcAddress: '',
    wrappableTokens: []
  },
  // polgyon mainet
  137: {
    crucibleFactoryAddress: '0xE2dD7930d8cA478d9aA38Ae0F5483B8A3B331C40', // V2
    crucibleFactoryCreationBlock: 25571510,
    mistTokenAddress: '',
    mintingTokenAddress: '',
    aludelAddress: '',
    aludelFactory: '0x075d940Fa6878c6164f3F44CFc584923c4F5654C',
    rewardPoolFactory: '0xf92D86483438BDe1d68e501ce15470155DeE08B3',
    powerSwitchFactory: '0xC3771D04bA835A611c4187FEEa0f85512F4f4D19',
    transmuterAddress: '0x4F91Ba2A5e6079f6fC929d062699e279bF85bBF7', // V2
    customMinterAddress: '0x8971dac20F6061fD487555242748061e7c34B42E',
    rpcUrl:
      'https://polygon-mainnet.infura.io/v3/00a5b13ef0cf467698571093487743e6',
    uniswapPoolUrl: '',
    crucibleSubgraphUrl:
      'https://api.studio.thegraph.com/query/81146/crucible-polygon/version/latest',
    flashbotsRpcAddress: '',
    wrappableTokens: []
  },
  // polgyon mumbai testnet
  80001: {
    crucibleFactoryAddress: '0x5D15d226303cB96AC2EA7f760a313eA6bB36C508',
    crucibleFactoryCreationBlock: 24823890,
    mistTokenAddress: '',
    mintingTokenAddress: '',
    aludelAddress: '',
    aludelFactory: '0x86CE6C574C6e4Cc5aD0e2Ed9aD3934eE7beC659c',
    rewardPoolFactory: '0xf3D4b566ecEF776d44Aba803306480Ef634CB1Da',
    powerSwitchFactory: '0x9D6d9005C9EC3149b5DE0804eEb5B98B297d4876',
    transmuterAddress: '0xF6c1210Aca158bBD453A12604A03AeD2659ac0ef',
    customMinterAddress: '0x07dd1e6f4312f1ebcfabec0d37e702088c3ff5ce',
    rpcUrl:
      'https://polygon-mumbai.infura.io/v3/00a5b13ef0cf467698571093487743e6',
    uniswapPoolUrl: '',
    crucibleSubgraphUrl:
      'https://api.thegraph.com/subgraphs/name/alchemistcoin/crucible-mumbai',
    flashbotsRpcAddress: '',
    wrappableTokens: []
  },
  // Avalanche Mainnet C-Chain
  43114: {
    crucibleFactoryAddress: '0x6D07709A30fcE07901B2a6D8e1D6E6ac17eb96De',
    crucibleFactoryCreationBlock: 11939644,
    universalVaultAddress: '0xceD5A1061F5507172059FE760CA2e9F050caBF02',
    mistTokenAddress: '',
    mintingTokenAddress: '',
    aludelAddress: '',
    aludelFactory: '0x075d940Fa6878c6164f3F44CFc584923c4F5654C',
    rewardPoolFactory: '0x406476e84941ee7964e53371c01914f53822b258',
    powerSwitchFactory: '0xC3771D04bA835A611c4187FEEa0f85512F4f4D19',
    transmuterAddress: '0xF18f0485e1dBC7f1Cea8620ddbf16187A40E6520',
    transmuterAddressAmpleforth: '0x25BcaEd6377CEAA345f12C2005a42e669B8a29fC',
    customMinterAddress: '0x6ec19EF96C569ac1B6c4Fe7404c5a948a9bB7416', // 1 AVAX,
    // customMinterAddress: '0x1625b84D233dF4b131da7B49c2b540890aBA0E96', // 0.05 AVAX,
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    uniswapPoolUrl: '',
    crucibleSubgraphUrl:
      'https://api.studio.thegraph.com/query/81146/crucible-avalanche/version/latest',
    flashbotsRpcAddress: '',
    wrappableTokens: []
  },
  // Avalanche FUJI C-Chain (test net)
  43113: {
    crucibleFactoryAddress: '0x4a8eC6b46EBb1A69480Ad0E6f70cF51fb7a39F74',
    crucibleFactoryCreationBlock: 24823890,
    mistTokenAddress: '',
    mintingTokenAddress: '',
    aludelAddress: '',
    aludelFactory: '0xf5972635Bc524a9214C27D1c29E6CD6dBfD81eee',
    rewardPoolFactory: '0x45522b727d50258780c3DE972b299D1CD69b20d4',
    powerSwitchFactory: '0x86CE6C574C6e4Cc5aD0e2Ed9aD3934eE7beC659c',
    transmuterAddress: '0xe3F21Ee108aB8841Fb281fC7B2DB604B414E27EE',
    customMinterAddress: '0x07dd1e6f4312f1ebcfabec0d37e702088c3ff5ce',
    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    uniswapPoolUrl: '',
    crucibleSubgraphUrl:
      'https://api.thegraph.com/subgraphs/name/alchemistcoin/crucible-fuji',
    flashbotsRpcAddress: '',
    wrappableTokens: []
  }
};

export const commonConfig: CommonConfig = {
  supportedChainIds: [1, 5, 137, 80001, 43114, 43113],
  appName: 'https://crucible.wtf',
  mintCrucibleDoc: 'https://docs.crucible.wtf/guides/how-do-i-mint-a-crucible',
  lpTokensDoc: 'https://docs.crucible.wtf/acquiring-and-adding-liquidity',
  aludelRewardsDoc: 'https://docs.crucible.wtf/',
  portisApiKey: 'e86e917b-b682-4a5c-bbc5-0f8c3b787562',
  etherscanApiKey: 'Y44B2NZ5TEZGIG7IAK2M4AYATEYQUX79E4',
  polygonscanApiKey: '9QAZXSH68D3YU4SNNGICDH2XBUKQ65BVCN',
  snowtraceApiKey: '57ZG81PMF37D5DSXMN1ANT1GR6PT8MK9YY',
  getMistUrl: 'http://swap.mist.alchemist.wtf',
  infuraApiKey: '00a5b13ef0cf467698571093487743e6',
  governanceList: ['alchemistcoin.eth', 'masq.eth'],
  artworkWhitelist: [
    '0xFC107a6D78F6B7bC21924010374e5a428461aef5',
    '0xFA10ceA919C9E403d150318ee0eccdEC57c0d941',
    '0x7f400d8d111B951F3aC7400555ca8a704816E7Fb'
  ]
};
