import { useWeb3React } from 'hooks/web3';
import { useConnection } from 'store/connection';
import { Queries } from 'types/enum';
import { useQuery } from 'react-query';
import { ExtendedRewardProgram } from 'types';
import { fetchPrograms, fetchRewards, fetchStaked } from 'api/firebase';
import { ProgramRewards, ProgramStaked } from 'types/firebase';
import { useBlockedPrograms } from './useBlockedPrograms';
import { useMaintainerBlacklist } from './useMaintainerBlacklist';
import { useRewardPrograms } from 'store/zustand/useRewardPrograms';
import { PowerSwitchStatus } from 'api/blockchain/rewardProgram';

export function usePrograms() {
  const { chainId = 1 } = useWeb3React();
  const { setCurrentRewardProgram, setRewardPrograms, currentRewardProgram } =
    useRewardPrograms();
  const { tried } = useConnection();
  const { data: blockedPrograms } = useBlockedPrograms();
  const { data: maintainerBlacklist } = useMaintainerBlacklist();

  const { data, isFetching, isLoading } = useQuery<
    ExtendedRewardProgram[],
    Error
  >(
    [Queries.REWARD_PROGRAMS, chainId, blockedPrograms, maintainerBlacklist],
    () => fetchPrograms(chainId, blockedPrograms!, maintainerBlacklist!),
    {
      enabled: tried && !!blockedPrograms && !!maintainerBlacklist,
      onSuccess: (d) => {
        setRewardPrograms(d.map((p) => p.rewardProgram));
      }
    }
  );

  // Check if current reward program is set according to reward program data
  // Keep unset if no reward programs exist
  if (
    data &&
    data.length > 0 &&
    (!currentRewardProgram ||
      !data
        .map(
          (p) =>
            p.rewardProgramAddress.toLowerCase() ===
            currentRewardProgram.address.toLowerCase()
        )
        .includes(true))
  ) {
    const filteredByExpiry = data.filter(
      (p) =>
        ((p.rewardProgram.expiryTimestamp &&
          p.rewardProgram.expiryTimestamp > Math.floor(Date.now() / 1000)) ||
          !p.rewardProgram.expiryTimestamp) &&
        (p.rewardProgram.rewardProgramState === PowerSwitchStatus.ONLINE ||
          p.rewardProgram.rewardProgramState === PowerSwitchStatus.NOTSTARTED)
    );

    if (filteredByExpiry.length > 0) {
      setCurrentRewardProgram(chainId, filteredByExpiry[0].rewardProgram);
    } else {
      setCurrentRewardProgram(chainId, data[0].rewardProgram);
    }
  }

  return { data, isFetching, isLoading };
}

export function useProgramsRewards() {
  const { chainId = 1 } = useWeb3React();
  const { tried } = useConnection();
  return useQuery<ProgramRewards, Error>(
    [Queries.PROGRAMS_REWARDS_USD, chainId],
    () => fetchRewards(chainId),
    {
      enabled: tried
    }
  );
}

export function useProgramsStaked() {
  const { chainId = 1 } = useWeb3React();
  const { tried } = useConnection();
  return useQuery<ProgramStaked, Error>(
    [Queries.PROGRAMS_STAKED_USD, chainId],
    () => fetchStaked(chainId),
    {
      enabled: tried
    }
  );
}
