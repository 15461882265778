export interface walletConfigInterface {
  chainName: string;
  chainTokenSymbol: string;
  chainTokenName: string;
  chainTokenDecimals: number;
  rpcUrls: string[];
  blockExplorerUrls: string[];
  iconPath: string;
  testnet?: boolean;
}

interface walletConfigOptions {
  [key: number]: walletConfigInterface;
}

export const walletConfig: walletConfigOptions = {
  1: {
    chainName: 'Ethereum',
    chainTokenSymbol: 'ETH',
    chainTokenName: 'Ethereum',
    chainTokenDecimals: 18,
    rpcUrls: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: ['https://etherscan.io'],
    iconPath: '/tokens/ethereum.svg'
  },
  5: {
    chainName: 'Görli',
    chainTokenSymbol: 'ETH',
    chainTokenName: 'Görli ETH',
    chainTokenDecimals: 18,
    rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
    iconPath: '/tokens/ethereum.svg',
    testnet: true
  },
  137: {
    chainName: 'Polygon',
    chainTokenSymbol: 'MATIC',
    chainTokenName: 'MATIC',
    chainTokenDecimals: 18,
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com'],
    iconPath: '/tokens/polygon.svg'
  },
  80001: {
    chainName: 'Mumbai',
    chainTokenSymbol: 'MATIC',
    chainTokenName: 'Mumbai MATIC',
    chainTokenDecimals: 18,
    rpcUrls: ['https://rpc.ankr.com/polygon_mumbai'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    iconPath: '/tokens/polygon.svg',
    testnet: true
  },
  43114: {
    chainName: 'Avalanche',
    chainTokenSymbol: 'AVAX',
    chainTokenName: 'Avalanche',
    chainTokenDecimals: 18,
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/'],
    iconPath: '/tokens/avalanche.svg'
  },
  43113: {
    chainName: 'Fuji',
    chainTokenName: 'AVAX',
    chainTokenSymbol: 'Fuji AVAX',
    chainTokenDecimals: 18,
    rpcUrls: ['https://rpc.ankr.com/avalanche_fuji'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/'],
    iconPath: '/tokens/avalanche.svg',
    testnet: true
  }
};
