import { BigNumber } from 'ethers';
import { queryClient } from 'index';
import { UserBalances } from 'types';
import { Queries } from 'types/enum';

export function getTokenBalance(
  address: string,
  chainId: number,
  account?: string | null
) {
  const data = queryClient.getQueryData<UserBalances>([
    Queries.USER_BALANCES, chainId, account
  ]);
  if (data && data[address]) {
    return data[address].value.amount;
  } else {
    return BigNumber.from(0);
  }
}
