import { ethers } from 'ethers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { crucibleAbi } from 'abi/crucibleAbi';
import { truncate } from 'utils/address';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import formatNumber from 'utils/formatNumber';

export const transferBetweenCrucibles = createAsyncThunk(
  THUNK_PREFIX.TRANSFER_BETWEEN_CRUCIBLES,
  async ({
    web3React,
    updateTx,
    toast,
    modal,
    logger,
    fromCrucibleAddress,
    toCrucibleAddress,
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    amount
  }: any) => {
    // create UUID for tracking this tx
    const txnId = uuid();

    const description = `${formatNumber.token(
      amount,
      tokenDecimals
    )} ${tokenSymbol} from Crucible ${truncate(
      fromCrucibleAddress
    )} to Crucible ${truncate(toCrucibleAddress)}`;

    updateTx({
      id: txnId,
      type: TxnType.transferBetweenCrucibles,
      status: TxnStatus.Initiated,
      description
    });

    try {
      const { library, account, chainId } = web3React;
      const signer = library.getSigner();

      const crucibleContract = new ethers.Contract(
        fromCrucibleAddress,
        crucibleAbi,
        signer
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm token transfer transaction' />
        )
      });

      const gasLimit = await crucibleContract.estimateGas.transferERC20(
        tokenAddress,
        toCrucibleAddress,
        amount
      );
      const gasPrice = await signer.getGasPrice();

      const transferTx = await crucibleContract.transferERC20(
        tokenAddress,
        toCrucibleAddress,
        amount,
        {
          gasLimit: gasLimit,
          gasPrice: gasPrice
        }
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: transferTx.hash
      });

      await transferTx.wait(1);

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: transferTx.hash
      });
    } catch (error) {
      const errorMessage = parseTransactionError(error);
      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });

      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
