import { ethers } from 'ethers';
import { ERC20Token } from 'types';
import { Config } from 'store/config';
import { Web3React } from 'hooks/web3';
import { convertChainIdToNativeTokenSymbol } from 'utils/convertChainIdToNetworkName';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';

export async function fetchUserBalances(web3React: Web3React, config: Config) {
  const { library, account, provider, chainId = 1 } = web3React;
  const { mistTokenAddress, mintingTokenAddress } = config;

  const tokensToTrack =
    chainId === 1 ? [mistTokenAddress, mintingTokenAddress] : [];

  const tokens: { [address: string]: ERC20Token } = {};

  await Promise.all(
    tokensToTrack.map(async (tokenAddress) => {
      const tokenContract = new ethers.Contract(
        tokenAddress,
        IUniswapV2ERC20.abi,
        provider
      );
      const tokenSymbol = await tokenContract.symbol();
      const tokenDecimals = await tokenContract.decimals();
      const balance = await tokenContract.balanceOf(account);

      tokens[tokenAddress] = {
        value: { amount: balance },
        address: tokenAddress,
        symbol: tokenSymbol === 'UNI-V2' ? 'LP' : tokenSymbol,
        decimals: tokenDecimals
      };
    })
  );

  const nativeTokenBalance = await library.getBalance(account);
  const nativeToken = {
    value: { amount: nativeTokenBalance },
    address: '',
    symbol: convertChainIdToNativeTokenSymbol(chainId),
    decimals: 18
  };

  tokens[''] = nativeToken;
  return tokens;
}
