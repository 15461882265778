import { BigNumber } from 'ethers';
import { ProgramToken, TokenValue } from 'types';
import { fetchAutoRetry } from 'utils/fetchAutoRetry';

type CoingeckoToken = {
  id: string;
  name: string;
  symbol: string;
  platforms: { [platform: string]: string };
};

interface CoingeckoTokenExtended extends CoingeckoToken {
  image: { [size: string]: string };
  market_data: { [prices: string]: { [currency: string]: number } };
}

export const chainToCoingeckoId: { [key: string]: string } = {
  '1': 'ethereum',
  '137': 'polygon-pos',
  '43114': 'avalanche'
};

export async function fetchCoingeckoTokens(
  chainId: number
): Promise<ProgramToken[]> {
  if (chainToCoingeckoId[chainId.toString()] === undefined) {
    console.log(
      `[Warning] Coingecko Tokenlist API unsupported for chain ${chainId}`
    );
    return [];
  }

  const network = chainToCoingeckoId[chainId.toString()];

  const resBody: CoingeckoToken[] = await fetchAutoRetry(
    'https://api.coingecko.com/api/v3/coins/list?include_platform=true',
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    },
    5,
    fetch
  );

  const programTokens: Promise<ProgramToken>[] = resBody
    .filter(
      (token) =>
        token.platforms[network] !== '' &&
        token.platforms[network] !== undefined
    )
    // Remove duplicates from api response
    .filter(
      (v, i, a) =>
        a.findIndex(
          (v2) =>
            v.platforms[network] === v2.platforms[network] &&
            v.platforms[network] === v2.platforms[network]
        ) === i
    )

    .map(async (token) => {
      const address = token.platforms[network];
      const tokenSymbol = token.symbol;

      // Hardcoded to 0, will be verified/updated on token selection
      const decimals = 0;

      const programToken = { address, tokenSymbol, decimals };
      return programToken;
    });

  return Promise.all(programTokens);
}

export async function fetchCoingeckoSingleToken(
  chainId: number,
  tokenAddress: string,
  fetch: any
): Promise<ProgramToken> {
  if (chainToCoingeckoId[chainId.toString()] === undefined) {
    throw new Error(
      `[Warning] Coingecko API does not support chain ${chainId}`
    );
  }

  const network = chainToCoingeckoId[chainId.toString()];

  const token: CoingeckoTokenExtended = await fetchAutoRetry(
    `https://api.coingecko.com/api/v3/coins/${network}/contract/` +
      tokenAddress.toLowerCase(),
    {
      method: 'GET',
      cache: 'force-cache',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    },
    5,
    fetch
  );

  const address = token.platforms[network];
  const tokenSymbol = token.symbol;
  const imageUrl = token.image.small;
  const decimals = 0;
  const value: TokenValue = {
    amount: BigNumber.from(1),
    amountUSD: token.market_data.current_price.usd
  };

  return { address, tokenSymbol, decimals, imageUrl, value };
}
