import { FC } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useCrucibles } from 'hooks/query/useCrucibles';
import { useConnection } from 'store/connection';
import GradientBackground from 'components/shared/GradientBackground';

const CruciblesBadge: FC = () => {
  const { tried } = useConnection();
  const { data: crucibles = [], isLoading } = useCrucibles();
  return (
    <GradientBackground visible borderRadius='2xl'>
      <Flex
        w='30px'
        h='24px'
        fontSize='small'
        fontWeight='500'
        borderRadius='2xl'
        bg='gray.700'
        justifyContent='center'
        alignItems='center'
      >
        {isLoading || !tried ? (
          <Spinner size='xs' />
        ) : (
          <Text>{crucibles.length}</Text>
        )}
      </Flex>
    </GradientBackground>
  );
};

export default CruciblesBadge;
