export enum Network {
  MAINNET = 1,
  GOERLI = 5,
  KOVAN = 42,
  xDAI = 100
}

export function convertChainIdToNetworkName(networkId?: Network) {
  if (!networkId) return 'Ethereum';

  switch (Number(networkId)) {
    case 1:
      return 'Ethereum';
    case 5:
      return 'Goerli';
    case 42:
      return 'Kovan';
    case 137:
      return 'Polygon';
    case 80001:
      return 'Mumbai';
    case 43114:
      return 'Avalanche';
    case 43113:
      return 'Fuji';
    default:
      return 'localhost';
  }
}

export function convertChainIdToInfuraPrefix(networkId?: Network) {
  if (!networkId) return 'Mainnet';

  switch (Number(networkId)) {
    case 1:
      return 'mainnet';
    case 5:
      return 'goerli';
    case 42:
      return 'kovan';
    case 137:
      return 'polygon-mainnet';
    case 80001:
      return 'polygon-mumbai';
    case 43114:
      return 'avalanche-mainnet';
    case 43113:
      return 'avalanche-fuji';
    default:
      return 'localhost';
  }
}

export function convertChainIdToIconPath(networkId?: Network) {
  if (!networkId) return '/tokens/ethereum.svg';

  switch (Number(networkId)) {
    case 1:
      return '/tokens/ethereum.svg';
    case 5:
      return '/tokens/ethereum.svg';
    case 42:
      return '/tokens/ethereum.svg';
    case 137:
      return '/tokens/polygon.svg';
    case 80001:
      return '/tokens/polygon.svg';
    case 43114:
      return '/tokens/avalanche.svg';
    case 43113:
      return '/tokens/avalanche.svg';
    default:
      return '/tokens/ethereum.svg';
  }
}

export function convertChainIdToNativeTokenSymbol(networkId: Network) {
  switch (Number(networkId)) {
    case 1:
      return 'ETH';
    case 5:
      return 'ETH';
    case 42:
      return 'ETH';
    case 137:
      return 'MATIC';
    case 80001:
      return 'MATIC';
    case 43114:
      return 'AVAX';
    case 43113:
      return 'AVAX';
    default:
      return 'ETH';
  }
}

export function convertChainIdToNativeTokenSymbolForPrice(networkId: Network) {
  switch (Number(networkId)) {
    case 137:
      return 'MATIC';
    case 80001:
      return 'MATIC';
    case 43114:
      return 'nAVAX';
    case 43113:
      return 'nAVAX';
    default:
      return 'Gwei';
  }
}
