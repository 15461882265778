import { TypedDataField } from '@ethersproject/abstract-signer';
import { BigNumberish, Contract, Signer } from 'ethers';
import { splitSignature } from 'ethers/lib/utils';
import { TypedData } from 'ethers-eip712';

export const signPermission = async (
  method: string,
  vault: Contract,
  owner: Signer,
  delegateAddress: string,
  tokenAddress: string,
  amount: BigNumberish,
  vaultNonce: BigNumberish
) => {
  // get chainId
  const chainId = (await vault.provider.getNetwork()).chainId;
  // craft permission

  const types = {} as Record<string, TypedDataField[]>;
  types[method] = [
    { name: 'delegate', type: 'address' },
    { name: 'token', type: 'address' },
    { name: 'amount', type: 'uint256' },
    { name: 'nonce', type: 'uint256' }
  ];

  const typedData: TypedData = {
    types,
    primaryType: method,
    domain: {
      name: 'UniversalVault',
      version: '1.0.0',
      chainId: chainId,
      verifyingContract: vault.address
    },
    message: {
      delegate: delegateAddress,
      token: tokenAddress,
      amount: amount.toString(),
      nonce: vaultNonce.toString()
    }
  };

  // sign permission
  // todo: add fallback if wallet does not support eip 712 rpc
  const signedPermission = await (owner as any)._signTypedData(
    typedData.domain,
    typedData.types,
    typedData.message
  );

  const replaceV: any = [];
  replaceV['00'] = '1b';
  replaceV['01'] = '1c';

  let signedPermissionNew;
  if (replaceV[signedPermission.slice(-2)]) {
    signedPermissionNew =
      signedPermission.slice(0, signedPermission.length - 2) +
      replaceV[signedPermission.slice(-2)];
  } else {
    signedPermissionNew = signedPermission;
  }

  // return
  return signedPermissionNew;
};

export const signPermitEIP2612 = async (
  owner: Signer,
  walletAddress: string,
  token: Contract,
  spenderAddress: string,
  value: BigNumberish,
  deadline: BigNumberish,
  nonce?: BigNumberish
) => {
  // get nonce
  nonce = nonce?.toString() || (await token.nonces(walletAddress)).toString();
  // get chainId
  const chainId = (await token.provider.getNetwork()).chainId;
  const tokenName = await token.name();

  // build EIP712 typed data

  const typedData: TypedData = {
    types: {
      Permit: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' }
      ]
    },
    primaryType: 'Permit',
    domain: {
      name: tokenName,
      version: '1',
      chainId: chainId,
      verifyingContract: token.address
    },
    message: {
      owner: walletAddress,
      spender: spenderAddress,
      value: value.toString(),
      nonce: nonce,
      deadline: deadline
    }
  };

  // sign permission
  // todo: add fallback if wallet does not support eip 712 rpc
  const signedPermission = await (owner as any)._signTypedData(
    typedData.domain,
    typedData.types,
    typedData.message
  );

  // split signature
  const sig = splitSignature(signedPermission);

  // return
  return [walletAddress, spenderAddress, value, deadline, sig.v, sig.r, sig.s];
};
