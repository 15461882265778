import { FC } from 'react';
import {
  Container,
  HStack,
  Link as ChakraLink,
  LinkBox,
  LinkOverlay,
  Grid,
  GridItem,
  Text,
  useMediaQuery,
  ButtonProps,
  Button,
  Box,
  Tooltip
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { Image } from '@chakra-ui/image';

import logo from 'img/logo.svg';
import icon from 'img/icon-alchemist.svg';
import { externalLinks } from 'helpers/links';
import { ModalType } from 'components/modals/types';
import { useModal } from 'store/modals';
import { MdFeedback } from 'react-icons/md';

const FeedbackButton = (props: ButtonProps) => {
  return (
    <Tooltip label='Feedback' hasArrow>
      <Button
        variant='ghost'
        background='#1C2237'
        borderRadius='3xl'
        position='fixed'
        bottom='19px'
        right='19px'
        _hover={{ bg: 'gray.800' }}
        p='3'
        {...props}
      >
        <Box color='cyan.500' as={MdFeedback} fontSize='1.5rem' />
      </Button>
    </Tooltip>
  );
};

const FooterLinks = () => {
  const { openModal } = useModal();
  return (
    <HStack justifyContent='center' spacing={4} color='gray.400' mt={2}>
      <ChakraLink
        isExternal
        href='https://dune.xyz/alchemistcoin/Alchemist-Dashboard'
      >
        Stats
      </ChakraLink>
      <ChakraLink isExternal href='https://docs.crucible.wtf/'>
        Docs
      </ChakraLink>
      <ChakraLink
        isExternal
        href='https://docs.crucible.wtf/frequently-asked-questions'
      >
        FAQs
      </ChakraLink>
      <ChakraLink as='button' onClick={() => openModal(ModalType.feedback)}>
        Feedback
      </ChakraLink>
      <FeedbackButton onClick={() => openModal(ModalType.feedback)} />
    </HStack>
  );
};

const Footer: FC = () => {
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const { pathname } = useLocation();

  const isCrucibleNFTRoute = pathname.match(/^\/nft-scene\/(\S+)$/);
  if (isCrucibleNFTRoute) return null;

  return (
    <Container maxW='container.xl' py={8} zIndex={2}>
      <Grid templateColumns='repeat(6, 1fr)' alignItems='center'>
        <GridItem colSpan={[6, null, null, 2]} mb={[8, null, null, 0]}>
          <LinkBox>
            <LinkOverlay as={Link} to='/'>
              <Image
                src={logo}
                width='140px'
                htmlWidth='140px'
                mx={['auto', null, null, 0]}
              />
            </LinkOverlay>
          </LinkBox>
        </GridItem>
        <GridItem colSpan={[6, null, null, 2]} mb={[8, null, null, 0]}>
          <HStack spacing={4} justifyContent='center'>
            {externalLinks.map(({ href, label, icon: Icon }) => (
              <ChakraLink key={label} href={href} isExternal>
                <Icon
                  aria-label={label}
                  color='white'
                  width='40px'
                  height='40px'
                />
              </ChakraLink>
            ))}
          </HStack>
          {!isLargerThan992 && <FooterLinks />}
        </GridItem>
        <GridItem colSpan={[6, null, null, 2]}>
          <ChakraLink
            href='https://www.alchemist.wtf'
            isExternal
            _hover={{ textDecoration: 'none' }}
          >
            <HStack justifyContent={['center', null, null, 'flex-end']}>
              <HStack
                minW='230px'
                spacing={1}
                px={3}
                py={2}
                borderWidth={1}
                borderRadius='3xl'
                borderColor='gray.700'
                color='gray.400'
              >
                <Image src={icon} width='18px' htmlWidth='18px' mr={2} />
                <Text>incubated by alchemist</Text>
              </HStack>
            </HStack>
          </ChakraLink>
        </GridItem>
      </Grid>
      {isLargerThan992 && <FooterLinks />}
    </Container>
  );
};

export default Footer;
