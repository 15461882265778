import {
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import { FC } from 'react';
import { useModal } from 'store/modals';
import { isMobile } from 'react-device-detect';
import { truncate } from 'utils/address';
import { Link as ChakraLink } from '@chakra-ui/react';
import { RiExternalLinkLine } from 'react-icons/ri';
import { Strategy } from 'types/snapshot';
import { isAddress } from 'ethers/lib/utils';
import { ModalType } from './types';

type Props = {
  strategies: Strategy[];
};

const StrategiesModal: FC<Props> = ({ strategies }) => {
  const { closeModal } = useModal();

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.strategies)}
      size={isMobile ? 'full' : 'md'}
      scrollBehavior={isMobile ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        bg='gray.800'
        mt={isMobile ? 0 : undefined}
        borderRadius={isMobile ? 'none' : 'xl'}
      >
        <ModalHeader fontSize='24px'>
          <Text textAlign='center'>Strategies</Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <VStack spacing={5} mb={4}>
            {strategies.map((strategy) => (
              <VStack
                px={4}
                py={3}
                spacing={3}
                borderWidth={1}
                borderRadius='xl'
                alignItems='flex-start'
                w='100%'
              >
                <Text p={1} fontSize='20px' fontWeight='600'>
                  {strategy.name}
                </Text>
                <Divider />
                {Object.entries(strategy.params).map(([param, value]) => (
                  <Flex
                    key={param}
                    borderRadius='md'
                    px={1}
                    justifyContent='space-between'
                    w='100%'
                  >
                    <Text color='gray.200'>{param}</Text>
                    {typeof value === 'string' && isAddress(value as string) ? (
                      <HStack>
                        <Text>{truncate(value)}</Text>
                        <ChakraLink
                          href={`https://etherscan.io/address/${value}`}
                          isExternal
                          fontSize='lg'
                        >
                          <RiExternalLinkLine />
                        </ChakraLink>
                      </HStack>
                    ) : (
                      <Text>{value}</Text>
                    )}
                  </Flex>
                ))}
              </VStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StrategiesModal;
