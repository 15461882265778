export const transmuterAbi = [
  {
    inputs: [
      { internalType: 'address', name: 'aludel', type: 'address' },
      { internalType: 'address', name: 'crucibleFactory', type: 'address' },
      { internalType: 'address', name: 'crucibleOwner', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'bytes32', name: 'salt', type: 'bytes32' },
      { internalType: 'bytes', name: 'permission', type: 'bytes' },
    ],
    name: 'mintCrucibleAndStake',
    outputs: [{ internalType: 'address', name: 'vault', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'aludel', type: 'address' },
      { internalType: 'address', name: 'crucibleFactory', type: 'address' },
      { internalType: 'address', name: 'crucibleOwner', type: 'address' },
      { internalType: 'bytes32', name: 'salt', type: 'bytes32' },
      {
        components: [
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'address', name: 'spender', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct TransmuterV1.Permit',
        name: 'permit',
        type: 'tuple',
      },
      { internalType: 'bytes', name: 'permission', type: 'bytes' },
    ],
    name: 'mintCruciblePermitAndStake',
    outputs: [{ internalType: 'address', name: 'vault', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'aludel', type: 'address' },
      { internalType: 'address', name: 'vault', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'address', name: 'spender', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct TransmuterV1.Permit',
        name: 'permit',
        type: 'tuple',
      },
      { internalType: 'bytes', name: 'permission', type: 'bytes' },
    ],
    name: 'permitAndStake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'master', type: 'address' },
      { internalType: 'bytes32', name: 'salt', type: 'bytes32' },
      { internalType: 'address', name: 'deployer', type: 'address' },
    ],
    name: 'predictDeterministicAddress',
    outputs: [{ internalType: 'address', name: 'instance', type: 'address' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'aludel', type: 'address' },
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'bytes', name: 'permission', type: 'bytes' },
        ],
        internalType: 'struct TransmuterV1.StakeRequest[]',
        name: 'requests',
        type: 'tuple[]',
      },
    ],
    name: 'stakeMulti',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'aludel', type: 'address' },
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'address', name: 'recipient', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'bytes', name: 'permission', type: 'bytes' },
        ],
        internalType: 'struct TransmuterV1.UnstakeRequest[]',
        name: 'requests',
        type: 'tuple[]',
      },
    ],
    name: 'unstakeMulti',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
