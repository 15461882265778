/*
  The following code keeps track of and stores user data
  such as account addresses, wallets and device types. This data is used
  to improve our product by understanding user trends. 
  The data is not used to target individual users and it's not shared with third parties.
*/
import { useEffect } from 'react';
import { convertConnectorTypeToWalletName } from '../utils/convertConnectorTypeToWalletName';
import { isMobile } from 'react-device-detect';
import { useWeb3React } from 'hooks/web3';
import { Logger } from 'libraries/loggly';

export const useAccountTracker = () => {
  const { account, connector } = useWeb3React();

  useEffect(() => {
    if (account && window === window.parent) {
      const logger = new Logger();
      const storedAccount = localStorage.getItem('userAccountAddress');
      const storedWallet = localStorage.getItem('userWallet');
      const storedDeviceType = localStorage.getItem('userDeviceType');

      const wallet = convertConnectorTypeToWalletName(connector) || '';
      const deviceType = isMobile ? 'mobile' : 'desktop';

      if (
        account !== storedAccount ||
        wallet !== storedWallet ||
        deviceType !== storedDeviceType
      ) {
        const userInfo = {
          address: account,
          wallet: convertConnectorTypeToWalletName(connector),
          deviceType: isMobile ? 'mobile' : 'desktop'
        };

        logger.push(userInfo);
        localStorage.setItem('userAccountAddress', account);
        localStorage.setItem('userWallet', wallet);
        localStorage.setItem('userDeviceType', deviceType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, connector]);
};
