import DynamicLoader from 'DynamicLoader';
import NoMatch from 'pages/NoMatch';
import { Switch, Route } from 'react-router-dom';

export default function Routes() {
  return (
    <Switch>
      <Route
        exact
        path={process.env.PUBLIC_URL + '/'}
        component={LandingPage}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/reward-programs'}
        component={RewardProgramsPage}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/reward-programs/:rewardProgramAddress'}
        component={RewardProgramsPage}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/governance'}
        component={Governance}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/governance/:spaceId'}
        component={Proposals}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/governance/:spaceId/:proposalId'}
        component={ProposalDetail}
      />
      <Route path={process.env.PUBLIC_URL + '/mint'} component={Mint} />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/crucible/:crucibleId/:tab?'}
        component={Crucibles}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/explore'}
        component={Explore}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/explore/:crucibleId/:tab?'}
        component={ExploreCrucible}
      />
      <Route
        exact
        path={
          process.env.PUBLIC_URL + '/nft-scene/:shuffledAddress/:screenshot?'
        }
        component={CrucibleNFT}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/onboarding'}
        component={Onboarding}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/manage'}
        component={Manage}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/manage/:rewardProgramAddress'}
        component={ManageDetail}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + '/leaderboard/:rewardProgramAddress'}
        component={Leaderboard}
      />
      <Route path='*'>
        <NoMatch />
      </Route>
    </Switch>
  );
}

const LandingPage = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Landing" */
      /* webpackPrefetch: true */
      './pages/Landing'
    )
);

const Governance = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Governance" */
      /* webpackPrefetch: true */
      './pages/Governance'
    )
);

const RewardProgramsPage = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "RewardPrograms" */
      /* webpackPrefetch: true */
      './pages/RewardPrograms'
    )
);
const Mint = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Mint" */
      /* webpackPrefetch: true */
      './pages/Mint'
    )
);
const Crucibles = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Crucibles" */
      /* webpackPrefetch: true */
      './pages/Crucibles'
    )
);

const CrucibleNFT = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "CrucibleNFT" */
      /* webpackPrefetch: true */
      './pages/CrucibleNFT'
    )
);

const Proposals = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Proposals" */
      /* webpackPrefetch: true */
      './pages/Proposals'
    )
);
const ProposalDetail = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "ProposalDetail" */
      /* webpackPrefetch: true */
      './pages/ProposalDetail'
    )
);
const Explore = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Explore" */
      /* webpackPrefetch: true */
      './pages/Explore'
    )
);

const ExploreCrucible = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "ExploreCrucible" */
      /* webpackPrefetch: true */
      './pages/ExploreCrucible'
    )
);

const Leaderboard = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Leaderboard" */
      /* webpackPrefetch: true */
      './pages/Leaderboard'
    )
);

const Onboarding = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Onboarding" */
      /* webpackPrefetch: true */
      './pages/Onboarding'
    )
);
const Manage = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "Manage" */
      /* webpackPrefetch: true */
      './pages/Manage'
    )
);
const ManageDetail = DynamicLoader(
  () =>
    import(
      /* webpackChunkName: "ManageDetail" */
      /* webpackPrefetch: true */
      './pages/ManageDetail'
    )
);
