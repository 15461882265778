import { BigNumber, ethers } from 'ethers';
import { StakingTokenBalance } from 'types';
import { crucibleAbi } from 'abi/crucibleAbi';
import { Provider } from '@ethersproject/providers';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';

export async function fetchStakingTokenBalance(
  stakingTokenAddress: string,
  rewardProgramAddress: string,
  crucibleId: string,
  account: string,
  provider: Provider
): Promise<StakingTokenBalance> {
  // Initiase contracts
  const stakingTokenContract = new ethers.Contract(
    stakingTokenAddress,
    IUniswapV2ERC20.abi,
    provider
  );
  const crucibleContract = new ethers.Contract(
    crucibleId,
    crucibleAbi,
    provider
  );
  const stakingTokenDecimals: number = await stakingTokenContract
    .decimals()
    .catch(() => 0);

  // Get crucible balances
  const balance: BigNumber = await stakingTokenContract
    .balanceOf(crucibleId)
    .catch(() => BigNumber.from(0));

  const balanceLocked: BigNumber = await crucibleContract
    .getBalanceDelegated(stakingTokenAddress, rewardProgramAddress)
    .catch(() => BigNumber.from(0));

  const balanceUnlocked: BigNumber = balance.sub(balanceLocked);

  // Get wallet balances
  const balanceInWallet: BigNumber = await stakingTokenContract
    .balanceOf(account)
    .catch(() => BigNumber.from(0));
  return {
    stakingTokenUnlocked: balanceUnlocked,
    stakingTokenLocked: balanceLocked,
    stakingTokenInWallet: balanceInWallet,
    stakingTokenDecimals
  };
}
