import {
  TxnDetails,
  TxnStatus,
  UseTransactions
} from 'store/transactions/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useConfig } from 'store/config';
import { useWeb3React } from 'hooks/web3';
import { useToast } from '@chakra-ui/toast';
import { transferCrucible as _transferCrucible } from 'store/transactions/actions/transferCrucible';
import { mintCrucible as _mintCrucible } from 'store/transactions/actions/mintCrucible';
import { mintCrucibleCustom as _mintCrucibleCustom } from 'store/transactions/actions/mintCrucibleCustom';
import { unlockArtwork as _unlockArtwork } from 'store/transactions/actions/unlockArtwork';
import { addSubscription as _addSubscription } from 'store/transactions/actions/addSubscription';
import { claimRewards as _claimRewards } from 'store/transactions/actions/claimRewards';
import { withdrawFromCrucible as _withdrawFromCrucible } from 'store/transactions/actions/withdrawFromCrucible';
import { transferBetweenCrucibles as _transferBetweenCrucibles } from 'store/transactions/actions/transferBetweenCrucibles';
import { depositToCrucible as _depositFromcrucible } from 'store/transactions/actions/depositToCrucible';
import { rageQuit as _rageQuit } from 'store/transactions/actions/rageQuit';
import { handleWrapping as _handleWrapping } from 'store/transactions/actions/handleWrapping';
import { deployAludel as _deployAludel } from 'store/transactions/actions/deployAludel';
import { fundAludel as _fundAludel } from 'store/transactions/actions/fundAludel';
import { registerBonusToken as _registerBonusToken } from 'store/transactions/actions/registerBonusToken';
import { fundBonusToken as _fundBonusToken } from 'store/transactions/actions/fundBonusToken';
import { transferProgramOwnership as _transferProgramOwnership } from 'store/transactions/actions/transferProgramOwnership';
import { renounceOwnership as _renounceOwnership } from 'store/transactions/actions/renounceOwnership';
import { updateVaultFactory as _updateVaultFactory } from 'store/transactions/actions/updateVaultFactory';
import { updateAludelStatus as _updateAludelStatus } from 'store/transactions/actions/updateAludelStatus';
import { rescueTokensFromRewardPool as _rescueTokensFromRewardPool } from 'store/transactions/actions/rescueTokensFromRewardPool';
import { rescueERC20 as _rescueERC20 } from 'store/transactions/actions/rescueERC20';
import { transactionsSlice } from 'store/transactions/reducer';
import TxnSucceeded from 'components/toasts/TxnSucceeded';
import { BigNumber, ContractInterface, ethers } from 'ethers';
import TxnBroadcasted from 'components/toasts/TxnBroadcasted';
import { useModal } from 'store/modals';
import { batch } from 'react-redux';
import { AsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from 'index';
import { Queries } from 'types/enum';
import { ProgramToken, RewardProgram } from 'types';
import { Logger } from 'libraries/loggly';
import { useEffect } from 'react';
import { BonusToken } from 'components/onboarding/deployment';
import { synchronizeFirebase } from 'utils/onboarding';

export const useTransactions = (): UseTransactions => {
  const dispatch = useAppDispatch();
  const web3React = useWeb3React();
  const { config } = useConfig(web3React.chainId || 1);
  const toast = useToast();
  const modal = useModal();
  const { transactions } = useAppSelector((state) => state.transactions);

  useEffect(() => {
    if (window === window.parent) {
      dispatch(transactionsSlice.actions.reloadTransactions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3React.chainId]);

  const clearSavedTransactions = () => {
    dispatch(
      transactionsSlice.actions.clearSavedTransactions(web3React.chainId || 1)
    );
  };

  const updateSavedTransaction = (updatedTx: Partial<TxnDetails>) => {
    if (updatedTx.status === TxnStatus.PendingOnChain) {
      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <TxnBroadcasted
            txHash={updatedTx.hash || ''}
            onClose={onClose}
            chainId={web3React.chainId || 1}
          />
        )
      });
    }

    if (updatedTx.status === TxnStatus.Mined) {
      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <TxnSucceeded
            txHash={updatedTx.hash || ''}
            onClose={onClose}
            chainId={web3React.chainId || 1}
          />
        )
      });
    }

    dispatch(
      // @ts-ignore
      transactionsSlice.actions.setTransactionStatus({
        ...updatedTx,
        account: web3React.account as string,
        chainId: web3React.chainId as number
      })
    );
  };

  // TODO: MN - Add invalidate queries for onboarding contract calls
  const resolveTransaction = async (
    rawAction: AsyncThunk<any, any, {}>,
    dispatchedAction: PayloadAction<any, any, {}>
  ) => {
    if (rawAction.fulfilled.match(dispatchedAction)) {
      if (
        dispatchedAction.type === 'transactions/addSubscription/fulfilled' ||
        dispatchedAction.type === 'transactions/unsubscribeLP/fulfilled'
      ) {
        batch(() => {
          queryClient.invalidateQueries([
            Queries.USER_BALANCES,
            dispatchedAction.meta.arg.web3React.chainId,
            dispatchedAction.meta.arg.web3React.account
          ]);
          queryClient.invalidateQueries([
            Queries.CRUCIBLES_LOCKS,
            dispatchedAction.meta.arg.web3React.chainId,
            dispatchedAction.meta.arg.web3React.account
          ]);
          queryClient.invalidateQueries([
            Queries.CRUCIBLE_ASSETS,
            dispatchedAction.meta.arg.crucibleAddress,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
          queryClient.invalidateQueries([
            Queries.WALLET_ASSETS,
            dispatchedAction.meta.arg.web3React.account,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
          queryClient.invalidateQueries([
            Queries.SUBSCRIPTIONS,
            dispatchedAction.meta.arg.crucibleAddress,
            dispatchedAction.meta.arg.currentRewardProgram.address
          ]);
          queryClient.invalidateQueries([Queries.STAKING_TOKEN_BALANCE]);
        });
      }

      if (dispatchedAction.type === 'crucibles/rageQuit/fulfilled') {
        batch(() => {
          queryClient.invalidateQueries([
            Queries.CRUCIBLES_LOCKS,
            dispatchedAction.meta.arg.web3React.chainId,
            dispatchedAction.meta.arg.web3React.account
          ]);
          queryClient.invalidateQueries([
            Queries.CRUCIBLE_ASSETS,
            dispatchedAction.meta.arg.crucibleAddress,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
          queryClient.invalidateQueries([
            Queries.SUBSCRIPTIONS,
            dispatchedAction.meta.arg.crucibleAddress,
            dispatchedAction.meta.arg.currentRewardProgram.address
          ]);
          queryClient.invalidateQueries([Queries.STAKING_TOKEN_BALANCE]);
        });
      }

      if (
        dispatchedAction.type === 'crucibles/depositToCrucible/fulfilled' ||
        dispatchedAction.type === 'crucibles/withdrawFromCrucible/fulfilled'
      ) {
        batch(() => {
          queryClient.invalidateQueries([
            Queries.CRUCIBLE_ASSETS,
            dispatchedAction.meta.arg.crucibleAddress,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
          queryClient.invalidateQueries([
            Queries.WALLET_ASSETS,
            dispatchedAction.meta.arg.web3React.account,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
          queryClient.invalidateQueries([
            Queries.USER_BALANCES,
            dispatchedAction.meta.arg.web3React.chainId,
            dispatchedAction.meta.arg.web3React.account
          ]);
          queryClient.invalidateQueries([Queries.STAKING_TOKEN_BALANCE]);
        });
      }

      if (
        dispatchedAction.type === 'crucibles/transferBetweenCrucibles/fulfilled'
      ) {
        batch(() => {
          queryClient.invalidateQueries([
            Queries.CRUCIBLE_ASSETS,
            dispatchedAction.meta.arg.fromCrucibleAddress,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
          queryClient.invalidateQueries([
            Queries.CRUCIBLE_ASSETS,
            dispatchedAction.meta.arg.toCrucibleAddress,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
        });
      }

      if (
        dispatchedAction.type === 'transactions/mintCrucible/fulfilled' ||
        dispatchedAction.type === 'transactions/mintCrucibleCustom/fulfilled' ||
        dispatchedAction.type === 'transactions/unlockArtwork/fulfilled'
      ) {
        queryClient.invalidateQueries([
          Queries.USER_BALANCES,
          dispatchedAction.meta.arg.web3React.chainId,
          dispatchedAction.meta.arg.web3React.account
        ]);
        queryClient.invalidateQueries([
          Queries.OWNED_CRUCIBLES,
          dispatchedAction.meta.arg.web3React.chainId,
          dispatchedAction.meta.arg.web3React.account
        ]);
        queryClient.invalidateQueries([
          Queries.WALLET_ASSETS,
          dispatchedAction.meta.arg.web3React.account,
          dispatchedAction.meta.arg.web3React.chainId
        ]);
        queryClient.invalidateQueries([Queries.STAKING_TOKEN_BALANCE]);
      }

      if (dispatchedAction.type === 'transactions/transferCrucible/fulfilled') {
        queryClient.invalidateQueries([
          Queries.OWNED_CRUCIBLES,
          dispatchedAction.meta.arg.web3React.chainId,
          dispatchedAction.meta.arg.web3React.account
        ]);
      }

      if (dispatchedAction.type === 'transactions/handleWrapping/fulfilled') {
        batch(() => {
          queryClient.invalidateQueries([
            Queries.CRUCIBLE_ASSETS,
            dispatchedAction.meta.arg.crucibleAddress,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
          queryClient.invalidateQueries([
            Queries.WALLET_ASSETS,
            dispatchedAction.meta.arg.web3React.account,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
        });
      }

      if (
        dispatchedAction.type === 'transactions/updateAludelStatus/fulfilled'
      ) {
        batch(() => {
          queryClient.invalidateQueries([
            Queries.REWARD_PROGRAM_STATE,
            dispatchedAction.meta.arg.aludelAddress,
            dispatchedAction.meta.arg.web3React.chainId
          ]);
        });
      }
    }

    // errors thrown from transaction actions will be caught here
    if (rawAction.rejected.match(dispatchedAction)) {
      // generic error handling
      console.log('Transaction Error: ', dispatchedAction);
    }
  };

  const actionArguments = {
    config,
    web3React,
    updateTx: updateSavedTransaction,
    modal,
    toast,
    logger: window === window.parent ? new Logger() : null
  };

  const transferCrucible = async (crucibleId: string, transferTo: string) => {
    const transferAction = await dispatch(
      _transferCrucible({
        ...actionArguments,
        transferTo,
        crucibleId
      })
    );
    resolveTransaction(_transferCrucible, transferAction);
  };

  const mintCrucible = async (amountLp: BigNumber) => {
    const mintAction = await dispatch(
      _mintCrucible({
        ...actionArguments,
        amountLp
      })
    );
    resolveTransaction(_mintCrucible, mintAction);
  };

  const addSubscription = async (
    stakingTokenAmount: BigNumber,
    crucibleAddress: string,
    balanceFrom: 'crucible' | 'wallet',
    currentRewardProgram: RewardProgram,
    stakingToken: ProgramToken
  ) => {
    const addSubscriptionAction = await dispatch(
      _addSubscription({
        ...actionArguments,
        stakingTokenAmount,
        crucibleAddress,
        balanceFrom,
        currentRewardProgram,
        stakingToken
      })
    );
    resolveTransaction(_addSubscription, addSubscriptionAction);
  };

  const claimRewards = async (
    amount: BigNumber,
    crucibleAddress: string,
    currentRewardProgram: RewardProgram,
    stakingTokenSymbol: string
  ) => {
    const unsubLpAction = await dispatch(
      _claimRewards({
        ...actionArguments,
        amount,
        crucibleAddress,
        currentRewardProgram,
        stakingTokenSymbol
      })
    );
    resolveTransaction(_claimRewards, unsubLpAction);
  };

  const withdrawFromCrucible = async (
    crucibleAddress: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number,
    amount: BigNumber
  ) => {
    const withdrawFromCrucibleAction = await dispatch(
      _withdrawFromCrucible({
        ...actionArguments,
        crucibleAddress,
        tokenAddress,
        tokenSymbol,
        tokenDecimals,
        amount
      })
    );
    resolveTransaction(_withdrawFromCrucible, withdrawFromCrucibleAction);
  };

  const depositToCrucible = async (
    crucibleAddress: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number,
    amount: BigNumber
  ) => {
    const depositToCrucibleAction = await dispatch(
      _depositFromcrucible({
        ...actionArguments,
        crucibleAddress,
        tokenAddress,
        tokenSymbol,
        tokenDecimals,
        amount
      })
    );
    resolveTransaction(_depositFromcrucible, depositToCrucibleAction);
  };

  const transferBetweenCrucibles = async (
    fromCrucibleAddress: string,
    toCrucibleAddress: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number,
    amount: BigNumber
  ) => {
    const transferBetweenCruciblesAction = await dispatch(
      _transferBetweenCrucibles({
        ...actionArguments,
        fromCrucibleAddress,
        toCrucibleAddress,
        tokenAddress,
        tokenSymbol,
        tokenDecimals,
        amount
      })
    );
    resolveTransaction(
      _transferBetweenCrucibles,
      transferBetweenCruciblesAction
    );
  };

  const mintCrucibleCustom = async (withFee: boolean, fee: number) => {
    const mintCrucibleCustomAction = await dispatch(
      _mintCrucibleCustom({
        ...actionArguments,
        withFee,
        fee
      })
    );
    resolveTransaction(_mintCrucibleCustom, mintCrucibleCustomAction);
  };

  const unlockArtwork = async (crucibleAddress: string, fee: number) => {
    const unlockArtworkAction = await dispatch(
      _unlockArtwork({
        ...actionArguments,
        crucibleAddress,
        fee
      })
    );
    resolveTransaction(_unlockArtwork, unlockArtworkAction);
  };

  const rageQuit = async (
    crucibleAddress: string,
    currentRewardProgram: RewardProgram,
    stakingTokenAddress: string
  ) => {
    const rageQuitAction = await dispatch(
      _rageQuit({
        ...actionArguments,
        crucibleAddress,
        currentRewardProgram,
        stakingTokenAddress
      })
    );
    resolveTransaction(_rageQuit, rageQuitAction);
  };

  const deployAludel = async (
    name: string,
    stakingTokenUrl: string,
    stakingToken: string,
    rewardToken: string,
    bonusTokens: BonusToken[],
    crucibleFactoryAddress: string,
    startTime: number,
    scalingFloor: number,
    scalingCeiling: number,
    scalingDays: number,
    templateId: string,
    templateName: string
  ): Promise<any> => {
    const deployAludelAction = await dispatch(
      _deployAludel({
        ...actionArguments,
        name,
        stakingTokenUrl,
        stakingToken,
        rewardToken,
        bonusTokens,
        crucibleFactoryAddress,
        startTime,
        scalingFloor,
        scalingCeiling,
        scalingDays,
        templateId,
        templateName
      })
    );
    resolveTransaction(_deployAludel, deployAludelAction);
    return deployAludelAction.payload; // return deployed program
  };

  const handleWrapping = async (
    crucibleAddress: string,
    wrapperToken: { wrapperTokenAddress: string; wrapperTokenSymbol: string },
    underlyingToken: {
      underlyingTokenAddress: string;
      underlyingTokenSymbol: string;
    },
    toAddress: string,
    amount: BigNumber,
    isWrap: boolean,
    autoDeposit?: boolean
  ) => {
    const handleWrappingAction = await dispatch(
      _handleWrapping({
        ...actionArguments,
        crucibleAddress,
        wrapperToken,
        underlyingToken,
        toAddress,
        amount,
        isWrap,
        autoDeposit
      })
    );
    resolveTransaction(_handleWrapping, handleWrappingAction);
  };

  const fundAludel = async (
    aludelAddress: string,
    rewardTokenAmount: number,
    unlockDays: number,
    rewardProgramDataMethod: string,
    rewardProgramFundMethod: string,
    aludelAbi: ethers.ContractInterface
  ) => {
    const fundAludelAction = await dispatch(
      _fundAludel({
        ...actionArguments,
        aludelAddress,
        rewardTokenAmount,
        unlockDays,
        rewardProgramDataMethod,
        rewardProgramFundMethod,
        aludelAbi
      })
    );
    await synchronizeFirebase(
      actionArguments.web3React.chainId || 1,
      aludelAddress,
      'stats',
      fundAludelAction
    );
    resolveTransaction(_fundAludel, fundAludelAction);
  };

  const registerBonusToken = async (
    aludelAddress: string,
    bonusToken: BonusToken,
    rewardProgramDataMethod: string,
    aludelAbi: ethers.ContractInterface
  ) => {
    const registerBonusTokenAction = await dispatch(
      _registerBonusToken({
        ...actionArguments,
        aludelAddress,
        bonusToken,
        rewardProgramDataMethod,
        aludelAbi
      })
    );

    // Bypass sync check when token is registered, as we don't want to
    // trigger a rejected state otherwise we cannot pass checks for funding.
    if (registerBonusTokenAction.payload !== 'registered') {
      await synchronizeFirebase(
        actionArguments.web3React.chainId || 1,
        aludelAddress,
        'programs',
        registerBonusTokenAction
      );
    }
    resolveTransaction(_registerBonusToken, registerBonusTokenAction);
    return registerBonusTokenAction.payload;
  };

  const fundBonusToken = async (
    aludelAddress: string,
    bonusToken: BonusToken,
    rewardProgramDataMethod: string,
    aludelAbi: ethers.ContractInterface
  ) => {
    const fundBonusTokenAction = await dispatch(
      _fundBonusToken({
        ...actionArguments,
        aludelAddress,
        bonusToken,
        rewardProgramDataMethod,
        aludelAbi
      })
    );
    await synchronizeFirebase(
      actionArguments.web3React.chainId || 1,
      aludelAddress,
      'stats',
      fundBonusTokenAction
    );
    resolveTransaction(_fundBonusToken, fundBonusTokenAction);
  };

  const transferProgramOwnership = async (
    programAddress: string,
    newOwnerAddress: string,
    aludelAddress: string,
    aludelAbi: ethers.ContractInterface
  ) => {
    const transferProgramOwnershipAction = await dispatch(
      _transferProgramOwnership({
        ...actionArguments,
        programAddress,
        newOwnerAddress,
        aludelAbi
      })
    );
    await synchronizeFirebase(
      actionArguments.web3React.chainId || 1,
      aludelAddress,
      'programs',
      transferProgramOwnershipAction
    );
    resolveTransaction(
      _transferProgramOwnership,
      transferProgramOwnershipAction
    );
  };

  const updateVaultFactory = async (
    programAddress: string,
    vaultFactoryAddress: string,
    action: string,
    aludelAbi: ethers.ContractInterface
  ) => {
    const updateVaultFactoryAction = await dispatch(
      _updateVaultFactory({
        ...actionArguments,
        programAddress,
        vaultFactoryAddress,
        action,
        aludelAbi
      })
    );
    await synchronizeFirebase(
      actionArguments.web3React.chainId || 1,
      programAddress,
      'programs',
      updateVaultFactoryAction
    );
    resolveTransaction(_updateVaultFactory, updateVaultFactoryAction);
  };

  const renounceOwnership = async (
    contractAddress: string,
    aludelAddress: string,
    aludelAbi: ethers.ContractInterface
  ) => {
    const renounceOwnershipAction = await dispatch(
      _renounceOwnership({
        ...actionArguments,
        contractAddress,
        aludelAbi
      })
    );
    await synchronizeFirebase(
      actionArguments.web3React.chainId || 1,
      aludelAddress,
      'programs',
      renounceOwnershipAction
    );
    resolveTransaction(_renounceOwnership, renounceOwnershipAction);
  };

  const updateAludelStatus = async (
    aludelAddress: string,
    powerSwitchAddress: string,
    action: 'powerOn' | 'powerOff' | 'emergencyShutdown',
    powerSwitchAbi: ethers.ContractInterface
  ) => {
    const updateAludelStatusAction = await dispatch(
      _updateAludelStatus({
        ...actionArguments,
        aludelAddress,
        powerSwitchAddress,
        action,
        powerSwitchAbi
      })
    );
    await synchronizeFirebase(
      actionArguments.web3React.chainId || 1,
      aludelAddress,
      'programs',
      updateAludelStatusAction
    );
    resolveTransaction(_updateAludelStatus, updateAludelStatusAction);
  };

  const rescueTokensFromRewardPool = async (
    aludelAddress: string,
    aludelAbi: ContractInterface,
    tokenAddress: string,
    tokenSymbol: string,
    recipient: string,
    amount: number,
    decimals: number
  ) => {
    const rescueTokensFromRewardPoolAction = await dispatch(
      _rescueTokensFromRewardPool({
        ...actionArguments,
        aludelAddress,
        aludelAbi,
        tokenAddress,
        tokenSymbol,
        recipient,
        amount,
        decimals
      })
    );
    await synchronizeFirebase(
      actionArguments.web3React.chainId || 1,
      aludelAddress,
      'stats'
    );
    resolveTransaction(
      _rescueTokensFromRewardPool,
      rescueTokensFromRewardPoolAction
    );
  };

  const rescueERC20 = async (
    aludelAddress: string,
    rewardPoolAddress: string,
    tokens: string[],
    recipient: string,
    rewardPoolAbi: ethers.ContractInterface
  ) => {
    const rescueERC20 = await dispatch(
      _rescueERC20({
        ...actionArguments,
        aludelAddress,
        rewardPoolAddress,
        tokens,
        recipient,
        rewardPoolAbi
      })
    );
    await synchronizeFirebase(
      actionArguments.web3React.chainId || 1,
      aludelAddress,
      'stats'
    );
    resolveTransaction(_rescueERC20, rescueERC20);
  };

  const txnsByAccountAndNetwork = transactions.filter(
    (txn: TxnDetails) =>
      txn.account === web3React.account && txn.chainId === web3React.chainId
  );

  return {
    transactions: txnsByAccountAndNetwork,
    clearSavedTransactions,
    transferCrucible,
    mintCrucible,
    mintCrucibleCustom,
    unlockArtwork,
    addSubscription,
    claimRewards,
    withdrawFromCrucible,
    depositToCrucible,
    transferBetweenCrucibles,
    rageQuit,
    handleWrapping,
    deployAludel,
    fundAludel,
    registerBonusToken,
    fundBonusToken,
    transferProgramOwnership,
    renounceOwnership,
    updateVaultFactory,
    updateAludelStatus,
    rescueTokensFromRewardPool,
    rescueERC20
  };
};
