const containsAny = (str: string, substrings: string[]): string | null => {
  for (let i = 0; i !== substrings.length; i++) {
    const substring = substrings[i];
    if (str.indexOf(substring) !== -1) {
      return substring;
    }
  }
  return null;
};

// a mapping of reasons why any transaction might fail and the human-readable version of that error
const commonErrors: { [key: string]: string } = {
  // user messed up manual gas values
  'transaction underpriced':
    'Transaction under-priced. Please check the supplied gas amount and try again.',
  'intrinsic gas too low':
    'Transaction under-priced. Please check the supplied gas amount and try again.',

  // user denied signature via metamask
  'User denied transaction signature': 'Denied transaction signature',
  'User denied message signature': 'Denied transaction signature',

  // transfer specific - recieving address may have been a crucible address
  'transfer to non ERC721Receiver implementer':
    'Invalid transfer recipient address',

  // Subscribing/unsubscribing before previous sub/unsub is complete
  'ERC1271: Invalid signature':
    'You have an ongoing transaction for this Crucible. Please wait until it has finished before trying again.',

  // balance errors
  'insufficient balance': 'Insufficient balance',

  // Flashbots errors
  'missing response': 'Flashbots relay failed to respond. Please try again',
  'bad response': 'Flashbots relay provided invalid response. Please try again',
  'invalid chain id for signer':
    'Invalid network - Flashbots supported on Mainnet or Görli',
  'insufficient funds for gas': 'Insufficient funds for transaction',

  // Phishing errors
  website: 'excecution reverted',
  'www.': 'excecution reverted',

  // Ledger
  LEDGER_WRONG_APP:
    'Please ensure your Ledger is unlocked and Blind Signing is enabled'
};

const parseTransactionError = (error: any) => {
  const errorMsg =
    error?.error?.message || error?.message || 'Transaction Failed';

  console.log('TRANSACTION ERROR', errorMsg);

  const commonError = containsAny(errorMsg, Object.keys(commonErrors));

  if (commonError) {
    return commonErrors[commonError];
  }
  return errorMsg.slice(0, 100);
};

export default parseTransactionError;
