export const aludelFactoryAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address'
      },
      {
        internalType: 'uint16',
        name: 'bps',
        type: 'uint16'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    inputs: [],
    name: 'AludelAlreadyRegistered',
    type: 'error'
  },
  {
    inputs: [],
    name: 'AludelNotRegistered',
    type: 'error'
  },
  {
    inputs: [],
    name: 'InvalidTemplate',
    type: 'error'
  },
  {
    inputs: [],
    name: 'ProgramAlreadyRegistered',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TemplateAlreadyAdded',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TemplateDisabled',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TemplateNotRegistered',
    type: 'error'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'program',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'url',
        type: 'string'
      }
    ],
    name: 'ProgramAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'program',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'url',
        type: 'string'
      }
    ],
    name: 'ProgramChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'template',
        type: 'address'
      }
    ],
    name: 'TemplateAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'template',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'disabled',
        type: 'bool'
      }
    ],
    name: 'TemplateUpdated',
    type: 'event'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'program',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'template',
        type: 'address'
      },
      {
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        internalType: 'string',
        name: 'stakingTokenUrl',
        type: 'string'
      },
      {
        internalType: 'uint64',
        name: 'startTime',
        type: 'uint64'
      }
    ],
    name: 'addProgram',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'template',
        type: 'address'
      },
      {
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        internalType: 'bool',
        name: 'disabled',
        type: 'bool'
      }
    ],
    name: 'addTemplate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'program',
        type: 'address'
      }
    ],
    name: 'delistProgram',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'feeBps',
    outputs: [
      {
        internalType: 'uint16',
        name: '',
        type: 'uint16'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'feeRecipient',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'template',
        type: 'address'
      }
    ],
    name: 'getTemplate',
    outputs: [
      {
        components: [
          {
            internalType: 'bool',
            name: 'listed',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'disabled',
            type: 'bool'
          },
          {
            internalType: 'string',
            name: 'name',
            type: 'string'
          }
        ],
        internalType: 'struct AludelFactory.TemplateData',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'who',
        type: 'address'
      }
    ],
    name: 'isAludel',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'template',
        type: 'address'
      },
      {
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        internalType: 'string',
        name: 'stakingTokenUrl',
        type: 'string'
      },
      {
        internalType: 'uint64',
        name: 'startTime',
        type: 'uint64'
      },
      {
        internalType: 'address',
        name: 'vaultFactory',
        type: 'address'
      },
      {
        internalType: 'address[]',
        name: 'bonusTokens',
        type: 'address[]'
      },
      {
        internalType: 'address',
        name: 'ownerAddress',
        type: 'address'
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes'
      }
    ],
    name: 'launch',
    outputs: [
      {
        internalType: 'address',
        name: 'aludel',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'program',
        type: 'address'
      }
    ],
    name: 'programs',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'template',
            type: 'address'
          },
          {
            internalType: 'uint64',
            name: 'startTime',
            type: 'uint64'
          },
          {
            internalType: 'string',
            name: 'name',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'stakingTokenUrl',
            type: 'string'
          }
        ],
        internalType: 'struct AludelFactory.ProgramData',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'bps',
        type: 'uint16'
      }
    ],
    name: 'setFeeBps',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newRecipient',
        type: 'address'
      }
    ],
    name: 'setFeeRecipient',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'program',
        type: 'address'
      },
      {
        internalType: 'string',
        name: 'newName',
        type: 'string'
      },
      {
        internalType: 'string',
        name: 'newUrl',
        type: 'string'
      }
    ],
    name: 'updateProgram',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'template',
        type: 'address'
      },
      {
        internalType: 'bool',
        name: 'disabled',
        type: 'bool'
      }
    ],
    name: 'updateTemplate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
];
