import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SLICE_NAME } from 'store/enum';
import { ERC20Token } from 'types';
import { queryClient } from 'index';
import { Queries } from 'types/enum';
import { useEffect } from 'react';
import { useWeb3React } from 'hooks/web3';

interface SelectedAssetsState {
  selectedWalletAsset: ERC20Token | null;
  selectedCrucibleAssets: { [crucibleAddress: string]: ERC20Token | null };
}

const initialState: SelectedAssetsState = {
  selectedWalletAsset: null,
  selectedCrucibleAssets: {}
};

export const selectedAssetsSlice = createSlice({
  name: SLICE_NAME.SELECTED_ASSETS,
  initialState,
  reducers: {
    setSelectedWalletAsset: (state, action) => {
      state.selectedWalletAsset = action.payload.asset;
    },
    setSelectedCrucibleAsset: (state, action) => {
      state.selectedCrucibleAssets[action.payload.crucibleAddress] =
        action.payload.asset;
    }
  }
});

// crucibleAddress is optional because we don't want to force callers
// that only want wallet asset data to have to pass a crucibleAddress
export const useSelectedAssets = (crucibleAddress: string = '') => {
  const { chainId = 1, account } = useWeb3React();
  const dispatch = useAppDispatch();

  const selectedWalletAsset = useAppSelector(
    (state) => state.selectedAssets.selectedWalletAsset
  );

  const selectedCrucibleAsset = useAppSelector(
    (state) => state.selectedAssets.selectedCrucibleAssets[crucibleAddress]
  );

  const setSelectedWalletAsset = (asset: ERC20Token | null) =>
    dispatch(selectedAssetsSlice.actions.setSelectedWalletAsset({ asset }));

  const setSelectedCrucibleAsset = (asset: ERC20Token | null) =>
    dispatch(
      selectedAssetsSlice.actions.setSelectedCrucibleAsset({
        crucibleAddress,
        asset
      })
    );

  const crucibleAssets = queryClient.getQueryData([
    Queries.CRUCIBLE_ASSETS,
    crucibleAddress,
    chainId
  ]) as ERC20Token[];

  const walletAssets = queryClient.getQueryData([
    Queries.WALLET_ASSETS,
    account,
    chainId
  ]) as ERC20Token[];

  const updateSelectedAsset = (
    assets: ERC20Token[],
    selectedAsset: ERC20Token | null,
    setSelectedAsset: (asset: ERC20Token | null) => {
      payload: any;
      type: string;
    }
  ) => {
    if (assets) {
      if (assets.length > 0) {
        setSelectedAsset(
          assets.find((asset) => asset.address === selectedAsset?.address) ||
            assets[0]
        );
      } else {
        setSelectedAsset(null);
      }
    }
  };

  useEffect(() => {
    updateSelectedAsset(
      crucibleAssets,
      selectedCrucibleAsset,
      setSelectedCrucibleAsset
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crucibleAssets, selectedCrucibleAsset]);

  useEffect(() => {
    updateSelectedAsset(
      walletAssets,
      selectedWalletAsset,
      setSelectedWalletAsset
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAssets, selectedWalletAsset]);

  return {
    selectedWalletAsset,
    selectedCrucibleAsset,
    setSelectedWalletAsset,
    setSelectedCrucibleAsset
  };
};

export default selectedAssetsSlice.reducer;
