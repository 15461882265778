import { FC } from 'react';
import {
  Box,
  Circle,
  HStack,
  Link as ChakraLink,
  StackDivider,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react';
import { useModal } from 'store/modals';
import { Spinner } from '@chakra-ui/spinner';
import { Tooltip } from '@chakra-ui/tooltip';
import { useWeb3React } from 'hooks/web3';
import { ModalType } from 'components/modals/types';
import { TxnDetails, TxnStatus } from 'store/transactions/types';
import { useTransactions } from 'store/transactions/useTransactions';
import { EtherscanLinkType, getEtherscanLink } from 'utils/getEtherscanLink';
import { BiLinkExternal, HiCheck, RiErrorWarningLine } from 'react-icons/all';
import parseTransactionError from 'utils/parseTransactionError';

const convertTxnStatusToIcon = (status?: TxnStatus) => {
  switch (status) {
    case TxnStatus.Failed:
      return <RiErrorWarningLine size='23px' color='rgba(229, 62, 62)' />;
    case TxnStatus.Mined:
      return (
        <Circle size='22px' bg='#3C475F'>
          <HiCheck size='15px' color='#53DD63' />
        </Circle>
      );
    case TxnStatus.PendingOnChain:
      return (
        <Spinner
          size='md'
          thickness='1px'
          color='gray.400'
          height='18px'
          width='18px'
        />
      );

    // these txns should be filtered out
    case TxnStatus.Initiated:
    case TxnStatus.PendingApproval:
    case TxnStatus.Ready:
      return '';
  }
};

const TransactionList: FC = () => {
  const { transactions } = useTransactions();
  const { openModal } = useModal();
  const { chainId } = useWeb3React();

  const txnsToDisplay = transactions.filter(
    (txn) =>
      txn.status === TxnStatus.Failed ||
      txn.status === TxnStatus.PendingOnChain ||
      txn.status === TxnStatus.Mined
  );

  const getColorScheme = (txn: TxnDetails) => {
    switch (txn.status) {
      case TxnStatus.Mined:
        return 'green';
      case TxnStatus.PendingApproval:
        return 'gray';
      case TxnStatus.PendingOnChain:
        return 'gray';
      case TxnStatus.Failed:
        return 'red';
      default:
        return 'undefined';
    }
  };

  const getColor = (txn: TxnDetails) => {
    switch (txn.status) {
      case TxnStatus.Mined:
        return 'green.500';
      case TxnStatus.PendingApproval:
        return 'gray.400';
      case TxnStatus.PendingOnChain:
        return 'gray.400';
      case TxnStatus.Failed:
        return 'red.500';
      default:
        return 'undefined';
    }
  };

  return (
    <Box>
      {txnsToDisplay.length > 0 ? (
        <VStack
          px={4}
          py={2}
          spacing={1}
          bg='gray.700'
          borderRadius='xl'
          align='stretch'
          divider={<StackDivider borderColor='gray.600' />}
        >
          {txnsToDisplay.reverse().map((txn) => {
            const shortError = parseTransactionError(txn.error);

            return (
              <Box py={3} key={txn.id}>
                <HStack justifyContent='space-between' mb={2}>
                  <Tag
                    size='sm'
                    color={getColor(txn)}
                    colorScheme={getColorScheme(txn)}
                    width='fit-content'
                    variant='subtle'
                    fontWeight='bold'
                    py={1}
                    px={3}
                    borderRadius='full'
                  >
                    {txn.type}
                  </Tag>
                  {txn.status === TxnStatus.Failed ? (
                    <Tooltip
                      hasArrow
                      placement='top'
                      cursor='pointer'
                      label={shortError}
                      onClick={() =>
                        // TODO: Figure out how to manage modal z-index so the error modal is displayed on top
                        openModal(ModalType.txnError, {
                          error: txn.error
                        })
                      }
                    >
                      <div>{convertTxnStatusToIcon(txn.status)}</div>
                    </Tooltip>
                  ) : (
                    convertTxnStatusToIcon(txn.status)
                  )}
                </HStack>
                <HStack>
                  <Tooltip hasArrow label={txn.description}>
                    <Text
                      isTruncated
                      fontSize='14px'
                      maxW='220px'
                      placement='top'
                    >
                      {txn.description}
                    </Text>
                  </Tooltip>
                  {txn.hash && (
                    <ChakraLink
                      isExternal
                      href={getEtherscanLink(
                        chainId,
                        txn.hash as string,
                        EtherscanLinkType.TRANSACTION
                      )}
                    >
                      <BiLinkExternal />
                    </ChakraLink>
                  )}
                </HStack>
              </Box>
            );
          })}
        </VStack>
      ) : (
        <Text color='gray.400'>No Transactions</Text>
      )}
    </Box>
  );
};

export default TransactionList;
