import { BonusToken } from 'components/onboarding/deployment';
import { BigNumber, ethers } from 'ethers';
import { ProgramToken, RewardProgram } from 'types';

export enum TxnType {
  mint = 'MINT - PLATINUM',
  mintBasic = 'MINT - BASIC',
  mintPro = 'MINT - PRO',
  unlockArtwork = 'UNLOCK ARTWORK',
  addSubscription = 'ADD SUBSCRIPTION',
  transfer = 'TRANSFER',
  unsubscribe = 'UNSUBSCRIBE',
  withdraw = 'WITHDRAW',
  depositToCrucible = 'DEPOSIT',
  withdrawFromCrucible = 'WITHDRAW',
  transferBetweenCrucibles = 'TRANSFER BETWEEN CRUCIBLES',
  rageQuit = 'FORCE EXIT',
  handleWrapping = 'WRAP/UNWRAP',
  deployAludel = 'DEPLOY ALUDEL',
  fundAludel = 'FUND ALUDEL',
  approveErc20 = 'APPROVE ERC20',
  registerBonusToken = 'REGISTER BONUS TOKEN',
  fundBonusToken = 'FUND BONUS TOKEN',
  transferProgramOwnership = 'TRANSFER PROGRAM OWNERSHIP',
  renounceOwnership = 'RENOUNCE OWNERSHIP',
  updateVaultFactory = 'UPDATE VAULT FACTORY',
  updateAludel = 'UPDATE_ALUDEL',
  rescueFromRewardPool = 'RESCUE_FROM_POOL',
  rescueERC20 = 'RESCUE_ERC20'
}

export enum TxnStatus {
  Ready = 'READY',
  Initiated = 'INITIATED',
  PendingApproval = 'PENDING_APPROVAL',
  PendingOnChain = 'PENDING_ON_CHAIN',
  Mined = 'MINED',
  Failed = 'FAILED'
}

export interface TxnDetails {
  id: string;
  type: TxnType;
  status?: TxnStatus;
  description?: string;
  hash?: string;
  chainId?: number;
  account?: string;
  error?: string;
  crucibleAddress?: string;
}

export type TransactionList = TxnDetails[];

export type TxnState = {
  transactions: TransactionList;
};

export type TransferCrucible = {
  (crucibleId: string, transferTo: string): Promise<void>;
};

export type MintCrucible = {
  (amountLp: BigNumber): Promise<void>;
};

export type MintCrucibleCustom = {
  (withFee: boolean, fee: number): Promise<void>;
};

export type UnlockArtwork = {
  (crucibleAddress: string, fee: number): Promise<void>;
};

export type AddSubscription = {
  (
    amount: BigNumber,
    crucibleAddress: string,
    balanceFrom: 'crucible' | 'wallet',
    currentRewardProgram: RewardProgram,
    stakingToken: ProgramToken
  ): Promise<void>;
};

export type ClaimRewards = {
  (
    amount: BigNumber,
    crucibleAddress: string,
    currentRewardProgram: RewardProgram,
    stakingTokenSymbol: string,
    stakingTokenDecimals: number
  ): Promise<void>;
};

export type Withdraw = {
  (amount: BigNumber, crucibleAddress: string): Promise<void>;
};

export type WithdrawFromCrucible = {
  (
    crucibleAddress: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number,
    amount: BigNumber
  ): Promise<void>;
};

export type DepositToCrucible = {
  (
    crucibleAddress: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number,
    amount: BigNumber
  ): Promise<void>;
};

export type TransferBetweenCrucibles = {
  (
    fromCrucibleAddress: string,
    toCrucibleAddress: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number,
    amount: BigNumber
  ): Promise<void>;
};

export type RageQuit = {
  (
    crucibileAddress: string,
    currentRewardProgram: RewardProgram,
    stakingTokenAddress: string
  ): Promise<void>;
};

export type DeployAludel = {
  (
    name: string,
    stakingTokenUrl: string,
    stakingToken: string,
    rewardToken: string,
    bonusTokens: BonusToken[],
    crucibleFactoryAddress: string,
    startTime: number,
    scalingFloor: number,
    scalingCeiling: number,
    scalingDays: number,
    templateId: string,
    templateName: string
  ): Promise<{
    aludelAddress: string;
    aludelDataMethod: string;
    aludelFundMethod: string;
    aludelAbi: ethers.ContractInterface;
  }>;
};

export type HandleWrapping = {
  (
    crucibleAddress: string,
    wrapperToken: { wrapperTokenAddress: string; wrapperTokenSymbol: string },
    underlyingToken: {
      underlyingTokenAddress: string;
      underlyingTokenSymbol: string;
    },
    toAddress: string,
    amount: BigNumber,
    isWrap: boolean,
    autoDeposit?: boolean
  ): Promise<void>;
};

export type FundAludel = {
  (
    aludelAddress: string,
    rewardTokenAmount: number,
    unlockDays: number,
    rewardProgramDataMethod: string,
    rewardProgramFundMethod: string,
    aludelAbi: ethers.ContractInterface
  ): Promise<void>;
};

export type RegisterBonusToken = {
  (
    aludelAddress: string,
    bonusToken: ProgramToken & { amount: number },
    rewardProgramDataMethod: string,
    aludelAbi: ethers.ContractInterface
  ): Promise<string>;
};

export type FundBonusToken = {
  (
    aludelAddress: string,
    bonusToken: ProgramToken & { amount: number },
    rewardProgramDataMethod: string,
    aludelAbi: ethers.ContractInterface
  ): Promise<void>;
};

export type TransferProgramOwnership = {
  (
    contractAddress: string,
    newOwnerAddress: string,
    aludelAddress: string,
    aludelAbi: ethers.ContractInterface
  ): Promise<void>;
};

export type RenounceOwnership = {
  (
    contractAddress: string,
    aludelAddress: string,
    aludelAbi: ethers.ContractInterface
  ): Promise<void>;
};

export type UpdateVaultFactory = {
  (
    programAddress: string,
    vaultFactoryAddress: string,
    action: string,
    aludelAbi: ethers.ContractInterface
  ): Promise<void>;
};

export type UpdateAludelStatus = {
  (
    aludelAddress: string,
    powerSwitchAddress: string,
    action: 'powerOn' | 'powerOff' | 'emergencyShutdown',
    powerSwitchAbi: ethers.ContractInterface
  ): Promise<void>;
};

export type RescueTokensFromRewardPool = {
  (
    aludelAddress: string,
    aludelAbi: ethers.ContractInterface,
    tokenAddress: string,
    tokenSymbol: string,
    recipient: string,
    amount: number,
    decimals: number
  ): Promise<void>;
};

export type RescueERC20 = {
  (
    aludelAddress: string,
    rewardPoolAddress: string,
    tokens: string[],
    recipient: string,
    rewardPoolAbi: ethers.ContractInterface
  ): Promise<void>;
};

export interface UseTransactions {
  transactions: TransactionList;
  clearSavedTransactions: () => void;
  transferCrucible: TransferCrucible;
  mintCrucible: MintCrucible;
  mintCrucibleCustom: MintCrucibleCustom;
  unlockArtwork: UnlockArtwork;
  addSubscription: AddSubscription;
  claimRewards: ClaimRewards;
  withdrawFromCrucible: WithdrawFromCrucible;
  depositToCrucible: DepositToCrucible;
  transferBetweenCrucibles: TransferBetweenCrucibles;
  rageQuit: RageQuit;
  handleWrapping: HandleWrapping;
  deployAludel: DeployAludel;
  fundAludel: FundAludel;
  registerBonusToken: RegisterBonusToken;
  fundBonusToken: FundBonusToken;
  transferProgramOwnership: TransferProgramOwnership;
  renounceOwnership: RenounceOwnership;
  updateVaultFactory: UpdateVaultFactory;
  updateAludelStatus: UpdateAludelStatus;
  rescueTokensFromRewardPool: RescueTokensFromRewardPool;
  rescueERC20: RescueERC20;
}
