import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SLICE_NAME } from 'store/enum';
import { Crucible } from 'types';
import { useEffect } from 'react';
import { queryClient } from 'index';
import { Queries } from 'types/enum';
import { useWeb3React } from 'hooks/web3';
import { useHistory, useLocation, useParams } from 'react-router';

interface SelectedCrucibleState {
  selectedCrucible: Crucible | null;
}

const initialState: SelectedCrucibleState = {
  selectedCrucible: null
};

export const selectedCrucibleSlice = createSlice({
  name: SLICE_NAME.SELECTED_CRUCIBLE,
  initialState,
  reducers: {
    setSelectedCrucible: (state, action) => {
      state.selectedCrucible = action.payload;
    }
  }
});

export const useSelectedCrucible = () => {
  const { crucibleId } = useParams<{ crucibleId: string }>();
  const { chainId = 1, account } = useWeb3React();
  const { pathname } = useLocation();
  const history = useHistory();

  const isCruciblesRoute =
    pathname.match(/^\/crucible\/(\S+)$/) ||
    pathname.match(/^\/leaderboard\/(\S+)$/);

  const dispatch = useAppDispatch();
  const selectedCrucible = useAppSelector(
    (state) => state.selectedCrucible.selectedCrucible
  );

  const setSelectedCrucible = (crucible: Crucible) =>
    dispatch(selectedCrucibleSlice.actions.setSelectedCrucible(crucible));

  const crucibles = queryClient.getQueryData<Crucible[]>([
    Queries.OWNED_CRUCIBLES,
    chainId,
    account
  ]);

  // Track changes to crucible list
  // TODO: Will this mess with explore crucibles? Maybe we need to move it out
  useEffect(() => {
    if (isCruciblesRoute && crucibles && crucibles.length > 0) {
      const selected = crucibles.find(
        (crucible) =>
          crucible.id.toLocaleLowerCase() ===
          selectedCrucible?.id.toLocaleLowerCase()
      );
      if (selected) {
        setSelectedCrucible(selected);
      }
      if (!selected) {
        setSelectedCrucible(crucibles[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crucibles]);

  // Track changes to crucible param
  useEffect(() => {
    if (isCruciblesRoute && crucibles && crucibles.length > 0 && crucibleId) {
      const selected = crucibles.find(
        (crucible) =>
          crucible.id.toLocaleLowerCase() === crucibleId.toLocaleLowerCase()
      );
      if (!selected) {
        history.push(`/crucible/${crucibles[0].id}`);
      } else {
        setSelectedCrucible(selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crucibleId]);

  return {
    setSelectedCrucible,
    selectedCrucible
  };
};

export default selectedCrucibleSlice.reducer;
