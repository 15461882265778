export const TagStyles = {
  sizes: {
    xl: {
      container: {
        minH: 10,
        minW: 10,
        fontSize: 'md',
        borderRadius: 'md',
        px: 5,
      },
    },
  },
};
