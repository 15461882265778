import { createSlice } from '@reduxjs/toolkit';
import { ContractInterface } from 'ethers';
import { useAppSelector } from 'store/hooks';
import { config, commonConfig } from './initialisers/config';

export interface Config {
  crucibleFactoryAddress: string;
  crucibleFactoryCreationBlock: number;
  universalVaultAddress?: string;
  mistTokenAddress: string;
  mintingTokenAddress: string;
  aludelAddress: string;
  aludelFactory: string;
  rewardPoolFactory: string;
  powerSwitchFactory: string;
  transmuterAddress: string;
  transmuterAddressAmpleforth?: string;
  customMinterAddress: string;
  rpcUrl: string;
  uniswapPoolUrl: string;
  crucibleSubgraphUrl: string;
  flashbotsRpcAddress: string;
  seasonalNFTAddress?: string;
  christmasTokenId?: string;
  wrappableTokens?: {
    underlyingTokenAddress: string;
    wrapperTokenAddress: string;
    underlyingTokenSymbol: string;
    wrapperTokenSymbol: string;
    supportsAutoDeposit?: boolean;
    wrapperTokenAbi: ContractInterface;
    wrapperToUnderlyingFunction?: string;
  }[];
  aaveTokens?: string[];
}

export interface ConfigOptions {
  [key: number]: Config;
}

export interface ConfigState {
  config: ConfigOptions;
  commonConfig: CommonConfig;
}

export interface CommonConfig {
  supportedChainIds: number[];
  appName: string;
  mintCrucibleDoc: string;
  lpTokensDoc: string;
  aludelRewardsDoc: string;
  portisApiKey: string;
  etherscanApiKey: string;
  polygonscanApiKey: string;
  snowtraceApiKey: string;
  getMistUrl: string;
  infuraApiKey: string;
  governanceList: string[];
  artworkWhitelist: string[];
}

const initialState: ConfigState = {
  config,
  commonConfig
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {}
});

export const useConfig = (chainId: number) => {
  const config = useAppSelector((state) => state.config.config[chainId]);
  const commonConfig = useAppSelector((state) => state.config.commonConfig);
  return { config, commonConfig };
};

export default configSlice.reducer;
