import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import IUniswapV2Pair from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import { ethers } from 'ethers';
import { Provider } from '@ethersproject/providers';
import {
  balancerBPT,
  balancerSmartPool,
  BalancerWeightedBPT,
  BalancerVault
} from 'abi/balancerAbi';
import { queryClient } from '../index';
import { simpleLPToken } from 'types';
import { Queries } from 'types/enum';
import { LpData } from 'types/firebase';

export const formatTokenSymbol = async (
  address: string,
  tokenSymbol: string,
  provider: Provider
) => {
  const isLp = tokenSymbol === 'UNI-V2' || tokenSymbol === 'SLP';
  const isBPT =
    tokenSymbol === 'BPT' ||
    tokenSymbol === 'BAL-REBASING-SMART-V1-AMPL-USDC' ||
    tokenSymbol === 'B-80TEMP-20WETH' ||
    tokenSymbol === '33WBTC-33WETH-33WAMPL' ||
    tokenSymbol === 'MTRL-ELA Liquidity Pool' ||
    tokenSymbol === 'MTRL-ETH Liquidity Pool';

  const { chainId } = await provider.getNetwork();

  const lpTokenObject =
    (queryClient.getQueryData([Queries.LP_DATA, chainId]) as LpData) || {};

  const isLpStored = lpTokenObject[address] !== undefined;

  if (isLpStored) {
    const symbolList = await lpTokenObject[address].map(
      (token: simpleLPToken) => {
        return token.symbol;
      }
    );

    let tokenSymbolEnd;

    // TODO: this is messy, build array/object of symbols
    if (tokenSymbol === 'UNI-V2') {
      tokenSymbolEnd = 'LP';
    } else if (tokenSymbol === 'SLP') {
      tokenSymbolEnd = 'SLP';
    } else if (
      tokenSymbol === 'BPT' ||
      tokenSymbol === 'BAL-REBASING-SMART-V1-AMPL-USDC' ||
      tokenSymbol === 'B-80TEMP-20WETH' ||
      tokenSymbol === '33WBTC-33WETH-33WAMPL' ||
      tokenSymbol === 'MTRL-ELA Liquidity Pool' ||
      tokenSymbol === 'MTRL-ETH Liquidity Pool'
    ) {
      tokenSymbolEnd = 'BPT';
    } else {
      tokenSymbolEnd = tokenSymbol;
    }

    return symbolList.join('-') + ' ' + tokenSymbolEnd;
  } else if (isLp) {
    try {
      const lpContract = new ethers.Contract(
        address,
        IUniswapV2Pair.abi,
        provider
      );

      const token0Address = await lpContract.token0();

      const token1Address = await lpContract.token1();

      const token0Contract = new ethers.Contract(
        token0Address,
        IUniswapV2ERC20.abi,
        provider
      );

      const token1Contract = new ethers.Contract(
        token1Address,
        IUniswapV2ERC20.abi,
        provider
      );

      const token0Symbol = await token0Contract.symbol();
      const token1Symbol = await token1Contract.symbol();

      let tokenSymbolEnd;
      if (tokenSymbol === 'SLP') {
        tokenSymbolEnd = tokenSymbol;
      } else {
        tokenSymbolEnd = 'LP';
      }

      return token0Symbol + '-' + token1Symbol + ' ' + tokenSymbolEnd;
    } catch (e) {
      console.log(e);
      return tokenSymbol;
    }
  } else if (isBPT) {
    let smartPoolContract;
    let vaultTokens = [];

    if (
      tokenSymbol === 'B-80TEMP-20WETH' ||
      tokenSymbol === 'MTRL-ELA Liquidity Pool' ||
      tokenSymbol === '33WBTC-33WETH-33WAMPL' ||
      tokenSymbol === 'MTRL-ETH Liquidity Pool'
    ) {
      const weightedPoolContract = new ethers.Contract(
        address,
        BalancerWeightedBPT,
        provider
      );

      const poolId = await weightedPoolContract.getPoolId();
      const vaultAddress = await weightedPoolContract.getVault();

      const vaultContract = new ethers.Contract(
        vaultAddress,
        BalancerVault,
        provider
      );

      vaultTokens = await vaultContract.getPoolTokens(poolId);
    } else if (tokenSymbol !== 'BPT') {
      smartPoolContract = new ethers.Contract(
        address,
        balancerSmartPool,
        provider
      );

      address = await smartPoolContract.bPool();
    }

    const lpContract = new ethers.Contract(address, balancerBPT, provider);

    const tokenAddresses: string[] =
      vaultTokens.length > 0
        ? vaultTokens.tokens
        : await lpContract.getCurrentTokens();

    const symbolList = await Promise.all(
      tokenAddresses.map(async (token) => {
        const tokenContract = new ethers.Contract(
          token,
          IUniswapV2ERC20.abi,
          provider
        );

        const tokenSymbol = await tokenContract.symbol();

        return tokenSymbol;
      })
    );

    return symbolList.join('-') + ' BPT';
  } else {
    return tokenSymbol;
  }
};
