import { FC } from 'react';
import { useModal, Modal } from 'store/modals';
import { ModalType } from 'components/modals/types';
import ConnectWalletModal from 'components/modals/ConnectWalletModal';
import ClaimRewardsModal from 'components/modals/ClaimRewardsModal';
import AddSubscriptionModal from 'components/modals/AddSubscriptionModal';
import TxConfirmedFlashbotsModal from 'components/modals/tx/TxConfirmedFlashbotsModal';
import TxPendingFlashbotsModal from 'components/modals/tx/TxPendingFlashbotsModal';
import TxErrorModal from 'components/modals/tx/TxErrorModal';
import RewardProgramInfoModal from 'components/modals/RewardProgramModal';
import SelectProgramModal from './SelectProgramModal';
import TransferModal from './TransferModal';
import MintSuccessModal from './MintSuccessModal';
import FeedbackModal from './FeedbackModal';
import StrategiesModal from './StrategiesModal';
import VoteModal from './VoteModal';
import MintModal from './MintModal';
import RageQuitModal from './RageQuitModal';
import SelectTokenModal from './SelectTokenModal';
import LeaderboardRewardsModal from './LeaderboardRewardsModal';
import RemoveVaultFactoryModal from './RemoveVaultFactoryModal';

const ModalRoot: FC = () => {
  const { openModals } = useModal();

  const modalComponent = ({ modalType, modalProps }: Modal) => {
    switch (modalType) {
      case ModalType.connectWallet:
        return <ConnectWalletModal />;
      case ModalType.claimRewards:
        return <ClaimRewardsModal {...modalProps} />;
      case ModalType.addSubscription:
        return <AddSubscriptionModal {...modalProps} />;
      case ModalType.transfer:
        return <TransferModal {...modalProps} />;
      case ModalType.flashbotsConfirmed:
        return <TxConfirmedFlashbotsModal {...modalProps} />;
      case ModalType.flashbotsPending:
        return <TxPendingFlashbotsModal {...modalProps} />;
      case ModalType.txnError:
        return <TxErrorModal {...modalProps} />;
      case ModalType.rewardProgramInfo:
        return <RewardProgramInfoModal {...modalProps} />;
      case ModalType.mintSuccess:
        return <MintSuccessModal {...modalProps} />;
      case ModalType.feedback:
        return <FeedbackModal />;
      case ModalType.strategies:
        return <StrategiesModal {...modalProps} />;
      case ModalType.vote:
        return <VoteModal {...modalProps} />;
      case ModalType.mint:
        return <MintModal {...modalProps} />;
      case ModalType.selectProgram:
        return <SelectProgramModal {...modalProps} />;
      case ModalType.rageQuit:
        return <RageQuitModal {...modalProps} />;
      case ModalType.selectToken:
        return <SelectTokenModal {...modalProps} />;
      case ModalType.leaderboardRewards:
        return <LeaderboardRewardsModal {...modalProps} />;
      case ModalType.removeVaultFactory:
        return <RemoveVaultFactoryModal {...modalProps} />;
    }
  };

  return (
    <>
      {openModals.map((modal: Modal) =>
        modalComponent({
          modalType: modal.modalType,
          modalProps: modal.modalProps
        })
      )}
    </>
  );
};

export default ModalRoot;
