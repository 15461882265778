/* eslint-disable import/no-anonymous-default-export */
import singleChoice from './singleChoice';
import approval from './approval';
import quadratic from './quadratic';
import rankedChoice from './rankedChoice';
import weighted from './weighted';

const voting: { [key: string]: typeof singleChoice } = {
  'single-choice': singleChoice,
  'approval': approval,
  'quadratic': quadratic,
  'ranked-choice': rankedChoice,
  'weighted': weighted
};

export default voting;