import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalType } from 'components/modals/types';

export type Modal = {
  modalType: ModalType | null;
  modalProps: any; // TODO: we could type these props as a generic an pass the interface in
};

interface ModalState {
  openModals: Modal[];
}

const initialState: ModalState = {
  openModals: []
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action) => {
      if (
        !state.openModals.find(
          (modal: Modal) => modal.modalType === action.payload.modalType
        )
      ) {
        const newModal: Modal = {
          modalType: action.payload.modalType,
          modalProps: action.payload.modalProps || {}
        };
        state.openModals.push(newModal);
      }
    },
    closeModal: (state, action) => {
      state.openModals.forEach((modal: Modal, idx) => {
        if (modal.modalType === action.payload.modalType) {
          state.openModals.splice(idx, 1);
        }
      });
    },
    closeAll: (state) => {
      state.openModals = []
    }
  }
});

export const useModal = () => {
  const dispatch = useAppDispatch();
  const openModals = useAppSelector((state) => state.modals.openModals);
  const isOpen = (modalType: ModalType) =>
    openModals.find((modal: Modal) => modal.modalType === modalType) !== null;

  const openModal = (modalType: ModalType, modalProps?: any) =>
    dispatch(modalsSlice.actions.openModal({ modalType, modalProps }));
  const closeModal = (modalType: ModalType) =>
    dispatch(modalsSlice.actions.closeModal({ modalType }));
    const closeAll = () => dispatch(modalsSlice.actions.closeAll())
  return { openModals, isOpen, openModal, closeModal, closeAll };
};

export default modalsSlice.reducer;
