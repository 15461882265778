import { FC, useEffect, useRef } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import { useSideDrawer } from 'store/sideDrawer';
import { useSelectedCrucible } from 'store/selectedCrucible';
import { useCrucibles } from 'hooks/query/useCrucibles';
import CrucibleCard from 'components/crucible/CrucibleCard';

const SideDrawerCrucibles: FC = () => {
  const { isOpen, closeDrawer } = useSideDrawer();
  const { data: crucibles } = useCrucibles();
  const { selectedCrucible } = useSelectedCrucible();
  const crucibleRef = useRef(selectedCrucible);

  useEffect(() => {
    if (selectedCrucible !== crucibleRef.current) {
      closeDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCrucible]);

  // TODO: If cruciblesLoading, show skeleton
  return (
    <Drawer isOpen={isOpen} placement='left' onClose={closeDrawer} size='xs'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody bg='gray.800' py={6} px={0}>
          <Text px={5} fontSize='lg' fontWeight='500'>
            Select crucible
          </Text>
          <VStack py={6} spacing={6} bg='gray.800' borderRadius='xl'>
            {crucibles &&
              crucibles.map((crucible) => {
                return (
                  <CrucibleCard
                    key={crucible.id}
                    crucible={crucible}
                    isSelected={crucible.id === selectedCrucible?.id}
                  />
                );
              })}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideDrawerCrucibles;
