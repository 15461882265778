import { ethers, Wallet } from 'ethers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { crucibleAbi } from 'abi/crucibleAbi';
import { signPermission } from 'helpers/signatures';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { PopulatedTransaction } from '@ethersproject/contracts';
import { keccak256 } from '@ethersproject/keccak256';
import { SignatureLike } from '@ethersproject/bytes';
import { ModalType } from 'components/modals/types';
import {
  FlashbotsBundleProvider,
  FlashbotsTransactionResponse
} from '@flashbots/ethers-provider-bundle';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import TxnError from 'components/toasts/TxnError';
import { convertChainIdToNetworkName } from 'utils/convertChainIdToNetworkName';
import formatNumber from 'utils/formatNumber';
import { flashbotsRpcAbi } from 'abi/flashbotsRpcAbi';

export const claimRewards = createAsyncThunk(
  THUNK_PREFIX.UNSUBSCRIBE_LP,
  async ({
    web3React,
    updateTx,
    modal,
    toast,
    logger,
    amount,
    crucibleAddress,
    currentRewardProgram,
    stakingTokenSymbol,
    stakingTokenDecimals,
    config
  }: any) => {
    const txnId = uuid();
    // TODO: Pass in decimals
    const description = `${formatNumber.token(
      amount,
      stakingTokenDecimals
    )} ${stakingTokenSymbol} from ${currentRewardProgram.name}`;
    const { library, account, chainId, provider } = web3React;
    const signer = library.getSigner();
    const chainName = convertChainIdToNetworkName(chainId);
    const { openModal, closeModal } = modal;
    const { flashbotsRpcAddress } = config;

    updateTx({
      id: txnId,
      type: TxnType.unsubscribe,
      status: TxnStatus.Initiated,
      description,
      crucibleAddress
    });

    try {
      //set up the contract instances
      const aludel = new ethers.Contract(
        currentRewardProgram.address,
        currentRewardProgram.abi,
        signer
      );

      // get the aludel staking token address
      const { stakingToken } = await aludel[
        currentRewardProgram.getRewardProgramDataMethod
      ].call();

      // create contract instance for the relevant crucible
      const crucible = new ethers.Contract(
        crucibleAddress,
        crucibleAbi,
        signer
      );

      // get the transaction nonce from cruicble contract
      const nonce = await crucible.getNonce();

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Sign Crucible permission data (1 of 2)' />
        )
      });

      let flashbotsRPCDetection = false;
      if (flashbotsRpcAddress !== '') {
        const flasbotsRPCContract = new ethers.Contract(
          flashbotsRpcAddress,
          flashbotsRpcAbi,
          signer
        );
        flashbotsRPCDetection = await flasbotsRPCContract.isFlashRPC();
      }

      let prompt_fb = false;
      if (currentRewardProgram.version === 1 && flashbotsRPCDetection) {
        prompt_fb = window.confirm(
          'We have detected that you are using the Flashbots RPC. This transaction will be sent via the Flashbots RPC.\n\n' +
            'Please do not change RPC while the transaction is pending, doing so may cause your transaction to become public and your rewards to be frontrun.\n\n' +
            'Press cancel if you do not wish to proceed.'
        );

        if (!prompt_fb) {
          toast.closeAll();
          toast({
            description: 'User cancelled request',
            status: 'error',
            duration: 4000,
            isClosable: true
          });

          updateTx({
            id: txnId,
            status: TxnStatus.Failed
          });

          return;
        }
      }

      // get user to sign unlock permissions
      const permission = await signPermission(
        'Unlock',
        crucible,
        signer,
        aludel.address,
        stakingToken,
        amount,
        nonce
      );

      if (currentRewardProgram.version === 1 && chainId === 1 && !prompt_fb) {
        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm claim transaction (2 of 2)' />
          )
        });

        let estimatedGas;

        // estimate gasLimit for contract call
        if (currentRewardProgram.version === 1) {
          estimatedGas = await aludel.estimateGas.unstakeAndClaim(
            crucible.address,
            crucible.address,
            amount,
            permission
          );
        } else {
          estimatedGas = await aludel.estimateGas.unstakeAndClaim(
            crucible.address,
            amount,
            permission
          );
        }

        let estimateGasPrice = await signer.getGasPrice();

        if (estimateGasPrice > 0) {
          estimateGasPrice = estimateGasPrice.mul(125).div(100);
        } else {
          throw Error(
            'Unable to retrieve gas price from network, please try again.'
          );
        }

        let gasCalculation =
          ethers.BigNumber.from(estimateGasPrice).mul(estimatedGas);

        //Console notices for Gas Cost - Please retain for debugging
        console.log(
          'Estimated Gas Price: ' +
            ethers.utils.formatUnits(estimateGasPrice, 'gwei') +
            ' Gwei'
        );
        console.log('Estimated Gas Required: ' + estimatedGas + ' units');
        console.log(
          'Estimated Total Cost ' +
            ethers.utils.formatEther(gasCalculation) +
            ' ETH'
        );

        const prompt = window.confirm(
          'This transaction will cost ' +
            ethers.utils.formatEther(gasCalculation) +
            ' ETH\n (' +
            estimatedGas +
            ' units - ' +
            ethers.utils.formatUnits(estimateGasPrice, 'gwei') +
            ' gwei)\n\nThere is no charge if this transaction fails.\n\nAre you sure you want to continue?'
        );

        if (!prompt) {
          toast.closeAll();
          toast({
            description: 'User cancelled request',
            status: 'error',
            duration: 4000,
            isClosable: true
          });

          updateTx({
            id: txnId,
            status: TxnStatus.Failed
          });

          return;
        }

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm claim transaction (2 of 2)' />
          )
        });

        let populatedResponse = {};
        let hash: string = '';
        let serialized;

        let nonce_user = await aludel.signer.getTransactionCount();

        if (currentRewardProgram.version === 1) {
          await aludel.populateTransaction
            .unstakeAndClaim(
              crucible.address,
              crucible.address,
              amount,
              permission,
              {
                nonce: nonce_user,
                gasLimit: estimatedGas,
                gasPrice: estimateGasPrice
              }
            )
            .then((response: PopulatedTransaction) => {
              delete response.from;
              response.chainId = chainId;
              serialized = ethers.utils.serializeTransaction(response);
              hash = keccak256(serialized);
              populatedResponse = response;
              return populatedResponse;
            });
        } else {
          await aludel.populateTransaction
            .unstakeAndClaim(crucible.address, amount, permission, {
              nonce: nonce_user,
              gasLimit: estimatedGas,
              gasPrice: estimateGasPrice
            })
            .then((response: PopulatedTransaction) => {
              delete response.from;
              response.chainId = chainId;
              serialized = ethers.utils.serializeTransaction(response);
              hash = keccak256(serialized);
              populatedResponse = response;
              return populatedResponse;
            });
        }

        let isMetaMask: boolean | undefined;

        if (signer.provider.provider.isMetaMask) {
          isMetaMask = signer.provider.provider.isMetaMask;
          signer.provider.provider.isMetaMask = false;
        }

        const getSignature_unstake = await signer.provider
          .send('eth_sign', [account.toLowerCase(), ethers.utils.hexlify(hash)])
          .then((signature: SignatureLike) => {
            const txWithSig = ethers.utils.serializeTransaction(
              populatedResponse,
              signature
            );
            return txWithSig;
          })
          .finally(() => {
            if (isMetaMask) {
              signer.provider.provider.isMetaMask = isMetaMask;
            }
          });

        // Check if user is using hardware

        let parsedTx = ethers.utils.parseTransaction(getSignature_unstake);

        if (parsedTx.from !== account) {
          if (signer.provider.provider.isMetaMask) {
            throw new Error(
              'Hardware wallet not supported with this method. Please try again using the Flashbots RPC instead.'
            );
          } else {
            throw new Error(
              'Your wallet does not support the required signing method. We recommend using MetaMask or Flashbots RPC.'
            );
          }
        }

        toast.closeAll();
        openModal(ModalType.flashbotsPending);

        //flashbots API variables
        const flashbotsAPI =
          chainId === 1
            ? '/flashbots-relay-mainnet'
            : '/flashbots-relay-goerli';

        //Flashbots Initilize
        const authSigner = Wallet.createRandom();

        // Flashbots provider requires passing in a standard provider
        const flashbotsProvider = await FlashbotsBundleProvider.create(
          provider, // a normal ethers.js provider, to perform gas estimiations and nonce lookups
          authSigner, // ethers.js signer wallet, only for signing request payloads, not transactions
          flashbotsAPI,
          chainName
        );

        const flashbotsTransactionBundle = [
          {
            signedTransaction: getSignature_unstake
          }
        ];

        const blockNumber = await provider.getBlockNumber();

        const minTimestamp = (await provider.getBlock(blockNumber)).timestamp;
        const maxTimestamp = minTimestamp + 240; // 60 * 4 min max timeout

        const signedTransactions = await flashbotsProvider.signBundle(
          flashbotsTransactionBundle
        );

        const simulation = await flashbotsProvider.simulate(
          signedTransactions,
          blockNumber + 1
        );

        if ('error' in simulation) {
          throw Error(simulation.error.message);
        }

        const data = await Promise.all(
          Array.from(Array(15).keys()).map(async (v) => {
            const response = (await flashbotsProvider.sendBundle(
              flashbotsTransactionBundle,
              blockNumber + 1 + v,
              {
                minTimestamp,
                maxTimestamp
              }
            )) as FlashbotsTransactionResponse;
            console.log(
              'Submitting Bundle to Flashbots for inclusion attempt on Block ' +
                (blockNumber + 1 + v)
            );
            return response;
          })
        );

        let successFlag = 0;

        await Promise.all(
          data.map(async (v, i) => {
            const response = await v.wait();

            // Useful for debugging block response from FlashBots
            console.log('Bundle ' + i + ' Response: ' + response);

            if (response === 0) {
              successFlag = 1;

              closeModal(ModalType.flashbotsPending);

              openModal(ModalType.flashbotsConfirmed);

              updateTx({
                id: txnId,
                status: TxnStatus.Mined
              });

              return;
            }
          })
        );

        if (successFlag === 0) {
          throw Error(
            'Failed to get Bundle included via Flashbots, please try again.'
          );
        }
      } else {
        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm claim transaction (2 of 2)' />
          )
        });

        let populatedTx;
        if (currentRewardProgram.version === 1) {
          // Prepare unstakeAndClaim transaction
          populatedTx = await aludel.populateTransaction.unstakeAndClaim(
            crucible.address,
            crucible.address,
            amount,
            permission
          );
        } else {
          // Prepare unstakeAndClaim transaction
          populatedTx = await aludel.populateTransaction.unstakeAndClaim(
            crucible.address,
            amount,
            permission
          );
        }

        // Send transaction via Signer
        const unstakeTx = await signer.sendTransaction(populatedTx);

        updateTx({
          id: txnId,
          status: TxnStatus.PendingOnChain,
          hash: unstakeTx.hash
        });

        await unstakeTx.wait(1);

        updateTx({
          id: txnId,
          status: TxnStatus.Mined,
          hash: unstakeTx.hash
        });
      }
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      closeModal(ModalType.flashbotsPending);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });

      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);

export default claimRewards;
