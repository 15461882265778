export const VOTES_QUERY = `
  query Votes($id: String!) {
    votes(first: 1000, where: { proposal: $id }) {
      id
      voter
      created
      choice
    }
  }
`;

export const SPACES_QUERY = `
  query Spaces($space_in: [String]) {
    spaces(where: { id_in: $space_in }, orderBy:"name", orderDirection:asc) {
      id
      network
      avatar
      name
    }
    proposals(where: { space_in: $space_in, state: "active" }) {
      space {
        id
      }
    }
  }
`;

export const PROPOSAL_QUERY = `
  query Proposal($id: String!) {
    proposal(id: $id) {
      id
      title
      body
      choices
      start
      end
      snapshot
      state
      author
      created
      plugins
      network
      type
      strategies {
        name
        params
      }
      space {
        id
        name
        symbol
      }
    }
  }
`;

export const PROPOSALS_QUERY = `
  query Proposals(
    $first: Int!
    $skip: Int!
    $state: String!
    $space: String
    $space_in: [String]
    $author_in: [String]
  ) {
    proposals(
      first: $first
      skip: $skip
      where: {
        space: $space
        state: $state
        space_in: $space_in
        author_in: $author_in
      }
    ) {
      id
      title
      body
      start
      end
      state
      author
      votes
      scores
      choices
      space {
        id
        name
        symbol
      }
    }
    space(id: $space) {
      id
      name
      admins
      members
      avatar
    }
  }
`;

export const PROPOSAL_VOTES_QUERY = `
  query (
    $id: String!
    $space: String
  ) {
    proposal(id: $id) {
      id
      title
      body
      choices
      start
      end
      snapshot
      state
      author
      created
      plugins
      network
      type
      ipfs
      strategies {
        name
        params
      }
      space {
        id
        name
        symbol
        network
      }
    }
    votes(first: 1000, where: { proposal: $id }) {
      id
      voter
      created
      choice
    }
    space(id: $space) {
      id
      network
      symbol
      avatar
      admins
      members
      name
    }
  }
`;

export const CRUCIBLES_STAKES_QUERY = `
  query Spaces($crucibleId: String!) {
    crucibleEntities(where: { id: $crucibleId }) {
      id
      owner
      timestamp
      index
      locks {
        id
        delegate
        token {
          symbol
          decimals
          address
        }
        balance
        stakesLength
        stakes {
          id
          amount
          timestamp
        }
        unstakesLength
        unstakes {
          id
          amount
          duration
          timestamp
        }
      }
    }
  }
`;

export const CRUCIBLES_LEADERBOARD_QUERY = `
  query CrucibleLeaderboard($blockNumber: Int!) {
    crucibleEntities(first: 1000, orderBy: blockNumber, where: { blockNumber_gt: $blockNumber }) {
      id
      owner
      timestamp
      blockNumber
      index
      rewardsLength
      factory
      locks {
        id
        delegate
        token {
          symbol
          decimals
          address
        }
        balance
        stakesLength
        stakes {
          id
          amount
          timestamp
        }
        unstakesLength
        unstakes {
          id
          amount
          duration
          timestamp
        }
      }
    }
  }
`;

export const CRUCIBLE_REWARD_QUERY = `
  query CrucibleReward($id: String!) {
    rewards(first: 1000, orderBy: id, where: { id_gt: $id }) {
      id,
      token {
        symbol
        decimals
        address
      }
      aludel,
      amount,
      lastUpdate,
      crucible {
        id
      }
    }
  }
`;

export const CRUCIBLE_INDEX = `
  query CrucibleIndex($crucibleAddress: String!) {
    crucibleEntities(where: { id: $crucibleAddress }) {
      index
    }
  }
`;

export const OWNED_CRUCIBLES = `
  query OwnedCrucibles($account: String!) {
    crucibleEntities(where: { owner: $account }) {
      id
      timestamp
      blockNumber
    }
  }
`;

export const SINGLE_CRUCIBLE = `
  query SingleCrucible($crucibleAddress: String!) {
    crucibleEntities(where: { id: $crucibleAddress }) {
      id
      owner
      timestamp
      blockNumber
    }
  }
`;

export const REWARD_PROGRAMS = `
  query rewardPrograms {
    rewardPrograms {
      id
      owner
      stakingTokenUrl
      template {
        id
        name
        disabled
      }
      name
      startTime,
      vaultFactories {
        id
      }
    }
  }
`;

export const ALUDEL_TEMPLATES = `
  query templates {
    templates {
      id
      name
      disabled
      }
    }
`;
