import { Circle, Image } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

// assets
import homeImg from 'img/background-art/centralPiece.png';

const BackgroundAssets = () => {
  const { pathname } = useLocation();

  // Route Checks
  const isCrucibleNFTRoute = pathname.match(/^\/nft-scene\/(\S+)$/);

  if (isCrucibleNFTRoute) return null;

  return (
    <>
      <Circle
        size='lg'
        bgGradient='linear(to-t, purple.300, cyan.900 )'
        position='absolute'
        left='-400px'
        top='320px'
        zIndex={1}
      />
      <Circle
        display='block'
        size='lg'
        bgGradient={'linear(20deg, gray.900 40%, #2D3240 80%)'}
        position='absolute'
        right={pathname === '/' ? '-200px' : '-250px'}
        top={['30px', null, '60px']}
        zIndex={1}
      >
        {pathname === '/' && (
          <Image
            display={['none', 'none', 'none', 'block']}
            src={homeImg}
            height='400px'
            htmlHeight='400px'
            mx='auto'
            position='relative'
            top='60px'
          />
        )}
      </Circle>
    </>
  );
};

export default BackgroundAssets;
