import { customMinterAbi } from 'abi/customMinterAbi';
import { ethers } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { useCallback, useEffect, useState } from 'react';
import { useConfig } from 'store/config';
import { useConnection } from 'store/connection';
import { useWeb3React } from './web3';

export const useCustomMinterFee = () => {
  const { tried } = useConnection();
  const { chainId = 1, provider } = useWeb3React();
  const { config } = useConfig(chainId);
  const [fee, setFee] = useState(0);

  const handleSetFee = useCallback(async () => {
    const customMinterAddress = config.customMinterAddress;

    const customMinterContract = new ethers.Contract(
      customMinterAddress,
      customMinterAbi,
      provider
    );

    const fee = await customMinterContract.getMintFee();
    const feeString = formatEther(fee);
    const feeNumber = Number(feeString);
    setFee(feeNumber);
  }, [config, provider]);

  useEffect(() => {
    if (tried) {
      handleSetFee();
    }
  }, [tried, handleSetFee]);

  return { fee };
};
