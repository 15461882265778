import { FC, useEffect, useMemo, useState } from 'react';
import { Button } from '@chakra-ui/button';
import { HStack, Box, Flex, Text, Link } from '@chakra-ui/layout';
import { RiExternalLinkLine } from 'react-icons/all';
import { useTransactions } from 'store/transactions/useTransactions';
import { useConfig } from 'store/config';
import { useWeb3React } from 'hooks/web3';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { sanitize } from 'utils/sanitize';
import { ModalType } from 'components/modals/types';
import { useModal } from 'store/modals';
import { getTokenBalance } from 'helpers/tokenBalance';
import { TxnStatus, TxnType } from 'store/transactions/types';
import formatNumber from 'utils/formatNumber';
import CustomInput from 'components/shared/CustomInput';
import getTokenDecimals from 'helpers/getTokenDecimals';

const MintingFormControl: FC = () => {
  const { openModal } = useModal();
  const { chainId = 1, account, provider } = useWeb3React();
  const { uniswapPoolUrl, mintingTokenAddress } = useConfig(chainId).config;
  const { mintCrucible, transactions } = useTransactions();
  const [amount, setAmount] = useState('');
  const [mintTokenDecimals, setMintTokenDecimals] = useState<number>(0);
  const [decimalsLoading, setDecimalsLoading] = useState(true);

  const mintTokenBalance = getTokenBalance(
    mintingTokenAddress,
    chainId,
    account
  );

  const getDecimals = async () => {
    const decimals = await getTokenDecimals(mintingTokenAddress, provider);
    setMintTokenDecimals(decimals);
    setDecimalsLoading(false);
  };

  useEffect(() => {
    getDecimals();
    // eslint-disable-next-line
  }, [mintingTokenAddress]);

  const handleMintCrucible = () => {
    mintCrucible(parseUnits(amount, mintTokenDecimals));
  };

  const isMinting = useMemo(() => {
    return (
      transactions.filter(
        (txn) =>
          txn.status === TxnStatus.PendingOnChain && txn.type === TxnType.mint
      ).length > 0
    );
  }, [transactions]);

  return (
    <Box>
      <Box mb={6} borderRadius='xl'>
        <Flex mb={4} justifyContent={'space-between'} alignItems='center'>
          <Text>Amount</Text>
          <HStack>
            <Text>
              Balance:{' '}
              <strong>
                {mintTokenBalance ? formatNumber.token(mintTokenBalance) : '-'}{' '}
                LP
              </strong>
            </Text>
            <Link
              href={uniswapPoolUrl}
              isExternal
              color='cyan.600'
              fontSize='lg'
            >
              <RiExternalLinkLine />
            </Link>
          </HStack>
        </Flex>
        <CustomInput
          size='lg'
          bg='gray.700'
          border='none'
          max={formatUnits(mintTokenBalance, mintTokenDecimals)}
          tokenDecimals={mintTokenDecimals}
          value={amount}
          onUserInput={(input) => setAmount(input)}
          showRadioGroup
          showMaxButton
          maxButtonVariant='ghostLight'
          isDisabled={isMinting}
          _placeholder={{
            color: 'gray.500'
          }}
        />
      </Box>
      <Button
        isFullWidth
        isDisabled={
          (!!account &&
            (parseUnits(sanitize(amount), mintTokenDecimals).lte(0) ||
              parseUnits(sanitize(amount), mintTokenDecimals).gt(
                mintTokenBalance
              ))) ||
          isMinting ||
          decimalsLoading
        }
        isLoading={isMinting}
        loadingText='Pending'
        onClick={
          account
            ? handleMintCrucible
            : () => openModal(ModalType.connectWallet)
        }
      >
        {account ? 'Mint a Crucible' : 'Connect wallet'}
      </Button>
    </Box>
  );
};

export default MintingFormControl;
