import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useModal } from 'store/modals';
import { isMobile } from 'react-device-detect';
import RewardProgramInfoTab from 'components/rewardPrograms/RewardProgramInfoTab';
import RewardProgramSubTab from 'components/rewardPrograms/RewardProgramSubTab';
import { ModalType } from './types';
import { RewardProgram, RewardProgramTokens } from 'types';
import { Maintainer } from 'components/rewardPrograms/Maintainer';

type Props = {
  rewardProgram: RewardProgram;
  defaultTab?: 'information' | 'subscribe';
  rewardProgramTokens: RewardProgramTokens;
  // TODO: Update any
  carouselProps: any;
  boxProps: any;
};

const RewardProgramModal: FC<Props> = ({
  rewardProgram,
  defaultTab,
  rewardProgramTokens,
  carouselProps
}) => {
  const { closeModal } = useModal();
  const [tabIndex, setTabIndex] = useState(
    defaultTab === 'information' ? 1 : 0
  );

  const tabProps = {
    borderRadius: 'lg',
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    _selected: { color: 'gray.800', bg: 'cyan.400' },
    marginBottom: '1px'
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.rewardProgramInfo)}
      size={isMobile ? 'full' : 'lg'}
      scrollBehavior={isMobile ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        bg='gray.800'
        mt={isMobile ? 0 : undefined}
        borderRadius={isMobile ? 'none' : 'xl'}
      >
        <ModalHeader fontSize='24px'>
          <VStack alignItems='stretch' spacing={0}>
            <Text textAlign='left'>{rewardProgram.name}</Text>
            <Text
              textAlign='left'
              fontWeight='400'
              color='gray.300'
              fontSize='md'
            >
              <Maintainer
                maintainer={rewardProgram.maintainer}
                owner={rewardProgram.owner}
              />
            </Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Tabs
            index={tabIndex}
            isFitted
            defaultIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
            size='md'
            mb={4}
          >
            <TabList borderRadius='xl' border='1px solid' padding='5px'>
              <Tab {...tabProps}>Subscribe</Tab>
              <Tab {...tabProps}>Information</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                <RewardProgramSubTab
                  rewardProgram={rewardProgram}
                  rewardProgramTokens={rewardProgramTokens}
                />
              </TabPanel>
              <TabPanel px={0}>
                <RewardProgramInfoTab
                  carouselProps={carouselProps}
                  rewardProgram={rewardProgram}
                  rewardProgramTokens={rewardProgramTokens}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RewardProgramModal;
