import { ethers } from 'ethers';
import { randomBytes } from 'ethers/lib/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { THUNK_PREFIX } from 'store/enum';
import { crucibleAbi } from 'abi/crucibleAbi';
import { transmuterAbi } from 'abi/transmuterAbi';
import { aludelAbi } from 'abi/aludelAbi';
import { crucibleFactoryAbi } from 'abi/crucibleFactoryAbi';
import { signPermission, signPermitEIP2612 } from 'helpers/signatures';
import { wait } from 'utils/wait';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { ModalType } from 'components/modals/types';
import { v4 as uuid } from 'uuid';
import { queryClient } from 'index';
import { Queries } from 'types/enum';
import { fetchBlockTimestamp } from 'api/blockchain/blockTimestamp';
import parseTransactionError from 'utils/parseTransactionError';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import TxnError from 'components/toasts/TxnError';
import formatNumber from 'utils/formatNumber';

export const mintCrucible = createAsyncThunk(
  THUNK_PREFIX.MINT_CRUCIBLE,
  async ({
    web3React,
    config,
    updateTx,
    toast,
    modal,
    logger,
    amountLp
  }: any) => {
    const txnId = uuid();
    const description = `Crucible with ${formatNumber.token(amountLp)} LP`;

    updateTx({
      id: txnId,
      type: TxnType.mint,
      status: TxnStatus.Initiated,
      description
    });

    try {
      const { provider, library, account, chainId } = web3React;
      const signer = library.getSigner();
      const { crucibleFactoryAddress, aludelAddress, transmuterAddress } =
        config;
      const salt = randomBytes(32);
      const deadline =
        (await library.getBlock('latest')).timestamp + 60 * 60 * 24;

      //set up the aludel, staking, factory and transmuter contract instances
      const aludel = new ethers.Contract(aludelAddress, aludelAbi, signer);

      const stakingToken = new ethers.Contract(
        (await aludel.getAludelData()).stakingToken,
        IUniswapV2ERC20.abi,
        provider
      );

      const crucibleFactory = new ethers.Contract(
        crucibleFactoryAddress,
        crucibleFactoryAbi,
        provider
      );

      const transmuter = new ethers.Contract(
        transmuterAddress,
        transmuterAbi,
        signer
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      //craft permission
      const crucible = new ethers.Contract(
        await transmuter.predictDeterministicAddress(
          await crucibleFactory.getTemplate(),
          salt,
          crucibleFactory.address
        ),
        crucibleAbi,
        signer
      );

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Sign token permit data (1 of 3)' />
        )
      });

      //Signature #1
      const permit = await signPermitEIP2612(
        signer,
        account,
        stakingToken,
        transmuter.address,
        amountLp,
        deadline
      );

      //short wait so that metamask can pop up the second signature request
      await wait(300);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Sign Crucible permission data (2 of 3)' />
        )
      });

      //signature #2
      const permission = await signPermission(
        'Lock',
        crucible,
        signer,
        aludel.address,
        stakingToken.address,
        amountLp,
        0
      );

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm mint Crucible transaction (3 of 3)' />
        )
      });

      const estimateGas =
        await transmuter.estimateGas.mintCruciblePermitAndStake(
          aludel.address,
          crucibleFactory.address,
          account,
          salt,
          permit,
          permission
        );

      let tx;

      if (estimateGas.gt(0)) {
        // wallet confirmation
        tx = await transmuter.mintCruciblePermitAndStake(
          aludel.address,
          crucibleFactory.address,
          account,
          salt,
          permit,
          permission,
          {
            gasLimit: estimateGas.mul(12).div(10)
          }
        );
      } else {
        throw Error(
          'Unable to retrieve gas limit from network, please try again.'
        );
      }

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: tx.hash
      });

      await tx.wait(1);

      const blockTimestamp = await fetchBlockTimestamp(chainId);
      queryClient.setQueryData(
        [Queries.BLOCK_TIMESTAMP, chainId],
        blockTimestamp
      );

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: tx.hash
      });

      const symbol = await crucibleFactory.symbol();

      modal.openModal(ModalType.mintSuccess, {
        crucibleSymbol: symbol,
        crucibleAddress: crucible.address,
        crucibleFactoryAddress
      });
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });

      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);

export default mintCrucible;
