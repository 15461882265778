import { FC } from 'react';
import {
  Box,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useMediaQuery
} from '@chakra-ui/react';
import { IoInfiniteSharp } from 'react-icons/io5';
import { Carousel } from 'react-responsive-carousel';
import { CountUp } from 'use-count-up';
import formatNumber from 'utils/formatNumber';
import { Maintainer } from 'components/rewardPrograms/Maintainer';

type Props = {
  showMaintainer: boolean;
  showDivider: boolean;
  maintainer: string;
  owner: string;
  apy: string | number;
  rewardMultiplier: number;
  rewardScalingTime: number;
  expiryTimestamp: number | undefined;
  daysLeft: string;
  programRewardsUSD: number;
  programStakedUSD: number;
  boxProps: any;
  stackProps: any;
  lastFundedDate?: string;
};

const RewardProgramCarousel: FC<Props> = ({
  showMaintainer,
  showDivider,
  maintainer,
  owner,
  apy,
  rewardMultiplier,
  rewardScalingTime,
  expiryTimestamp,
  daysLeft,
  programRewardsUSD,
  programStakedUSD,
  boxProps,
  stackProps,
  lastFundedDate
}) => {
  const [isSmallerThan350] = useMediaQuery('(max-width: 349px)');
  const [isSmallerThan480] = useMediaQuery('(max-width: 479px)');
  const [isSmallerThan600] = useMediaQuery('(max-width: 599px)');

  const titleProps = {
    color: 'gray.300',
    isTruncated: true
  };

  const getApyString = () => {
    if (typeof apy === 'number') {
      return formatNumber.percent(apy);
    } else {
      return apy;
    }
  };

  return (
    <Box {...boxProps}>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        dynamicHeight
        emulateTouch
      >
        <Stack
          width='100%'
          justifyContent='space-evenly'
          direction={['column', 'row']}
          userSelect='none'
          divider={
            showDivider && !isSmallerThan480 ? (
              <StackDivider borderColor='whiteAlpha.200' />
            ) : undefined
          }
          {...stackProps}
        >
          {showMaintainer && (
            <Stack
              spacing={0}
              direction={['row', 'column']}
              justifyContent='space-between'
              textAlign='center'
            >
              <Text {...titleProps}>Maintainer</Text>
              <Text fontWeight='500' noOfLines={1}>
                <Maintainer maintainer={maintainer} owner={owner} link={true} />
              </Text>
            </Stack>
          )}
          <Stack
            spacing={0}
            direction={['row', 'column']}
            justifyContent='space-between'
            textAlign='center'
          >
            <Text {...titleProps}>APY</Text>
            {getApyString().length >= 9 ? (
              <Tooltip label={getApyString()} placement='top' hasArrow>
                <Text
                  fontWeight='500'
                  noOfLines={1}
                >{`${getApyString().substring(0, 8)}...`}</Text>
              </Tooltip>
            ) : (
              <Text fontWeight='500'>{getApyString()}</Text>
            )}
          </Stack>
          {rewardMultiplier !== 1 && (
            <Stack
              spacing={0}
              direction={['row', 'column']}
              justifyContent='space-between'
              textAlign='center'
            >
              <Text {...titleProps}>Max Multiplier</Text>
              <Text fontWeight='500' noOfLines={1}>
                {rewardMultiplier}x {isSmallerThan350 ? '/' : 'over'}{' '}
                {rewardScalingTime}d
              </Text>
            </Stack>
          )}
          {lastFundedDate && !isSmallerThan600 && (
            <Stack
              spacing={0}
              direction={['row', 'column']}
              justifyContent='space-between'
              textAlign='center'
            >
              <Text {...titleProps}>Last Funded</Text>
              <Text fontWeight='500' noOfLines={1}>
                {lastFundedDate}
              </Text>
            </Stack>
          )}
          <Stack
            spacing={0}
            direction={['row', 'column']}
            justifyContent='space-between'
            textAlign='center'
          >
            <Text {...titleProps}>Duration</Text>
            {expiryTimestamp ? (
              <Text fontWeight='500' noOfLines={1}>
                {daysLeft}
              </Text>
            ) : (
              <Box fontSize='20px' textAlign='center'>
                <IoInfiniteSharp style={{ margin: '0 auto' }} />
              </Box>
            )}
          </Stack>
        </Stack>

        <Stack
          justifyContent='space-evenly'
          direction={['column', 'row']}
          userSelect='none'
          divider={
            showDivider && !isSmallerThan480 ? (
              <StackDivider borderColor='whiteAlpha.200' />
            ) : undefined
          }
          {...stackProps}
        >
          <Stack
            spacing={0}
            direction={['row', 'column']}
            justifyContent='space-between'
            textAlign='center'
          >
            <Text {...titleProps}>Total rewards</Text>
            <Text fontWeight='500'>
              <CountUp
                duration={0}
                isCounting
                end={
                  isNaN(Math.round(programRewardsUSD))
                    ? 0
                    : Math.round(programRewardsUSD)
                }
                shouldUseToLocaleString={true}
                prefix='$'
              />
            </Text>
          </Stack>
          <Stack
            spacing={0}
            direction={['row', 'column']}
            justifyContent='space-between'
            textAlign='center'
          >
            <Text {...titleProps}>Total subscribed</Text>
            <Text fontWeight='500'>
              <CountUp
                duration={0}
                isCounting
                end={
                  isNaN(Math.round(programStakedUSD))
                    ? 0
                    : Math.round(programStakedUSD)
                }
                shouldUseToLocaleString={true}
                prefix='$'
              />
            </Text>
          </Stack>
          {lastFundedDate && isSmallerThan600 && (
            <Stack
              spacing={0}
              direction={['row', 'column']}
              justifyContent='space-between'
              textAlign='center'
            >
              <Text {...titleProps}>Last Funded</Text>
              <Text fontWeight='500'>{lastFundedDate}</Text>
            </Stack>
          )}
        </Stack>
      </Carousel>
    </Box>
  );
};

export default RewardProgramCarousel;
