import { ContractReceipt, ethers } from 'ethers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { THUNK_PREFIX } from 'store/enum';
import { crucibleFactoryAbi } from 'abi/crucibleFactoryAbi';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { ModalType } from 'components/modals/types';
import { v4 as uuid } from 'uuid';
import { customMinterAbi } from 'abi/customMinterAbi';
import { parseEther } from '@ethersproject/units';
import { hexStripZeros } from '@ethersproject/bytes';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';

export const mintCrucibleCustom = createAsyncThunk(
  THUNK_PREFIX.MINT_CRUCIBLE,
  async ({
    web3React,
    config,
    updateTx,
    toast,
    modal,
    logger,
    withFee,
    fee
  }: any) => {
    const txnId = uuid();
    const description = `Crucible`;
    const { library, account, chainId } = web3React;
    const { customMinterAddress, crucibleFactoryAddress } = config;

    const signer = library.getSigner();

    const crucibleFactory = new ethers.Contract(
      crucibleFactoryAddress,
      crucibleFactoryAbi,
      signer
    );

    if (withFee) {
      try {
        updateTx({
          id: txnId,
          type: TxnType.mintPro,
          status: TxnStatus.Initiated,
          description
        });

        const signer = library.getSigner();

        const symbol = await crucibleFactory.symbol();

        const customMinterContract = new ethers.Contract(
          customMinterAddress,
          customMinterAbi,
          signer
        );

        updateTx({
          id: txnId,
          status: TxnStatus.PendingApproval,
          account,
          chainId
        });

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm mint Crucible transaction' />
          )
        });

        const tx = await customMinterContract.createWithEther({
          value: parseEther(fee.toString())
        });

        updateTx({
          id: txnId,
          status: TxnStatus.PendingOnChain,
          hash: tx.hash
        });

        const receipt: ContractReceipt = await tx.wait(1);

        const crucibleAddress = hexStripZeros(receipt.logs[1].data);

        updateTx({
          id: txnId,
          status: TxnStatus.Mined,
          hash: tx.hash
        });

        modal.openModal(ModalType.mintSuccess, {
          crucibleSymbol: symbol,
          crucibleAddress,
          crucibleFactoryAddress
        });
      } catch (error: any) {
        let errorMessage = '';
        if (error?.data?.message?.includes('insufficient funds')) {
          errorMessage = 'Insufficient funds for transaction';
        } else {
          errorMessage = parseTransactionError(error);
        }

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          // @ts-ignore
          render: ({ onClose }) => (
            <TxnError
              onClose={onClose}
              modal={modal}
              errorMessage={errorMessage}
              error={error}
            />
          )
        });

        updateTx({
          id: txnId,
          status: TxnStatus.Failed,
          error
        });

        logger.push(error);
        throw error;
      }
    } else {
      try {
        updateTx({
          id: txnId,
          type: TxnType.mintBasic,
          status: TxnStatus.Initiated,
          description
        });

        const symbol = await crucibleFactory.symbol();

        updateTx({
          id: txnId,
          status: TxnStatus.PendingApproval,
          account,
          chainId
        });

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm mint Crucible transaction' />
          )
        });

        const tx = await crucibleFactory['create()']();

        updateTx({
          id: txnId,
          status: TxnStatus.PendingOnChain,
          hash: tx.hash
        });

        const receipt: ContractReceipt = await tx.wait(1);

        const crucibleAddress = hexStripZeros(receipt.logs[1].data);

        updateTx({
          id: txnId,
          status: TxnStatus.Mined,
          hash: tx.hash
        });

        modal.openModal(ModalType.mintSuccess, {
          crucibleSymbol: symbol,
          crucibleAddress,
          crucibleFactoryAddress
        });
      } catch (error) {
        const errorMessage = parseTransactionError(error);

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          // @ts-ignore
          render: ({ onClose }) => (
            <TxnError
              onClose={onClose}
              modal={modal}
              errorMessage={errorMessage}
              error={error}
            />
          )
        });

        updateTx({
          id: txnId,
          status: TxnStatus.Failed,
          error
        });

        logger.push(error);
        throw error;
      }
    }
  }
);

export default mintCrucibleCustom;
