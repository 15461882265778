import { ethers } from 'ethers';
import { wait } from 'utils/wait';
import { v4 as uuid } from 'uuid';
import { queryClient } from 'index';
import { Queries } from 'types/enum';
import { truncate } from 'utils/address';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { THUNK_PREFIX } from 'store/enum';
import { crucibleAbi } from 'abi/crucibleAbi';
import { transmuterAbi } from 'abi/transmuterAbi';
import { signPermission, signPermitEIP2612 } from 'helpers/signatures';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { fetchBlockTimestamp } from 'api/blockchain/blockTimestamp';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import TxnError from 'components/toasts/TxnError';
import formatNumber from 'utils/formatNumber';

export const addSubscription = createAsyncThunk(
  THUNK_PREFIX.ADD_SUBSCRIPTION,
  async ({
    web3React,
    modal,
    updateTx,
    toast,
    logger,
    stakingTokenAmount,
    crucibleAddress,
    balanceFrom,
    currentRewardProgram,
    stakingToken
  }: any) => {
    // create UUID for tracking this tx
    const txnId = uuid();

    const description = `${formatNumber.token(
      stakingTokenAmount,
      stakingToken.decimals
    )} ${stakingToken.tokenSymbol} to Crucible ${truncate(crucibleAddress)}`;

    // SETUP
    const { library, account, chainId, provider } = web3React;
    const signer = library.getSigner();

    //set up the required contract instances
    const rewardProgramContract = new ethers.Contract(
      currentRewardProgram.address,
      currentRewardProgram.abi,
      signer
    );

    const stakingTokenContract = new ethers.Contract(
      stakingToken.address,
      IUniswapV2ERC20.abi,
      signer
    );

    const crucible = new ethers.Contract(crucibleAddress, crucibleAbi, signer);

    updateTx({
      id: txnId,
      type: TxnType.addSubscription,
      status: TxnStatus.Initiated,
      description,
      crucibleAddress
    });

    const nonce = await crucible.getNonce();

    if (balanceFrom === 'wallet') {
      const permitSupported = await stakingTokenContract
        .PERMIT_TYPEHASH()
        .catch(() => {
          return false;
        });

      if (!permitSupported) {
        updateTx({
          id: txnId,
          status: TxnStatus.PendingApproval,
          account,
          chainId
        });

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals
              description={
                'Confirm transfer of ' +
                formatNumber.token(stakingTokenAmount, stakingToken.decimals) +
                ' ' +
                stakingToken.tokenSymbol +
                ' to Crucible transaction (1 of 3)'
              }
            />
          )
        });
        try {
          const transferTx = await stakingTokenContract.transfer(
            crucibleAddress,
            stakingTokenAmount
          );

          updateTx({
            id: txnId,
            status: TxnStatus.PendingOnChain,
            hash: transferTx.hash
          });

          await transferTx.wait(1);

          toast.closeAll();

          toast({
            duration: null,
            isClosable: true,
            position: 'bottom-right',
            render: () => (
              <TxnPendingApprovals description='Sign Crucible permission data (2 of 3)' />
            )
          });

          const permission = await signPermission(
            'Lock',
            crucible,
            signer,
            currentRewardProgram.address,
            stakingToken.address,
            stakingTokenAmount,
            nonce
          );

          await wait(300);

          toast.closeAll();
          toast({
            duration: null,
            isClosable: true,
            position: 'bottom-right',
            render: () => (
              <TxnPendingApprovals description='Confirm subscription transaction (3 of 3)' />
            )
          });

          let estimateGas = await rewardProgramContract.estimateGas.stake(
            crucibleAddress,
            stakingTokenAmount,
            permission
          );

          let tx;

          if (estimateGas.gt(0)) {
            tx = await rewardProgramContract.stake(
              crucibleAddress,
              stakingTokenAmount,
              permission,
              {
                gasLimit: estimateGas.mul(12).div(10)
              }
            );
          } else {
            throw Error(
              'Unable to retrieve gas limit from network, please try again.'
            );
          }

          updateTx({
            id: txnId,
            status: TxnStatus.PendingOnChain,
            hash: tx.hash
          });

          await tx.wait(1);

          // Update the blocktime in the cache to prevent subscription duration being negative
          const blockNumber = await provider.getBlockNumber();
          const block = await provider.getBlock(blockNumber);
          queryClient.setQueryData(
            [Queries.BLOCK_TIMESTAMP, chainId],
            block.timestamp
          );

          updateTx({
            id: txnId,
            status: TxnStatus.Mined,
            hash: tx.hash
          });
        } catch (error) {
          const errorMessage = parseTransactionError(error);

          toast.closeAll();
          toast({
            duration: null,
            isClosable: true,
            position: 'bottom-right',
            // @ts-ignore
            render: ({ onClose }) => (
              <TxnError
                onClose={onClose}
                modal={modal}
                errorMessage={errorMessage}
                error={error}
              />
            )
          });

          updateTx({
            id: txnId,
            status: TxnStatus.Failed,
            error
          });

          logger.push(error);

          // trigger redux toolkit's rejected.match hook
          throw error;
        }
        return;
      }

      const transmuter = new ethers.Contract(
        currentRewardProgram.transmuter,
        transmuterAbi,
        signer
      );

      try {
        const deadline = Date.now() + 60 * 60 * 24; // 1 day deadline

        updateTx({
          id: txnId,
          status: TxnStatus.PendingApproval,
          account,
          chainId
        });

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Sign token permit data (1 of 3)' />
          )
        });

        // open metamask modal and request 1st signature
        const permit = await signPermitEIP2612(
          signer,
          account,
          stakingTokenContract,
          transmuter.address,
          stakingTokenAmount,
          deadline
        );

        // needed for metamask to pop up the 2nd signature confirmation
        await wait(300);

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Sign Crucible permission data (2 of 3)' />
          )
        });

        const permission = await signPermission(
          'Lock',
          crucible,
          signer,
          currentRewardProgram.address,
          stakingToken.address,
          stakingTokenAmount,
          nonce
        );

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm subscription transaction (3 of 3)' />
          )
        });

        const estimateGas = await transmuter.estimateGas.permitAndStake(
          currentRewardProgram.address,
          crucibleAddress,
          permit,
          permission
        );

        let tx;

        if (estimateGas.gt(0)) {
          tx = await transmuter.permitAndStake(
            currentRewardProgram.address,
            crucibleAddress,
            permit,
            permission,
            {
              gasLimit: estimateGas.mul(12).div(10)
            }
          );
        } else {
          throw Error(
            'Unable to retrieve gas limit from network, please try again.'
          );
        }

        updateTx({
          id: txnId,
          status: TxnStatus.PendingOnChain,
          hash: tx.hash
        });

        await tx.wait(1);

        // Update the blocktime in the cache to prevent subscription duration being negative
        const blockTimestamp = await fetchBlockTimestamp(chainId);
        queryClient.setQueryData(
          [Queries.BLOCK_TIMESTAMP, chainId],
          blockTimestamp
        );

        updateTx({
          id: txnId,
          status: TxnStatus.Mined,
          hash: tx.hash
        });
      } catch (error) {
        const errorMessage = parseTransactionError(error);

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          // @ts-ignore
          render: ({ onClose }) => (
            <TxnError
              onClose={onClose}
              modal={modal}
              errorMessage={errorMessage}
              error={error}
            />
          )
        });
        updateTx({
          id: txnId,
          status: TxnStatus.Failed,
          error: errorMessage
        });

        logger.push(error);

        // trigger redux toolkit's rejected.match hook
        throw error;
      }
    } else {
      try {
        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Sign Crucible permission data (1 of 2)' />
          )
        });

        const permission = await signPermission(
          'Lock',
          crucible,
          signer,
          currentRewardProgram.address,
          stakingToken.address,
          stakingTokenAmount,
          nonce
        );

        await wait(300);

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          render: () => (
            <TxnPendingApprovals description='Confirm subscription transaction (2 of 2)' />
          )
        });

        let estimateGas = await rewardProgramContract.estimateGas.stake(
          crucibleAddress,
          stakingTokenAmount,
          permission
        );

        let tx;

        if (estimateGas.gt(0)) {
          tx = await rewardProgramContract.stake(
            crucibleAddress,
            stakingTokenAmount,
            permission,
            {
              gasLimit: estimateGas.mul(12).div(10)
            }
          );
        } else {
          throw Error(
            'Unable to retrieve gas limit from network, please try again.'
          );
        }

        updateTx({
          id: txnId,
          status: TxnStatus.PendingOnChain,
          hash: tx.hash
        });

        await tx.wait(1);

        // Update the blocktime in the cache to prevent subscription duration being negative
        const blockNumber = await provider.getBlockNumber();
        const block = await provider.getBlock(blockNumber);
        queryClient.setQueryData(
          [Queries.BLOCK_TIMESTAMP, chainId],
          block.timestamp
        );

        updateTx({
          id: txnId,
          status: TxnStatus.Mined,
          hash: tx.hash
        });
      } catch (error) {
        const errorMessage = parseTransactionError(error);

        toast.closeAll();
        toast({
          duration: null,
          isClosable: true,
          position: 'bottom-right',
          // @ts-ignore
          render: ({ onClose }) => (
            <TxnError
              onClose={onClose}
              modal={modal}
              errorMessage={errorMessage}
              error={error}
            />
          )
        });

        updateTx({
          id: txnId,
          status: TxnStatus.Failed,
          error
        });

        logger.push(error);

        // trigger redux toolkit's rejected.match hook
        throw error;
      }
    }
  }
);

export default addSubscription;
