import { FC } from 'react';
import { Flex } from '@chakra-ui/layout';
import {
  Container,
  HStack,
  Text,
  Link as ChakraLink,
  LinkBox,
  LinkOverlay,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { Image } from '@chakra-ui/image';
import { useMediaQuery } from '@chakra-ui/react';
import { DrawerType, useSideDrawer } from 'store/sideDrawer';
import { RiMenuUnfoldFill } from 'react-icons/all';
import { useCrucibles } from 'hooks/query/useCrucibles';
import { useSelectedCrucible } from 'store/selectedCrucible';
import logo from 'img/logo.svg';
import icon from 'img/icon.svg';
import CruciblesBadge from './CruciblesBadge';
import UserWallet from 'components/user/UserWallet';
import SideDrawerIcon from './NotificationsBadge';
import FlashbotsProtectionLabel from 'components/user/FlashbotsProtectionLabel';
import { BiChevronDown } from 'react-icons/bi';
import { useRewardPrograms } from 'store/zustand/useRewardPrograms';

const Header: FC = () => {
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const { data: crucibles } = useCrucibles();
  const { selectedCrucible } = useSelectedCrucible();
  const { currentRewardProgram } = useRewardPrograms();
  const { openDrawer } = useSideDrawer();
  const { pathname } = useLocation();

  const isCruciblesRoute = pathname.match(/^\/crucible\/(\S+)$/);
  const isExploreRoute =
    pathname.match(/^\/explore\/(\S+)$/) || pathname.match('/explore');
  const isGovernanceRoute =
    pathname.match(/^\/governance\/(\S+)$/) || pathname.match('/governance');
  const isCrucibleNFTRoute = pathname.match(/^\/nft-scene\/(\S+)$/);
  const isLeaderboardRoute =
    pathname.match(/^\/leaderboard\/(\S+)$/) || pathname.match('/leaderboard');

  if (isCrucibleNFTRoute) return null;

  const menuButtonStyles = {
    color: 'white',
    fontWeight: 'medium',
    height: 'max-content',
    p: 0,
    background: 'transparent !important',
    _hover: {
      background: 'transparent'
    },
    _active: {
      background: 'transparent'
    }
  };

  return (
    <Container maxW='container.xl' py={[4, 4, 4, 8]}>
      <Flex justifyContent='space-between' flexGrow={1}>
        <HStack alignItems='center'>
          {!isLargerThan992 && isCruciblesRoute && (
            <IconButton
              onClick={() => openDrawer(DrawerType.crucibles)}
              variant='ghost'
              icon={<RiMenuUnfoldFill />}
              aria-label='Crucibles menu'
              fontSize='x-large'
              bg='none'
            />
          )}
          <LinkBox mr={[0, 0, 0, 12]}>
            <LinkOverlay as={Link} to='/'>
              {isLargerThan992 ? (
                <Image
                  src={logo}
                  height='31px'
                  width='140px'
                  htmlWidth='140px'
                />
              ) : (
                <Image
                  src={icon}
                  height='34px'
                  width='34px'
                  htmlWidth='34px'
                  ignoreFallback
                />
              )}
            </LinkOverlay>
          </LinkBox>
          {isLargerThan992 && (
            <HStack spacing={6}>
              <ChakraLink
                as={Link}
                fontWeight='medium'
                to='/reward-programs'
                color={pathname === '/reward-programs' ? 'cyan.500' : 'white'}
              >
                <Text>Reward Programs</Text>
              </ChakraLink>
              <ChakraLink
                as={Link}
                fontWeight='medium'
                to='/governance'
                color={isGovernanceRoute ? 'cyan.link' : 'white'}
              >
                <Text>Governance</Text>
              </ChakraLink>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiChevronDown />}
                  sx={menuButtonStyles}
                >
                  Crucibles
                </MenuButton>
                <MenuList zIndex={10}>
                  <MenuItem>
                    <ChakraLink
                      as={Link}
                      width='100%'
                      fontWeight='medium'
                      to='/mint'
                      color={pathname === '/mint' ? 'cyan.link' : 'white'}
                    >
                      Mint a Crucible
                    </ChakraLink>
                  </MenuItem>
                  <MenuItem>
                    <ChakraLink
                      as={Link}
                      width='100%'
                      fontWeight='medium'
                      to='/explore'
                      color={isExploreRoute ? 'cyan.link' : 'white'}
                    >
                      Explore
                    </ChakraLink>
                  </MenuItem>
                  <MenuItem>
                    <ChakraLink
                      as={Link}
                      width='100%'
                      fontWeight='medium'
                      to={`/leaderboard/${currentRewardProgram.address}`}
                      color={isLeaderboardRoute ? 'cyan.500' : 'white'}
                    >
                      Leaderboards
                    </ChakraLink>
                  </MenuItem>
                  <Link
                    to={
                      selectedCrucible
                        ? `/crucible/${selectedCrucible.id}`
                        : !!crucibles?.length
                        ? `/crucible/${crucibles[0].id}`
                        : '/crucible/not-found'
                    }
                  >
                    <MenuItem _hover={{ textDecoration: 'underline' }}>
                      <HStack width='100%'>
                        {/* TODO_NEW_UI: If user has no crucibles do not redirect but show CTA */}
                        <Text
                          fontWeight='medium'
                          color={isCruciblesRoute ? 'cyan.link' : 'white'}
                        >
                          Your Crucibles
                        </Text>
                        <CruciblesBadge />
                      </HStack>
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>
            </HStack>
          )}
        </HStack>
        <HStack>
          <FlashbotsProtectionLabel />
          <UserWallet />
          <SideDrawerIcon />
        </HStack>
      </Flex>
    </Container>
  );
};

export default Header;
