import create from 'zustand';

type CrucibleOffsetState = {
  offset: number;
  setOffset: (by: number) => void;
};

export const useCrucibleOffset = create<CrucibleOffsetState>((set) => ({
  offset: 0,
  setOffset: (by: number) => set(() => ({ offset: by }))
}));
