import { FC } from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { useWeb3React } from 'hooks/web3';
import { useConfig } from 'store/config';
import {
  Alert,
  AlertDescription,
  Link,
  Stack,
  HStack,
  Badge
} from '@chakra-ui/react';
import { RiExternalLinkLine } from 'react-icons/ri';
import { getTokenBalance } from 'helpers/tokenBalance';
import { useUserBalances } from 'hooks/query/useUserBalances';
import MintingFormControl from 'components/minting/MintingFormControl';

const MintingForm: FC = () => {
  useUserBalances();
  const { chainId = 1, account } = useWeb3React();
  const { config, commonConfig } = useConfig(chainId);
  const { uniswapPoolUrl, mistTokenAddress, mintingTokenAddress } = config;
  const { getMistUrl } = commonConfig;
  const { isLoading } = useUserBalances();

  const mintingTokenBalance = getTokenBalance(
    mintingTokenAddress,
    chainId,
    account
  );
  const mistTokenBalance = getTokenBalance(mistTokenAddress, chainId, account);

  return (
    <Stack spacing={5} width='100%'>
      {mintingTokenBalance?.lte(0) && account && !isLoading && (
        <>
          <Text mt={4} mb={-2}>
            <strong>Insufficient MIST-ETH LP balance</strong>
          </Text>
          <Alert status='error' borderRadius='xl' py={4}>
            <Box width='100%'>
              <AlertDescription display='flex'>
                <Stack width='100%' spacing={4}>
                  {mistTokenBalance?.lte(0) && (
                    <AlertBoxRow
                      badgeText='1'
                      description='Get $MIST'
                      url={getMistUrl}
                      urlText='uniswap.org'
                    />
                  )}
                  <AlertBoxRow
                    badgeText={mistTokenBalance?.lte(0) ? '2' : ''}
                    description='Get MIST-ETH LP'
                    url={uniswapPoolUrl}
                    urlText='uniswap.org'
                  />
                </Stack>
              </AlertDescription>
            </Box>
          </Alert>
        </>
      )}
      <Box bg='gray.800' flexDir='column' borderRadius='2xl' pb={4}>
        <MintingFormControl />
      </Box>
    </Stack>
  );
};

type AlertBoxRowProps = {
  badgeText: string;
  description: string;
  url: string;
  urlText: string;
};

const AlertBoxRow: FC<AlertBoxRowProps> = ({
  badgeText,
  description,
  url,
  urlText
}) => {
  return (
    <HStack justifyContent='space-between'>
      <HStack>
        <Badge
          width='25px'
          height='25px'
          colorScheme='red'
          color='white'
          borderRadius='100%'
          textAlign={'center'}
        >
          {badgeText}
        </Badge>
        <Text>{description}</Text>
      </HStack>
      <Link href={url} isExternal color='blue.300' alignSelf='flex-end'>
        <HStack spacing={1}>
          <Text>{urlText}</Text>
          <RiExternalLinkLine />
        </HStack>
      </Link>
    </HStack>
  );
};

export default MintingForm;
