import { Box, HStack, useRadioGroup } from '@chakra-ui/react';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import RadioCard from './RadioCard';

type Props = {
  value: string;
  max: string;
  tokenDecimals: number;
  onChange: (string: string) => void;
};

const CustomInputRadio: React.FC<Props> = ({
  value,
  max,
  tokenDecimals,
  onChange
}) => {
  const options = [
    {
      value: formatUnits(parseUnits(max, tokenDecimals).mul(25).div(100), tokenDecimals),
      label: '25%',
    },
    {
      value: formatUnits(parseUnits(max, tokenDecimals).mul(50).div(100), tokenDecimals),
      label: '50%',
    },
    {
      value: formatUnits(parseUnits(max, tokenDecimals).mul(75).div(100), tokenDecimals),
      label: '75%',
    },
    {
      value: formatUnits(parseUnits(max, tokenDecimals).mul(100).div(100), tokenDecimals),
      label: '100%',
    },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange,
    name: 'amount'
  });

  const group = getRootProps();

  return (
    <HStack {...group} mt={4}>
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={label} {...radio}>
            <Box py={1} _checked={{ color: 'white' }}>
              {label}
            </Box>
          </RadioCard>
        );
      })}
    </HStack>
  );
};

export default CustomInputRadio;
