export enum THUNK_PREFIX {
  'GET_OWNED_CRUCIBLES' = 'crucibles/getOwnedCrucibles',
  'GET_LOCKS_ALL_CRUCIBLES' = 'crucibles/getLocksAllCrucibles',
  'UPDATE_LOCKS_SINGLE_CRUCIBLE' = 'crucibles/updateLocksForSingleCrucible',
  'GET_SUBSCRIPTIONS' = 'crucibles/getSubscriptions',
  'GET_SUBSCRIPTIONS_USD' = 'crucibles/getSubscriptionsUSD',
  'GET_REWARD_PROGRAM_TOKENS' = 'crucibles/getRewardProgramTokens',
  'GET_REWARD_SHARE_FOR_CRUCIBLE' = 'crucibles/getRewardsShare',
  'GET_USER_BALANCES' = 'user/getUserBalances',
  'UPDATE_USER_BALANCES' = 'user/updateUserBalances',
  'TRANSFER_CRUCIBLE' = 'transactions/transferCrucible',
  'MINT_CRUCIBLE' = 'transactions/mintCrucible',
  'ADD_SUBSCRIPTION' = 'transactions/addSubscription',
  'UNSUBSCRIBE_LP' = 'transactions/unsubscribeLP',
  'DEPOSIT_TO_CRUCIBLE' = 'crucibles/depositToCrucible',
  'WITHDRAW_FROM_CRUCIBLE' = 'crucibles/withdrawFromCrucible',
  'TRANSFER_BETWEEN_CRUCIBLES' = 'crucibles/transferBetweenCrucibles',
  'RAGE_QUIT' = 'crucibles/rageQuit',
  'HANDLE_WRAPPING' = 'transactions/handleWrapping',
  'DEPLOY_ALUDEL' = 'transactions/deployAludel',
  'FUND_ALUDEL' = 'transactions/fundAludel',
  'TRANSFER_PROGRAM_OWNERSHIP' = 'transactions/transferProgramOwnership',
  'UPDATE_ALUDEL_STATUS' = 'transactions/updateAludelStatus',
  'RESCUE_FROM_REWARD_POOL' = 'transactions/rescueFromRewardPool',
  'RESCUE_ERC20' = 'transactions/rescueERC20',
}

export enum SLICE_NAME {
  'SELECTED_CRUCIBLE' = 'crucible/selectedCrucible',
  'CRUCIBLE_LOCKS' = 'crucibleLocks',
  'SELECTED_ASSETS' = 'selectedAssets',
  'REWARDS_SHARE' = 'crucible/rewardsShare',
  'USER_BALANCES' = 'user/balances',
  'TRANSACTIONS' = 'transactions'
}
