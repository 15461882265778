import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TxnStatus, TxnType } from 'store/transactions/types';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import { parseUnits } from 'ethers/lib/utils';
import { truncate } from 'utils/address';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';

export const fundBonusToken = createAsyncThunk(
  THUNK_PREFIX.FUND_ALUDEL,
  async ({
    web3React,
    updateTx,
    toast,
    modal,
    logger,
    aludelAddress,
    bonusToken,
    rewardProgramDataMethod,
    aludelAbi
  }: any) => {
    // create UUID for tracking these tx
    let txnId = uuid();

    const parsedAmount = parseUnits(
      bonusToken.amount.toString(),
      bonusToken.decimals
    );

    let description = `Transfer ${bonusToken.amount} ${truncate(
      bonusToken.tokenSymbol.toUpperCase()
    )} to RewardPool`;

    updateTx({
      id: txnId,
      type: TxnType.fundBonusToken,
      status: TxnStatus.Initiated,
      description
    });

    try {
      const { account, chainId, library } = web3React;

      const aludel = new ethers.Contract(
        aludelAddress,
        aludelAbi,
        library.getSigner()
      );

      const aludelData = await aludel[rewardProgramDataMethod].call();
      const rewardPool = aludelData[2];

      const bonusTokenContract = new ethers.Contract(
        bonusToken.address,
        IUniswapV2ERC20.abi,
        library.getSigner()
      );

      const senderBalance = await bonusTokenContract.balanceOf(account);
      if (senderBalance < bonusToken.amount)
        throw new Error(
          `Sender ${truncate(
            account
          )} has insufficient ${bonusToken.tokenSymbol.toUpperCase()} balance`
        );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm tokens transfer to wallet transaction' />
        )
      });

      const transferTx = await bonusTokenContract.transfer(
        rewardPool,
        parsedAmount
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: transferTx.hash
      });

      await transferTx.wait(1);

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: transferTx.hash
      });
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });
      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error: errorMessage
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
