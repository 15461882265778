import { ethers } from 'ethers';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TxnStatus, TxnType } from 'store/transactions/types';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import { truncate } from 'utils/address';

export const transferProgramOwnership = createAsyncThunk(
  THUNK_PREFIX.TRANSFER_PROGRAM_OWNERSHIP,
  async ({
    web3React,
    updateTx,
    toast,
    modal,
    logger,
    programAddress,
    newOwnerAddress,
    aludelAbi
  }: any) => {
    // create UUID for tracking these tx
    const txnId = uuid();

    let description = `Transfer ownership of ${truncate(
      programAddress
    )} to ${truncate(newOwnerAddress)}`;

    updateTx({
      id: txnId,
      type: TxnType.transferProgramOwnership,
      status: TxnStatus.Initiated,
      description
    });

    try {
      const { account, chainId, library } = web3React;

      const programContract = new ethers.Contract(
        programAddress,
        aludelAbi,
        library.getSigner()
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm ownership change transaction' />
        )
      });

      const transferTx = await programContract.transferOwnership(
        newOwnerAddress
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: transferTx.hash
      });

      await transferTx.wait(1);

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: transferTx.hash
      });
    } catch (error) {
      const errorMessage = parseTransactionError(error);

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });
      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error: errorMessage
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
