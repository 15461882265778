import { Contract, ethers } from 'ethers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { THUNK_PREFIX } from 'store/enum';
import { TxnStatus, TxnType } from 'store/transactions/types';
import { crucibleAbi } from 'abi/crucibleAbi';
import { truncate } from 'utils/address';
import TxnError from 'components/toasts/TxnError';
import parseTransactionError from 'utils/parseTransactionError';
import TxnPendingApprovals from 'components/toasts/TxnPendingApprovals';
import formatNumber from 'utils/formatNumber';

const getTx = async (
  crucibleContract: Contract,
  walletAddress: string,
  amount: string,
  tokenAddress: string,
  signer: any
) => {
  if (tokenAddress === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
    return crucibleContract.transferETH(walletAddress, amount);
  } else {
    const gasLimit = await crucibleContract.estimateGas.transferERC20(
      tokenAddress,
      walletAddress,
      amount
    );
    const gasPrice = await signer.getGasPrice();

    return crucibleContract.transferERC20(tokenAddress, walletAddress, amount, {
      gasLimit: gasLimit,
      gasPrice: gasPrice
    });
  }
};

export const withdrawFromCrucible = createAsyncThunk(
  THUNK_PREFIX.WITHDRAW_FROM_CRUCIBLE,
  async ({
    web3React,
    updateTx,
    toast,
    modal,
    logger,
    crucibleAddress,
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    amount
  }: any) => {
    // create UUID for tracking this tx
    const txnId = uuid();

    const description = `${formatNumber.token(
      amount,
      tokenDecimals
    )} ${tokenSymbol} from Crucible ${truncate(crucibleAddress)}`;

    updateTx({
      id: txnId,
      type: TxnType.withdrawFromCrucible,
      status: TxnStatus.Initiated,
      description
    });

    try {
      const { library, account, chainId } = web3React;
      const signer = library.getSigner();

      const walletAddress = account;
      const crucibleContract = new ethers.Contract(
        crucibleAddress,
        crucibleAbi,
        signer
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingApproval,
        account,
        chainId
      });

      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        render: () => (
          <TxnPendingApprovals description='Confirm token withdrawl transaction' />
        )
      });

      const withdrawTx = await getTx(
        crucibleContract,
        walletAddress,
        amount,
        tokenAddress,
        signer
      );

      updateTx({
        id: txnId,
        status: TxnStatus.PendingOnChain,
        hash: withdrawTx.hash
      });

      await withdrawTx.wait(1);

      updateTx({
        id: txnId,
        status: TxnStatus.Mined,
        hash: withdrawTx.hash
      });
    } catch (error) {
      const errorMessage = parseTransactionError(error);
      toast.closeAll();
      toast({
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        // @ts-ignore
        render: ({ onClose }) => (
          <TxnError
            onClose={onClose}
            modal={modal}
            errorMessage={errorMessage}
            error={error}
          />
        )
      });

      updateTx({
        id: txnId,
        status: TxnStatus.Failed,
        error
      });

      logger.push(error);

      // trigger redux toolkit's rejected.match hook
      throw error;
    }
  }
);
