import { FC } from 'react';
import { useWeb3React } from 'hooks/web3';
import { Flex, VStack, Badge, Text } from '@chakra-ui/layout';
import { useRewardPrograms } from 'store/zustand/useRewardPrograms';
import { useModal } from 'store/modals';
import { RewardProgram } from 'types';
import { ModalType } from 'components/modals/types';
import { useHistory } from 'react-router-dom';

type Props = {
  rewardProgram: RewardProgram;
  isExpired: (rp: RewardProgram) => boolean;
  daysLeft: (expiryTimestamp?: number) => string;
  isLeaderboard: boolean;
};

const RewardProgramSearchCard: FC<Props> = ({
  rewardProgram,
  isExpired,
  daysLeft,
  isLeaderboard
}) => {
  const { closeModal } = useModal();
  const { chainId = 1 } = useWeb3React();
  const { currentRewardProgram, setCurrentRewardProgram } = useRewardPrograms();
  const history = useHistory();
  return (
    <Flex
      py={2}
      bg='whiteAlpha.100'
      borderRadius='md'
      alignItems='center'
      justifyContent='center'
      key={rewardProgram.name}
      borderWidth={
        rewardProgram.address === currentRewardProgram.address ? '1px' : '0px'
      }
      borderColor={'cyan.500'}
      role='button'
      onClick={() => {
        isLeaderboard
          ? history.push(`/leaderboard/${rewardProgram.address}`)
          : setCurrentRewardProgram(chainId, rewardProgram);
        closeModal(ModalType.selectProgram);
      }}
      _hover={{ background: 'whiteAlpha.200' }}
    >
      <VStack width='90%'>
        <Text maxW='100%' isTruncated>
          {rewardProgram.name}
        </Text>
        {isExpired(rewardProgram) ? (
          <Badge colorScheme='red'>Ended</Badge>
        ) : (
          <Badge>{daysLeft(rewardProgram.expiryTimestamp)}</Badge>
        )}
      </VStack>
    </Flex>
  );
};

export default RewardProgramSearchCard;
