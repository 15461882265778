import { Link as ChakraLink, Stack } from '@chakra-ui/react';
import { useCrucibles } from 'hooks/query/useCrucibles';
import { Link, useLocation } from 'react-router-dom';
import { useRewardPrograms } from 'store/zustand/useRewardPrograms';
import { useSelectedCrucible } from 'store/selectedCrucible';
import { useSideDrawer } from 'store/sideDrawer';

const MobileNavLinks = () => {
  const { data: crucibles } = useCrucibles();
  const { selectedCrucible } = useSelectedCrucible();
  const { currentRewardProgram } = useRewardPrograms();
  const { pathname } = useLocation();
  const { closeDrawer } = useSideDrawer();

  const isGovernanceRoute =
    pathname.match(/^\/governance\/(\S+)$/) || pathname.match('/governance');

  const linkProps = {
    as: Link,
    px: '22px',
    onClick: closeDrawer,
    borderLeftWidth: 2,
    _focus: {
      textDecor: 'none'
    }
  };

  const isCruciblesRoute = pathname.match(/^\/crucible\/(\S+)$/);
  const isExploreRoute =
    pathname.match(/^\/explore\/(\S+)$/) || pathname.match('/explore');

  return (
    <Stack>
      <ChakraLink
        to='/reward-programs'
        borderLeftColor={
          pathname === '/reward-programs' ? 'cyan.500' : 'gray.800'
        }
        color={pathname === '/reward-programs' ? 'white' : 'gray.400'}
        {...linkProps}
      >
        Reward Programs
      </ChakraLink>
      <ChakraLink
        to={`/leaderboard/${currentRewardProgram.address}`}
        borderLeftColor={
          pathname.includes('leaderboard') ? 'cyan.500' : 'gray.800'
        }
        color={pathname.includes('leaderboard') ? 'white' : 'gray.400'}
        {...linkProps}
      >
        Leaderboard
      </ChakraLink>
      <ChakraLink
        to='/governance'
        borderLeftColor={isGovernanceRoute ? 'cyan.500' : 'gray.800'}
        color={isGovernanceRoute ? 'white' : 'gray.400'}
        {...linkProps}
      >
        Governance
      </ChakraLink>
      <ChakraLink
        to='/mint'
        borderLeftColor={pathname === '/mint' ? 'cyan.500' : 'gray.800'}
        color={pathname === '/mint' ? 'white' : 'gray.400'}
        {...linkProps}
      >
        Mint a Crucible
      </ChakraLink>
      <ChakraLink
        to='/explore'
        borderLeftColor={isExploreRoute ? 'cyan.500' : 'gray.800'}
        color={isExploreRoute ? 'white' : 'gray.400'}
        {...linkProps}
      >
        Explore
      </ChakraLink>
      <ChakraLink
        to={
          selectedCrucible
            ? `/crucible/${selectedCrucible.id}`
            : !!crucibles?.length
            ? `/crucible/${crucibles[0].id}`
            : '/crucible/not-found'
        }
        borderLeftColor={isCruciblesRoute ? 'cyan.500' : 'gray.800'}
        color={isCruciblesRoute ? 'white' : 'gray.400'}
        {...linkProps}
      >
        Your Crucibles
      </ChakraLink>
    </Stack>
  );
};

export default MobileNavLinks;
