import { aludelAbiV2, aludelAbi, geyserAbiV2 } from 'abi/aludelAbi';
import { powerSwitchFactoryAbi } from 'abi/powerSwitchFactory';
import { rewardPoolAbi } from 'abi/rewardPoolAbi';
import { TemplateName } from 'types';

export const mapTemplateNameToAludelAbi: { [key in TemplateName]: any } = {
  [TemplateName.AludelV1]: aludelAbi,
  [TemplateName.GeyserV2]: geyserAbiV2,
  [TemplateName.AludelV2]: aludelAbiV2
};

export const mapTemplateNameToPowerswitchAbi: { [key in TemplateName]: any } = {
  [TemplateName.AludelV1]: powerSwitchFactoryAbi,
  [TemplateName.GeyserV2]: powerSwitchFactoryAbi,
  [TemplateName.AludelV2]: powerSwitchFactoryAbi
};

export const mapTemplateNameToRewardpoolAbi: { [key in TemplateName]: any } = {
  [TemplateName.AludelV1]: rewardPoolAbi,
  [TemplateName.GeyserV2]: rewardPoolAbi,
  [TemplateName.AludelV2]: rewardPoolAbi
};

export const mapTemplateNameToDataMethod: { [key in TemplateName]: any } = {
  [TemplateName.AludelV1]: 'getAludelData()',
  [TemplateName.GeyserV2]: 'getGeyserData()',
  [TemplateName.AludelV2]: 'getAludelData()'
};

export const mapTemplateNameToFundMethod: { [key in TemplateName]: any } = {
  [TemplateName.AludelV1]: 'fund',
  [TemplateName.GeyserV2]: 'fundGeyser',
  [TemplateName.AludelV2]: 'fund'
};

export const mapTemplateNameToVersion: { [key in TemplateName]: any } = {
  [TemplateName.AludelV1]: 1,
  [TemplateName.GeyserV2]: 1.5,
  [TemplateName.AludelV2]: 1.5
};

export const mapTemplateNameToDescription: { [key in TemplateName]: any } = {
  [TemplateName.AludelV1]: 'Aludel V1. Not available for external use.',
  [TemplateName.GeyserV2]:
    'Ampleforth Geyser V2. Not available for external use.',
  [TemplateName.AludelV2]:
    'Supports multiplier, fund release schedules and up to 51 reward tokens. No locking periods or vesting.'
};

export const mapTemplateNameToName: { [key in TemplateName]: any } = {
  [TemplateName.AludelV1]: 'Aludel V1',
  [TemplateName.GeyserV2]: 'Geyser V2',
  [TemplateName.AludelV2]: 'Standard Aludel - 1% fee'
};
