import { FC } from 'react';
import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';

type StyleProps = {
  flexGrow?: number;
  fontWeight?: string;
  px?: number;
  py?: number;
  checkedBg?: string;
  checkedColor?: string;
};

const RadioCard: FC<UseRadioProps & StyleProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label' flexGrow={props.flexGrow || 1} flexBasis={0}>
      <input {...input} />
      <Box
        {...checkbox}
        color='gray.400'
        fontWeight={props.fontWeight || ''}
        cursor='pointer'
        borderWidth='1px'
        boxShadow='md'
        borderRadius='xl'
        textAlign='center'
        height='100%'
        _checked={{
          color: props.checkedColor ? props.checkedColor : 'white',
          borderColor: 'cyan.400',
          bg: props.checkedBg ? props.checkedBg : ''
        }}
        px={props.px || 0}
        py={props.py || 1}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default RadioCard;
