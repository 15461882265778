import { __PROD__ } from 'utils/constants';
import { configureStore, Middleware } from '@reduxjs/toolkit';
import configReducer from 'store/config';
import modalsReducer from 'store/modals';
import selectedCrucibleReducer from 'store/selectedCrucible';
import selectedAssetsReducer from 'store/selectedAssets';
import transactionsReducer from 'store/transactions/reducer';
import featureFlagReducer from 'store/featureFlag';
import sideDrawerReducer from 'store/sideDrawer';
import connectionReducer from 'store/connection';

const customMiddleware: Middleware[] = !__PROD__ ? [] : [];

export const store = configureStore({
  reducer: {
    config: configReducer,
    modals: modalsReducer,
    selectedCrucible: selectedCrucibleReducer,
    selectedAssets: selectedAssetsReducer,
    transactions: transactionsReducer,
    featureFlag: featureFlagReducer,
    sideDrawer: sideDrawerReducer,
    connection: connectionReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(customMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
