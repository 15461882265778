import { FC } from 'react';
import { useWeb3React } from 'hooks/web3';
import { BiChevronDown } from 'react-icons/bi';
import { MenuDivider, MenuGroup, useToast } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useNetworkState } from 'store/zustand/useLocalNetwork';
import {
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  TagProps
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import { commonConfig } from 'store/initialisers/config';
import { walletConfig, walletConfigInterface } from 'store/walletConfig';

const NetworkPill: FC<TagProps> = () => {
  const toast = useToast();
  const { chainId = 1, account } = useWeb3React();
  const { localChainId, setLocalChainId } = useNetworkState();
  const { supportedChainIds } = commonConfig;

  const handleConnect = async (
    chainId: number,
    chainDetails: walletConfigInterface
  ) => {
    setLocalChainId(chainId);

    const chainIdToHexValueString = ethers.utils
      .hexValue(ethers.utils.hexlify(chainId))
      .toString();

    if (!account) {
      return;
    }

    //@ts-ignore
    if (!window.ethereum) {
      toast({
        title: 'Please connect your wallet first',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      return;
    }
    try {
      //@ts-ignore
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdToHexValueString }]
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          //@ts-ignore
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chainIdToHexValueString,
                chainName: chainDetails.chainName,
                nativeCurrency: {
                  name: chainDetails.chainTokenName,
                  symbol: chainDetails.chainTokenSymbol,
                  decimals: chainDetails.chainTokenDecimals
                },
                rpcUrls: chainDetails.rpcUrls,
                blockExplorerUrls: chainDetails.blockExplorerUrls
              }
            ]
          });
        } catch (addError) {
          toast({
            title: 'Failed to add network',
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          return;
        }
      }
      toast({
        title: 'Error switching network',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<BiChevronDown />}
        sx={{
          color: 'white',
          background: 'gray.800',
          bgGradient: 'none',
          _hover: {
            background: 'gray.600'
          },
          _active: {
            background: 'gray.600'
          }
        }}
      >
        <Stack direction='row' alignItems='center'>
          <Image
            boxSize='1.5rem'
            src={walletConfig[chainId].iconPath}
            alt={walletConfig[chainId].chainName}
          />
          {!isMobile && <span>{walletConfig[chainId].chainName}</span>}
        </Stack>
      </MenuButton>
      <MenuList zIndex={999} minHeight='48px'>
        <MenuGroup>
          {supportedChainIds.map((network) => {
            if (!walletConfig[network].testnet) {
              return (
                <MenuItem
                  value={network}
                  onClick={() => handleConnect(network, walletConfig[network])}
                  bg={localChainId === network ? 'gray.600' : ''}
                >
                  <Image
                    boxSize='1.3rem'
                    src={walletConfig[network].iconPath}
                    alt={walletConfig[network].chainName}
                    mr='12px'
                  />
                  <span>{walletConfig[network].chainName}</span>
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title='testnets'>
          {supportedChainIds.map((network) => {
            if (walletConfig[network].testnet) {
              return (
                <MenuItem
                  value={network}
                  onClick={() => handleConnect(network, walletConfig[network])}
                  bg={localChainId === network ? 'gray.600' : ''}
                >
                  <Image
                    boxSize='1.5rem'
                    src={walletConfig[network].iconPath}
                    alt={walletConfig[network].chainName}
                    mr='12px'
                  />
                  <span>{walletConfig[network].chainName}</span>
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default NetworkPill;
