import {
  Box,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Spinner
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import { FC, useEffect, useState } from 'react';
import { useModal } from 'store/modals';
import { ProgramToken } from 'types';
import { ModalType } from './types';
import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
import { useWeb3React } from 'hooks/web3';

type Props = {
  tokenList: ProgramToken[];
  onSelectToken: (token: ProgramToken) => any;
};

const SelectTokenModal: FC<Props> = ({ tokenList, onSelectToken }) => {
  const { closeModal } = useModal();
  const [searchQuery, setSearchQuery] = useState('');
  const [displayedTokens, setDisplayedTokens] = useState<ProgramToken[]>([]);
  const [tokenLoading, setTokenLoading] = useState(false);
  const [error, setError] = useState('');

  const { provider } = useWeb3React();

  useEffect(() => {
    setError('');
    if (searchQuery !== '') {
      const tokensFromList = tokenList.filter(
        (token) =>
          token.tokenSymbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
          token.address.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (tokensFromList.length) {
        setDisplayedTokens(tokensFromList.slice(0, 10));
      } else if (ethers.utils.isAddress(searchQuery)) {
        setTokenLoading(true);
        const contract = new ethers.Contract(
          searchQuery,
          IUniswapV2ERC20.abi,
          provider
        );

        Promise.all([contract.symbol(), contract.decimals()])
          .then((values) => {
            setTokenLoading(false);
            setDisplayedTokens([
              {
                address: searchQuery,
                tokenSymbol: values[0],
                decimals: values[1]
              }
            ]);
          })
          .catch((e) => {
            console.log(e);
            setTokenLoading(false);
          });
      } else {
        setError('No tokens found');
        setDisplayedTokens([]);
      }
    } else {
      setDisplayedTokens([]);
    }
  }, [searchQuery, tokenList, setDisplayedTokens, provider]);

  return (
    <Modal isOpen={true} onClose={() => closeModal(ModalType.selectToken)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select token</ModalHeader>
        <ModalCloseButton />
        <ModalBody px={0}>
          <Box px={6} mb={4}>
            <Input
              type='text'
              size='lg'
              placeholder='Search token by name or address'
              disabled={tokenLoading}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {error && (
              <Text my={4} color='gray.200'>
                {error}
              </Text>
            )}
          </Box>
          <Box
            px={6}
            mb={4}
            textAlign='center'
            display={!tokenLoading ? 'none' : ''}
          >
            <Spinner />
          </Box>
          {displayedTokens.map((token) => (
            <Box
              key={token.address}
              w='100%'
              p={4}
              _hover={{ cursor: 'pointer', background: 'gray.600' }}
              onClick={() => onSelectToken(token)}
            >
              <Text>{token.tokenSymbol.toUpperCase()}</Text>
              <Text fontSize='small' color='gray.400'>
                {token.address}
              </Text>
            </Box>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectTokenModal;
