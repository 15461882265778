import { useWeb3React } from 'hooks/web3';
import { getContainedAssets } from 'helpers/wallet';
import { useQuery } from 'react-query';
import { useConfig } from 'store/config';
import { ERC20Token } from 'types';
import { Queries } from 'types/enum';
import { useTokenBlacklist } from './useTokenBlacklist';

export function useWalletAssets() {
  const { account, chainId = 1, provider } = useWeb3React();
  const { etherscanApiKey, polygonscanApiKey, snowtraceApiKey } = useConfig(chainId).commonConfig;
  const { data: tokenBlacklist } = useTokenBlacklist();

  return useQuery<ERC20Token[]>(
    [Queries.WALLET_ASSETS, account, chainId],
    () =>
      getContainedAssets(
        account!,
        chainId,
        etherscanApiKey,
        polygonscanApiKey,
        snowtraceApiKey,
        provider,
        false
      ),
    {
      enabled: !!account && !!tokenBlacklist,
    }
  );
}
