import { FC, useEffect, useMemo, useState, useRef } from 'react';
import { Button, Text, Alert, AlertIcon, Spinner } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import { useTransactions } from 'store/transactions/useTransactions';
import { useModal } from 'store/modals';
import { BigNumber } from 'ethers';
import {
  Crucible,
  ProgramToken,
  RewardProgram,
  RewardProgramTokens
} from 'types';
import { Tab, TabList, Tabs } from '@chakra-ui/tabs';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { sanitize } from 'utils/sanitize';
import { isMobile } from 'react-device-detect';
import { TxnStatus, TxnType } from 'store/transactions/types';
import CustomInput from 'components/shared/CustomInput';
import { ModalType } from './types';
import { ZeroBalance } from 'components/rewardPrograms/ZeroBalance';

type Props = {
  crucible: Crucible;
  stakingToken: ProgramToken;
  stakingTokenCrucibleBalance: BigNumber;
  stakingTokenWalletBalance: BigNumber;
  stakingTokenDecimals: number;
  currentRewardProgram: RewardProgram;
  rewardProgramTokens: RewardProgramTokens;
};

const AddSubscriptionModal: FC<Props> = ({
  crucible,
  stakingToken,
  stakingTokenCrucibleBalance,
  stakingTokenWalletBalance,
  stakingTokenDecimals,
  currentRewardProgram,
  rewardProgramTokens
}) => {
  const { addSubscription } = useTransactions();
  const { closeModal } = useModal();
  const focusRef = useRef<HTMLInputElement>(null);
  const { transactions } = useTransactions();

  const [amountToSubscribe, setAmountToSubscribe] = useState('');
  const [stakingTokenBalance, setStakingTokenBalance] = useState(
    stakingTokenCrucibleBalance
  );

  const [tabIndex, setTabIndex] = useState(
    stakingTokenCrucibleBalance.isZero() ? 1 : 0
  );
  const radioValue = useMemo(() => {
    return tabIndex === 1 ? 'wallet' : 'crucible';
  }, [tabIndex]);

  const tabProps = {
    borderRadius: 'lg',
    fontWeight: '600',
    fontSize: ['14px', '16px'],
    _selected: { color: 'purple.800', bg: 'cyan.400' },
    marginBottom: '1px'
  };

  useEffect(() => {
    if (stakingToken.address) {
      getStakingTokenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioValue, stakingToken.address]);

  const isPendingTx = useMemo(() => {
    return (
      transactions.filter(
        (txn) =>
          txn.status === TxnStatus.PendingOnChain &&
          (txn.type === TxnType.addSubscription ||
            txn.type === TxnType.unsubscribe) &&
          txn.crucibleAddress === crucible.id
      ).length > 0
    );
  }, [transactions, crucible.id]);

  const isStakeTxnPending =
    transactions.filter((txn) => {
      return (
        txn.type === TxnType.addSubscription &&
        txn.status === TxnStatus.PendingOnChain &&
        txn.crucibleAddress === crucible.id
      );
    }).length > 0;

  const getStakingTokenBalance = async () => {
    if (radioValue === 'crucible') {
      setStakingTokenBalance(stakingTokenCrucibleBalance);
    } else {
      setStakingTokenBalance(stakingTokenWalletBalance);
    }
  };

  const handleAddSubscription = () => {
    addSubscription(
      parseUnits(amountToSubscribe, stakingTokenDecimals),
      crucible.id,
      radioValue,
      currentRewardProgram,
      stakingToken
    );
    closeModal(ModalType.addSubscription);
    window.scrollTo(0, 0);
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal(ModalType.addSubscription)}
      initialFocusRef={focusRef}
      size={isMobile ? 'full' : 'md'}
      scrollBehavior={isMobile ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={isMobile ? 'none' : 'xl'}
        mt={isMobile ? 0 : undefined}
        bg='gray.800'
      >
        <ModalHeader>Add subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isPendingTx && (
            <Alert status='error' mb={4} borderRadius='xl'>
              <AlertIcon marginTop={-16} />
              There is a pending transaction on this Crucible. Please wait until
              it has finished before adding another subscription to avoid the
              transaction failing.
            </Alert>
          )}
          <Text mb={6} fontWeight='300'>
            Add a subscription to the {currentRewardProgram.name} Rewards
            program by locking {stakingToken.tokenSymbol} tokens in your
            Crucible.
          </Text>
          <Text>Select your source of funds to subscribe:</Text>
          <Tabs
            index={tabIndex}
            isFitted
            defaultIndex={tabIndex}
            onChange={(index) => {
              setTabIndex(index);
              setAmountToSubscribe('');
            }}
            mb={6}
            size='sm'
          >
            <TabList borderRadius='xl' my={2} border='1px solid' padding='5px'>
              <Tab {...tabProps}>Crucible</Tab>
              <Tab {...tabProps}>Wallet</Tab>
            </TabList>
          </Tabs>
          {stakingTokenBalance.isZero() && (
            <ZeroBalance
              rewardProgram={currentRewardProgram}
              rewardProgramTokens={rewardProgramTokens}
            />
          )}
          <CustomInput
            inputRef={focusRef}
            max={formatUnits(stakingTokenBalance, stakingTokenDecimals)}
            value={amountToSubscribe}
            tokenDecimals={stakingTokenDecimals}
            onUserInput={(input) => setAmountToSubscribe(input)}
            showRadioGroup
            showMaxButton
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isFullWidth
            onClick={handleAddSubscription}
            isDisabled={
              parseUnits(sanitize(amountToSubscribe), stakingTokenDecimals).lte(
                0
              ) ||
              parseUnits(sanitize(amountToSubscribe), stakingTokenDecimals).gt(
                stakingTokenBalance
              )
            }
          >
            {!isStakeTxnPending && 'Add subscription'}
            {isStakeTxnPending && (
              <span>
                Pending <Spinner ml={2} size='sm' />
              </span>
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSubscriptionModal;
