import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from 'store/enum';
import { TxnDetails, TxnState, TxnStatus } from 'store/transactions/types';

export const initialState: TxnState = {
  transactions:
    typeof window === 'undefined' || window !== window.parent
      ? []
      : JSON.parse(localStorage.getItem('transactions') || '[]')
};

export const transactionsSlice = createSlice({
  name: SLICE_NAME.TRANSACTIONS,
  initialState,
  reducers: {
    clearSavedTransactions: (state, action) => {
      const chainId = action.payload;
      const currentTransactions = JSON.parse(localStorage.getItem('transactions') || '[]');
      const filteredTransactions = currentTransactions.filter((t: any) => t.chainId !== chainId);

      localStorage.setItem('transactions', JSON.stringify(filteredTransactions));
      state.transactions = [filteredTransactions];
    },
    reloadTransactions: (state) => {
      const savedTxns: TxnDetails[] = JSON.parse(
        localStorage.getItem('transactions') || '[]'
      );

      localStorage.setItem('transactions', JSON.stringify(savedTxns));
      state.transactions = savedTxns;
    },
    setTransactionStatus: (state, action: PayloadAction<TxnDetails>) => {
      const currentTransaction =
        state.transactions.filter((txn) => txn.id === action.payload.id)[0] ||
        {};

      const updatedTransaction = {
        ...currentTransaction,
        ...(action.payload || {})
      };

      const savedTxns: TxnDetails[] = JSON.parse(
        localStorage.getItem('transactions') || '[]'
      );
      const indexToUpdate = savedTxns.findIndex(
        (t) => t.id === updatedTransaction.id
      );

      // push to local storage if the txn isn't saved there
      // and it's not an inflight, but cleared txn
      if (
        indexToUpdate === -1 &&
        updatedTransaction.status === TxnStatus.Initiated
      ) {
        savedTxns.push(updatedTransaction);
      } else {
        // otherwise update the transaction
        savedTxns[indexToUpdate] = updatedTransaction;
      }

      localStorage.setItem('transactions', JSON.stringify(savedTxns));
      state.transactions = savedTxns;
    }
  }
});

export default transactionsSlice.reducer;
