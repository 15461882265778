const BLOCK_EXPLORER_URL: { [chainId: number]: string } = {
  1: 'etherscan.io',
  5: 'goerli.etherscan.io',
  42: 'kovan.etherscan.io',
  137: 'polygonscan.com',
  80001: 'mumbai.polygonscan.com',
  43114: 'snowtrace.io',
  43113: 'testnet.snowtrace.io'
};

export enum EtherscanLinkType {
  TRANSACTION = 'transaction',
  ADDRESS = 'address'
}

export function getEtherscanLink(
  chainId: number = 1,
  address: string,
  type: EtherscanLinkType
): string {
  const baseUrl = `https://${BLOCK_EXPLORER_URL[chainId]}`;

  switch (type) {
    case EtherscanLinkType.TRANSACTION:
      return `${baseUrl}/tx/${address}`;
    case EtherscanLinkType.ADDRESS:
      return `${baseUrl}/address/${address}`;
    default:
      return `${baseUrl}`;
  }
}
